import Vue from 'vue'
import { employeeAccount,employeeAccountAdd,employeeAccountDetail,employeeAccountUpdate,employeeAccountRoles,employeeAccountRecovery,employeeAccountDelete,
  employeeAccountResetPassword,employeeAccountQrcode,employeeApply,employeeApplyConfirm,employeeApplyDelelte,employeeOrganization,employeeOrganizationSave,
  employeeOrganizationUpdate,employeeOrganizationDel,employeeOrganizationMove,employeeOrganizationTreeGraph,employeeRole,employeeRolePermission,employeeRoleAdd,
  employeeRoleUpdate,employeeRoleAssign,employeeRoleRemove, employeeGroup, employeeGroupAdd, employeeRule, employeeGroupUpdate, employeeGroupDelete, employeeAccountCleanFace,employeeAccountFace,employeeAccountSetFace,
  employeeGroupRemove, employeeGroupRelatedTo,employeeGroupType,employeeAccountMoveTo,employeePosition,employeePositionUpdate,employeePositionAdd,employeePositionDelete,
  employeeAccountTreeGraph,} from '@/api/employee'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    employeeAccountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeAccountFaceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountFace(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeAccountSetFaceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountSetFace(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeAccountCleanFaceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountCleanFace(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeAccountRolesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountRoles(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountResetPasswordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountResetPassword(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountQrcodeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountQrcode(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeAccountMoveToAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountMoveTo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeAccountTreeGraphAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeAccountTreeGraph(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeApplyConfirmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeApplyConfirm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeApplyDelelteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeApplyDelelte(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeOrganizationAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganization(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeOrganizationSaveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganizationSave(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeOrganizationUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganizationUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeOrganizationDelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganizationDel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeOrganizationMoveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganizationMove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeOrganizationTreeGraphAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeOrganizationTreeGraph(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeRoleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRole(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeRolePermissionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRolePermission(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeRoleAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRoleAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeRoleUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRoleUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeRoleAssignAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRoleAssign(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    employeeRoleRemoveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRoleRemove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeRuleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeRule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupRemoveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupRemove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupRelatedToAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupRelatedTo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeeGroupTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeeGroupType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeePositionDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeePositionDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeePositionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeePosition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeePositionUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeePositionUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    employeePositionAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        employeePositionAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

  }
}
