import Vue from 'vue'
import { oaIndex, oaAdd, oaDetail, oaUpdate, oaDelete, stageIndex, stageExport, stageAttend, stageNoShow, oaSeen,oaAnnounceIndex,oaAnnounceAdd,oaAnnounceSeen,stageAttendScheduleExport,stageAttendSchedule,
  oaAddComment,journalTodayCrm,oaExamineAdd,oaExamineIndex,oaExamineDetail,oaExamineUpdate,oaExamineRevoke,oaExamineCheck,oaExamineCheckRecord,journalRecord,journalExport,
  oaExamineStep,oaTaskIndex,oaTaskAdd,oaTaskDeatil,oaTaskUpdate,oaTaskDelete,oaTaskCheck,oaTaskLog,oaTaskAddLog,oaTaskReOrder,oaUpgradeLog} from '@/api/journal'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    oaIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaSeenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaSeen(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaAddCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaAddComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    journalTodayCrmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        journalTodayCrm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    journalRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        journalRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    journalExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        journalExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaAnnounceIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaAnnounceIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaAnnounceAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaAnnounceAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaAnnounceSeenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaAnnounceSeen(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    stageIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    stageExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    stageAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    stageAttendScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageAttendSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    stageAttendScheduleExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageAttendScheduleExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    stageNoShowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stageNoShow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaExamineAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaExamineIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaExamineDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaExamineUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaExamineRevokeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineRevoke(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaExamineCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaExamineCheckRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineCheckRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaExamineStepAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaExamineStep(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaTaskIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaTaskAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaTaskDeatilAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskDeatil(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    oaTaskUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaTaskDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaTaskCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaTaskLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaTaskAddLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskAddLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaTaskReOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaTaskReOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    oaUpgradeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        oaUpgradeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
