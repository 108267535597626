import url from '@/utils/URL'
import request from '@/utils/request'

export function storeProductCategory(params) {
  return request({
    url: url.storeProductCategory,
    method: 'POST',
    ...params
  })
}

export function storeProductCategoryAdd(params) {
  return request({
    url: url.storeProductCategoryAdd,
    method: 'POST',
    ...params
  })
}


export function storeSuppliers(params) {
  return request({
    url: url.storeSuppliers,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersStock(params) {
  return request({
    url: url.storeSuppliersStock,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersStockDelete(params) {
  return request({
    url: url.storeSuppliersStockDelete,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersStockIn(params) {
  return request({
    url: url.storeSuppliersStockIn,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersDelete(params) {
  return request({
    url: url.storeSuppliersDelete,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersUpdate(params) {
  return request({
    url: url.storeSuppliersUpdate,
    method: 'POST',
    ...params
  })
}

export function storeSuppliersAdd(params) {
  return request({
    url: url.storeSuppliersAdd,
    method: 'POST',
    ...params
  })
}

export function storeProductCategoryDetail(params) {
  return request({
    url: url.storeProductCategoryDetail,
    method: 'POST',
    ...params
  })
}

export function storeProductCategoryUpdate(params) {
  return request({
    url: url.storeProductCategoryUpdate,
    method: 'POST',
    ...params
  })
}

export function storeProductCategoryDelete(params) {
  return request({
    url: url.storeProductCategoryDelete,
    method: 'POST',
    ...params
  })
}

export function storeProductSpec(params) {
  return request({
    url: url.storeProductSpec,
    method: 'POST',
    ...params
  })
}

export function storeProductSpecAdd(params) {
  return request({
    url: url.storeProductSpecAdd,
    method: 'POST',
    ...params
  })
}

export function storeProductSpecDetail(params) {
  return request({
    url: url.storeProductSpecDetail,
    method: 'POST',
    ...params
  })
}

export function storeProductSpecUpdate(params) {
  return request({
    url: url.storeProductSpecUpdate,
    method: 'POST',
    ...params
  })
}

export function storeProductSpecDelete(params) {
  return request({
    url: url.storeProductSpecDelete,
    method: 'POST',
    ...params
  })
}

export function storeProductSpecDeleteDet(params) {
  return request({
    url: url.storeProductSpecDeleteDet,
    method: 'POST',
    ...params
  })
}

export function storeProduct(params) {
  return request({
    url: url.storeProduct,
    method: 'POST',
    ...params
  })
}

export function storeProductAdd(params) {
  return request({
    url: url.storeProductAdd,
    method: 'POST',
    ...params
  })
}

export function storeProductDetail(params) {
  return request({
    url: url.storeProductDetail,
    method: 'POST',
    ...params
  })
}

export function storeProductSpcs(params) {
  return request({
    url: url.storeProductSpcs,
    method: 'POST',
    ...params
  })
}

export function storeProductUpdate(params) {
  return request({
    url: url.storeProductUpdate,
    method: 'POST',
    ...params
  })
}

export function storeProductDelete(params) {
  return request({
    url: url.storeProductDelete,
    method: 'POST',
    ...params
  })
}

export function storeProductRecovery(params) {
  return request({
    url: url.storeProductRecovery,
    method: 'POST',
    ...params
  })
}

export function storeOrder(params) {
  return request({
    url: url.storeOrder,
    method: 'POST',
    ...params
  })
}

export function storeOrderDetail(params) {
  return request({
    url: url.storeOrderDetail,
    method: 'POST',
    ...params
  })
}

export function storeOrderExpress(params) {
  return request({
    url: url.storeOrderExpress,
    method: 'POST',
    ...params
  })
}

export function storeOrderExpressCode(params) {
  return request({
    url: url.storeOrderExpressCode,
    method: 'POST',
    ...params
  })
}

export function storeOrderDelete(params) {
  return request({
    url: url.storeOrderDelete,
    method: 'POST',
    ...params
  })
}

export function storeRefund(params) {
  return request({
    url: url.storeRefund,
    method: 'POST',
    ...params
  })
}

export function storeCoupon(params) {
  return request({
    url: url.storeCoupon,
    method: 'POST',
    ...params
  })
}

export function storeCouponAdd(params) {
  return request({
    url: url.storeCouponAdd,
    method: 'POST',
    ...params
  })
}

export function storeCouponDetail(params) {
  return request({
    url: url.storeCouponDetail,
    method: 'POST',
    ...params
  })
}

export function storeCouponUpdate(params) {
  return request({
    url: url.storeCouponUpdate,
    method: 'POST',
    ...params
  })
}

export function storeCouponDelete(params) {
  return request({
    url: url.storeCouponDelete,
    method: 'POST',
    ...params
  })
}

export function storeCouponSend(params) {
  return request({
    url: url.storeCouponSend,
    method: 'POST',
    ...params
  })
}

export function storeComment(params) {
  return request({
    url: url.storeComment,
    method: 'POST',
    ...params
  })
}

export function storeCommentDelete(params) {
  return request({
    url: url.storeCommentDelete,
    method: 'POST',
    ...params
  })
}

export function storeCredit(params) {
  return request({
    url: url.storeCredit,
    method: 'POST',
    ...params
  })
}

export function storeCreditAdd(params) {
  return request({
    url: url.storeCreditAdd,
    method: 'POST',
    ...params
  })
}

export function storeCreditDelete(params) {
  return request({
    url: url.storeCreditDelete,
    method: 'POST',
    ...params
  })
}