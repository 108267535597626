<template>
    <img :src="newSrc" class="common-img"/>
    <!-- <a-avatar :src="newSrc"/> -->
</template>

<script>
import comp from "@/common/mixins/comp";
import url from '@/utils/URL'

export default {
  name: 'LImg',
  mixins: [comp],

  props: {
    src: String,
  },
  
  computed: {
    newSrc() {
      if (!this.src) {
        return ''
      }
      if (this.src.startsWith('http')) {
        return this.src
      } else {
        return `${url.imageRoot}${this.src}`
      }
    }
  },
}
</script>
<style lang="scss">
  .common-img{
    border-radius:3.75px;
  }
</style>
