<template>
  <div class="dashboard">
    <div class="clearfix table-tools">
        <div class="search">
          <a-form layout='inline'>
              <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>时间筛选</span>
                    </template>
                    <a-range-picker
                    style="width: 240px"
                    :ranges="rangesData"
                    v-model='searchDatas.promise'
                    allowClear
                    @change="(val,time)=>handleTime(val,time,'promise')" />
                </a-tooltip>
              </a-form-item>
              <a-form-item>
              <a-radio-group :value="searchParams.search.book_status" @change="handleTypeButChange" >
                <a-radio-button value="2">
                    已上课
                </a-radio-button>
                <a-radio-button value="1">
                    待上课
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
              <a-form-item>
                <a-button @click="searchList" type="primary">搜索</a-button>
            </a-form-item>
            <!-- <a-form-item>
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item> -->
          </a-form>
        </div>
    </div> 
    
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1000 }" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="classes_name" slot-scope="class_name, record">
            <a @click="showDetailModal(record,'1')">{{ class_name }}</a>
          </template>  
          <template slot="classDate" slot-scope="text, record">
            <a @click="showStudentModal(record)" v-if="text">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</a>
          </template>
          <!-- <template slot="classDate" slot-scope="text, record, index">
            <span v-if="index+1 == list.length"></span>
            <span v-else>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
          </template> -->
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <detailModal v-if="detailVisible" :item="modalData"/>
    <studentModal v-if="studentVisible" :item="modalData"/>
  </div>
</template>

<script>
import moment from 'moment'
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  // { title: '上课校区', width: '140px', dataIndex: 'studio_id', key: 'name'},
  { title: '上课时间',width: '250px', dataIndex: 'course_date_time', key: 'course_date_time', scopedSlots: { customRender: 'classDate' },sorter:()=>{}},
  { title: '班级名称', dataIndex: 'classes_name', key: 'classes_name',ellipsis:true,scopedSlots: { customRender: 'classes_name' },},
  { title: '课程名称', dataIndex: 'course_name', key: '1' ,ellipsis:true}, 
  { title: '授课老师',width: '120px', dataIndex: 'teacher_name', key: '3' ,align:"center"},
  { title: '课时消耗',width: '120px', dataIndex: 'book_costs', key: '7',align:"center"},
  { title: '课消金额',width: '120px', dataIndex: 'book_balance', key: 'book_balance',align:"center"},
  { title: '课程状态',width: '120px', dataIndex: 'book_status', key: '8',align:"center",sorter:()=>{}},
  { title: '备注', width: '150px',dataIndex: 'book_remark', key: 'book_remark' ,ellipsis:true},
]
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import detailModal from '@/views/classes/bjgl/detail/index'
import studentModal from '@/views/classes/bjgl/detail/bjpk/studentModal'
export default {
  name: 'skjl',
  props: {
    studentId: Number
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {book_status:"2"},
        "sort": ''
      },
      list: [],
      current: 1,
      searchDatas:{},
      visible: false,
      refundVisible: false,
      detailVisible: false,
      studentVisible: false,
      modalData:{},
    }
  },

  mixins:[tableMixins, ranges],
  components: {
    detailModal,
    studentModal,
  },

  methods: {
    moment,
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileCourseClassAction', { data: this.searchParams })
      this.list = res.items
      this.list.push({
        book_id:-1,
        class_start:'',
        classes_name:'',
        teacher_name:'',
        book_costs:res._total.book_costs,
        book_balance:res._total.book_balance,
        book_status:'',
        book_remark:'',
      })
      this.pageParams = res._meta
      this.loading = false
    },
    showDetailModal(item,num) {
      this.modalData = item
      this.modalData.activeIndex = num
      this.detailVisible = true
    },
    hideDetailModal() {
      this.detailVisible = false
    },
    showStudentModal(item) {
      this.modalData = item
      this.studentVisible = true
    },
    hideStudentModal(val) {
      this.studentVisible = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList()
    },
    handleTime(val,time,name){
        this.searchDatas[name] = val
        this.searchParams.search.start_date = time[0]
        this.searchParams.search.end_date = time[1]
    },
    handleTypeButChange(e){
      if(this.searchParams.search.book_status == e.target.value){
        this.searchParams.search.book_status = undefined
      }else{
        this.searchParams.search.book_status = e.target.value  
      }
      this.getList()
    },
  }
}
</script>
