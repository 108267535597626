import url from '@/utils/URL'
import request from '@/utils/request'

export function students(params) {
  return request({
    url: url.students,
    method: 'POST',
    ...params
  })
}

export function studentsSetFace(params) {
  return request({
    url: url.studentsSetFace,
    method: 'POST',
    ...params
  })
}

export function studentsDetail(params) {
  return request({
    url: url.studentsDetail,
    method: 'POST',
    ...params
  })
}
export function studentsAdd(params) {
  return request({
    url: url.studentsAdd,
    method: 'POST',
    ...params
  })
}
export function studentsUpdate(params) {
  return request({
    url: url.studentsUpdate,
    method: 'POST',
    ...params
  })
}
export function studentsDelete(params) {
  return request({
    url: url.studentsDelete,
    method: 'POST',
    ...params
  })
}
export function studentsHistory(params) {
  return request({
    url: url.studentsHistory,
    method: 'POST',
    ...params
  })
}
export function studentsMerge(params) {
  return request({
    url: url.studentsMerge,
    method: 'POST',
    ...params
  })
}
export function studentsWeixinQrcode(params) {
  return request({
    url: url.studentsWeixinQrcode,
    method: 'POST',
    ...params
  })
}
export function studentsExport(data) {
  return request({
    url: url.studentsExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}
export function studentsPotential(params) {
  return request({
    url: url.studentsPotential,
    method: 'POST',
    ...params
  })
}
export function studentsStudentCheck(params) {
  return request({
    url: url.studentsStudentCheck,
    method: 'POST',
    ...params
  })
}
export function studentsRecovery(params) {
  return request({
    url: url.studentsRecovery,
    method: 'POST',
    ...params
  })
}
export function studentsUpdateStatus(params) {
  return request({
    url: url.studentsUpdateStatus,
    method: 'POST',
    ...params
  })
}
export function studentsStudentsFilter(params) {
  return request({
    url: url.studentsStudentsFilter,
    method: 'POST',
    ...params
  })
}


export function studentsProfile(params) {
  return request({
    url: url.studentsProfile,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCredit(params) {
  return request({
    url: url.studentsProfileCredit,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCosts(params) {
  return request({
    url: url.studentsProfileCosts,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCourseClass(params) {
  return request({
    url: url.studentsProfileCourseClass,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCancelClass(params) {
  return request({
    url: url.studentsProfileCancelClass,
    method: 'POST',
    ...params
  })
}

export function studentsProfileBookCosts(params) {
  return request({
    url: url.studentsProfileBookCosts,
    method: 'POST',
    ...params
  })
}

export function studentsProfileClasses(params) {
  return request({
    url: url.studentsProfileClasses,
    method: 'POST',
    ...params
  })
}

export function studentsProfileContact(params) {
  return request({
    url: url.studentsProfileContact,
    method: 'POST',
    ...params
  })
}

export function studentsProfileAddContact(params) {
  return request({
    url: url.studentsProfileAddContact,
    method: 'POST',
    ...params
  })
}

export function studentsProfileContactDetail(params) {
  return request({
    url: url.studentsProfileContactDetail,
    method: 'POST',
    ...params
  })
}

export function studentsProfileUpdateContact(params) {
  return request({
    url: url.studentsProfileUpdateContact,
    method: 'POST',
    ...params
  })
}

export function studentsProfileHomework(params) {
  return request({
    url: url.studentsProfileHomework,
    method: 'POST',
    ...params
  })
}

export function studentsProfileDetail(params) {
  return request({
    url: url.studentsProfileDetail,
    method: 'POST',
    ...params
  })
}

export function studentsProfilePendding(params) {
  return request({
    url: url.studentsProfilePendding,
    method: 'POST',
    ...params
  })
}

export function studentsProfileRefresh(params) {
  return request({
    url: url.studentsProfileRefresh,
    method: 'POST',
    ...params
  })
}

export function studentsProfileWallet(params) {
  return request({
    url: url.studentsProfileWallet,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCertificate(params) {
  return request({
    url: url.studentsProfileCertificate,
    method: 'POST',
    ...params
  })
}

export function studentsProfileWalletContract(params) {
  return request({
    url: url.studentsProfileWalletContract,
    method: 'POST',
    ...params
  })
}

export function studentsProfileContract(params) {
  return request({
    url: url.studentsProfileContract,
    method: 'POST',
    ...params
  })
}

export function studentsProfileContractRefund(params) {
  return request({
    url: url.studentsProfileContractRefund,
    method: 'POST',
    ...params
  })
}

export function studentsProfileCanJoinClasses(params) {
  return request({
    url: url.studentsProfileCanJoinClasses,
    method: 'POST',
    ...params
  })
}

export function studentsProfileAddToClasses(params) {
  return request({
    url: url.studentsProfileAddToClasses,
    method: 'POST',
    ...params
  })
}

export function pendding(params) {
  return request({
    url: url.pendding,
    method: 'POST',
    ...params
  })
}

export function studentsAddCredit(data) {
  return request({
    url: url.studentsAddCredit,
    method: 'POST',
    data
  })
}

export function studentsMinusCredit(data) {
  return request({
    url: url.studentsMinusCredit,
    method: 'POST',
    data
  })
}

export function studentsCoupons(params) {
  return request({
    url: url.studentsCoupons,
    method: 'POST',
    ...params
  })
}