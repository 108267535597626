<template>
  <div class="zcxy">
    <div class="dashboard">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
              <a-form-item>
                <a-radio-group :value="searchParams.search.class_mode">
                  <a-radio-button @click="handleTypeButChange('0')" value="0">
                      试听课
                  </a-radio-button>
                  <a-radio-button @click="handleTypeButChange('1')" value="1">
                      常规课
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item>
                <a-radio-group :value="searchParams.search.book_status">
                  <a-radio-button @click="handleTypeButChangeBooxStatus('2')" value="2">
                      已上课
                  </a-radio-button>
                  <a-radio-button @click="handleTypeButChangeBooxStatus('0')" value="0">
                      已请假
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
          </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="classDate" slot-scope="text, record">
          <span>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a v-if="record.book_status == '待上课'" @click="cancal(record)" href="javascript:;">取消</a>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <!-- <editModal v-if="visible" :item="modalData"/> -->
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', width:'70', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '上课校区', dataIndex: 'studio_id', key: '1' },
  { title: '课程名称', dataIndex: 'course_name', key: '2' },
  { title: '上课时间', dataIndex: 'class_start',scopedSlots: { customRender: 'classDate' } ,key: 'class_start'},
  { title: '老师', dataIndex: 'teacher_name', key: '3' },  
  { title: '课时', dataIndex: 'book_costs', key: 'book_costs' },  
  { title: '类型', dataIndex: 'audition_type', key: '4' },
  { title: '状态', dataIndex: 'book_status', key: '5'},
  { title: '操作', key: 'operation', width: 50, scopedSlots: { customRender: 'action' }}
]

import tableMixins from '@/common/mixins/table'
import moment from 'moment'
// import editModal from './editModal'

export default {
  name: 'audition',
  inject: ['parent'],
  mixins: [ tableMixins ],
  components: {
    // editModal
  },
  props: {
    item: Object,
    leadsId: Number,
    auditionNum: Number,
    isTab:String
  },
  data() {
    return {
      loading: false,
      columns,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

    }
  },
  methods: {
    moment,
    async getList() {
      this.loading = true
      this.searchParams.customer_id = this.item.customer_id
      let res = await this.$store.dispatch('cCustomerAuditionAction',this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    cancal(item){
      let that = this
      this.$confirm({
          title: '您确定取消？？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          zIndex: 4200,
          onOk() {
          return new Promise(async (resolve, reject) => {
              await that.$store.dispatch('auditionCancelAction', {data:{book_id:item.book_id}})
              .then(res=>{
                  that.$message.success('操作成功~')
                  that.getList()
                  that.parent.getDetail()
                  resolve(res)
              })
              .catch(err=>{
                  console.log(err)
                  that.$message.error('操作失败~')
              })
          }).catch(error => console.log(error))
          }
      })
    },
    handleTypeButChange(e){
      if(this.searchParams.search.class_mode == e){
        this.searchParams.search.class_mode = undefined
      }else{
        this.searchParams.search.class_mode = e
      }
      this.getList()
    },
    handleTypeButChangeBooxStatus(e){
      if(this.searchParams.search.book_status == e){
        this.searchParams.search.book_status = undefined
      }else{
        this.searchParams.search.book_status = e
      }
      this.getList()
    },
  },
  watch: {
    leadsId(newValue, oldValue) {
        this.pageParams.page = 1;
        this.list = []
        this.getList()
    },
    auditionNum(){
      this.pageParams.page = 1;
      this.list = []
      this.getList()
    }

  },
}
</script>
