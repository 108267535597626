import url from '@/utils/URL'
import request from '@/utils/request'

export function toolsQrcode(params) {
  return request({
    url: url.toolsQrcode,
    method: 'POST',
    ...params
  })
}


export function toolsClick(params) {
  return request({
    url: url.toolsClick,
    method: 'POST',
    ...params
  })
}

export function toolsShorturl(params) {
  return request({
    url: url.toolsShorturl,
    method: 'POST',
    ...params
  })
}

export function toolsSharePosterAdd(params) {
  return request({
    url: url.toolsSharePosterAdd,
    method: 'POST',
    ...params
  })
}

export function toolsSharePosterDetail(params) {
  return request({
    url: url.toolsSharePosterDetail,
    method: 'POST',
    ...params
  })
}

