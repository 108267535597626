import url from '@/utils/URL'
import request from '@/utils/request'

export function financeRefund(params) {
  return request({
    url: url.financeRefund,
    method: 'POST',
    ...params
  })
}

export function paymentRefundExport(params) {
  return request({
    url: url.paymentRefundExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function financeRefundCard(params) {
  return request({
    url: url.financeRefundCard,
    method: 'POST',
    ...params
  })
}

export function financeRefundList(params) {
  return request({
    url: url.financeRefundList,
    method: 'POST',
    ...params
  })
}

export function financeRefundExport(params) {
  return request({
    url: url.financeRefundExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function financeRefundView(params) {
  return request({
    url: url.financeRefundView,
    method: 'POST',
    ...params
  })
}

export function financeRefundApply(params) {
  return request({
    url: url.financeRefundApply,
    method: 'POST',
    ...params
  })
}

export function financeInvoice(params) {
  return request({
    url: url.financeInvoice,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceUpdate(params) {
  return request({
    url: url.financeInvoiceUpdate,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceCheck(params) {
  return request({
    url: url.financeInvoiceCheck,
    method: 'POST',
    ...params
  })
}

export function financeInvoicePullback(params) {
  return request({
    url: url.financeInvoicePullback,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceMultiAction(params) {
  return request({
    url: url.financeInvoiceMultiAction,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceSwitch(params) {
  return request({
    url: url.financeInvoiceSwitch,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceDetail(params) {
  return request({
    url: url.financeInvoiceDetail,
    method: 'POST',
    ...params
  })
}

export function financeInvoiceRollback(params) {
  return request({
    url: url.financeInvoiceRollback,
    method: 'POST',
    ...params
  })
}

export function financeStatistics(params) {
  return request({
    url: url.financeStatistics,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationRules(params) {
  return request({
    url: url.financeSalaryCalculationRules,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationAddRules(params) {
  return request({
    url: url.financeSalaryCalculationAddRules,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationUpdateRules(params) {
  return request({
    url: url.financeSalaryCalculationUpdateRules,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationDeleteRules(params) {
  return request({
    url: url.financeSalaryCalculationDeleteRules,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationTeacher(params) {
  return request({
    url: url.financeSalaryCalculationTeacher,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationCalculation(params) {
  return request({
    url: url.financeSalaryCalculationCalculation,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationCommision(params) {
  return request({
    url: url.financeSalaryCalculationCommision,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationCalculationExport(data) {
  return request({
    url: url.financeSalaryCalculationCalculationExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function financeSalaryCalculationExportCommision(data) {
  return request({
    url: url.financeSalaryCalculationExportCommision,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function financeSalaryCalculationSetCalculation(params) {
  return request({
    url: url.financeSalaryCalculationSetCalculation,
    method: 'POST',
    ...params
  })
}

export function financeSalaryCalculationRe(params) {
  return request({
    url: url.financeSalaryCalculationRe,
    method: 'POST',
    ...params
  })
}

export function financeSalary(params) {
  return request({
    url: url.financeSalary,
    method: 'POST',
    ...params
  })
}

export function financeSalaryColumns(params) {
  return request({
    url: url.financeSalaryColumns,
    method: 'POST',
    ...params
  })
}

export function financeSalaryAddColumns(params) {
  return request({
    url: url.financeSalaryAddColumns,
    method: 'POST',
    ...params
  })
}

export function financeSalaryUPdateColumns(params) {
  return request({
    url: url.financeSalaryUPdateColumns,
    method: 'POST',
    ...params
  })
}

export function financeSalaryDeleteColumns(params) {
  return request({
    url: url.financeSalaryDeleteColumns,
    method: 'POST',
    ...params
  })
}

export function financeSalaryUpdateOrder(params) {
  return request({
    url: url.financeSalaryUpdateOrder,
    method: 'POST',
    ...params
  })
}

export function financeSalaryBill(params) {
  return request({
    url: url.financeSalaryBill,
    method: 'POST',
    ...params
  })
}

export function financeSalaryUpdate(params) {
  return request({
    url: url.financeSalaryUpdate,
    method: 'POST',
    ...params
  })
}

export function financeSalaryDeleteBill(params) {
  return request({
    url: url.financeSalaryDeleteBill,
    method: 'POST',
    ...params
  })
}

export function financeSalaryDelete(params) {
  return request({
    url: url.financeSalaryDelete,
    method: 'POST',
    ...params
  })
}

export function financeSalaryConfirm(params) {
  return request({
    url: url.financeSalaryConfirm,
    method: 'POST',
    ...params
  })
}

export function financeSalaryExport(data) {
  return request({
    url: url.financeSalaryExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function financeSalaryImport(data) {
  return request({
    url: url.financeSalaryImport,
    method: 'POST',
    data
  })
}

export function financeSalaryExample(data) {
  return request({
    url: url.financeSalaryExample,
    method: 'POST',
    responseType: 'blob',
    data
  })
}