import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 教务中心-列表
 */
export function studioList(params) {
  return request({
    url: url.studioList,
    method: 'POST',
    ...params
  })
}
export function studioDetail(params) {
  return request({
    url: url.studioDetail,
    method: 'POST',
    ...params
  })
}
export function studioUpdate(params) {
  return request({
    url: url.studioUpdate,
    method: 'POST',
    ...params
  })
}
export function studioAdd(params) {
  return request({
    url: url.studioAdd,
    method: 'POST',
    ...params
  })
}
export function studioDelete(params) {
  return request({
    url: url.studioDelete,
    method: 'POST',
    ...params
  })
}
export function studioRecovery(params) {
  return request({
    url: url.studioRecovery,
    method: 'POST',
    ...params
  })
}
export function studioChangeOrder(params) {
  return request({
    url: url.studioChangeOrder,
    method: 'POST',
    ...params
  })
}
export function studioDownload(params) {
  return request({
    url: url.studioDownload,
    method: 'POST',
    ...params
  })
}
export function studioQrcodeview(params) {
  return request({
    url: url.studioQrcodeview,
    method: 'POST',
    ...params
  })
}
export function studioSuggestion(params) {
  return request({
    url: url.studioSuggestion,
    method: 'POST',
    ...params
  })
}
export function studioSuggestionDetail(params) {
  return request({
    url: url.studioSuggestionDetail,
    method: 'POST',
    ...params
  })
}
export function studioSuggestionRead(params) {
  return request({
    url: url.studioSuggestionRead,
    method: 'POST',
    ...params
  })
}
export function studioSuggestionMultiAction(params) {
  return request({
    url: url.studioSuggestionMultiAction,
    method: 'POST',
    ...params
  })
}
