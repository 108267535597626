<template>
    <div @click="showHelp" class="helpCenter">
        帮助引导
    </div>
</template>

<script>
    export default {
        name:'HelpCenters',
        inject: ['parent'],
        methods: {
            showHelp(){
                let pathStr = this.$route.path
                let arr = pathStr.split('/')
                this.parent.toShowHelp(arr,arr.length)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .helpCenter{
        width: 30px;
        padding: 8px;
        background: #fff;
        position: fixed;
        right: 0px;
        bottom: 450px;
        z-index: 5000;
        display: flex;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    }
</style>