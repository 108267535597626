import Vue from 'vue'
import { huidong, huidongCheck, huidongDelete, huidongExport, huidongSendNotify, huidongApplyDetail, huidongApplyUpdate, huidongApplyPreview,
        huidongReportEmployee, huidongReportEmployeeWeek, huidongReportStudio, huidongReportStudioWeek, huidongReportChannel, huidongReportHotLeads,
        huidongReportHotLeadsFollow,huidongPrint,huidongPrintWeek,huidongPrintDelete,huidongPrintExport, zhuqiaoApply, zhuqiaoApplyDetail, zhuqiaoApplyUpdate,
        zhuqiaoApplyExport} from '@/api/custom'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    huidongAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidong(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongSendNotifyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongSendNotify(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongApplyDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongApplyDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongApplyUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongApplyUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    huidongApplyPreviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongApplyPreview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportEmployeeWeekAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportEmployeeWeek(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportStudioWeekAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportStudioWeek(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportHotLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportHotLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongReportHotLeadsFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongReportHotLeadsFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongPrintAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongPrint(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    huidongPrintWeekAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongPrintWeek(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    huidongPrintDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongPrintDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    huidongPrintExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        huidongPrintExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    zhuqiaoApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zhuqiaoApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    zhuqiaoApplyDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zhuqiaoApplyDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    zhuqiaoApplyUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zhuqiaoApplyUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    zhuqiaoApplyExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zhuqiaoApplyExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
