import Vue from 'vue'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const User = () => import('@/views/user')
const NotFound = () => import('@/views/user/notFound')
const Login = () => import('@/views/user/login')
const Register = () => import('@/views/user/register')
const ContractExport = () => import('@/views/export/contractExport')
const Stock = () => import('@/views/export/stock')
const biScreen = ()  => import('@/views/bi/screen')
const datavScreen = () => import('@/views/bi/datav')

import adminRouter from './modules/admin'
import marketRouter from './modules/market'
import settingRouter from './modules/setting'
import financeRouter from './modules/finance'
import studentsRouter from './modules/student'
import customerRouter from './modules/customer'
import analysisRouter from './modules/analysis'
import educationRouter from './modules/education'
import enterpriseRouter from './modules/enterprise'
import orginizationRouter from './modules/orginization'
import workserviceRouter from './modules/workservice'
import RSSRouter from './modules/rss'


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/user/login'
  // },
  {
    path: "/404",
    name: "notFound",
    component: NotFound
  },
  {
    path: '/screen',
    component: biScreen,
    meta: { title: '智慧大屏'}
  },
  {
    path: '/datav',
    component: datavScreen,
    meta: { title: '智慧大屏'}
  },
  {
    path: '/user',
    component: User,
    redirect: '/user/login',
    children: [{
      path: 'login',
      name: 'Login',
      component: Login,
      meta: { title: '登录' ,index:'1'}
    },
    {
      path: 'register',
      name: 'Register',
      component: Register,
      meta: { title: '注册'}
    }]
  },
  {
    path: '/user',
    component: User,
    redirect: '/user/qr',
    children: [
    {
      path: 'qr',
      name: 'Login',
      component: Login,
      meta: { title: '登录' ,index:'2'}
    },
    {
      path: 'register',
      name: 'Register',
      component: Register,
      meta: { title: '注册' }
    }]
  },
  {
    path: '/zoro',
    component: ContractExport,
    meta: { title: 'ZORO' }
  },
  {
    path: '/stock',
    component: Stock,
    meta: { title: 'stock' }
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

export const asyncRouterMap = [
  adminRouter,
  marketRouter,
  settingRouter,
  financeRouter,
  studentsRouter,
  analysisRouter,
  customerRouter,
  educationRouter,
  enterpriseRouter,
  orginizationRouter,
  workserviceRouter,
  RSSRouter
]
