import Vue from 'vue'
import { orginizationList } from '@/api/orginization'
import config from '@/utils/const'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    orginizationListAction({ commit }) {
      return new Promise((resolve,reject) => {
        orginizationList().then(response => {
          const { data } = response
          resolve(data)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }
  }
}
