import Vue from 'vue'
import { knowledgePackage,knowledgePackageAdd,knowledgePackageDetail,knowledgePackageUpdate,knowledgePackageDelete,knowledgePackageRecovery,knowledgePackageCanRelatedContent,knowledgeContent,knowledgeContentDetail,knowledgeContentAdd,knowledgeContentRelatedTo,knowledgeContentCancelRelated,knowledgeContentUpdate,knowledgeContentDelete,knowledgeContentRecovery,
  knowledgeContentComment,knowledgeContentCommentComfirm,knowledgeContentCommentRefuse,knowledgePackageContent,knowledgeContentChangeOrder,knowledgePackageMember,
  } from '@/api/knowledge'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    knowledgePackageAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackage(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgePackageAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    knowledgePackageContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    knowledgePackageMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgePackageDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgePackageUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgePackageDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgePackageRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    knowledgePackageCanRelatedContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgePackageCanRelatedContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentRelatedToAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentRelatedTo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentCancelRelatedAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentCancelRelated(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    knowledgeContentChangeOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentChangeOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentCommentComfirmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentCommentComfirm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    knowledgeContentCommentRefuseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        knowledgeContentCommentRefuse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
