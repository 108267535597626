import Vue from 'vue'
import { settingSystem,settingSystemEducation,settingSystemEducationUpdate,settingSystemAudition,settingStudioNotice,settingStudioNoticeAdd,
  settingStudioNoticeDelete,settingStudioNoticeAccount,settingScanCheckin,settingScanCheckinAdd,settingScanCheckinDelete,settingPaymentNotice,
  settingPaymentNoticeAdd,settingPaymentNoticeDelete,settingPaymentNoticeAccount,settingAgreement,settingBanner,settingBannerAdd,settingBannerUpdate,
  settingBannerDetail,settingBannerDelete,settingBannerRecovery,settingSystemLongpoll,settingSystemSmsPay,settingSystemSms,settingStudioNoticeUpdate,
  loggingIndex, settingCreditUpdate , settingCreditIndex, settingAliPayment, settingAliPaymentUpdate, settingSystemEducationAchievement,settingSystemEducationAchievementUpdate,
  loggingExport,settingWxwork,settingWxworkInstall,settingWxworkUpdate,settingWxworkSetKeywords,settingWxworkInvalidKeywords,settingSystemSmsAnalysis,settingSystemMonitor,settingSystemMonitorUpdate,
  settingWxworkCallback,smartserviceIndex,smartserviceAdd,smartserviceUpdate,smartserviceDetail,smartserviceClose,smartserviceVersions,smartserviceAddReply,enterpriseWorkOrder,
  enterpriseWorkOrderMine,enterpriseWorkOrderDetail,enterpriseWorkOrderWorker,enterpriseWorkOrderAssign,enterpriseWorkOrderFinish,enterpriseWorkOrderVersions,enterpriseWorkOrderAddReply,
  settingWxworkTagGroup,settingWxworkAddTags,settingWxworkTagSync,settingWxworkDelTags,enterpriseWorkOrderAnalysis,enterpriseFilterApp,settingfinanceConfig,
  settingfinanceConfigUpdate,settingfinanceFiscal,settingfinanceSetFiscal,settingfinanceLockFiscal,settingfinanceUnlockFiscal,settingSystemEducationAppointment,enterpriseFilterBelongStudio,
  settingSystemEducationAddAppointment,settingSystemEducationDeleteAppointment} from '@/api/setting'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    settingSystemAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystem(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemSmsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystemSms(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemSmsPayAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystemSmsPay(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemSmsAnalysisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystemSmsAnalysis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemLongpollAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystemLongpoll(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemMonitorAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemMonitor(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingSystemMonitorUpdateAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemMonitorUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingSystemEducationAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducation(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingSystemEducationUpdateAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemEducationAchievementAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemEducationAchievementUpdateAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationAchievementUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingSystemEducationAppointmentAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationAppointment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemEducationAddAppointmentAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationAddAppointment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingSystemEducationDeleteAppointmentAction({ commit }, params) {
      console.log(params)
      return new Promise((resolve,reject) => {
        settingSystemEducationDeleteAppointment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingSystemAuditionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingSystemAudition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingStudioNoticeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingStudioNotice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingStudioNoticeAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingStudioNoticeAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingStudioNoticeUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingStudioNoticeUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingStudioNoticeDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingStudioNoticeDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingStudioNoticeAccountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingStudioNoticeAccount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingScanCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingScanCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingScanCheckinAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingScanCheckinAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingScanCheckinDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingScanCheckinDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingPaymentNoticeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingPaymentNotice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingPaymentNoticeAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingPaymentNoticeAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
    settingPaymentNoticeDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingPaymentNoticeDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingPaymentNoticeAccountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingPaymentNoticeAccount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingAliPaymentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingAliPayment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingAliPaymentUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingAliPaymentUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingAgreementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingAgreement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingBannerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBanner(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingBannerAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBannerAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingBannerUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBannerUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    settingBannerDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBannerDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingBannerDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBannerDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingBannerRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingBannerRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingWxworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxwork(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingWxworkInstallAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkInstall(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingWxworkCallbackAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkCallback(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingWxworkUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingWxworkSetKeywordsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkSetKeywords(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingWxworkAddTagsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkAddTags(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingWxworkDelTagsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkDelTags(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingWxworkTagGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkTagGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    settingWxworkTagSyncAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkTagSync(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingWxworkInvalidKeywordsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingWxworkInvalidKeywords(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    loggingIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        loggingIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    loggingExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        loggingExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCreditUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCreditUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCreditIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCreditIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceConfigAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceConfig(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceConfigUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceConfigUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceFiscalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceFiscal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceSetFiscalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceSetFiscal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceLockFiscalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceLockFiscal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingfinanceUnlockFiscalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingfinanceUnlockFiscal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    smartserviceIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    smartserviceAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    smartserviceUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    smartserviceDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    smartserviceCloseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceClose(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    smartserviceVersionsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceVersions(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    smartserviceAddReplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        smartserviceAddReply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },



    enterpriseWorkOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseWorkOrderMineAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderMine(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseWorkOrderDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    enterpriseWorkOrderWorkerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderWorker(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseWorkOrderAssignAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderAssign(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseWorkOrderFinishAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderFinish(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    enterpriseWorkOrderVersionsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderVersions(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    enterpriseWorkOrderAddReplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderAddReply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    enterpriseWorkOrderAnalysisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseWorkOrderAnalysis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    enterpriseFilterAppAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFilterApp(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    enterpriseFilterBelongStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        enterpriseFilterBelongStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    
  }
}
