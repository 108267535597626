import Vue from 'vue'
import router from '@/router'
import { login, loginQrcode, qrLogin, wxworkQrLogin, logout, accout, accoutUpdate, accoutChangePassword,getAuth,accoutSubordinate,faceLogin} from '@/api/user'
import config from '@/utils/const'

export default {
  state: {
    employee_id:null,
    realname:null,
    brand_info:{
      app_name:'测试',
      app_id:'123',
      app_sms_count:0
    },
    wxwork_info:{},
    allAuth:null,// 总权限信息 默认空 调整动态路由
  },
  mutations: {
    SET_ALLAUTH: (state, allAuth) => {
      state.allAuth = allAuth
    },
    SET_EMPLOYEE_ID:(state, id) => {
      state.employee_id = id
    },
    SET_REALNAME:(state, name) => {
      state.realname = name
    },
    SET_BRAND_INFO:(state, info) => {
      state.brand_info = info
    },
    SET_WXWORK_INFO:(state, info) => {
      state.wxwork_info = info
    },
  },
  actions: {
    loginAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        login(params).then(response => {
          const { data } = response
          Vue.ls.set(config.ACCESS_TOKEN, data.access_token, config.ACCESS_TIME)
          Vue.ls.set('permission', data.permission)
          if(data.permission.length == 0){
            reject(1)
          }else{
            commit('SET_ALLAUTH', data.permission)
            resolve(response)
          }
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    faceLoginAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        faceLogin(params).then(response => {
          const { data } = response
          if(data.status=='success'){
            Vue.ls.set(config.ACCESS_TOKEN, data.access_token, config.ACCESS_TIME)
            Vue.ls.set('permission', data.permission)
          }
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    loginQrcodeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        loginQrcode(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    qrLoginAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        qrLogin(params).then(response => {
          const { data } = response
          // Vue.ls.set('USER_INFO', result, config.ACCESS_TIME )
          // Vue.ls.set(config.ACCESS_TOKEN, result.token, config.ACCESS_TIME)
          Vue.ls.set(config.ACCESS_TOKEN, data.access_token, config.ACCESS_TIME)
          resolve(data)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkQrLoginAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkQrLogin(params).then(response => {
          const { data } = response
          // Vue.ls.set('USER_INFO', result, config.ACCESS_TIME )
          // Vue.ls.set(config.ACCESS_TOKEN, result.token, config.ACCESS_TIME)
          Vue.ls.set(config.ACCESS_TOKEN, data.access_token, config.ACCESS_TIME)
          resolve(data)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    logoutAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        logout(params).then(response => {
          Vue.ls.remove(config.ACCESS_TOKEN)
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    accoutAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        accout(params).then(response => {
          const { data } = response
          commit('SET_EMPLOYEE_ID', data.employee_id)
          commit('SET_REALNAME', data.realname)
          commit('SET_BRAND_INFO', data.brand_info)
          commit('SET_WXWORK_INFO', data.wxwork_info)
          Vue.ls.set('employee_group_id',data.group_id)
          Vue.ls.set('employee_id',data.employee_id)
          Vue.ls.set('wxwork_userid',data.wxwork_info.userid)
          Vue.ls.set('wxwork_info',data.wxwork_info)
          Vue.ls.set('realname',data.realname)
          Vue.ls.set('appid',data.brand_info.app_id)
          Vue.ls.set('image_avatar',data.image_avatar)
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    accoutUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        accoutUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    accoutSubordinateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        accoutSubordinate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    accoutChangePasswordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        accoutChangePassword(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    getAuthAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        getAuth(params).then(response => {
          const { data } = response
          Vue.ls.set('permission', data)
          if(data.length == 0){
            reject(1)
          }else{
            commit('SET_ALLAUTH', data)
            resolve(response)
          }
        }).catch(reason => {
          reject(reason)
        })
      })
    },
  }
}
