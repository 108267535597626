<template>
    <div
        class="chatrecord-list"
        :style="{ height:wHeight}"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
        >
        <vue-element-loading :active="loading && list.length ==0" color="#00cca2" spinner="spinner"/>
        <a-list item-layout="horizontal" :data-source="list">
            <a-list-item v-if="item.msgtype =='text'" slot="renderItem" slot-scope="item, index">
                <div class="chatrecord-record">
                    <div class="chatrecord-record-avatar">
                        <a-avatar shape="square" :size="34" icon="user" :src="item.avatar" />
                    </div>
                    <div class="chatrecord-record-content">
                        <div class="chatrecord-record-info">
                            <div class="chatrecord-record-name">{{item.name}}</div>
                            <div class="chatrecord-record-time">{{item.msgtime_txt}}</div>
                            <div @click="toCheck(item)" class="chatrecord-record-look">查看详细</div>
                        </div>
                        <div class="chatrecord-record-text" v-html="convertText2Emoji(item.text.content)"></div>
                    </div>
                </div>
            </a-list-item>
            <div slot="footer">
                <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
            </div>
        </a-list>
    </div>
</template>

<script>
    // import {convertText2Emoji} from '@znlbwo/wechat-emoji'
    import { parsingEmoji } from '@/utils/emjoymethod'
    import '@/styles/emjoy.css'
    import moment from 'moment'
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'searchRecord',
        inject: ['parent'],
        data() {
            return {
                busy:true,
                loading:false,
                list:[],
                searchParams: {
                    "page": 1,
                    "per-page": 30,
                    "search": {}
                },
            }
        },
        props:{
            staff_ext_id:String,
            chatItem:Object,
            keyword:String,
            wHeight:String
        },
        directives: { infiniteScroll },
        created () {
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                this.searchParams.staff_ext_id = this.staff_ext_id
                this.searchParams.session_ext_id = this.chatItem.session_ext_id
                this.searchParams.search.keyword = this.keyword
                this.searchParams.session_type = this.chatItem.session_type
                let res = await this.$store.dispatch('wxworkMsgSearchAction', { data: this.searchParams})
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                    this.busy = false
                }else{
                    this.busy = true
                }
                this.loading = false
            },
             handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            toCheck(item){
                this.parent.msgID = item.id
            },
            convertText2Emoji(text){
                return parsingEmoji(this.changeColor(text,[this.keyword]))
            },
            changeColor(content, keywords) {
                let str = content
                keywords.map((keyitem, keyindex) => {
                    // 匹配关键字正则
                        let replaceReg = new RegExp(keyitem, "g")
                        // 高亮替换v-html值
                        let replaceString =
                        '<span class="highlight"' +
                        ' style="color:#00cca2;">' +
                        keyitem +
                        "</span>"
                        str = str.replace(
                        replaceReg,
                        replaceString
                        )
                });
                return str
            },
        },
        watch: {
            keyword(newValue, oldValue) {
                this.list = []
                this.searchParams.page = 1
                this.busy = true
                this.getList()
            }
        },
    }
</script>

<style lang="scss" scoped>
    .chatrecord-list{
        overflow-y: scroll;
    }
    .chatrecord-record{
            width: 100%;
            display: flex;
            &-avatar{
                margin: 0 10px;
            }
            &-content{
                width:100%
            }
            &-info{
                width: 100%;
                position: relative;
                display: flex;
                align-items: center;
            }
            &-time{
                position: absolute;
                right: 15px;
                font-size: 12px;
            }
            &-look{
                position: absolute;
                right: 15px;
                bottom: -20px;
                color: #00cca2;
                cursor: pointer;
            }
            &-name{
                color:#222;
            }
            &-text{
                width: 80%;
                font-size: 12px;
            }
        }
</style>