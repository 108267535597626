import Vue from 'vue'
import { 
  students, studentsDetail, studentsAdd, studentsUpdate, studentsDelete,
  studentsHistory, studentsPotential, studentsStudentCheck,studentsExport,
  studentsRecovery, studentsUpdateStatus, studentsStudentsFilter,studentsMerge,studentsWeixinQrcode,
  studentsProfile,studentsProfileCredit,studentsProfileCosts,studentsProfileCourseClass,
  studentsProfileCancelClass,studentsProfileBookCosts,studentsProfileContact,studentsProfileAddContact,
  studentsProfileContactDetail,studentsProfileUpdateContact,studentsProfileClasses,studentsProfileHomework,studentsSetFace,
  studentsProfileDetail,studentsProfilePendding,studentsProfileRefresh,studentsProfileWallet,studentsProfileWalletContract,studentsProfileContract,studentsProfileContractRefund,
  studentsProfileCertificate,pendding,studentsAddCredit,studentsMinusCredit,studentsCoupons,studentsProfileCanJoinClasses,studentsProfileAddToClasses
} from '@/api/students'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    studentsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        students(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsSetFaceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsSetFace(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsHistoryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsHistory(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsMergeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsMerge(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsWeixinQrcodeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsWeixinQrcode(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsPotentialAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsPotential(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsStudentCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsStudentCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsUpdateStatusAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsUpdateStatus(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studentsStudentsFilterAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsStudentsFilter(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsProfileAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfile(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileCreditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCredit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileCostsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCosts(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileCourseClassAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCourseClass(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileCancelClassAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCancelClass(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileBookCostsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileBookCosts(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileAddContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileAddContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileContactDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileContactDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileUpdateContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileUpdateContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileHomeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileHomework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfilePenddingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfilePendding(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileRefreshAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileRefresh(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileWalletAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileWallet(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileCertificateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCertificate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsProfileWalletContractAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileWalletContract(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsProfileContractAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileContract(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    studentsProfileContractRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileContractRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsProfileCanJoinClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileCanJoinClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsProfileAddToClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsProfileAddToClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    penddingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        pendding(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsAddCreditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsAddCredit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsMinusCreditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsMinusCredit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studentsCouponsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studentsCoupons(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

  }
}
