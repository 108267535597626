import Vue from 'vue'
import { cCategoryList, cCategoryAdd, cCategoryStudios, cCategoryUpdate, cCategoryDetail, cCategoryDelete, cCategoryRecovery, cCategoryCoursesSingleFee,
  cCategoryCoursesList, cCategoryCoursesAdd, cCategoryCoursesUpdate, cCategoryCoursesDelete, cCategoryCoursesRecovery } from '@/api/courseCategory'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    cCategoryListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryStudiosAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryStudios(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesSingleFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesSingleFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cCategoryCoursesRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCategoryCoursesRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
