<template>
    <div class="lempty">
        <a-empty :image="simpleImage" />
    </div>
</template>
<script>
import { Empty } from 'ant-design-vue';
export default {
  name:'LEmpty',
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
};
</script>
<style lang="scss">
  .lempty{
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -67px;
  }
</style>