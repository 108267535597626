import Vue from 'vue'
import { storeProductCategory,storeProductCategoryAdd,storeProductCategoryDetail,storeProductCategoryUpdate,storeProductCategoryDelete,storeProductSpec,storeSuppliersStockDelete,storeSuppliersAdd,storeSuppliersDelete,storeSuppliersUpdate,storeSuppliers,
  storeProductSpecAdd,storeProductSpecDetail,storeProductSpecUpdate,storeProductSpecDelete,storeProductSpecDeleteDet,storeProduct,storeProductAdd,storeProductDetail,storeSuppliersStock,storeSuppliersStockIn,
  storeRefund,storeCoupon,storeCouponAdd,storeCouponDetail,storeCouponUpdate,storeCouponDelete,storeCouponSend,storeComment,storeCommentDelete,storeCredit,storeProductUpdate,
  storeCreditAdd,storeCreditDelete } from '@/api/store'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    storeProductCategoryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductCategory(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductCategoryAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductCategoryAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeSuppliersAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliers(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeSuppliersStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeSuppliersStockDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersStockDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    storeSuppliersStockInAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersStockIn(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeSuppliersDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    storeSuppliersAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeSuppliersUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeSuppliersUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductCategoryDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductCategoryDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductCategoryUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductCategoryUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductCategoryDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductCategoryDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpec(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpecAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpecDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpecUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpecDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpecDeleteDetAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpecDeleteDet(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProduct(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductSpcsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductSpcs(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeProductRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeProductRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeOrderDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeOrderDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeOrderExpressAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeOrderExpress(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeOrderExpressCodeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeOrderExpressCode(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeOrderDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeOrderDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCoupon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCouponAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCouponDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCouponUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCouponDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCouponSendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCouponSend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCommentDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCommentDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeCreditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCredit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    storeCreditAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCreditAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    storeCreditDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        storeCreditDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
