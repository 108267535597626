import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
import { delay } from '@/utils/timeFormat'
export default {
  provide() {
    return {
      parent: this
    }
  },
  data() {
    return {
      SHOW_ALL,
      selectedRecord: [],
      selectedRowKeys: [],
      selectedRecordObj:[],
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchVisible: false,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      isEdit:0,
      visible: false,
      modalData: null,
      copyData: null,
      firstTime:1
    }
  },
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.getList()
  },
  methods: {
    closeSearch() {
      this.visible = false
      this.searchVisible = false
    },
    reset() {
      this.searchParams.search = {}
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.current = 1
      delay(() => {
        this.getList()
      }, 200)
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.selectedRowKeys = []
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    onSelectChange(selectedRowKeys,record) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRecordObj[this.searchParams.page] = record
      let arr = []
      this.selectedRecordObj.forEach(item=>{
          arr.push(...item)
      })
      this.selectedRecord = arr
    },
    showEditModal(item) {
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.copyData = null
      this.visible = true
    },
    hideModal(num) {
      if(num){
        this.getList()
      }
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    refreshList(name,value){
        this.modalData[name] = value
    }
  }
}