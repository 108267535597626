const Layout = () => import('@/views/layout')
const Students = () => import('@/views/students')
const StudentsCarryForward = () => import('@/views/students/carryForward')

const studentsRouter = {
    path: '/students',
    component: Layout,
    hide: true,
    parent:'studentsRouter',
    meta: {
      title: '学员中心',
      requiresAuth: false,
      type:'students',
      icon: '#icon-EnshrineOutline',
      menusKey:2
    },
    children:[
        {
            path: 'index',
            component: Students,
            hide: false,
            meta: { 
                title: '学员中心', 
                icon: '#icon-PersonalOutline',
                requiresAuth: true,
                type:'students',
                parent: 'students'
            }
        }, 
        {
            path: 'carryForward',
            component: StudentsCarryForward,
            hide: true,
            meta: { 
                title: '结转', 
                icon: '#icon-UrlsOutline',
                requiresAuth: true,
                type:'students',
                parent: 'students'
            }
        },
        // {
        //     path: 'transfer',
        //     component: Students,
        //     hide: false,
        //     meta: { 
        //         title: '学员转校', 
        //         icon: '#icon-UrlsOutline',
        //         requiresAuth: true,
        //         type:'students',
        //         parent: 'students'
        //     }
        // },
        // {
        //     path: 'refund',
        //     component: Students,
        //     hide: false,
        //     meta: { 
        //         title: '学员退费', 
        //         icon: '#icon-CancelOutline ',
        //         requiresAuth: true,
        //         type:'students',
        //         parent: 'students'
        //     }
        // }, 
    ]
}

export default studentsRouter