import url from '@/utils/URL'
import request from '@/utils/request'

export function extendingAlert(params) {
  return request({
    url: url.extendingAlert,
    method: 'POST',
    ...params
  })
}

export function extendingLimit(params) {
  return request({
    url: url.extendingLimit,
    method: 'POST',
    ...params
  })
}