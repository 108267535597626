<template>
    <a-card>
        <div class="LGraphCard" ref="mains"></div>
        <LEmpty v-if="first>0 && item.data.length == 0"/>
    </a-card>
</template>

<script>
    import * as echarts from 'echarts'
    // import myJson from './ceshi.json'
    export default {
        name:'LGraph',
        props:{
            item: Object,
            loading: Boolean,
            refresh: Number
        },
        data() {
            return {
                first:0
            }
        },
        mounted () {
            this.setMap()
        },
        methods: {
            setMap() {
                let that = this
                setTimeout(()=>{
                that.$nextTick(()=>{
                    var myChart = echarts.init(that.$refs.mains)
                    let option = {
                        tooltip: {},
                        animationDuration: 1000,
                        animationEasingUpdate: 'quinticInOut',
                        series: [
                            {
                            name: '',
                            type: 'graph',
                            layout: 'force',
                            zoom: 1,
                            edgeSymbol: ['circle', 'arrow'],
                            edgeSymbolSize: [2, 10],
                            symbolSize: 36, // 调整节点的大小
                            draggable: false,
                            itemStyle: {
                                normal: {
                                borderWidth: 0,
                                },
                            },
                            force: {
                                repulsion: 200,
                                edgeLength: [10, 150],
                                layoutAnimation: false,
                            },
                            data: this.item.data,
                            links: this.item.links,
                            categories: this.item.categories,
                            // data: myJson.data.data,
                            // links: myJson.data.links,
                            // categories: myJson.data.categories,
                            focusNodeAdjacency: true,
                            roam: true,
                            edgeLabel: {
                                normal: {
                                show: false,
                                formatter: function (x) {
                                    return x.data.name;
                                },
                                },
                            },
                            label: {
                                normal: {
                                show: true,
                                position: 'inside',
                                color: '#fff',
                                textBorderWidth: 0,
                                },
                            },
                            lineStyle: {
                                normal: {
                                    width:2,
                                    color: 'source',
                                    curveness: 0,
                                    type: 'solid',
                                },
                            },
                            },
                        ],
                    }
                    myChart.clear()
                    myChart.setOption(option,true)
                })},100)
            }
        },
        watch: {
            loading(newValue, oldValue) {
                this.first++
            },
            refresh(){
                this.setMap()
            }
        },
    }
</script>

<style lang="scss" scoped>
    .LGraphCard{
        width: 100%;
        height:calc(100vh - 350px)
    }
</style>