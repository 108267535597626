import Vue from 'vue'
import { educationList,scheduleCourseList,scheduleLeave,scheduleHomework,scheduleClasses,scheduleIndex,schedulecourseExport,scheduleHeatmap,scheduleTeachStatis,scheduleWait,
  courseRoom,scheduleLeaveApprove,scheduleComment,scheduleAddComment,scheduleAttend,scheduleMessages,scheduleAddHomework,scheduleUpdateHomework,scheduleDeleteHomework,scheduleHomeworkFinishDetail,
  scheduleExportBook,scheduleAudition,scheduleAuditionExport} from '@/api/education'
import config from '@/utils/const'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    educationListAction({ commit }) {
      return new Promise((resolve,reject) => {
        educationList().then(response => {
          const { data } = response
          resolve(data)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleCourseListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleCourseList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleLeaveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleLeave(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }, 
    
    scheduleWaitAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleWait(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }, 
    
    scheduleAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }, 
    
    scheduleExportBookAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleExportBook(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }, 
    
    scheduleHomeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleHomework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleAddHomeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleAddHomework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    scheduleUpdateHomeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleUpdateHomework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleDeleteHomeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleDeleteHomework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    scheduleHomeworkFinishDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleHomeworkFinishDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    scheduleMessagesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleMessages(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleTeachStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleTeachStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleLeaveApproveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleLeaveApprove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    scheduleCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    scheduleAddCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleAddComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    scheduleAuditionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleAudition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    scheduleAuditionExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        scheduleAuditionExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    schedulecourseExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        schedulecourseExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    }
  }
}
