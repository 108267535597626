import Vue from 'vue'
import { labsRss, labsRssAdd, labsRssDelete, labsRssClick, labsRssChannel, labsRssDeleteChannel } from '@/api/rss'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    labsRssAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRss(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
    labsRssAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRssAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    labsRssDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRssDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    labsRssClickAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRssClick(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
    labsRssChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRssChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
    labsRssDeleteChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        labsRssDeleteChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
  }
}
