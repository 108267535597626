import url from '@/utils/URL'
import request from '@/utils/request'

export function courseSchedule(params) {
  return request({
    url: url.courseSchedule,
    method: 'POST',
    ...params
  })
}
export function courseScheduleAddSchedule(params) {
  return request({
    url: url.courseScheduleAddSchedule,
    method: 'POST',
    ...params
  })
}
export function courseScheduleCalendar(params) {
  return request({
    url: url.courseScheduleCalendar,
    method: 'POST',
    ...params
  })
}
export function courseScheduleDetail(params) {
  return request({
    url: url.courseScheduleDetail,
    method: 'POST',
    ...params
  })
}

export function courseClipIndex(data) {
  return request({
    url: url.courseClipIndex,
    method: 'POST',
    data
  })
}

export function courseClipDelete(data) {
  return request({
    url: url.courseClipDelete,
    method: 'POST',
    data
  })
}

export function courseRecallIndex(data) {
  return request({
    url: url.courseRecallIndex,
    method: 'POST',
    data
  })
}

export function courseRecallClip(data) {
  return request({
    url: url.courseRecallClip,
    method: 'POST',
    data
  })
}

export function courseRecallFinish(data) {
  return request({
    url: url.courseRecallFinish,
    method: 'POST',
    data
  })
}

export function courseScheduleHighlight(data) {
  return request({
    url: url.courseScheduleHighlight,
    method: 'POST',
    data
  })
}

export function courseScheduleDelHighlight(data) {
  return request({
    url: url.courseScheduleDelHighlight,
    method: 'POST',
    data
  })
}

export function courseScheduleAddHighlight(data) {
  return request({
    url: url.courseScheduleAddHighlight,
    method: 'POST',
    data
  })
}

export function courseScheduleLiveRecord(params) {
  return request({
    url: url.courseScheduleLiveRecord,
    method: 'POST',
    ...params
  })
}

export function courseScheduleMultiSetting(params) {
  return request({
    url: url.courseScheduleMultiSetting,
    method: 'POST',
    ...params
  })
}
export function courseScheduleSubstitute(params) {
  return request({
    url: url.courseScheduleSubstitute,
    method: 'POST',
    ...params
  })
}
export function courseScheduleAdd(params) {
  return request({
    url: url.courseScheduleAdd,
    method: 'POST',
    ...params
  })
}

export function courseScheduleExport(data) {
  return request({
    url: url.courseScheduleExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function courseScheduleCancelCourse(data) {
  return request({
    url: url.courseScheduleCancelCourse,
    method: 'POST',
    data
  })
}

export function courseScheduleNextday(data) {
  return request({
    url: url.courseScheduleNextday,
    method: 'POST',
    data
  })
}

export function courseScheduleBookRemark(data) {
  return request({
    url: url.courseScheduleBookRemark,
    method: 'POST',
    data
  })
}

export function courseScheduleSummary(data) {
  return request({
    url: url.courseScheduleSummary,
    method: 'POST',
    data
  })
}

export function courseScheduleSetSummary(data) {
  return request({
    url: url.courseScheduleSetSummary,
    method: 'POST',
    data
  })
}

export function courseScheduleAvailableTeacher(data) {
  return request({
    url: url.courseScheduleAvailableTeacher,
    method: 'POST',
    data
  })
}

export function courseScheduleAvailableRoom(data) {
  return request({
    url: url.courseScheduleAvailableRoom,
    method: 'POST',
    data
  })
}

export function courseScheduleGenerateDate(data) {
  return request({
    url: url.courseScheduleGenerateDate,
    method: 'POST',
    data
  })
}

export function courseScheduleXgjCheck(data) {
  return request({
    url: url.courseScheduleXgjCheck,
    method: 'POST',
    data
  })
}

export function courseScheduleCleanCheckin(data) {
  return request({
    url: url.courseScheduleCleanCheckin,
    method: 'POST',
    data
  })
}

export function courseScheduleRemoveMember(data) {
  return request({
    url: url.courseScheduleRemoveMember,
    method: 'POST',
    data
  })
}

export function courseScheduleAdjustMember(data) {
  return request({
    url: url.courseScheduleAdjustMember,
    method: 'POST',
    data
  })
}

export function courseConflict(params) {
  return request({
    url: url.courseConflict,
    method: 'POST',
    ...params
  })
}

export function courseConflictView(params) {
  return request({
    url: url.courseConflictView,
    method: 'POST',
    ...params
  })
}

export function courseConflictEditAjax(params) {
  return request({
    url: url.courseConflictEditAjax,
    method: 'POST',
    ...params
  })
}

export function courseConflictDelete(params) {
  return request({
    url: url.courseConflictDelete,
    method: 'POST',
    ...params
  })
}

export function courseConflictCancel(params) {
  return request({
    url: url.courseConflictCancel,
    method: 'POST',
    ...params
  })
}

export function courseConflictAjaxCancel(params) {
  return request({
    url: url.courseConflictAjaxCancel,
    method: 'POST',
    ...params
  })
}

export function courseConflictSubstitute(params) {
  return request({
    url: url.courseConflictSubstitute,
    method: 'POST',
    ...params
  })
}

export function courseWare(params) {
  return request({
    url: url.courseWare,
    method: 'POST',
    ...params
  })
}

export function courseWareAdd(params) {
  return request({
    url: url.courseWareAdd,
    method: 'POST',
    ...params
  })
}

export function courseWareAddCateLevel(params) {
  return request({
    url: url.courseWareAddCateLevel,
    method: 'POST',
    ...params
  })
}

export function courseWareAddCateLevelContent(params) {
  return request({
    url: url.courseWareAddCateLevelContent,
    method: 'POST',
    ...params
  })
}

export function courseWareUpdateCateLevelContent(params) {
  return request({
    url: url.courseWareUpdateCateLevelContent,
    method: 'POST',
    ...params
  })
}

export function courseWareDetail(params) {
  return request({
    url: url.courseWareDetail,
    method: 'POST',
    ...params
  })
}

export function courseWareUpdate(params) {
  return request({
    url: url.courseWareUpdate,
    method: 'PUT',
    ...params
  })
}

export function courseWareDownload(params) {
  return request({
    url: url.courseWareDownload,
    method: 'POST',
    ...params
  })
}

export function courseWareDelete(params) {
  return request({
    url: url.courseWareDelete,
    method: 'POST',
    ...params
  })
}

export function courseWareRecovery(params) {
  return request({
    url: url.courseWareRecovery,
    method: 'POST',
    ...params
  })
}

export function courseWareCate(params) {
  return request({
    url: url.courseWareCate,
    method: 'POST',
    ...params
  })
}

export function courseWareCateLevel(params) {
  return request({
    url: url.courseWareCateLevel,
    method: 'POST',
    ...params
  })
}

export function courseWareCateLevelContent(params) {
  return request({
    url: url.courseWareCateLevelContent,
    method: 'POST',
    ...params
  })
}

export function courseWareCourseContent(params) {
  return request({
    url: url.courseWareCourseContent,
    method: 'POST',
    ...params
  })
}

export function courseWareAddCourseContent(params) {
  return request({
    url: url.courseWareAddCourseContent,
    method: 'POST',
    ...params
  })
}

export function courseWareUpdateCourseContent(params) {
  return request({
    url: url.courseWareUpdateCourseContent,
    method: 'POST',
    ...params
  })
}

export function courseWareDelCateLevelContent(params) {
  return request({
    url: url.courseWareDelCateLevelContent,
    method: 'POST',
    ...params
  })
}

export function courseWareUpdateCateLevel(params) {
  return request({
    url: url.courseWareUpdateCateLevel,
    method: 'POST',
    ...params
  })
}

export function courseWareDelCateLevel(params) {
  return request({
    url: url.courseWareDelCateLevel,
    method: 'POST',
    ...params
  })
}

export function courseWareCateLevelContentDetail(params) {
  return request({
    url: url.courseWareCateLevelContentDetail,
    method: 'POST',
    ...params
  })
}

export function courseWareCateLevelContentComment(params) {
  return request({
    url: url.courseWareCateLevelContentComment,
    method: 'POST',
    ...params
  })
}

export function courseWareCateLevelContentAddComment(params) {
  return request({
    url: url.courseWareCateLevelContentAddComment,
    method: 'POST',
    ...params
  })
}

export function courseCertificate(params) {
  return request({
    url: url.courseCertificate,
    method: 'POST',
    ...params
  })
}

export function courseCertificateList(params) {
  return request({
    url: url.courseCertificateList,
    method: 'POST',
    ...params
  })
}

export function courseCertificateSendCertificate(params) {
  return request({
    url: url.courseCertificateSendCertificate,
    method: 'POST',
    ...params
  })
}

export function courseCertificateCancalCertificate(params) {
  return request({
    url: url.courseCertificateCancalCertificate,
    method: 'POST',
    ...params
  })
}

export function courseCertificateSendList(params) {
  return request({
    url: url.courseCertificateSendList,
    method: 'POST',
    ...params
  })
}

export function courseCertificateAdd(params) {
  return request({
    url: url.courseCertificateAdd,
    method: 'POST',
    ...params
  })
}

export function courseCertificateEdit(params) {
  return request({
    url: url.courseCertificateEdit,
    method: 'POST',
    ...params
  })
}

export function courseCertificateView(params) {
  return request({
    url: url.courseCertificateView,
    method: 'POST',
    ...params
  })
}

export function courseCertificateViewCertificate(params) {
  return request({
    url: url.courseCertificateViewCertificate,
    method: 'POST',
    ...params
  })
}

export function courseCertificateDelete(params) {
  return request({
    url: url.courseCertificateDelete,
    method: 'POST',
    ...params
  })
}

export function courseCertificateRecovery(params) {
  return request({
    url: url.courseCertificateRecovery,
    method: 'POST',
    ...params
  })
}

export function courseAchievement(params) {
  return request({
    url: url.courseAchievement,
    method: 'POST',
    ...params
  })
}

export function courseAchievementAdd(params) {
  return request({
    url: url.courseAchievementAdd,
    method: 'POST',
    ...params
  })
}

export function courseAchievementUpdate(params) {
  return request({
    url: url.courseAchievementUpdate,
    method: 'POST',
    ...params
  })
}

export function courseAchievementDetail(params) {
  return request({
    url: url.courseAchievementDetail,
    method: 'POST',
    ...params
  })
}

export function courseAchievementRecord(params) {
  return request({
    url: url.courseAchievementRecord,
    method: 'POST',
    ...params
  })
}

export function courseAchievementAddIcon(params) {
  return request({
    url: url.courseAchievementAddIcon,
    method: 'POST',
    ...params
  })
}

export function courseAchievementViewIcon(params) {
  return request({
    url: url.courseAchievementViewIcon,
    method: 'POST',
    ...params
  })
}

export function courseAchievementDetailIcon(params) {
  return request({
    url: url.courseAchievementDetailIcon,
    method: 'POST',
    ...params
  })
}

export function courseAchievementUpdateIcon(params) {
  return request({
    url: url.courseAchievementUpdateIcon,
    method: 'POST',
    ...params
  })
}

export function courseAchievementAjaxSortMadel(params) {
  return request({
    url: url.courseAchievementAjaxSortMadel,
    method: 'POST',
    ...params
  })
}

export function courseAchievementDelete(params) {
  return request({
    url: url.courseAchievementDelete,
    method: 'POST',
    ...params
  })
}

export function courseAchievementRecovery(params) {
  return request({
    url: url.courseAchievementRecovery,
    method: 'POST',
    ...params
  })
}

export function courseAchievementDeleteIcon(params) {
  return request({
    url: url.courseAchievementDeleteIcon,
    method: 'POST',
    ...params
  })
}

export function coursePrepare(params) {
  return request({
    url: url.coursePrepare,
    method: 'POST',
    ...params
  })
}

export function coursePrepareMy(params) {
  return request({
    url: url.coursePrepareMy,
    method: 'POST',
    ...params
  })
}

export function coursePrepareCheck(params) {
  return request({
    url: url.coursePrepareCheck,
    method: 'POST',
    ...params
  })
}

export function coursePrepareAdd(params) {
  return request({
    url: url.coursePrepareAdd,
    method: 'POST',
    ...params
  })
}

export function coursePrepareAnalysis(params) {
  return request({
    url: url.coursePrepareAnalysis,
    method: 'POST',
    ...params
  })
}

export function coursePrepareView(params) {
  return request({
    url: url.coursePrepareView,
    method: 'POST',
    ...params
  })
}

export function coursePrepareRespond(params) {
  return request({
    url: url.coursePrepareRespond,
    method: 'POST',
    ...params
  })
}

export function coursePrepareAjaxSendNotice(params) {
  return request({
    url: url.coursePrepareAjaxSendNotice,
    method: 'POST',
    ...params
  })
}

export function coursePrepareAjaxIgnore(params) {
  return request({
    url: url.coursePrepareAjaxIgnore,
    method: 'POST',
    ...params
  })
}

export function coursePrepareSendNotice(params) {
  return request({
    url: url.coursePrepareSendNotice,
    method: 'POST',
    ...params
  })
}

export function analysisPrepare(params) {
  return request({
    url: url.analysisPrepare,
    method: 'POST',
    ...params
  })
}

export function courseFee(params) {
  return request({
    url: url.courseFee,
    method: 'POST',
    ...params
  })
}

export function courseFeeDelete(params) {
  return request({
    url: url.courseFeeDelete,
    method: 'POST',
    ...params
  })
}

export function courseFeeAdd(params) {
  return request({
    url: url.courseFeeAdd,
    method: 'POST',
    ...params
  })
}

export function courseFeeUpdate(params) {
  return request({
    url: url.courseFeeUpdate,
    method: 'POST',
    ...params
  })
}

export function courseCoupon(params) {
  return request({
    url: url.courseCoupon,
    method: 'POST',
    ...params
  })
}

export function courseCouponAdd(params) {
  return request({
    url: url.courseCouponAdd,
    method: 'POST',
    ...params
  })
}

export function courseCouponDetail(params) {
  return request({
    url: url.courseCouponDetail,
    method: 'POST',
    ...params
  })
}

export function courseCouponUpdate(params) {
  return request({
    url: url.courseCouponUpdate,
    method: 'POST',
    ...params
  })
}

export function courseCouponDelete(params) {
  return request({
    url: url.courseCouponDelete,
    method: 'POST',
    ...params
  })
}

export function courseCouponSendList(params) {
  return request({
    url: url.courseCouponSendList,
    method: 'POST',
    ...params
  })
}

export function courseCouponGrant(data) {
  return request({
    url: url.courseCouponGrant,
    method: 'POST',
    data
  })
}

export function courseCouponExport(data) {
  return request({
    url: url.courseCouponExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function courseCouponCancelCoupon(data) {
  return request({
    url: url.courseCouponCancelCoupon,
    method: 'POST',
    data
  })
}

export function courseCouponSendListGraph(data) {
  return request({
    url: url.courseCouponSendListGraph,
    method: 'POST',
    data
  })
}