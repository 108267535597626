<template>
  <a-modal title="添加临时插班学员" v-model="visible"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <template slot="footer">
      <a-button key="over" type="primary" :loading="confirmLoading0" @click="handleOk(0)">
        仅上这节课
      </a-button>
      <a-button key="submit" :loading="confirmLoading1" type="primary" @click="handleOk(1)">
        加入此班
      </a-button>
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
    </template>
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-input v-model="student_name" placeholder="学员名称/手机号" style="width: 160px"/>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button> 
            </a-form-item>
          </a-form>
      </div>
    </div>
    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="student_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChanges}">
        <template slot="user" slot-scope="text, record">
          <LUser :istoProfile='1' :is_student_wechat_bind="1" :data="record"/>
        </template>
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
    </a-table>
    <div>
      <a-tag style="margin:5px 5px 0 0" v-for="(item,index) in selectedRecord" :key="item.student_id" closable @close="handleClose(index)">
        {{item.student_name}}
      </a-tag>
    </div>
  </a-modal>
</template>

<script>
import tableMixins from '@/common/mixins/table'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name', scopedSlots: { customRender: 'user' }},
  { title: '手机号码', dataIndex: 'student_cellphone', key: 'student_cellphone'},
  { title: '剩余课时', dataIndex: 'student_available_times', key: 'student_available_times',align:"center"},
  { title: '学员状态', dataIndex: 'student_course_status', key: 'student_course_status',align:"center"},
]


export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  
  data() {
    return {
      visible: false,
      loading: false,
      confirmLoading0: false,
      confirmLoading1: false,
      columns,
      list: [],
      student_name:'',
    }
  },
  mixins: [ tableMixins ],
  async created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList(){
      this.loading = true
      let params = {
        'q':this.student_name,
        'class_id':this.item.class_id
      }
      let res = await this.$store.dispatch('searchStudentsAction', { params })
      this.list = res.data
      this.loading = false
    },
    async handleOk(e) {
      e == 1 ? this.confirmLoading1 = true : this.confirmLoading0 = true
      let params = {
        in_class:e,
        student_id: this.selectedRowKeys,
        course_class_id: this.item.course_class_id
      }
      await this.$store.dispatch('classesAddTempMemberAction', params)
      .then(res=>{
        this.$message.success('操作成功~')
        this.parent.hideAddTempModal(1)
      })
      .catch(err=>{
        e == 1 ? this.confirmLoading1 = false : this.confirmLoading0 = false
        this.$message.error('操作失败~')
      })
    },
    handleCancel() {
      this.parent.hideAddTempModal(0)
    },
    handleClose(index){
      this.selectedRowKeys.splice(index,1)
      this.selectedRecord.splice(index,1)
    },
    onSelectChanges(selectedRowKeys,record) {
      this.selectedRowKeys = selectedRowKeys
      if(this.selectedRecord.length === 0){
        this.selectedRecord = record
      }else{
        let arr = []
        let selectedRecord_id = []
        this.selectedRecord.forEach(item=>{
          selectedRecord_id.push(item.student_id)
        })
        record.forEach(item=>{
          if(selectedRecord_id.indexOf(item.student_id) === -1){
            arr.push(item)
          }
        })
        this.selectedRecord = [...this.selectedRecord,...arr]
      }
    },
    clickRow(record, index) {
      return {
          on: {
              click: () => {
                let index = this.selectedRowKeys.indexOf(record.student_id)
                if(index == -1){
                  this.selectedRowKeys.push(record.student_id)
                  this.selectedRecord.push(record)
                }else{
                  this.selectedRowKeys.splice(index,1)
                  this.selectedRecord.splice(index,1)
                }
              }
          }
      }
    },
  }
}
</script>
