const Layout = () => import('@/views/layout')
const Setting = () => import('@/views/setting')
const SettingBizParam = () => import('@/views/setting/bizParam')
const SettingSmartservice = () => import('@/views/setting/smartservice')
const SettingSmartserviceAdd = () => import('@/views/setting/smartservice/editModal')
const SettingSmartserviceDetail = () => import('@/views/setting/smartservice/detailModal')
const Role = () => import('@/views/setting/role')
const RoleSystem = () => import('@/views/setting/role/system')

const settingRouter = {
    path: '/manager',
    component: Layout,
    hide: true,
    parent:'settingRouter',
    meta: {
      title: '通用设置',
      requiresAuth: false,
      type:'admin',
      icon: '#icon-SetOutline',
      menusKey:8
    },
    children:[
          {
            path: 'BizParam',
            component: SettingBizParam,
            hide:false,
            parent:'setting',
            meta: { title: '系统设置', icon: '#icon-RepairOutline' ,parent: 'manager'}
          },{
            path: 'smartservice',
            component: SettingSmartservice,
            hide:false,
            parent:'setting',
            meta: { title: '工单服务', icon: '#icon-DiamondOutline1' ,parent: 'manager'}
          }, {
            path: 'smartservice/add',
            component: SettingSmartserviceAdd,
            hide:true,
            parent:'setting',
            meta: { title: '提交工单', icon: '#icon-DiamondOutline1' ,parent: 'manager'}
          }, {
            path: 'smartservice/update',
            component: SettingSmartserviceAdd,
            hide:true,
            parent:'setting',
            meta: { title: '编辑工单', icon: '#icon-DiamondOutline1' ,parent: 'manager'}
          }, {
            path: 'smartservice/detail',
            component: SettingSmartserviceDetail,
            hide:true,
            parent:'setting',
            meta: { title: '工单详情', icon: '#icon-DiamondOutline1' ,parent: 'manager'}
          }, {
            path: 'role',
            component: Role,
            hide: false,
            parent:'setting',
            meta: { title: '角色管理' , icon: '#icon-QueryOutline',parent: 'manager', requiresAuth: false, type:'role'},
            children:[{
              path: 'role-auth/:type_id/:title',
              component: RoleSystem,
              meta: { title: '' , icon: '#icon-QueryOutline'},
            }]
          },
    ]
}

export default settingRouter