const Layout = () => import('@/views/layout')
const Education = () => import('@/views/education')
const EducationSchedule = () => import('@/views/education/schedule')
const EducationAbsence = () => import('@/views/education/absence')
const EducationRate = () => import('@/views/education/rate')
const EducationUnClass = () => import('@/views/education/unClass')
const EducationAchievement = () => import('@/views/education/achievement')
const EducationCertificate = () => import('@/views/education/certificate')
const EducationPrepare = () => import('@/views/education/prepare')
const EducationWare = () => import('@/views/education/ware')
const EducationHomework = () => import('@/views/education/homework')
const EducationAskForLeave = () => import('@/views/education/askForLeave')
const EducationClass = () => import('@/views/education/class')
const EducationMySchedule = () => import('@/views/education/mySchedule')
const EducationZydp = () => import('@/views/education/zydp')
const EducationMessages = () => import('@/views/education/messages')
const EducationCourseware = () => import('@/views/education/ware')
const EducationLibrary = () => import('@/views/education/library')
const EducationAlbum = () => import('@/views/education/album')
const EducationRecall= () => import('@/views/education/recall')
const Students = () => import('@/views/students')
const EducationReview = () => import('@/views/education/review')
const EducationBriefing = () => import('@/views/education/briefing')
const EducationAudition = () => import('@/views/education/audition')

const educationRouter = {
    path: '/education',
    component: Layout,
    hide: true,
    parent:'educationRouter',
    meta: {
      title: '教务中心',
      requiresAuth: false,
      type:'education',
      icon: '#icon-EnshrineOutline',
      menusKey:3
    },
    children:[
          // {
          //   path: 'index',
          //   component: Education,
          //   hide: false,
          //   meta: { title: '教务中心', icon: '#icon-EnshrineOutline'}
          // }, 
          {
            path: 'mine/schedule',
            component: EducationMySchedule,
            hide: false,
            meta: { 
              title: '我的课表', 
              icon: '#icon-ScheduleOutline',
              requiresAuth: true,
              type:'schedule',
              parent: 'education'
            }
          }, 
          {
            path: 'mine/audition',
            component: EducationAudition,
            hide: false,
            meta: { 
              title: '我的试听' , 
              icon: '#icon-CompleteOutline',
              requiresAuth: true,
              type:'absence',
              parent: 'education'
            }
          }, 
          {
            path: 'mine/absence',
            component: EducationAskForLeave,
            hide: false,
            meta: { 
              title: '到课/缺勤' , 
              icon: '#icon-LetterOutline',
              requiresAuth: true,
              type:'absence',
              parent: 'education'
            }
          }, 
          {
            path: 'mine/classes',
            component: EducationClass,
            hide: false,
            meta: { 
              title: '我的班级' , 
              icon: '#icon-BookOutline',
              requiresAuth: true,
              type:'classes',
              parent: 'education'
            }
          }, 
          // {
          //   path: 'mine/students',
          //   component: Students,
          //   hide: false,
          //   parent:'dashboard',
          //   meta: { 
          //     title: '在籍学员', 
          //     icon: '#icon-PersonalOutline',
          //     requiresAuth: true,
          //     type:'students',
          //     parent: 'education'
          //   }
          // },
          {
            path: 'messages',
            component: EducationMessages,
            hide: false,
            meta: { 
              title: '课后反馈' , 
              icon: '#icon-ReferOutline',
              requiresAuth: false,
              type:'messages',
              parent: 'education'
            }
          },
          
          {
            path: 'mine/rate',
            component: EducationRate,
            hide: false,
            meta: { 
              title: '学员反馈' , 
              icon: '#icon-ScheduleOutline',
              requiresAuth: true,
              type:'rate',
              parent: 'education'
            }
          }, 
          {
            path: 'mine/homework',
            component: EducationReview,
            hide: false,
            meta: { 
              title: '作业点评' , 
              icon: '#icon-SmileOutline',
              requiresAuth: true,
              type:'homework',
              parent: 'education'
            }
          },
          // {
          //   path: 'mine/prepare',
          //   component: EducationMySchedule,
          //   hide: false,
          //   meta: { 
          //     title: '我的备课' ,
          //     icon: '#icon-SackOutline',
          //     requiresAuth: true,
          //     type:'prepare',
          //     parent: 'education'
          //   }
          // },
          {
            path: 'mine/ware',
            component: EducationCourseware,
            hide: false,
            meta: { 
              title: '教案内容' , 
              icon: '#icon-BoxOutline',
              requiresAuth: true,
              type:'ware',
              parent: 'education'
            }
          }, 
          // {
          //   path: 'mine/library',
          //   component: EducationLibrary,
          //   hide: false,
          //   meta: { 
          //     title: '学习资料' , 
          //     icon: '#icon-DuplicationOutline',
          //     requiresAuth: false,
          //     type:'library',
          //     parent: 'education'
          //   }
          // },
          {
            path: 'mine/library',
            component: EducationLibrary,
            hide: false,
            meta: { 
              title: '学习资料' , 
              icon: '#icon-DuplicationOutline',
              requiresAuth: false,
              type:'library',
              parent: 'education'
            }
          },
          {
            path: 'mine/album',
            component: EducationAlbum,
            hide: false,
            meta: { 
              title: '班级相册' , 
              icon: '#icon-DataOutline',
              requiresAuth: false,
              type:'album',
              parent: 'education'
            }
          },
          {
            path: 'mine/recall',
            component: EducationRecall,
            hide:false,
            meta: { 
              title: '教学回顾' , 
              icon: '#icon-VideoOutline',
              requiresAuth: false,
              type:'review',
              parent: 'education'
            }
          }
          // ,{
          //   path: 'mine/workbench',
          //   component: EducationBriefing,
          //   hide: false,
          //   meta: { title: '教务简报' , icon: '#icon-OperateOutline'}
          // }
          ,
          //  {
          //   path: 'schedule',
          //   component: EducationSchedule,
          //   hide: true,
          //   meta: { title: '课表管理' }
          // }, {
          //   path: 'absence',
          //   component: EducationAbsence,
          //   hide: true,
          //   meta: { title: '上课/请假记录' }
          // }, {
          //   path: 'rate',
          //   component: EducationRate,
          //   hide: true,
          //   meta: { title: '学员评价/课后反馈' }
          // }, {
          //   path: 'un_class',
          //   component: EducationUnClass,
          //   hide: true,
          //   meta: { title: '待排班学员' }
          // }, {
          //   path: 'achievement',
          //   component: EducationAchievement,
          //   hide: true,
          //   meta: { title: '学员成就' }
          // }, 
          {
            path: 'certificate',
            component: EducationCertificate,
            hide: false,
            meta: { 
              title: '学员证书' ,
              icon: '#icon-MedalOutline',
          }
          }, 
          // {
          //   path: 'prepare',
          //   component: EducationPrepare,
          //   hide: true,
          //   meta: { title: '备课中心' }
          // }, {
          //   path: 'ware',
          //   component: EducationWare,
          //   hide: true,
          //   meta: { title: '课件中心' }
          // }, {
          //   path: 'homework',
          //   component: EducationHomework,
          //   hide: true,
          //   meta: { title: '回家作业' }
          // }, 
    ]
}

export default educationRouter