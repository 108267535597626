<template>
  <a-modal v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认排课" width="80%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div class="clearfix table-tools">
            <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            v-model='paramsData.time'
                            :allowClear="false"
                            style="width:240px"
                            @change="(val, date)=>handleTime(val, date)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <v-gantt-chart 
           :startTime="startTime"
           :endTime="endTime"
           dataKey="id"
           :cellHeight="30"
           :cellWidth="30"
           :arrayKeys="arrayKeys"
           :datas="datas">
            <template v-slot:block="{ data,item }">
                <a-popover title="">
                    <template slot="content">
                    <p>时间：{{item.start.slice(10,16)}} -{{item.end.slice(10,16)}}</p>
                    <p>老师：{{item.teacher_name}}</p>
                    <p>人数：{{item.name}}</p>
                    <p>课程：{{item.course_name}}</p>
                    <p>教室：{{item.room_name}}</p>
                    </template>
                    <div v-if="data.type == '老师'" style="background:#00cca2;color:#fff;text-align:center;">{{item.name}}</div>
                    <div v-else style="background:#FCC955;color:#fff;text-align:center;">{{item.name}}</div>
                </a-popover>
            </template>
            <template v-slot:left="{ data }">
                <div style="display:flex;justify-content: space-between;padding: 0 5px;">
                    <span style="width: 150px;display: block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{data.name}}</span>
                    <span>{{data.type}}</span>
                </div>
            </template>
            <template v-slot:timeline="{ day , getTimeScales }">
                <div>
                    <div>{{moment(day.$d).format('MM/DD 【dddd】')}}</div>
                    <div style="display:flex">
                        <span style="flex:1" v-for="(i,index) in getTimeScales(day)" :key="index"> {{i.format('HH')}}</span>
                    </div>
                </div>
            </template>
            <template v-slot:title>老师/教室</template>
        </v-gantt-chart>
    </div>
  </a-modal>
</template>

<script>
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'availableTeacher',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                loading: false,
                confirmLoading: false,
                startTime: moment().startOf('d').toString(),//时间轴开始时间
                endTime: moment() 
                    .add(7, "d")
                    .endOf('d').toString(), //时间结束时间
                datas: [], // gantt数据
                arrayKeys: ["gtArray", "error"],
                paramsData:{
                    start_date:moment().startOf('d').format('YYYY-MM-DD'),
                    end_date:moment() .add(7, "d").endOf('d').format('YYYY-MM-DD'),
                },
            }
        },
        mixins: [ ranges ],
        props:['studio_id'],
        created () {
            this.visible = true
            this.getTeacher()
        },
        methods: {
            moment,
            async getTeacher(){
                this.loading = true
                await this.$store.dispatch('courseScheduleAvailableTeacherAction',{search:{studio_id:this.studio_id,start_date:this.paramsData.start_date,end_date:this.paramsData.end_date}})
                .then(res=>{
                    this.datas = res.data
                    this.loading = false
                })
            },
            handleOk() {},
            handleCancel() {
                this.parent.hideTeacher()
            },
            handleTime(val,date){
                this.paramsData.time = date
                this.paramsData.start_date = moment(date[0]).format('YYYY-MM-DD')
                this.paramsData.end_date = moment(date[1]).format('YYYY-MM-DD')
                this.startTime = moment(date[0]).startOf('d').toString()
                this.endTime = moment(date[1]).endOf('d').toString()
            },
            searchList(){
                this.getTeacher()
            },
        },
    }
</script>

<style lang="scss">
    .gantt-block-item{
        margin-top: 5px;
    }
    .gantt-leftbar-item{
        line-height: 30px;
    }
</style>