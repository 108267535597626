<template>
    <div style="height:100%">
        <div class="messages">
            <div 
                class="messages-list"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading && list.length==0" color="#00cca2" spinner="spinner"/>
                <div class="messages-send">
                    <div>
                        <a-mentions v-model="mentionsValue" rows="4" placeholder="请输入动态内容"></a-mentions>
                    </div>
                    <div class="messages-send-icon">
                        <div class="messages-send-iconL">
                            <a-upload
                                name="file" 
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeImageUpload"
                                :showUploadList="false"
                                listType="picture"
                                @change="changeFile">
                                <a-button>
                                    <a-icon type="picture" /> 图片
                                </a-button>
                            </a-upload> 
                            <a-upload 
                                name="file"
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeUpload"
                                :showUploadList="false"
                                @change="changeFile2">
                                <a-button>
                                    <a-icon type="sound" /> 音频
                                </a-button>
                            </a-upload>
                            <a-upload 
                                name="file"
                                multiple
                                class="messages-send-upload"
                                :data="uploadParams"
                                :action="uploadUrl" 
                                :beforeUpload="beforeUpload"
                                :showUploadList="false"
                                @change="changeFile3">
                                <a-button>
                                    <a-icon type="video-camera" /> 视频
                                </a-button>
                            </a-upload>
                            <div>
                                <a-popover v-model="zlVisible" title="" trigger="click" placement="bottom">
                                    <div slot="content">
                                        <a-select allowClear placeholder="请选择" v-model="messageKnowledge" style="width:150px">
                                            <a-select-option v-for="(item, index) of zlList" :key="index" :value="item.id">{{ item.file_name }}</a-select-option>
                                        </a-select>
                                    </div>
                                    <a-button>
                                        <a-icon type="file" /> 资料
                                    </a-button>
                                </a-popover>
                            </div>
                        </div>
                        <div>
                            <a-button @click="toSend" type="primary">发布</a-button>
                        </div>
                    </div>
                    <div>
                        <div v-for="(item, idx) of fileList2" :key="idx" class="messages-send-audio">
                            <audio :src="`http://v01.wedots.cn/${item.response.key}`" controls />
                            <a-icon @click="delUpload(idx,'fileList2')" class="messages-send-audio-icon" type="delete" />
                        </div>
                        <div v-if="audioLoading" class="messages-send-audio-loading"><vue-element-loading :active="audioLoading" color="#00cca2" spinner="spinner"/></div>
                    </div>
                    <div class="messages-send-img">
                        <div class="messages-send-imgBox" v-for="(item,index) in fileList" :key="index">
                            <img :preview="index" :src="`http://i01.wedots.cn/${item.response.key}`">
                            <div @click.stop="delUpload(index,'fileList')" class="messages-send-close">
                                <a-icon type="close" />
                            </div>
                        </div>
                        <div @click="showVideoModal(`http://v01.wedots.cn/${video.response.key}`)" v-for="(video, idx) of fileList3" :key="idx + 100"  class="messages-send-video">
                            <video style="background:#000;border-radius:2px" :src="`http://v01.wedots.cn/${video.response.key}`"  width="60" height="60">
                                Your browser does not support the video tag.
                            </video>
                            <div class="messages-send-video-icon">
                                <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                            </div>
                            <div @click.stop="delUpload(index,'fileList3')" class="messages-send-close">
                                <a-icon type="close" />
                            </div>
                        </div>
                        <div v-if="upLoadLoading" class="messages-send-loading">
                            <vue-element-loading :active="upLoadLoading" color="#00cca2" spinner="spinner"/>
                        </div>
                    </div>
                </div>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <a-card class="messages-card">
                            <div class="messages-card-nav">
                                <a-avatar :src="item.creater.teacher_avartar" :size="35" icon="user" />
                                <div class="messages-card-name">
                                    <div>{{item.creater.teacher_name}}</div>
                                    <!-- <a-icon :type="item.message_to.to=='student'?'user':'team'" />  -->
                                    <div style="font-size: 12px;">{{item.message_created_at}}  ·  <span style="color:#00cca2"> {{item.message_to.to_name}}</span></div>
                                </div>
                            </div>
                            <div class="messages-card-mar messages-card-text">
                                {{item.message_content}}
                            </div>
                            <div class="messages-card-mar messages-card-img">
                                <img :preview="index" v-for="(it,ind) in item.message_images" :key="ind" :src="it">
                                <div @click="showVideoModal(video)" v-for="(video, idx) of item.message_video" :key="idx + 100"  class="messages-card-video">
                                    <video style="background:#000;border-radius:2px" :src="video"  width="60" height="60">
                                        Your browser does not support the video tag.
                                    </video>
                                    <div class="messages-card-video-icon">
                                        <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.message_audio.length > 0" class="messages-card-mar">
                                <audio v-for="(img, idx) of item.message_audio" :key="idx" :src="img.audio_path" controls />
                            </div>
                            <div class="messages-card-mar messages-card-rate" v-if="item.message_template_content.length > 0">
                                <img class="messages-card-rateImg" :src="`https://i01.wedots.cn/wxapp/star/${report(item.message_template_content)}.png`">
                                <div>
                                    <div class="messages-card-rateBox" v-for="(it,ind) in item.message_template_content" :key="ind">
                                        <div class="messages-card-rateName">{{it.name}}</div>
                                        <a-rate :default-value="it.star" disabled allow-half />
                                    </div>
                                </div>
                            </div>
                            <template slot="actions" class="ant-card-actions">
                                <div><a-icon key="eye" type="eye" /><span style="margin-left:5px">{{item.view_count}}</span></div>
                                <div @click="item.comment_show = !item.comment_show"><a-icon key="message" type="message" /><span style="margin-left:5px">{{item.comment_count}}</span></div>
                                <div><a-icon key="like" type="like" /><span style="margin-left:5px">{{item.like_count}}</span></div>
                                <!-- <a-icon key="menu" type="menu" /> -->
                            </template>
                        </a-card>
                        <div v-if="item.comments.length > 0 && item.comment_show" class="messages-comments">
                            <a-comment v-for="(it,j) in item.comments" :key="it.commnet_id" :class="j == item.comments.length - 1?'':'messages-comments-border'">
                                <a slot="author">{{it.commnet_name}}</a>
                                <a-avatar
                                slot="avatar"
                                :src="it.commnet_avatar"
                                />
                                <p slot="content">
                                    {{it.commnet_content}}
                                </p>
                                <span slot="datetime">{{it.comment_at}}</span>
                                <a-comment v-for="i in it.commnet_children" :key="i.commnet_id">
                                    <a slot="author">{{i.commnet_name}}</a>
                                    <a-avatar
                                    slot="avatar"
                                    :src="i.commnet_avatar"
                                    />
                                    <p slot="content">
                                        {{i.commnet_content}}
                                    </p>
                                    <span slot="datetime">{{i.comment_at}}</span>
                                </a-comment>
                            </a-comment>
                        </div>
                    </a-list-item>
                    <div slot="footer">
                        <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                        <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                    </div>
                </a-list>
            </div>
        </div>
        <videoModal v-if="isVideoModal" :video='video'/>
    </div>
</template>

<script>
    import moment from 'moment'
    import url from '@/utils/URL'
    import { delay } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    import videoModal from '@/views/education/review/videoModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'messages',
        provide() {
            return {
                parent: this
            }
        },
        props: {
            id: Number || String,
            toRefresh:Number
        },
        data() {
            return {
                busy: true,
                visible: false,
                zlVisible: false,
                loading: false,
                stuLoading: false,
                upLoadLoading: false,
                audioLoading: false,
                list:[],
                teachers:[],
                video:'',
                isVideoModal:false,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                fileList: [],
                fileList2: [],
                fileList3: [],
                zlList:[],
                messageKnowledge:undefined,
                student_id:'',
                student_name:'',
                mentionsValue:'',
                students:[]
            }
        },
        components: {
            videoModal,
        },
        directives: { infiniteScroll },
        mixins:[ranges],
        created () {
            this.rangesValue = []
            this.getList()
            this.getZlList()
        },
        methods: {
            async getList() {
                this.loading = true
                const { searchParams } = this
                searchParams.search.classes_id = this.id
                let res = await this.$store.dispatch('daMessagesClassesAction', { data: searchParams })
                if(this.searchParams.page == 1){
                    this.busy = false
                }
                if(res.items.length >0){
                    this.list = [...this.list,...res.items]
                }
                if(res._meta.totalCount == this.list.length){
                    this.busy = true
                }
                this.pageParams = res._meta
                this.loading = false
            },
            async getZlList() {
                let res = await this.$store.dispatch('zlShareListAction', { })
                this.zlList = res.items
            },
            report(e){
                var num=0
                e.forEach(function (item, index){
                    num += Number(item.star*2)
                })
                var data = parseInt(num/e.length)/2
                if (data.toString().indexOf('.')==-1){
                    data=data.toString()+'.0'
                }
                return data
            },
            showVideoModal(src){
                this.video = src
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getList()
                }
            },
            async beforeImageUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            async beforeUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('massTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeFile({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList.push(file)
                    this.upLoadLoading = false
                }
                console.log(file,fileList)
            },
            changeFile2({ file, fileList }) {
                this.audioLoading = true
                if(file.status == "done"){
                    this.fileList2.push(file)
                    this.audioLoading = false
                }
                console.log(fileList)
            },
            changeFile3({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList3.push(file)
                    this.upLoadLoading = false
                }
                console.log(fileList)
            },
            delUpload(index,name){
                this[name].splice(index,1)
            },
            async toSend(){
                let params = {}
                if(!this.mentionsValue){
                    this.$message.warning('请输入动态内容!!')
                    return false
                }
                params.message_content = this.mentionsValue
                if (this.fileList.length) {
                    let arr = []
                    for (let file of this.fileList) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    params.message_images = arr
                }
                if (this.fileList2.length) {
                    let arr = []
                    for (let file of this.fileList2) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    params.message_audios = arr
                }
                if (this.fileList3.length) {
                    let arr = []
                    for (let file of this.fileList3) {
                    if (file.response.key) {
                        arr.push(file.response.key)
                    }
                    }
                    params.message_videos = arr
                }
                if(this.messageKnowledge){
                    params.messageKnowledge = this.messageKnowledge
                }
                params.class_id = this.id
                await this.$store.dispatch('daMessagesPublishAction', { data: params })
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.searchList()
                    this.student_id = ''
                    this.student_name = ''
                    this.mentionsValue = ''
                    this.fileList = []
                    this.fileList2 = []
                    this.fileList3 = []
                    this.messageKnowledge = undefined
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            searchList(){
                this.busy = true
                this.searchParams.page = 1
                this.list = []
                this.getList()
            },
        },
    }
</script>

<style lang="scss" scoped>
    .messages{
        &-list{
            height: calc(100vh - 205px);
            overflow-y: auto;
        }
        &-search{
            width: 700px;
            margin: 0 auto;
        }
        &-send{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-audio{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                &-icon{
                    margin-left: 10px;
                    font-size: 24px;
                    cursor: pointer;
                }
                &-loading{
                    width: 300px;
                    height: 54px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
                margin-right: 5px;
                margin-bottom: 5px;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        &-card{
            width: 700px;
            margin: 0 auto;
            &-nav{
                display: flex;
            }
            &-name{
                margin-left: 20px;
            }
            &-mar{
                margin-left: 55px;
                margin-top: 10px;
            }
            &-text{
                white-space: normal;
                word-break: break-all;
            }
            &-img{
                display: flex;
                img{
                    height: 60px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }
            }
            &-video{
                display: inline-block;
                position: relative;
            }
            &-video-icon{
                position: absolute;
                top: 15px;
                left: 15px;
                background: #000;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
            }
            &-rate{
                display: flex;
                align-items: center;
            }
            &-rateImg{
                width: 100px;
                margin-right: 50px;
            }
            &-rateBox{
                display: flex;
                align-items: center;
            }
            &-rateName{
                width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis
            }
        }
        &-comments{
            width: 700px;
            margin: 0 auto;
            background: #fff;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 12px 24px;
        }
        .ant-list-item{
            border-bottom: none;
            display: block;
        }
    }
</style>
<style lang="scss">
    .messages{
        .ant-comment-inner{
            padding-top: 5px;
            padding-bottom: 0px;
            p{
                margin-bottom:0px;
                padding-bottom: 5px;
            }
        }
        .messages-comments-border{
            p{
                border-bottom: 1px solid #e8e8e8;
            }
        }
    }
</style>