import url from '@/utils/URL'
import request from '@/utils/request'

export function huidong(data) {
  return request({
    url: url.huidong,
    method: 'POST',
    data
  })
}

export function huidongCheck(data) {
  return request({
    url: url.huidongCheck,
    method: 'POST',
    data
  })
}

export function huidongDelete(data) {
  return request({
    url: url.huidongDelete,
    method: 'POST',
    data
  })
}

export function huidongExport(data) {
  return request({
    url: url.huidongExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function huidongSendNotify(data) {
  return request({
    url: url.huidongSendNotify,
    method: 'POST',
    data
  })
}

export function huidongApplyDetail(data) {
  return request({
    url: url.huidongApplyDetail,
    method: 'POST',
    data
  })
}

export function huidongApplyUpdate(data) {
  return request({
    url: url.huidongApplyUpdate,
    method: 'POST',
    data
  })
}

export function huidongApplyPreview(data) {
  return request({
    url: url.huidongApplyPreview,
    method: 'POST',
    data
  })
}

export function huidongReportEmployee(data) {
  return request({
    url: url.huidongReportEmployee,
    method: 'POST',
    data
  })
}

export function huidongReportEmployeeWeek(data) {
  return request({
    url: url.huidongReportEmployeeWeek,
    method: 'POST',
    data
  })
}

export function huidongReportStudio(data) {
  return request({
    url: url.huidongReportStudio,
    method: 'POST',
    data
  })
}

export function huidongReportStudioWeek(data) {
  return request({
    url: url.huidongReportStudioWeek,
    method: 'POST',
    data
  })
}

export function huidongReportChannel(data) {
  return request({
    url: url.huidongReportChannel,
    method: 'POST',
    data
  })
}

export function huidongReportHotLeads(data) {
  return request({
    url: url.huidongReportHotLeads,
    method: 'POST',
    data
  })
}

export function huidongReportHotLeadsFollow(data) {
  return request({
    url: url.huidongReportHotLeadsFollow,
    method: 'POST',
    data
  })
}

export function huidongPrint(data) {
  return request({
    url: url.huidongPrint,
    method: 'POST',
    data
  })
}

export function huidongPrintWeek(data) {
  return request({
    url: url.huidongPrintWeek,
    method: 'POST',
    data
  })
}

export function huidongPrintDelete(data) {
  return request({
    url: url.huidongPrintDelete,
    method: 'POST',
    data
  })
}

export function huidongPrintExport(data) {
  return request({
    url: url.huidongPrintExport,
    method: 'POST',
    data
  })
}

export function zhuqiaoApply(data) {
  return request({
    url: url.zhuqiaoApply,
    method: 'POST',
    data
  })
}

export function zhuqiaoApplyDetail(data) {
  return request({
    url: url.zhuqiaoApplyDetail,
    method: 'POST',
    data
  })
}

export function zhuqiaoApplyUpdate(data) {
  return request({
    url: url.zhuqiaoApplyUpdate,
    method: 'POST',
    data
  })
}

export function zhuqiaoApplyExport(data) {
  return request({
    url: url.zhuqiaoApplyExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}
