import url from '@/utils/URL'
import request from '@/utils/request'

export function absenceCancel(params) {
  return request({
    url: url.absenceCancel,
    method: 'POST',
    ...params
  })
}

export function absenceCancelExport(data) {
  return request({
    url: url.absenceCancelExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function absenceApply(params) {
  return request({
    url: url.absenceApply,
    method: 'POST',
    ...params
  })
}

export function absenceApplyConfirm(params) {
  return request({
    url: url.absenceApplyConfirm,
    method: 'POST',
    ...params
  })
}

export function absenceApplyRefuse(params) {
  return request({
    url: url.absenceApplyRefuse,
    method: 'POST',
    ...params
  })
}

export function absenceSignin(params) {
  return request({
    url: url.absenceSignin,
    method: 'POST',
    ...params
  })
}
export function absenceSigninCancel(params) {
  return request({
    url: url.absenceSigninCancel,
    method: 'POST',
    ...params
  })
}
export function absenceSigninCheckin(params) {
  return request({
    url: url.absenceSigninCheckin,
    method: 'POST',
    ...params
  })
}
export function absenceSigninAttend(params) {
  return request({
    url: url.absenceSigninAttend,
    method: 'POST',
    ...params
  })
}
export function absenceSigninExport(data) {
  return request({
    url: url.absenceSigninExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}