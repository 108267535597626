<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="32.5%" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import moment from 'moment'

const formData = [
  {
    name: 'contact_type',
    label: '联系关系',
    type: 'select',
    rules: [],
    items: {
      data: 'studentContactTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'contact_name',
    label: '联系人姓名',
    type: 'input',
    rules: [{ required: true, message: '请输入联系人姓名!' }]
  },
  {
    name: 'contact_tellphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: '请输入联系电话!' }]
  },
  {
    name: 'contact_wechat',
    label: '联系微信',
    type: 'input',
    rules: []
  },
  {
    name: 'contact_email',
    label: '联系邮箱',
    type: 'input',
    rules: []
  },
]

export default {
  name: 'EditModal',
  mixins: [ editModal ],
  inject: ['parent'],

  data() {
    return {
      formData,
      seleteItems: {
        studentContactTypes: C_ITEMS.studentContactTypes
      },
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    let { item, authArr } = this
    if (item) {
      this.ModalTitle = '编辑联系人'
      let detail = await this.getDetail()
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = detail[d.name]
      }
      this.form.setFieldsValue(formFields)
    } else {
      this.ModalTitle = '新增联系人'
    }
  },

  methods: {
    async getDetail() {
      let { id } = this.item
      let res = await this.$store.dispatch('cCustomerContactDetailAction', {id, task_id: this.parent.item.task_id})
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.id = item.id
          }
          params.task_id = this.parent.item.task_id
          let res = await this.$store.dispatch(item ? 'cCustomerUpdateContactAction' : 'cCustomerAddContactAction', params)
          if(res) {
            form.resetFields()
            this.parent.submitModal()
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },

  }
}
</script>
