import url from '@/utils/URL'
import request from '@/utils/request'

export function dashboardIndex(params) {
  return request({
    url: url.dashboardIndex,
    method: 'POST',
    ...params
  })
}

export function dashboardIndexCrm(params) {
  return request({
    url: url.dashboardIndexCrm,
    method: 'POST',
    ...params
  })
}

export function dashboardIndexEducation(params) {
  return request({
    url: url.dashboardIndexEducation,
    method: 'POST',
    ...params
  })
}

export function dashboardIndexFinance(params) {
  return request({
    url: url.dashboardIndexFinance,
    method: 'POST',
    ...params
  })
}

export function dashboardTeacher(params) {
  return request({
    url: url.dashboardTeacher,
    method: 'POST',
    ...params
  })
}

export function dashboardCc(params) {
  return request({
    url: url.dashboardCc,
    method: 'POST',
    ...params
  })
}