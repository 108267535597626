<template>
<div>
  <a-breadcrumb class="breadcrumb" v-if="!isModal">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>素材管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
  <div class="common zlgx">
    <category />
    <div class="c-main">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-upload name="file" 
                multiple
                accept='.png,.jpeg,.jpg'
                :showUploadList="false"
                :data="uploadParams"
                :action="uploadUrl" 
                :beforeUpload="beforeUpload"
                @change="changeFile">
                <a-button type="primary" icon="plus">上传图片</a-button>
              </a-upload>
            </a-form-item>
            <a-form-item>
              <a-button v-if='!imgCheckable' v-show="!isSingle" icon="delete" @click="toDeleteImages">批量删除</a-button>
              <a-button v-else icon="delete" @click="cancelDeleteImages">取消删除</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
        </div>
      </div>
      <div class="zl-list">
        <div class="title">
          <h3>{{ currentCategory.name }}</h3>
        </div>
        <div class="image-wrap" style="padding: 20px 10px;height:calc(100vh - 230px);overflow-y:auto;">
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <a-empty v-if="list.length == 0" />
          <div v-if='imgCheckable' style="margin-bottom:15px">
            <span style='margin-right:5px'>选中{{checkedValues.length}}项</span>
            <a-button @click='deleteImages' size='small'>确认删除</a-button>
          </div>
          <vue-select-image 
            :dataImages="list"
            :is-multiple="isMultiple"
            :use-label='true'
            w='100px'
            h='100px'
            ref='vueSelectImage'
            :selectedImages="initialSelected"
            @onselectimage='onselectimage'
            @onselectmultipleimage="onSelectMultipleImage">
          </vue-select-image>
          <div class="page">
            <a-pagination
              :pageSizeOptions="pageSizeOptions"
              :total="pageParams.totalCount"
              showSizeChanger
              :pageSize="pageParams.perPage"
              v-model="current"
              @change="changePage"
              @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                  <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                  <span v-if="props.value==='100'">100条/页</span>
                </template>
              </a-pagination>
          </div>
        </div>
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</div>
</div>
</template>

<script>
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import category from './category/index'
import editModal from './editModal'

export default {
  name: 'zlgx',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  components: {
    category,
    editModal
  },

  props: {
    isModal: Boolean,
    isSingle:String
  },

  data() {
    return {
      loading: false,
      C_ITEMS,
      currentCategory: { },
      list: [],

      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
        },
        "sort": ''
      },

      visible: false,
      modalData: null,
      isMultiple:true,
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      checkedValues: [],
      initialSelected:[],
      imgCheckable: false,
    }
  },

  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    if(this.isSingle){
      this.isMultiple = false
    }
  },

  watch: {
    currentCategory() {
      this.getList()
    },
    isSingle(){
      console.log(123)
    }
  },

  methods: {
    changeCategory(item) {
      this.currentCategory = item
    },
    async getList() {
      this.loading = true
      const { searchParams } = this
      const { path_id } = this.currentCategory
      let res = await this.$store.dispatch('materialAction', { params: { path_id: path_id }, data: searchParams })
      res.items.forEach((item)=>{
        item.src = item.thumb
        item.id= item.image_id
        item.alt = item.name
      })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },

    showEditModal(item) {
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },

    checkedImage(checkedValues) {
      console.log(checkedValues)
      this.checkedValues = checkedValues
    },
    toDeleteImages(){
        this.imgCheckable = true
    },
    deleteImages() {
      if (this.checkedValues.length <= 0) {
        return 
      }
      let that = this
      this.$confirm({
        title: `确定要删除选中的图片吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('materialDeleteAction', { data: {image_id: that.checkedValues} })
            if (res.code ==200) {
              that.$refs.vueSelectImage.multipleSelected = []
              that.getList()
              resolve(res)
              that.$message.success('操作成功!')
              that.checkedValues = []
            }else{
              that.$message.error('操作失败!')
            }
          }).catch(error => console.log(error))
        }
      })
    },
    cancelDeleteImages(){
        this.$refs.vueSelectImage.multipleSelected = []
        this.checkedValues = []
        this.imgCheckable = false
    },
    async beforeUpload(file) {
      console.log(1)
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    async changeFile(file) {
      console.log(2)
      const { path_id } = this.currentCategory
      if (file.file && file.file.status === 'done') {
        console.log(file)
        let {size, type} = file.file
        const data = {
          path_id,
          image: [{
            name: file.file.name,
            path: file.file.response.key,
            size,
            type
          }]
        }
        let res = await this.$store.dispatch('materialUploadAction', { data })
        if (res) {
          this.getList()
        }
      }
    },

    chooseImage(image) {
      if (this.parent.handleOk) {
        this.parent.handleOk(image)
      }
    },
    onselectimage(a){
      if (this.parent.handleOk) {
        this.parent.handleOk(a)
      }
    },
    onSelectMultipleImage(a){
      console.log(a)
      let arr = []
      if(a.length != 0){
        a.forEach(item=>{
          arr.push(item.id)
        })
      }
      this.checkedValues = arr
    },
  }
}
</script>
<style lang="scss">
  .vue-select-image__item{
    margin-left:0px !important;
  }
  .vue-select-image__thumbnail{
    label{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        display:inline-block;
        text-align:center;
        width:100px;
        margin-top:4px;
    }
  }
</style>
