import Vue from 'vue'
import VCharts from 'v-charts'
import App from './App.vue'
import router from './router'
import store from './store'
import VueStorage from 'vue-ls'
import config from './utils/const'
import Antd from 'ant-design-vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import ComponentList from './components'
// import Vue2Editor from "vue2-editor"
import VueAMap from 'vue-amap'
import preview from '@mvpleung/vue-photo-preview'
import AudioVisual from 'vue-audio-visual'
import CertPoster from 'vue-cert-poster'

// import VueBetterDPlayer from 'vue-better-dplayer'

// import VueDPlayer from 'vue-dplayer'
// import dplayer from 'vue-dplayer2/player';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
// import 'vue-dplayer/dist/vue-dplayer.css'
import 'ant-design-vue/dist/antd.less'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import '@/styles/index.scss'
import VueClipboard from 'vue-clipboard2'
import LiquorTree from 'liquor-tree'
import '@mvpleung/vue-photo-preview/dist/skin.css'
import FullCalendar from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import Distpicker from 'v-distpicker'
import { message } from 'ant-design-vue'
import './permission'
import VueElementLoading from 'vue-element-loading'
import VueSelectImage from 'vue-select-image'
require('vue-select-image/dist/vue-select-image.css')
import vueEventCalendar from 'vue-event-calendar'
import 'vue-event-calendar/dist/style.css'
import vGanttChart from 'v-gantt-chart'
import waterfall from 'vue-waterfall2'
import VueTour from 'vue-tour'
import audio from 'vue-mobile-audio'
import dataV from '@jiaminghi/data-view'
import BaiduMap from 'vue-baidu-map'

import Icon from 'vue-awesome/components/Icon';
require('vue-tour/dist/vue-tour.css')

import FastClick from 'fastclick'
FastClick.attach(document.body)
FastClick.prototype.focus = function(targetElement) {
  var length
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
    length = targetElement.value.length
    targetElement.focus()
    targetElement.setSelectionRange(length, length)
  } else {
    targetElement.focus();
  }
}


// import AgoraRtcVue from "agora-rtc-vue"
// import "agora-rtc-vue/lib/agora-rtc-vue.css"
// Vue.use(AgoraRtcVue)
Vue.use(audio)
Vue.use(VueTour)
Vue.use(vueEventCalendar, {locale: 'zh',color: '#00cca2'})
Vue.use(VueSelectImage)
Vue.use(FullCalendar)
Vue.use(LiquorTree)
Vue.use(VueClipboard)
Vue.use(VCharts)
Vue.use(vGanttChart)
Vue.use(waterfall)



Vue.use(dataV)
// 全局注册图标
Vue.component('icon', Icon);

// Vue.use(DPlayer)
Vue.config.productionTip = false
Vue.use(VueStorage, config.STORAGE_CONFIG)

Vue.use(Antd)
Vue.use(PerfectScrollbar)
// Vue.use(Vue2Editor)
// Vue.use(dplayer);
// Vue.use(VueBetterDPlayer)
Vue.use(AudioVisual)
Vue.use(CertPoster)
// Vue.use(VueDPlayer)
Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(VueAMap)

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Cu2jpaPFLUy982u04QKed1hZYGMGO4ME'
})

// VueAMap.initAMapApiLoader({
//   key: 'daecb1ae2ba780031150e9eaad06d158',
//   plugin: ['AMap.Autocomplete', 'AMap.Geocoder','AMap.Geolocation'],
//   v: '1.4.6'
// })

for (const component of ComponentList) {
  Vue.use(component)
}

// 适配flex
import '@/common/flexible.js';
//引入echart
import echarts from 'echarts'
//引入rsa
import Rsa from "@/utils/rsa.js"
Vue.prototype.$echarts = echarts
Vue.prototype.$message = message;
Vue.prototype.Rsa = Rsa
message.config({
    duration: 2,// 持续时间
    top:`30%`, // 到页面顶部距离
    maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
Vue.component('v-distpicker', Distpicker)
// Vue.component('vue-dplayer', dplayer);
Vue.component('VueElementLoading', VueElementLoading)
// Vue.component('v-chart', ECharts)

Vue.use(preview)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')