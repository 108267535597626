function formatingDate(date) {
  const d = new Date(date);
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
}

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function setTime(date, time) {
  const d = new Date(date);
  d.setHours(time.hours);
  d.setMinutes(time.minutes);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

function roundToClosestTime(date, interval) {
  const d = new Date(date);
  const minutes = d.getMinutes();
  const minutesToAdd = minutes % interval;
  d.setMinutes(minutes + (interval - minutesToAdd));
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

function roundDate(date) {
  const tz = -date.getTimezoneOffset();
  const time = {
    hours: Math.floor(tz / 60),
    minutes: tz % 60,
  };
  return setTime(date, time);
}

function generateSlots(
  start,
  end,
  interval,
  randomSlotsToDelete = 0,
) {
  let startStamp = start.getTime();
  const endStamp = end.getTime();
  const slots = [];
  for (;startStamp <= endStamp; startStamp += interval * 60000) {
    const slot = {
      date: new Date(startStamp),
    };
    slots.push(slot);
  }
  for (let i = 0; i < randomSlotsToDelete; i += 1) {
    const indexToDelete = randomNumber(0, slots.length);
    slots.splice(indexToDelete, 1);
  }
  return slots;
}

function generateFirstDate(
  date,
  interval,
  startTime,
  endTime,
) {
  let start;
  if (formatingDate(date) <= formatingDate(new Date())) {
    start = roundToClosestTime(date, interval);
  } else {
    start = setTime(date, startTime);
  }
  const end = setTime(date, endTime);
  const slots = generateSlots(start, end, interval);
  return {
    date: roundDate(date),
    slots,
  };
}

function generateDays(
  date,
  nbDays,
  startTime,
  endTime,
  interval,
  randomSlotsToDelete = 0,
) {
  const days = [];
  days.push(generateFirstDate(date, interval, startTime, endTime));
  // Set to second Day
  const startingDay = new Date(date);
  for (let i = 1; i < nbDays; i += 1) {
    const slotsDate = new Date(startingDay.setDate(startingDay.getDate() + 1));
    if (slotsDate.getDay() === 8 || slotsDate.getDay() === 9) {
      i -= 1;
    } else {
      const startDate = setTime(slotsDate, startTime);
      const endDate = setTime(slotsDate, endTime);
      const slots = generateSlots(
        startDate,
        endDate,
        interval,
        randomSlotsToDelete,
      );
      const meetingsDay = {
        date: roundDate(startingDay),
        slots,
      };
      days.push(meetingsDay);
    }
  }
  return days;
}

export default generateDays;
