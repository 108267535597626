<template>
    <div class="contacts">
        <div 
            class="contacts-list"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            ref="scrollbox"
        >
            <vue-element-loading :active="loading && list.length ==0" color="#00cca2" spinner="spinner"/>
            <a-list item-layout="horizontal" :data-source="list">
                <a-list-item @mouseover="handleOver(index,2)" @mouseout="handleOut(index)" :class="{'contacts-item-bg':checkIndex == index,'contacts-item-bgF':chatIndex == index}" slot="renderItem" slot-scope="item, index">
                    <div @click="changeChat(item,index)" class="contacts-item">
                        <div style="margin:0 10px">
                            <img style="border-radius:2px" v-if="item.session_type == 'staff_group'" src="@/assets/neibuqunliao.svg" />
                            <img style="border-radius:2px" v-if="item.session_type == 'external_group'" src="@/assets/qunliao.svg" />
                            <a-avatar v-if="item.session_type != 'staff_group' && item.session_type != 'external_group'" shape="square" :size="46" icon="user" :src="item.avatar" />
                        </div>
                        <div style="flex:1">
                            <div class="contacts-item-info">
                                <span class="contacts-item-name">{{item.name}}</span>
                                <!-- <img style="margin-left:5px" v-if="item.session_type == 'external_group' || item.session_type == 'ext_user'" src="@/assets/out.svg"> -->
                                <span class="contacts-item-time">{{item.msgtime_txt}}</span>
                            </div>
                            <div class="contacts-item-text">{{item.last_msg_thumb}}</div>
                        </div>
                    </div>
                </a-list-item>
                <div slot="footer">
                    <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                </div>
            </a-list>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import infiniteScroll from 'vue-infinite-scroll'
    import { parsingEmoji } from '@/utils/emjoymethod'
    import '@/styles/emjoy.css'
    export default {
        name:'contacts',
        inject: ['parent'],
        data() {
            return {
                myHeight:0,
                busy:true,
                loading:false,
                list:[],
                searchParams: {
                    "page": 1,
                    "per-page": 20,
                    "search": {},
                },
                chatIndex:-1,
                checkIndex:0,
                chatCount:0,

            }
        },
        props: {
            item: Object,
            leadsId: Number,
            isTab:String
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 320}px`
            },
        },
        directives: { infiniteScroll },
        created () {
            this.myHeight = this.windowHeight()
            this.getList(1)
            var that = this
            document.onkeydown = function(e) {
                let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
                //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
                if(e1 && e1.keyCode == 40){
                    if(that.checkIndex != that.list.length-1 && that.list.length>0){
                        that.checkIndex = that.checkIndex + 1
                        that.changeChat(that.list[that.checkIndex],that.checkIndex)
                        that.$refs.scrollbox.scrollTop = that.$refs.scrollbox.scrollTop + 71
                    }
                }
                if(e1 && e1.keyCode == 38){
                    if(that.checkIndex != 0 && that.list.length>0){
                        that.checkIndex = that.checkIndex - 1
                        that.changeChat(that.list[that.checkIndex],that.checkIndex)
                        that.$refs.scrollbox.scrollTop = that.$refs.scrollbox.scrollTop - 71
                    }
                }
            }
        },
        methods: {
            moment,
            async getList(val){
                this.loading = true
                this.searchParams.customer_id = this.item.customer_id
                await this.$store.dispatch('cCustomerWxworkContactAction', this.searchParams)
                .then(res=>{
                    this.chatCount = res._meta.totalCount
                    if(res.items.length >0){
                        if(this.searchParams.page == 1){
                            this.list = res.items
                            this.busy = false
                        }else{
                            this.list = [...this.list,...res.items]
                        }
                    }else{
                        this.busy = true
                    }
                    if(val && res.items.length >0){
                        this.parent.changeValue(res.items[0])
                    }
                    this.pageParams = res._meta
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            changeChat(item,index){
                this.parent.changeValue(item)
                this.checkIndex = index
            },
            async handleInfiniteOnLoad(){
                if(!this.busy){
                    await this.searchParams.page++
                    await this.getList()
                }
            },
            handleOver(index,num){
                this.chatIndex = index
            },
            handleOut(index){
                this.chatIndex = -1
            },
            convertText2Emoji(text){
                return parsingEmoji(text)
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.pageParams.page = 1
                this.list = []
                this.getList(1)
            }
        },
        beforeDestroy () {
            document.onkeydown = undefined
        },
    }
</script>

<style lang="scss" scoped>
    .contacts{
        border-right:1px solid #eee;
        .contacts-nav{
            display: flex;
            align-items: center;
            padding: 10px 0;
            cursor: pointer;
            position: relative;
            &-quit{
                width: 20px;
                position: absolute;
                top: 10px;
                left: 0px;
            }
        }
        .contacts-list{
            overflow-y: scroll;
        }
        .contacts-item{
            display: flex;
            width: 100%;
            cursor: pointer;
            &-bg{
                background: aliceblue;
            }
            &-bgF{
                background: aliceblue;
            }
            &-info{
                position: relative;
                display: flex;
                align-items: center;
            }
            &-name{
                display: inline-block;
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                color: #222;
            }
            &-time{
                position: absolute;
                right: 15px;
                font-size: 12px;
                color: rgba(0,0,0,.65);
            }
            &-tag{
                width: 26px;
                height: 15px;
                background: darkcyan;
            }
            &-text{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                width: 230px;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
</style>