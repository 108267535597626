import url from '@/utils/URL'
import request from '@/utils/request'

export function monitorIndex(data) {
  return request({
    url: url.monitorIndex,
    method: 'POST',
    data
  })
}

export function monitorAdd(data) {
  return request({
    url: url.monitorAdd,
    method: 'POST',
    data
  })
}

export function monitorUpdate(data) {
  return request({
    url: url.monitorUpdate,
    method: 'POST',
    data
  })
}

export function monitorRefreash(data) {
  return request({
    url: url.monitorRefreash,
    method: 'POST',
    data
  })
}

export function monitorRecallIndex(data) {
  return request({
    url: url.monitorRecallIndex,
    method: 'POST',
    data
  })
}