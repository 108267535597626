<template>
  <a-form :form="form">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-row>
            <a-col :span="12" v-for="(item, index) of formData" :key="index" >
                <a-form-item :label="item.label" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-date-picker v-if="item.type === 'dates'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
                    <a-date-picker show-time  v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD HH:mm:ss">
                      <template slot="renderExtraFooter">
                          <a-tag @click="handleTimeTag(7,item.name)">7天后</a-tag>
                          <a-tag @click="handleTimeTag(30,item.name)">30天后</a-tag>
                          <a-tag @click="handleTimeTag(60,item.name)">60天后</a-tag>
                          <a-tag @click="handleTimeTag(90,item.name)">90天后</a-tag>
                      </template>
                    
                    </a-date-picker>
                    <a-select v-else-if="item.type === 'select'" placeholder="请选择"
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                    </a-radio-group >
                    <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
                    v-decorator="[item.name, { rules: item.rules }]"/>
                </a-form-item>
            </a-col>
            <a-col style="text-align:right" :span="24">
              <a-button @click="toSave" type="primary" :loading="confirmLoading">保存</a-button>
            </a-col>
        </a-row>
  </a-form>
</template>

<script>
import moment from 'moment'
import C_ITEMS from '@/utils/items'

const formData = [
    {
      name: 'promise_date_time',
      label: '诺访时间',
      type: 'date',
      rules: []
    },
    {
      name: 'next_time',
      label: '回访时间',
      type: 'date',
      rules: []
    },
    {
      name: 'attend_time',
      label: '实际到访',
      type: 'date',
      rules: []
    },
    {
      name: 'is_attend',
      label: '到访状态',
      type: 'radio',
      rules: [],
      items: {
        data: 'attendList',
        label: 'label',
        value: 'value'
      }
    },
    {
      name: 'pay_time',
      label: '预计付费',
      type: 'dates',
      rules: []
    },
    {
      name: 'intention_level',
      label: '意向程度',
      type: 'radio',
      rules: [],
      items: {
        data: 'intentionLevels',
        label: 'label',
        value: 'value'
      }
    }
]

export default {
        name:'message',
        props: {
            item: Object,
            leadsId: Number,
            isTab:String
        },
        data() {
            return {
                loading: false,
                followOpen: false,
                confirmLoading:false,
                formData,
                form: this.$form.createForm(this),
                seleteItems:{
                  attendList:[
                    { label: '未到访', value: 0 },
                    { label: '已到访', value: 1 },
                  ],
                  intentionLevels:C_ITEMS.intentionLevels
                }
            }
        },
        created(){
            this.getDetail()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getDetail(){
                this.loading = true
                let res = await this.$store.dispatch('cCustomerDetailAction', {task_id:this.item.task_id})
                let formFields = {}
                for (let d of this.formData) {
                  if(d.type === 'date'){
                    if(res.data.task_detail[d.name]){
                        formFields[d.name] = moment(res.data.task_detail[d.name])
                    }
                  }else{
                    formFields[d.name] = res.data.task_detail[d.name]
                  }
                }
                this.form.setFieldsValue(formFields)
                this.loading = false
            },
            async toSave(){
              this.confirmLoading = true
              const { form } = this
              let params = await form.validateFields()
              for (let d of this.formData) {
                  if(d.type === 'date' || d.type === 'dates'){
                    if(params[d.name]){
                        params[d.name] = moment(params[d.name]).format('YYYY-MM-DD')
                    }
                  }
              }
              params.task_id = this.item.task_id
              let res = await this.$store.dispatch('cCustomerTaskUpdateAction', params)
                .then(res=>{
                  this.$message.success('操作成功~')
                  this.getDetail()
                  this.confirmLoading = false
                })
                .catch(err=>{
                  this.$message.error('操作失败~')
                  this.confirmLoading = false
                })
            },
            handleTimeTag(val,name){
              this.form.setFieldsValue({[name]:moment().add(val, 'd')})
            },
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.getDetail()
            }
        },
    }
</script>