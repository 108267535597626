import Vue from 'vue'

export default {
  state: {
    loadding: false,
    count:{},
  },
  mutations: {
    showloadding(state, load) {
      state.loadding = load
    },
    changeCount(state,obj){
      state.count = obj
    }
  },
  actions: {
    setloadding(context,load){
      context.commit("showloadding",load);
    },
    setCount(context,obj){
      context.commit("changeCount",obj);
    }
  },
  getters: {
    isloading:(state)=>{
      return state.loadding
    },
    isCount:(state)=>{
      return state.count
    }
  }
}
