<template>
<div class="l-user" v-if="data" @click="toProfile">
  <LImg class="photo" :src="data.student_avatar"/>
  <a class="name" :title="data.student_name">{{ data.student_name }}</a>
  <svg class="sexIcon" aria-hidden="true">
    <use v-show="data.student_gender == 2" xlink:href="#icon-sex_woman"></use>
    <use v-show="data.student_gender == 1" xlink:href="#icon-sex_man"></use>
  </svg>
  <svg v-if="!is_student_wechat_bind" style="width: 12px;height: 12px;margin-left: 5px;" aria-hidden="true">
      <use v-show="data.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
      <use v-show="data.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
  </svg>
  <profileModal v-if="profileVisible" :studentId="studentId" :student_name='student_name' :isRefresh="isRefresh" />
</div>
</template>
<style lang="scss" scoped>
.l-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  .name{
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .photo {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    border-radius: 50%;
    overflow: hidden;
  }
  .sexIcon{
    width: 16px;
    height: 16px;
    margin-left: 6px;
  }
  .common-img{
    border-radius: 50%;
  }
}
</style>

<script>
import profileModal from '@/views/modal/profile'

export default {
  name: 'LUser',
  inject: ['parent'],
  props: {
    data: Object,
    istoProfile:Number,
    isRefresh:Number,
    is_student_wechat_bind:Number,

  },
  components: {
    profileModal
  },
  provide() {
    return {
      parent: this
    }
  },

  data() {
    return {
      profileVisible: false,
      studentId: '',
      student_name:''
    }
  },

  methods: {
    toProfile() {
      this.studentId = this.data.student_id
      this.student_name = this.data.student_name
      if(this.istoProfile){
        return false
      }
      this.showProfileModal()
    },

    showProfileModal() {
      this.profileVisible = true
    },
    hideProfileModal() {
      this.profileVisible = false
    },
    toParent(){
      this.$emit('eventSon')
    },
    getList(){
      if(this.isRefresh){
        this.parent.getList()
      }
    }
  }
}
</script>
