import Vue from 'vue'
import { deviceScanIndex , deviceScanCheckin , deviceScanCheckinList , deviceScanEvents , deviceScanPayment} from '@/api/device'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    deviceScanIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        deviceScanIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    deviceScanCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        deviceScanCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    deviceScanCheckinListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        deviceScanCheckinList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    deviceScanEventsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        deviceScanEvents(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    deviceScanPaymentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        deviceScanPayment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
