<template>
    <div class="followRecord">
        <div class="followRecord-tagList">
            <a-tag  @click="addTextValue(item)" v-for="(item,index) in tagList" :key="index">#{{item.discourse_content}}</a-tag>
            <!-- <a-input
            v-if="inputVisible"
            ref="input"
            type="text"
            size="small"
            :style="{ width: '78px' }"
            :value="inputValue"
            @change="handleInputChange"
            @blur="handleInputConfirm"
            @keyup.enter="handleInputConfirm"
            /> -->
            <a-textarea
            v-if="inputVisible"
            allowClear
            ref="input"
            size="small"
            v-model="inputValue"
            :style="{ width: '78px' }"
            :auto-size="{ minRows: 1, maxRows: 1 }"
            @change="handleInputChange"
            @blur="handleUnInputConfirm"
            @keyup.enter="handleInputConfirm"
            />
            <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <a-icon type="plus" /> 快捷跟进模板
            </a-tag>
        </div>

        <div class="followRecord-sel" v-if="detailData.tips && this.isTab == '0'" style="margin-bottom:10px;">
             <a-alert type="success" style="width:100%;">
                <template slot="message">
                    <div style="white-space:pre-line;">{{detailData.tips}}</div>
                </template>
              </a-alert>
        </div>

        <div class="followRecord-inp">
            <!-- <a-mentions v-model="textValue" :loading="empLoading" @search="onSearch" @select="onSelect" rows="4" placeholder="请先使用@xxx来选择学员">
                <a-mentions-option v-for="item in students" :key="item.student_id" :value="`${item.student_name}+=${item.student_id}+=`">
                    <a-avatar :src="item.student_avatar" icon="user" />
                    {{item.student_name}} ({{item.student_cellphone}})
                </a-mentions-option>
            </a-mentions> -->
            <a-textarea
                v-model="textValue"
                placeholder="请输入内容"
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
            <div ref="upDiv" class="followRecord-img">
                <a-upload
                    v-if="fileList.length !=0"
                    accept='.png,.jpeg,.jpg'
                    list-type="picture-card"
                    :file-list="fileList"
                    :data="uploadParams"
                    :action="uploadUrl"
                    :multiple='true'
                    :beforeUpload="beforeUpload"
                    @change="changeFile">
                    <div>
                        <a-icon type="plus" />
                    </div>
                </a-upload>
            </div>
            <div class="followRecord-btn">
                <div>
                    <a-upload
                        :showUploadList='false'
                        accept='.png,.jpeg,.jpg'
                        :data="uploadParams"
                        :action="uploadUrl" 
                        :file-list="fileList"
                        :multiple='true'
                        :beforeUpload="beforeUpload"
                        @change="changeFile">
                        <a-icon type="file-image" />
                        <span style="margin-left:6px">图片</span>
                    </a-upload>
                </div>
            </div>
        </div>
        
        <div class="followRecord-sel">
            <div class="followRecord-div">
                <a-select v-model="selectValue" style="width: 90px" :dropdownStyle='{zIndex:4200}'>
                    <a-select-option v-for="(item,index) in recordType" :key="index" :value="item.value">{{item.label}}</a-select-option>
                </a-select>
            </div>
            <div class="followRecord-div">
                <a-select v-model="eventValue" style="width: 120px" :dropdownStyle='{zIndex:4200}' placeholder="请选择事件" allowClear showSearch :filterOption="filterOption">
                    <div slot="dropdownRender" slot-scope="menu">
                        <v-nodes :vnodes="menu" />
                        <a-divider style="margin: 4px 0;" />
                        <div
                            style="padding: 4px 8px; cursor: pointer;"
                            @click="showEventItem = true"
                            @mousedown="e => e.preventDefault()"
                        >
                            <span><a-icon type="plus" /> 添加事件</span>
                        </div>
                    </div>
                    <a-select-option v-for="(item,index) in eventList" :key="index" :value="item.event_id">{{item.event_name}}</a-select-option>
                </a-select>
            </div>
            
            <div class="followRecord-divs">
                <a-form :form="form">
                  <a-form-item :validate-status="validateStatus">
                    <a-date-picker
                        v-model="startValue"
                        :show-time="{ format: 'HH:mm' }"
                        :showToday="false"
                        format="YYYY-MM-DD HH:mm"
                        placeholder="请选择下次回访时间"
                        :disabled-date="disabledDate"
                        @openChange="handleStartOpenChange"
                        :popupStyle='{zIndex:4003}'>
                            <template slot="renderExtraFooter">
                                <a-tag @click="handleTimeTag(1)">明天</a-tag>

                                <a-tag @click="handleTimeTag(3)">3天后</a-tag>

                                <a-tag @click="handleTimeTag(2,1)">本周六</a-tag>
                                <a-tag @click="handleTimeTag(2,2)">本周日</a-tag>

                                
                                <a-tag @click="handleTimeTag(7)">7天后</a-tag>
                                <a-tag @click="handleTimeTag(30)">30天后</a-tag>
                                <!-- <a-tag @click="handleTimeTag(60)">60天后</a-tag>
                                <a-tag @click="handleTimeTag(90)">90天后</a-tag> -->
                            </template>
                    </a-date-picker>
                    </a-form-item>
                </a-form>
            </div>

            <div style="height:24px;margin-left:10px">
                <a-dropdown :overlayStyle='{zIndex:4003}' placement="bottomCenter" :trigger="['click']">
                    <a-tag style='cursor:pointer' v-for="it in dealStatus" :key='it.value' :color="it.color"  v-if="task_step==it.value">
                        {{it.label}} <a-icon style="margin-left:5px" type="down" />
                    </a-tag>
                    <a-menu slot="overlay">
                        <a-menu-item @click='handleDealStatus(it,listItem)' v-for="it in dealStatus" :key='it.value'>
                            <a-tag style='cursor:pointer' :color="it.color">
                                {{it.label}}
                            </a-tag>
                            <a-icon type="check" :style="{color: '#00cca2' }" v-if="task_step==it.value"/>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>

            <div class="followRecord-out">
                <a-dropdown-button  :disabled='sendLoading' class="followRecord-drop-btn" @click="toRelease(1)">
                    发布
                    <a-menu slot="overlay">
                        <a-menu-item @click="toRelease(2)" key="1">不再跟进</a-menu-item>
                    </a-menu>
                </a-dropdown-button>
            </div>
        </div>
        <div 
            class="followRecord-rec"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10">
            <vue-element-loading :active="loading && dataList.length==0" color="#00cca2" spinner="spinner"/>
            <a-list item-layout="horizontal" :data-source="dataList">
                <a-list-item slot="renderItem" slot-scope="item, index">
                 <div style="width:100%">
                    <div class="followRecord-rec-nav">
                        <div class="followRecord-rec-img" style="color:rgba(69, 90, 100, 0.6);" >
                            <a-avatar :size="30" :src="item.employee_avatar" />
                            <div style="margin-left:10px">
                                <div>
                                    {{item.employee_name}} •
                                    <a-tooltip slot="datetime">
                                        <span>{{item.created_at}}</span>
                                    </a-tooltip> 
                                </div>
                            </div>
                        </div>
                        <!-- <div class="followRecord-rec-del">
                            <span style="margin-right:6px">跟进记录</span>
                            <a-icon type="delete" />
                        </div> -->
                    </div>
                    <div class="followRecord-rec-content" style="white-space: pre-line;">
                        {{item.log}}
                    </div>

                    <div class="followRecord-rec-content" v-if="item.log_images.length">
                        <LImg v-for="(it,index) in item.log_images" preview="0" :key="index" :src="it.image" style="height: 98px; margin: 0 5px 5px 0"/>
                    </div>

                    <div class="followRecord-rec-content"> 
                        <a-tag >{{item.log_type}}</a-tag>
                        <a-tag v-if="item.next_time">{{item.next_time}}</a-tag>
                        <a-tag >{{item.follow_type}}</a-tag>
                        <a-tag v-if="item.is_back_visit == 1">#回访</a-tag>
                        <a-tag v-if="item.log_event">#{{item.log_event}}</a-tag>
                    </div>
                 </div>
                </a-list-item>
                <div slot="footer">
                    <vue-element-loading :active="loading && dataList.length > 0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="dataList.length == totalCount && dataList.length > 0">没有更多了~</div>
                 </div>
            </a-list>
        </div>
        <eventModal v-if="showEventItem" />
    </div>
</template>

<script>
    import url from '@/utils/URL'
    import { delay } from '@/utils/timeFormat'
    import C_ITEMS from '@/utils/items'
    import moment from 'moment'
    import createFilePath from '@/utils/tools'
    import infiniteScroll from 'vue-infinite-scroll'
    import eventModal from './eventModal'
    export default {
        name:"followRecord",
        inject: ['parent'],
        props: {
            item: Object,
            detailData:Object,
            taskData:Object,
            leadsId: Number,
            isTab:String,
            isRefresh:Number,
            task_step:Number,
        },
        provide() {
            return {
                parent: this
            }
        },
        directives: { infiniteScroll },
        data() {
            return {
                loading: false,
                empLoading: false,
                stuLoading: false,
                sendLoading: false,
                totalCount:0,
                busy: false,
                showEventItem: false,
                dataList:[],
                eventList:[],
                recordType:[
                    {label:'电话',value:'1'},
                    {label:'短信',value:'2'},
                    {label:'微信',value:'3'},
                    {label:'面咨',value:'4'},
                    {label:'其他',value:'0'},
                ],
                eventValue:undefined,
                selectValue:'1',
                textValue: '',
                students:[],
                previewImage:'',
                fileList:[],
                imgList:[],
                imgAddr:[],
                startValue: null,
                endOpen: false,
                checked:false,
                btnDisabled:false,
                uploadUrl: url.uploadQiNiu,
                dealStatus:C_ITEMS.dealStatus,
                uploadParams: {},
                pageParams: {
                    'page': 0,
                    'per-page':10,
                },
                validateStatus:'success',
                form: this.$form.createForm(this),
                listItem:{},
                tagList:[],
                leadsTagList:[],
                customerTagList:[],
                inputVisible: false,
                inputValue: '',
            }
        },
        components: {
            VNodes: {
                    functional: true,
                    render: (h, ctx) => ctx.props.vnodes,
            },
            eventModal,
        },
        created(){
            this.sendLoading = false
            this.listItem = this.item
            this.getList(this.pageParams)
            this.getTagList()
            this.getEventList()
        },
        methods:{
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(obj,num){
                this.loading = true
                this.btnDisabled = false
                let res
                if(this.isTab == '0'){
                    this.tagList=this.leadsTagList;
                    obj.leads_id = this.item.leads_id
                    res = await this.$store.dispatch('cLeadsFollowLogAction', obj)
                }else{
                    this.tagList=this.customerTagList;
                    obj.customer_id = this.item.customer_id
                    res = await this.$store.dispatch('cCustomerFollowLogAction', obj)
                }
                if(num){
                    this.dataList = res.items
                    this.busy = true
                    this.loading = false
                    return false
                }
                if(this.dataList.length == 0){
                    this.dataList = res.items
                }else{
                    if(res.items.length == 0){
                        // this.$message.warning('没有更多跟进记录了!')
                        this.busy = true
                    }else{
                        this.dataList = this.dataList.concat(res.items);
                    }
                }
                this.totalCount = res._meta.totalCount
                this.loading = false
            },
            async beforeUpload(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    let obj = {
                        uid:file.uid,
                        url:this.uploadParams.key,
                    }
                    this.imgAddr.push(obj)
                    return true
                }
                return false
            },
            async changeFile({fileList}){
                var arr = fileList;
                arr.forEach((item,index)=>{
                    var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png';
                    if(!isJpgOrPng){
                        arr.splice(index,1)
                    }
                })
                this.fileList = arr;
            },
            async getTagList(){
                let res
                res = await this.$store.dispatch('discourseLeadsAction', {})
                this.leadsTagList = res.data
                res = await this.$store.dispatch('discourseCustomerAction', {})
                this.customerTagList = res.data
                if(this.isTab == '0'){
                    this.tagList=this.leadsTagList;
                }else{
                    this.tagList=this.customerTagList;
                }
            },
            async clickTag(id){
                let res = await this.$store.dispatch('discourseClickAction', {discourse_id:id})
            },
            async getEventList(){
                await this.$store.dispatch('cFollowEventAction', {})
                .then(res=>{
                    this.eventList = res.data
                })
            },
            range(start, end) {
                const result = [];
                for (let i = start; i < end; i++) {
                    result.push(i);
                }
                return result;
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            onChange(e) {
                this.checked = e.target.checked
            },
            handleInfiniteOnLoad() {
                if(this.pageParams.page != 0){
                    if(!this.busy){
                        this.getList(this.pageParams)
                    }
                }
                this.pageParams.page++
                
            },
            async toRelease(num){
                if(!this.textValue && this.fileList.length == 0){
                    this.$message.warning('跟进内容不能为空!')
                    return false
                }
                if(!this.startValue && num !=2){
                    this.$message.warning('回访时间不能为空!')
                    return false
                }
                let obj = {
                    task_id:this.item.task_id,
                    contact_type:this.selectValue,
                    log:this.textValue,
                }
                if(num === 1){
                    if(this.startValue){
                        obj.next_time= moment(this.startValue).format("YYYY-MM-DD HH:mm:00")
                    }
                }else if(num === 2){
                    obj.stop_contact = 1
                }else{}
                if(this.eventValue){
                    obj.event_id = this.eventValue
                }
                this.validateStatus = 'success'
                this.btnDisabled = true
                let that = this
                let imgArr = []
                if(this.fileList.length !=0){
                    this.fileList.forEach((item)=>{
                        that.imgAddr.forEach((it)=>{
                            if(it.uid == item.uid){
                                imgArr.push(it.url)
                            }
                        })
                    })
                }
                obj.log_images = imgArr
                this.sendLoading = true
                await this.$store.dispatch(this.isTab == '0'?'cLeadsFollowAction':'cCustomerFollowAction', obj)
                .then(res=>{
                    this.$message.success("操作成功~")
                    this.sendLoading = false
                    this.pageParams.page = 1
                    this.dataList = []
                    this.textValue = ''
                    this.fileList = []
                    this.imgAddr = []
                    this.uploadParams = {}
                    this.getList(this.pageParams)
                    this.parent.getDetail()
                    this.parent.refreshList()
                })
                .catch(err=>{
                    this.$message.error("操作失败~")
                    this.sendLoading = false
                })
            },
            handleTimeTag(val,num){
                if(num){
                    this.startValue = num == 1?moment().endOf('week').add(-1,'d'):moment().endOf('week')
                }else{
                    this.startValue = moment().add(val, 'd')
                }
            },
            disabledDate(current) {
                return current < moment().subtract(1, 'day') || current > moment().add(30, 'day')
            },
            async handleDealStatus(item){
                let obj = {task_id:this.item.task_id,task_step:item.value}
                if(this.startValue){
                    obj.next_time= moment(this.startValue).format("YYYY-MM-DD HH:mm:00")
                }
                await this.$store.dispatch(this.isTab == '0'?'cLeadsTaskUpdateAction':'cCustomerTaskUpdateAction', obj)
                    .then(res=>{
                        this.$message.success('操作成功~')
                        this.listItem.task_step = item.value
                        this.parent.getDetail()
                        this.parent.refreshList('task_step',item.value)
                    })
                    .catch(err=>{
                        this.$message.error('操作失败~')
                    })
            },
            
            onSearch(search){
                this.stuLoading = !!search
                this.students = []
                let obj = {
                    q: search,
                }
                delay(() => {
                    this.handleSearch(obj)
                }, 500)
            },
            onSelect(option) {
                console.log('select', option)
                let strArr = this.textValuetextValue.split('+=')
                this.textValue = strArr[0] + strArr[2]
                this.student_id = strArr[1]
                this.student_name = strArr[0]
            },
            async handleSearch(obj) {
                let res = await this.$store.dispatch('searchStudentsAction', { params:obj })
                this.students = res.data
                this.stuLoading = false
            },
            handleStudent(item){
                if(item.message_to.to === 'student'){
                    this.students = [{
                        student_name:item.message_to.to_name,
                        student_id:item.message_to.to_id,
                        student_avatar:item.message_to.to_avatar,
                        student_cellphone:''
                    }]
                    this.busy = true
                    this.searchParams.search.student_id = item.message_to.to_id
                    this.searchParams.page = 1
                    this.list = []
                    this.getList()
                }
            },
            searchList(){
                this.busy = true
                this.searchParams.page = 1
                this.list = []
                this.getList()
            },
            closeSearch() {
                this.searchVisible = false
            },

            handleClose(removedTag) {
                const tagList = this.tagList.filter(tag => tag !== removedTag)
                this.tagList = tagList
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus()
                })
            },
            handleInputChange(e) {
                if(e.target.value!=this.inputValue){
                    this.inputValue = e.target.value
                }
                // console.log(this.inputValue)
            },
            async  handleUnInputConfirm(){
                this.inputVisible = false;
                this.inputValue = "";
            },
            async handleInputConfirm() {
                const inputValue = this.inputValue
                let tagList = this.tagList.map(item=>{return item.discourse_content})
                let list = this.tagList
                if (inputValue && tagList.indexOf(inputValue) === -1) {
                    let res
                    if(this.isTab == '0'){
                        res = await this.$store.dispatch('discourseLeadsAddAction', {discourse_content:inputValue})
                    }else{
                        res = await this.$store.dispatch('discourseCustomerAddAction', {discourse_content:inputValue})
                    }
                    // console.log(res)
                    this.tagList = [...list, res.data]
                    this.inputValue = ''
                    this.inputVisible = false
                }
            },
            addTextValue(item){
                this.textValue=this.textValue?this.textValue +'，'+ item.discourse_content:item.discourse_content
                this.clickTag(item.discourse_id)
            },
            hideEventModal(val){
                if(val){
                    this.getEventList()
                }
                this.showEventItem = false
            }
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.pageParams.page = 1;
                this.dataList = [];
                this.textValue = '';
                this.fileList = [];
                this.imgAddr = [];
                this.uploadParams = {}
                this.getList(this.pageParams,1)
                this.listItem = this.item
            },
            isRefresh(){
                this.pageParams.page = 1;
                this.dataList = [];
                this.textValue = '';
                this.fileList = [];
                this.imgAddr = [];
                this.uploadParams = {}
                this.getList(this.pageParams,1)
                this.listItem = this.item
            },
            detailData(){
                this.startValue = this.detailData.next_time ? moment(this.detailData.next_time): null
            },

        },
    }
</script>

<style lang="scss">
    .followRecord{
        &-tagList{
            display:flex;
            flex-wrap: wrap;
            .ant-tag{
                cursor: pointer;
                margin-bottom: 5px;
                color:#999 !important;
                border-color:#f5f7fa !important;
                border-bottom-left-radius:11px;
                border-top-left-radius:11px;
                border-top-right-radius:11px;
                border-bottom-right-radius:11px;
            }
        }
        .followRecord-inp{
            border: 1px solid #00cca2;
            border-radius: 3px;
            textarea{
                resize: none;
                border: none;
            }
            .ant-input:focus{
                border-color:#fff;
                box-shadow:none
            }
            .followRecord-btn{
                display: flex;
                background: rgb(249,249,249);
                div{
                    padding: 4px;
                }
            }
            .followRecord-img{
                padding-left: 15px;
            }
        }
        .followRecord-sel{
            display: flex;
            position: relative;
            margin-top: 10px;
            align-items: center;
            .followRecord-check{
                display: flex;
                align-items: center;
            }
            .followRecord-out{
                position: absolute;
                right: 0;
            }
            .followRecord-div{
                margin-right: 10px;
                span{
                    margin-right: 10px;
                }
            }
        }
        .followRecord-rec{
            border: 1px solid rgb(230,230,230);
            border-radius: 3px;
            padding: 10px;
            margin-top: 10px;
            overflow: scroll;
            height: 650px;
            .ant-list-item{
                padding: 6px 0;
            }
            .followRecord-rec-nav{
                display:flex;
                align-items:center;
                position:relative;
                width:100%;
                .followRecord-rec-img{
                    display:flex;
                    align-items:center;
                }
                .followRecord-rec-del{
                    position:absolute;
                    right:0
                }
                
            }
            .followRecord-rec-content{
                padding:6px 15px 6px 40px;
                img{
                    // width: 98px;
                    height: 98px;
                    margin: 5px;
                    border-radius: 3px;
                }
                .ant-tag{
                    color:#999 !important;
                    border-color:#f5f7fa !important;
                    border-bottom-left-radius:11px;
                    border-top-left-radius:11px;
                    border-top-right-radius:11px;
                    border-bottom-right-radius:11px;
                }
            }
        }
        .followRecord-divs{
            .ant-form-item{
                margin-bottom:0px;
            }
            .ant-calendar-picker-input{
                border: 1px solid #00cca2
            }

        }
    }
    .ant-dropdown-placement-bottomRight{
        z-index: 4300 !important;
    }

</style>
