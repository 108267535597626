<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form" layout="vertical">
        <a-row type="flex" align="top" :gutter="16">
            <a-col :span="item.type === 'textarea'?24:12" v-for="(item, index) of formData" :key="index" >
                <a-form-item v-if="item.type !== 'textarea'" :label="item.label">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-input-number style='width:100%' v-if="item.type === 'input-number'" :min='0' v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-date-picker v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
                    <a-select v-else-if="item.type === 'select'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-select v-else-if="item.type === 'selects'" placeholder="请选择"
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-tree-select v-else-if="item.type === 'tags'"
                        v-decorator="[item.name, { rules: item.rules }]"
                        :tree-data="tags"
                        tree-checkable
                        allowClear
                        treeNodeFilterProp="title"
                        :maxTagCount='1'
                        :maxTagTextLength='5'
                        :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                        :show-checked-strategy="SHOW_ALL"
                        placeholder="请选择标签"
                    />
                    <a-cascader
                    v-else-if="item.type === 'cascader'"
                    :options="seleteItems[item.items.data]"
                    :show-search="{ filterOption }"
                    :allowClear="false"
                    v-decorator="[item.name, { rules: item.rules }]"
                    placeholder="请选择"
                    :popupStyle='{zIndex:4200}'
                    @change="onChangeCascader">
                        <template slot="displayRender" slot-scope="{labels}">
                            <span>{{labels[1]}}</span>
                        </template>
                    </a-cascader>
                    <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                    </a-radio-group >
                    <!-- <a-textarea v-else-if="item.type === 'textarea'" auto-size
                    v-decorator="[item.name, { rules: item.rules }]"/> -->
                </a-form-item>
                <a-form-item v-if="item.type === 'textarea'" :label="item.label">
                  <a-textarea :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
                </a-form-item>
            </a-col>
        </a-row>
      
    </a-form>
  </a-modal>
</template>



<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'

function phoneCheck (rule, value, callbackFn) {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if (!reg.test(value)) {
    callbackFn('请输入正确手机号码!')
    return
  }
  callbackFn()
}

const formData = [
  {
    name: 'realname',
    label: '线索名称',
    type: 'input',
    rules: [{ required: true, message: '请输入线索名称!' }]
  },
  {
    name: 'cellphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: ' ' ,whitespace: true},{validator:phoneCheck.bind(this)}],
  },
  {
    name: 'studio_id',
    label: '所在校区',
    type: 'select',
    rules: [{ required: true, message: '请选择所属校区' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'channel_id',
    label: '来源渠道',
    type: 'cascader',
    rules: [{ required: true, message: '请选择来源渠道!' }],
    items: {
      data: 'channels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
  {
    name: 'course_cate',
    label: '意向课程',
    type: 'select',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'distribute_id',
    label: '课程顾问',
    type: 'select',
    rules: [{ required: true, message: '请选择课程顾问!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'gender',
    label: '线索性别',
    type: 'radio',
    rules: [],
    items: {
      data: 'genderList',
      label: 'gender_name',
      value: 'gender_id'
    }
  },
  {
    name: 'birthday',
    label: '出生日期',
    type: 'date',
    rules: [],
  },
  {
    name: 'year',
    label: '学员年龄',
    type: 'input-number',
    rules: []
  },
  {
    name: 'school',
    label: '在读学校',
    type: 'input',
    rules: [],
  },
  // {
  //   name: 'grade',
  //   label: '在读年级',
  //   type: 'select',
  //   rules: [],
  //   items: {
  //     data: 'grades',
  //     label: 'filter_name',
  //     value: 'value'
  //   }
  // },
  {
    name: 'address',
    label: '家庭住址',
    type: 'input',
    rules: [{ required: false, message: '请输入家庭住址!' }]   
  },
  {
    name: 'tags',
    label: '标签',
    type: 'tags',
    rules:[],
  },
  {
    name: 'remark',
    label: '线索备注',
    type: 'textarea',
    rules: [],
  },
]

export default {
  name: 'EditModal',
  mixins: [ editModal , ranges],
  provide() {
    return {
      parent: this
    }
  },
  data() {
    return {
      loading:false,
      formData,
      tags:[],
      seleteItems: {
        grades:[],
        studios: [],
        channels:[],
        genderList:[{gender_name:'男',gender_id:1},{gender_name:'女',gender_id:2},{gender_name:'未知',gender_id:0}],
        consultants:[],
        classCategorys:[],
      },
      channelsId:[],
      modalTitle:'',
    }

  },

  async created() {
      this.visible = true
      this.getConsultant()
      this.getStudio()
      // this.getGrade()
      this.getClassCategory()
  },
  async mounted(){
      let msg = this.item
      await this.$nextTick()
      if(this.item){
        this.modalTitle = '编辑线索'
        this.form.setFieldsValue({
          realname:msg.leads_detail.realname,
          cellphone:msg.leads_detail.cellphone,
          studio_id:msg.leads_detail.studio_id,
          address:msg.leads_detail.address,
          remark:msg.leads_detail.remark,
          school:msg.leads_detail.school,
          gender:msg.leads_detail.gender || 0,
          grade:msg.leads_detail.grade,
          year:msg.leads_detail.year,
          tags:msg.leads_detail.tags,
          course_cate:msg.leads_detail.course_cate,
          distribute_id:msg.distribute_id,
        })
        if(msg.leads_detail.birthday){
          this.form.setFieldsValue({birthday:moment(msg.leads_detail.birthday)})
        }
        this.getSourceChannel(msg.leads_detail.channel_id)
        this.getTag()
      }else{
        this.modalTitle = '新增线索'
        this.getSourceChannel()
        this.getTag()
        this.form.setFieldsValue({gender:1,distribute_id:this.$store.getters.employee_id})
      }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getGrade(){
      let res = await this.$store.dispatch('searchGetGradeTypeAction', {})
        this.seleteItems.grades = res.data
    },
    async getTag(){
        let res = await this.$store.dispatch('searchGetTagsAction', {})
        this.tags = res.data
    },
    async getStudio() {
        let res = await this.$store.dispatch('searchBelongStudioAction', {})
        this.seleteItems.studios = res.data
    },
    async getSourceChannel(id) {
      this.loading = true
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
      this.loading = false
      this.seleteItems.channels = res.data[0].children
      if(id){
        res.data[0].children.forEach(item=>{
          item.children.forEach(it=>{
            if(it.value == id){
              this.channelsId = [item.value,it.value]
              this.form.setFieldsValue({channel_id:this.channelsId})
            }
          })
        })
      }
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {data: {active:true}})
      this.seleteItems.consultants = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if(!(/^1[3456789]\d{9}$/.test(params.cellphone))){
          this.$message.warning('请输入正确的手机号!')
          return false
        }
        this.confirmLoading = true
        params.channel_id = this.channelsId[1]
        if(params.birthday){
          params.birthday = moment(params.birthday).format('YYYY-MM-DD')
        }
        if (params) {
          if (item) {
            params.task_id = item.task_id
          }
          let res = await this.$store.dispatch(item ? 'cLeadsUpdateAction' : 'cLeadsAddAction', params).then(res=>{
            this.confirmLoading = false
                this.$message.success('操作成功!')
                form.resetFields()
                this.parent.hideEditModal(1)
            }).catch(err=>{
                this.confirmLoading = false
            })
        }
      } catch {
      }
      this.confirmLoading=false
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    onChangeCascader(value, selectedOptions) {
        this.channelsId = value
    },
  }
}
</script>