import Vue from 'vue'
import { homework,homeworkPublish,homeworkClasses,homeworkDetail,homeworkReissue,homeworkResend,homeworkRecord,homeworkRate,homeworkAddComment,
         homeworkAddReply,homeworkUpdateReply,homeworkDeleteComment,homeworkDelete,homeworkReceipted} from '@/api/homework'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    homeworkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homework(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkPublishAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkPublish(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkReissueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkReissue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkResendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkResend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkRateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkRate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkAddCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkAddComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkAddReplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkAddReply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkUpdateReplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkUpdateReply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkDeleteCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkDeleteComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    homeworkDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    homeworkReceiptedAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        homeworkReceipted(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
