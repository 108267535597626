import Vue from 'vue'
import conf from '@/utils/const'
import router from './router'
import store from './store'

let loadAsyncRouter = false
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
        // document.title = to.meta.title +' - 微点云助教管理系统'
    }
    if(to.query.access_token){
      Vue.ls.set(conf.ACCESS_TOKEN, to.query.access_token, conf.ACCESS_TIME)
    }
    const token = Vue.ls.get(conf.ACCESS_TOKEN)
    if(token){
      if(to.path == '/user/login'){
          next()
      }else{
          if(!loadAsyncRouter){
              loadAsyncRouter = true
              if(store.getters.allAuth){
                store.dispatch('GenerateRoutes', store.getters.allAuth).then(()=>{
                  router.addRoutes(store.getters.addRouters)
                  if (to.path === '/404') {
                      sessionStorage.setItem("menusKey",store.getters.menusKey)
                      next({
                        path: to.redirectedFrom || '/',
                        replace: true,
                      })
                    } else {
                      next({
                        ...to,
                        replace: true
                      })
                    }
                })
              }else{
                store.dispatch('getAuthAction',{}).then(res => {
                  const auths = res.data
                  store.dispatch('GenerateRoutes', auths).then(()=>{
                    router.addRoutes(store.getters.addRouters)
                    if (to.path === '/404') {
                        next({
                          path: to.redirectedFrom || '/',
                          replace: true,
                        })
                      } else {
                        next({
                          ...to,
                          replace: true
                        })
                      }
                  })
                }).catch(err=>{
                  if(err === 1){
                    next({
                      path: '/404',
                      query:{auth:1},
                      replace: true,
                    })
                  }
                  console.log(err)
                })
              }
          }else{
              if(to.redirectedFrom){
                sessionStorage.setItem("menusKey",store.getters.menusKey)
              }
              if(to.query.access_token){
                next({
                  path: to.path,
                  replace: true,
                })
              }else{
                next()
              }
              
          }
      }
    }else{
      if(to.path === '/404'){
        next({
          path: '/user/login',
          replace: true,
        })
      }else{
        next()
      }
      
    }
})