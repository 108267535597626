<template>
   <div class="zcxy">
    <div class="dashboard">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="contract_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="pdf" slot-scope="text, record">
            <a-icon @click="toPreviewPDF(record)" v-show="record.contract_pdf" type="file-pdf" style="font-size: 18px;vertical-align: middle;cursor: pointer;"  />
        </template>
        <template slot="out_trade_no" slot-scope="text,record">
            <a href="javascript:;" @click="showOrderModal(record)">{{ text }}</a>
          </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <contractPdf v-if="isContractPdf" :pdfPath="pdfPath" />
    <orderModal v-if="orderVisible" :isFinance='0' :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  // { title: '', width:'40px', scopedSlots: { customRender: 'pdf' }, key: 'contract_pdf', align:'center'},
  { title: '合同编号', dataIndex: 'contract_no' ,ellipsis: true, key: 'contract_no', scopedSlots: { customRender: 'out_trade_no' }},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'course_cate_id' ,ellipsis: true},
  { title: '所在校区', dataIndex: 'studio_id', key: 'studio_id' ,ellipsis: true},
  { title: '签约课时', dataIndex: 'contract_times', key: 'contract_times' ,width:"100px",align:"center"},
  { title: '赠送课时', dataIndex: 'contract_gift_times', key: 'contract_gift_times',width:"100px" ,align:"center"},
  { title: '签约金额', dataIndex: 'contract_costs', key: '4' ,width:"100px",align:"center"},
  { title: '签约顾问', dataIndex: 'course_consultant_name', key: 'course_consultant_name' ,align:"center",ellipsis: true},
  { title: '类型', dataIndex: 'contract_type', key: '2',width:"60px"  },
  { title: '签约日期', dataIndex: 'contract_date', key: '5' },
//   { title: '结束日期', dataIndex: '', key: '6' },
  // { title: '状态', dataIndex: 'contract_status', key: '7' }
]
import orderModal from '@/views/finance/daily/orderModal'
import tableMixins from '@/common/mixins/table'
import contractPdf from './../../contract/contractPdf'

export default {
    name:'contract',
    mixins: [ tableMixins ],
    components: {
      orderModal,
      contractPdf
    },
    data() {
        return {
            loading: false,
            columns,
            orderVisible: false,
            isContractPdf:false,
            pdfPath:'',
            pageParams: {
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
        }
    },
    props: {
        item: Object,
        leadsId: Number,
        isTab:String
    },
    methods: {
          async toPreviewPDF(item){
            this.isContractPdf=true;
            this.pdfPath = item.contract_pdf;      
            // console.log(this.isContractPdf,this.pdfPath)
        },
        async getList() {
        this.loading = true
        this.searchParams.customer_id = this.item.customer_id
        let res = await this.$store.dispatch('cCustomerContractAction',this.searchParams)
        this.list = res.items
        this.pageParams = res._meta
        this.loading = false
        },
        showOrderModal(item) {
        this.modalData = item
        this.orderVisible = true
        },
        hideOrderModal(type) {
          if (type === 1) {
            this.getList()
          }
          this.orderVisible = false
        }
    },
    watch: {
        leadsId(newValue, oldValue) {
            this.pageParams.page = 1;
            this.list = []
            this.getList()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>