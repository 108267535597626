<template>
    <div class="crm-messages">
        <div class="crm-messages-left">
            <contacts ref="contacts" :class_id='id' />
        </div>
        <div class="crm-messages-right">
            <chatRecord :staff_ext_id="staff_ext_id" :class_id='id' :chatItem="chatItem" />
        </div>
    </div>
</template>

<script>
    import contacts from './contacts'
    import chatRecord from './chatRecord'
    export default {
        name:'wxworkLog',
        provide() {
            return {
                parent: this
            }
        },
        components: {
            contacts,
            chatRecord
        },
        props: {
            id: Number
        },
        data() {
            return {
                staff_ext_id:'',
                chatItem:{},
            }
        },
        methods: {
            changeValue(staff_ext_id,item) {
                this.staff_ext_id = staff_ext_id
                this.chatItem = item
            },
        },

    }
</script>

<style lang="scss" scoped>
    .crm-messages{
        display: flex;
        .crm-messages-left{
            width: 300px;
        }
        .crm-messages-right{
            flex: 1;
        }
    }
</style>