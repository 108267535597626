<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="绑定为新学员" okText="使用选中学员" width="700px" :zIndex='4002'
    @ok="handleOk" @cancel="handleCancel" :maskClosable='false' :closable='false'>
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
        type="radio"
        :selection="{key: 'key'}" :customRow="clickRow"
        :rowSelection="{type: 'radio', columnTitle: '选择', selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :columns="columns" :dataSource="item">
        <template slot="studentName" slot-scope="text, record">
          <LUser :data="record" :istoProfile='1' :is_student_wechat_bind='1'/>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '学员名称', width: '200px', dataIndex: 'student_name', key: 'student_name' ,scopedSlots: { customRender: 'studentName' }},
  { title: '手机号', width: '140px', dataIndex: 'student_cellphone', key: 'student_cellphone'},
  { title: '剩余课时', dataIndex: 'left_times', key: '4',align:'right' },
  { title: '赠送课时', dataIndex: 'left_gift_times', key: '5' ,align:'right' },
]
export default {
  name: 'AddModal',
  inject: ['parent'],
  props: {
    item: Array
  },

  data() {
    return {
      columns,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '选择业务办理学员',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      list: [],
      selectedRowKeys: []
    }
  },

  created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleOk() {
      let student_id = this.selectedRowKeys[0]
      if (!student_id) {
        this.$message.error('请先选择学员');
        return
      }
      this.parent.hideCheckModal(1, {student_id})
    },
    handleCancel() {
      this.parent.hideCheckModal(0)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    this.selectedRowKeys = [record.student_id]
                }
            }
        }
    },
  }
}
</script>