import moment from 'moment'
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
export default {
    provide() {
      return {
        parent: this
      }
    },
    data() {
      return {
        SHOW_ALL,
        rangesValue:[],
        rangesData:{
            '今天': [moment(), moment()],
            '昨天': [moment().add(-1, 'days'), moment().add(-1, 'days')],
            '本周': [moment().startOf('week'), moment().endOf('week')],
            '上周': [moment().week(moment().week() - 1).startOf('week'),moment().week(moment().week() - 1).endOf('week')],
            '下周': [moment().week(moment().week() + 1).startOf('week'),moment().week(moment().week() + 1).endOf('week')],
            '本月': [moment().startOf('month'),moment().endOf('month')],
            '上月': [moment(moment().add(-1,'month')).startOf('month'),moment(moment().add(-1,'month')).endOf('month')],
            // '本季度': [moment().startOf('quarter'),moment().endOf('quarter')],
            // '上季度': [moment(moment().quarter(moment().quarter() - 1).startOf('quarter').valueOf()),moment(moment().quarter(moment().quarter() - 1).endOf('quarter').valueOf())],
            // '去年': [moment().year(moment().year() -1).startOf('year'), moment().year(moment().year() -1).endOf('year')],
            '去年上半年': [moment(moment(moment().year(moment().year() -1)).month(0)).startOf('month'), moment(moment(moment().year(moment().year() -1)).month(5)).endOf('month')],
            '去年下半年': [moment(moment(moment().year(moment().year() -1)).month(6)).startOf('month'), moment(moment(moment().year(moment().year() -1)).month(11)).endOf('month')],
            '今年': [moment().startOf('year'), moment().endOf('year')],
            '上半年': [moment(moment().month(0)).startOf('month'), moment(moment().month(5)).endOf('month')],
            '下半年': [moment(moment().month(6)).startOf('month'), moment(moment().month(11)).endOf('month')],
            '第一季度': [moment(moment().month(0)).startOf('month'), moment(moment().month(2)).endOf('month')],
            '第二季度': [moment(moment().month(3)).startOf('month'), moment(moment().month(5)).endOf('month')],
            '第三季度': [moment(moment().month(6)).startOf('month'), moment(moment().month(8)).endOf('month')],
            '第四季度': [moment(moment().month(9)).startOf('month'), moment(moment().month(11)).endOf('month')],
            '近四周': [moment().week(moment().week() - 3).startOf('week'), moment().endOf('week')],
            
         }
      }
    },
    methods:{
      moment,
        disabledDate(current) {
            return current && current > moment().endOf('day');
        },
        disabledDateF(current){
            return current && current < moment().startOf('day');
        },
        onChange(date, dateString,isRefresh){
          let time
          let isYear = 0
          if(dateString.length>0){
            time = moment(dateString[1]).valueOf() - moment(dateString[0]).valueOf()
            isYear = moment.duration(time).asMonths()
          }
          if(isYear > 12){
            this.$message.error('最多选择一年时间!')
            this.rangesValue = []
          }else{
            this.rangesValue = date
            if(dateString[0]){
              this.paramsData.start_date = dateString[0]
              this.paramsData.end_date = dateString[1]
              if(this.searchParams){
                this.searchParams.search.start_date = dateString[0]
                this.searchParams.search.end_date = dateString[1]
              }
            }else{
              this.paramsData.start_date = ''
              this.paramsData.end_date = ''
              if(this.searchParams){
                this.searchParams.search.start_date = ''
                this.searchParams.search.end_date = ''
              }
            }
          }
          if(isRefresh == 1){
            this.toScreen()
          }
          if(isRefresh == 2){
            this.searchList()
          }
          if(isRefresh == 3){
            this.getList()
          }
        },
    }
  }