export const timeHandle = function(hour){
    let newTime = ''
    if(hour >= 0){
        if(hour >= 24){
            //newTime = '<a-badge color="green" text="'+ parseInt(hour/24) + '天后 />'  //+ hour%24 + '小时'
            newTime = {
                color:'green',
                text:`${parseInt(hour/24)}天后`
            }
        }else{
            if (hour)
                // newTime ='<a-badge color="yellow" text="'+hour +'小时后" />' 
                newTime = {
                    color:'yellow',
                    text:`${hour}小时后`
                }
            else{
                // newTime = '<a-badge color="yellow" text="现在" />'
                newTime = {
                    color:'yellow',
                    text:`现在`
                }
            }
        }
    }else{
        let newHour = 0 - hour
        if(newHour >= 24){
            // newTime = '<a-badge color="pink" text="超过' + parseInt(newHour/24) + '天" />';// + newHour%24 + '小时'
            newTime = {
                color:'pink',
                text:`超${parseInt(newHour/24)}天`
            }
        }else{
            // newTime = '<a-badge color="pink" text="超过' + newHour + '小时 />';
            newTime = {
                color:'pink',
                text:`超${newHour}小时`
            }
        }
    }
    return newTime
}

//阿拉伯数字转中文数字
export const NoToChinese = function (num) {
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
    }
    var AA = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");
    var BB = new Array("", "拾", "佰", "仟", "万", "亿", "元","","角",'分');
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    re += BB[6];
    if (a.length > 1) //加上小数部分(如果有小数部分) 
    {
        for (var i = 0; i < a[1].length; i++) re += `${AA[a[1].charAt(i)]}${BB[i+8]}`;
    } else 
    {
        re += '整';
    }
    return re;
}

//  文件大小格式化
export const RenderSize = function (value){
    if(null==value||value==''){
        return "0 Bytes";
    }
    var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
    var index=0;
    var srcsize = parseFloat(value);
    index=Math.floor(Math.log(srcsize)/Math.log(1024));
    var size =srcsize/Math.pow(1024,index);
    size=size.toFixed(2);//保留的小数位数
    return size+unitArr[index];
}

// 防抖函数
export const delay = (function () {
    let timer = 0
    return function (callback, ms) {
     clearTimeout(timer)
     timer = setTimeout(callback, ms)
    }
})()