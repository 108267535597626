import Vue from 'vue'
import { 
  stockWarehouse,stockWarehouseAdd,stockWarehouseDetail,stockWarehouseUpdate,stockWarehouseDelete,stockWarehouseRecovery,stockProduct,stockProductAdd,stockProductUpdate,stockProductDetail,stockProductStock,stockProductRecords,
  stockProductIncome,stockProductMinus,
 } from '@/api/stock'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    stockWarehouseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockWarehouseAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouseAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockWarehouseDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouseDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockWarehouseUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouseUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockWarehouseDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouseDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockWarehouseRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockWarehouseRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProduct(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductRecordsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductRecords(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductIncomeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductIncome(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    stockProductMinusAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        stockProductMinus(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
