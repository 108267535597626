const Layout = () => import('@/views/layout')
const ClientResource = () => import('@/views/clientresource')
const Workbench = () => import('@/views/clientresource/workbench')
const ClientResourceSchedule = () => import('@/views/clientresource/schedule')
const Clue= () => import('@/views/clientresource/clue')
const Customer= () => import('@/views/clientresource/customer')
const Lost= () => import('@/views/clientresource/lost')
const Invite= () => import('@/views/clientresource/invite')
const Collect= () => import('@/views/clientresource/collect')
const Seas= () => import('@/views/clientresource/seas')
const costWarning= () => import('@/views/clientresource/costWarning')
const FollowLog= () => import('@/views/clientresource/followLog/table')
const ClientResourceContract= () => import('@/views/clientresource/contract')
const Receipt= () => import('@/views/clientresource/receipt')
const TalkingSkills= () => import('@/views/clientresource/talkingSkills')
const Transfer= () => import('@/views/clientresource/transfer')
const ChangeLog= () => import('@/views/clientresource/changeLog')
const SendMessage= () => import('@/views/clientresource/sendMessage')
const SendMessageUserEditModal= () => import('@/views/clientresource/sendMessage/user/editModal')
const SendMessageGroupEditModal= () => import('@/views/clientresource/sendMessage/group/editModal')

const customerRouter = {
    path: '/crm',
    component: Layout,
    hide: true,
    redirect: '/crm/todo',
    parent:'customerRouter',
    meta: {
        title: '招生咨询' ,
        type:'crm',
        requiresAuth: true,
        icon: '#icon-QueryOutline',
        menusKey:1
    },
    children:[
        {
            path: 'todo',
            hide:false,
            component: ClientResourceSchedule,
            parent:'clientresource',
            meta: {
                title: '待办事项',
                requiresAuth: false,
                icon: '#icon-BadgeOutline',
                parent: 'crm'
            }
        },
        {
            path: 'clue',
            hide:false,
            component: Clue,
            parent:'clientresource',
            meta: {
                title: '我的线索' ,
                requiresAuth: true,
                type:'leads',
                icon: '#icon-GuideOutline1',
                parent: 'crm'
            }
        },
        {
            path: 'customer',
            hide:false,
            component: Customer,
            parent:'clientresource',
            meta: {
                title: '我的客户' ,
                type:'customer',
                requiresAuth: true,
                icon: '#icon-BookOutline',
                parent: 'crm'
            }
        },
        {
            path: 'invite',
            hide:false,
            component: Invite,
            parent:'clientresource',
            meta: {
                title: '邀约到访' ,
                type:'inviteattend',
                requiresAuth: true,
                icon: '#icon-CompleteOutline',
                parent: 'crm'
            }
        },
        {
            path: 'follow',
            hide:false,
            component: FollowLog,
            parent:'clientresource',
            meta: {
                title: '跟进记录' ,
                type:'inviteattend',
                requiresAuth: true,
                icon: '#icon-DiskOutline',
                parent: 'crm'
            }
        },
        {
            path: 'lost',
            hide:false,
            component: Lost,
            parent:'clientresource',
            meta: {
                title: '客户流失' ,
                type:'inviteattend',
                requiresAuth: true,
                icon: '#icon-HistoryOutline',
                parent: 'crm'
            }
        },
        {
            path: 'seas',
            hide:false,
            component: Seas,
            parent:'clientresource',
            meta: {
                title: '客户公海' ,
                type:'pool',
                requiresAuth: true,
                icon: '#icon-EarthOutline',
                parent: 'crm'
            }
        },
        {
            path: 'contract',
            hide:false,
            parent:'clientresource',
            component: ClientResourceContract,
            meta: {
                title: '我的合同' ,
                type:'contract',
                requiresAuth: true,
                icon: '#icon-IndentOutline',
                parent: 'crm'
            }
        },
        {
            path: 'receipt',
            hide:false,
            parent:'clientresource',
            component: Receipt,
            meta: {
                title: '我的发票' ,
                type:'receipt',
                requiresAuth: true,
                icon: '#icon-GoldOutline',
                parent: 'crm'
            }
        },
        // {
        //     path: 'costWarning',
        //     hide:false,
        //     parent:'clientresource',
        //     component: costWarning,
        //     meta: {
        //         title: '费用预警' ,
        //         type:'contract',
        //         requiresAuth: true,
        //         icon: '#icon-GivelikeOutline',
        //         parent: 'crm'
        //     }
        // },
        // {
        //     path: 'workbench',
        //     hide:false,
        //     component: Workbench,
        //     parent:'clientresource',
        //     meta: {
        //         title: '招生简报' ,
        //         requiresAuth: false,
        //         icon: '#icon-OperateOutline',
        //         parent: 'crm'
        //     }
        // },
        {
            path: 'collect',
            hide:false,
            component: Collect,
            parent:'clientresource',
            meta: {
                type:'discourse',
                title: '线索留资',
                requiresAuth: true,
                icon: '#icon-DiskOutline',
                parent: 'crm'
            }
        },
        {
            path: 'discourse',
            hide:false,
            component: TalkingSkills,
            parent:'clientresource',
            meta: {
                type:'discourse',
                title: '招生Q&A',
                requiresAuth: true,
                icon: '#icon-ScheduleOutline',
                parent: 'crm'
            }
        },
        {
            path: 'transfer',
            hide:false,
            component: Transfer,
            parent:'clientresource',
            meta: {
                type:'transfer',
                title: '转移记录',
                requiresAuth: true,
                icon: '#icon-CycleOutline',
                parent: 'crm'
            }
        },
        {
            path: 'changelog',
            hide:false,
            component: ChangeLog,
            parent:'clientresource',
            meta: {
                type:'changelog',
                title: '变更记录',
                requiresAuth: true,
                icon: '#icon-CountdownOutline',
                parent: 'crm'
            }
        },
        {
            path: 'sendMessage',
            hide:false,
            component: SendMessage,
            parent:'clientresource',
            meta: {
                type:'discourse',
                title: '企微群发',
                requiresAuth: true,
                icon: '#icon-qiyeweixin',
                parent: 'crm'
            }
        },
        {
            path: 'sendMessage/user/edit',
            hide:true,
            component: SendMessageUserEditModal,
            parent:'clientresource',
            meta: {
                type:'discourse',
                title: '信息编辑',
                requiresAuth: false,
                icon: '#icon-ScheduleOutline',
                parent: 'crm'
            }
        },
        {
            path: 'sendMessage/group/edit',
            hide:true,
            component: SendMessageGroupEditModal,
            parent:'clientresource',
            meta: {
                type:'discourse',
                title: '信息编辑',
                requiresAuth: false,
                icon: '#icon-ScheduleOutline',
                parent: 'crm'
            }
        },
        // {
        //     path: 'brief',
        //     hide:false,
        //     component: FollowLog,
        //     parent:'clientresource',
        //     meta: {
        //         title: '招生简报',
        //         requiresAuth: false,
        //         icon: '#icon-OperateOutline',
        //         parent: 'crm'
        //     }
        // }
    ]
}

export default customerRouter