import Vue from 'vue'
import { studioList, studioDetail, studioUpdate, studioAdd, studioDelete, 
  studioRecovery, studioChangeOrder, studioDownload, studioQrcodeview,
  studioSuggestion,studioSuggestionDetail,studioSuggestionRead,studioSuggestionMultiAction } from '@/api/studio'
import config from '@/utils/const'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    studioListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioChangeOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioChangeOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioDownloadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioDownload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioQrcodeviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioQrcodeview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioSuggestionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioSuggestion(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioSuggestionDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioSuggestionDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioSuggestionReadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioSuggestionRead(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    studioSuggestionMultiActionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioSuggestionMultiAction(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
