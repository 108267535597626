var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"confirmLoading":_vm.confirmLoading,"cancelText":"取消","okText":"确认排课","width":"80%","footer":null},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('div',{staticClass:"clearfix table-tools"},[_c('div',{staticClass:"search"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',[_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("时间筛选")])]),_c('a-range-picker',{staticStyle:{"width":"240px"},attrs:{"ranges":_vm.rangesData,"allowClear":false},on:{"change":function (val, date){ return _vm.handleTime(val, date); }},model:{value:(_vm.paramsData.time),callback:function ($$v) {_vm.$set(_vm.paramsData, "time", $$v)},expression:"paramsData.time"}})],2)],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchList}},[_vm._v("搜索")])],1)],1)],1)]),_c('v-gantt-chart',{attrs:{"startTime":_vm.startTime,"endTime":_vm.endTime,"dataKey":"id","cellHeight":30,"cellWidth":30,"arrayKeys":_vm.arrayKeys,"datas":_vm.datas},scopedSlots:_vm._u([{key:"block",fn:function(ref){
var data = ref.data;
var item = ref.item;
return [_c('a-popover',{attrs:{"title":""}},[_c('template',{slot:"content"},[_c('p',[_vm._v("时间："+_vm._s(item.start.slice(10,16))+" -"+_vm._s(item.end.slice(10,16)))]),_c('p',[_vm._v("老师："+_vm._s(item.teacher_name))]),_c('p',[_vm._v("人数："+_vm._s(item.name))]),_c('p',[_vm._v("课程："+_vm._s(item.course_name))]),_c('p',[_vm._v("教室："+_vm._s(item.room_name))])]),(data.type == '老师')?_c('div',{staticStyle:{"background":"#00cca2","color":"#fff","text-align":"center"}},[_vm._v(_vm._s(item.name))]):_c('div',{staticStyle:{"background":"#FCC955","color":"#fff","text-align":"center"}},[_vm._v(_vm._s(item.name))])],2)]}},{key:"left",fn:function(ref){
var data = ref.data;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"0 5px"}},[_c('span',{staticStyle:{"width":"150px","display":"block","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(_vm._s(data.name))]),_c('span',[_vm._v(_vm._s(data.type))])])]}},{key:"timeline",fn:function(ref){
var day = ref.day;
var getTimeScales = ref.getTimeScales;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.moment(day.$d).format('MM/DD 【dddd】')))]),_c('div',{staticStyle:{"display":"flex"}},_vm._l((getTimeScales(day)),function(i,index){return _c('span',{key:index,staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(i.format('HH')))])}),0)])]}},{key:"title",fn:function(){return [_vm._v("老师/教室")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }