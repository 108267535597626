<template>
      <a-card title="">
        <perfect-scrollbar :style="{height:`${setHeight}px`}">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-bar 
            judge-width
            :legend="{show:false}" 
            :grid="{right: '5%'}"
            :toolbox="toolbox"
            :height="wHeight"
            :title="{
                text:`${title} ${rank_data.title?`(${rank_data.title})`:''}`,
                subtext: subtitle?subtitle:(start_date?start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`:''),
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }" 
            :data="rank_data"
            :settings="{label: { show: true, position: 'right'},xAxisType: ['KMB', 'KMB']}" 
            :extend="{
        xAxis:{
            splitLine: {
            lineStyle: {
                type: 'dotted'
            }
            }
        },
        yAxis:{
            inverse: true,  
            splitLine:{show: false},
            axisLabel: {
                color: '#333',
                rich: {
                    b: {
                        color: '#f4bc2f',
                        align: 'center',
                    }
                },
                formatter: function(params,index) {
                    return [
                            `${params} {b| ${index + 1}}`
                        ].join('\n')
                }
            }
        }, 
        series:{type:'bar',barMaxWidth:10,margin:80,itemStyle:{
            normal:{
                color: function(params) {
                    return {
                        type: 'linear',
                        colorStops: [{
                            offset: 0,
                            color: line_color?'#fff':'#effffb'
                            
                        }, {
                            offset: 1,                    
                            color: line_color || '#23d9ae'
                        }],
                    }
            },barBorderRadius:[0,30,30,0]}}},dataZoom:[{type:'slider'}]}"  ></ve-bar>
            <LEmpty v-if="first>0 && rank_data.rows && rank_data.rows.length == 0"/>
        </div>
        </perfect-scrollbar>
    </a-card>
</template>

<script>
export default {
  name: 'LRanking',
  props: {
    start_date: String,
    end_date: String,
    rank_data:Object,
    title:String,
    subtitle:String,
    loading:Boolean,
    line_color:String,
    heightNum:Number
  },
  data(){
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [
          {
              name: '平均线',
              type: 'average'
          }
          ]
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
      return{
          first:0,
          setHeight:400,
      }
  },
  created(){
      this.setHeight = this.heightNum?this.heightNum:400
  },
  computed: {
        wHeight() {
            let height = this.setHeight
            if(this.rank_data.rows && this.rank_data.rows.length > 10){
                height = this.setHeight + ( this.rank_data.rows.length - 10 ) * 20
            }
            return `${height}px`
        }
  },
  watch: {
    loading(newValue, oldValue) {
        this.first++;
    }
  },
}
</script>
