import url from '@/utils/URL'
import request from '@/utils/request'

export function daMessages(params) {
  return request({
    url: url.daMessages,
    method: 'POST',
    ...params
  })
}
export function daMessagesClasses(params) {
  return request({
    url: url.daMessagesClasses,
    method: 'POST',
    ...params
  })
}
export function daMessagesPublish(params) {
  return request({
    url: url.daMessagesPublish,
    method: 'POST',
    ...params
  })
}
export function daMessagesAddCate(params) {
  return request({
    url: url.daMessagesAddCate,
    method: 'POST',
    ...params
  })
}
export function daMessagesDetail(params) {
  return request({
    url: url.daMessagesDetail,
    method: 'POST',
    ...params
  })
}
export function daMessagesRecommand(params) {
  return request({
    url: url.daMessagesRecommand,
    method: 'POST',
    ...params
  })
}
export function daMessagesUpdateCate(params) {
  return request({
    url: url.daMessagesUpdateCate,
    method: 'POST',
    ...params
  })
}
export function daMessagesStatus(params) {
  return request({
    url: url.daMessagesStatus,
    method: 'POST',
    ...params
  })
}
export function daMessagesDeleteCate(params) {
  return request({
    url: url.daMessagesDeleteCate,
    method: 'POST',
    ...params
  })
}
export function daMessagesRecoveryCate(params) {
  return request({
    url: url.daMessagesRecoveryCate,
    method: 'POST',
    ...params
  })
}
export function daMessagesDelete(params) {
  return request({
    url: url.daMessagesDelete,
    method: 'POST',
    ...params
  })
}
export function daMessagesRecovery(params) {
  return request({
    url: url.daMessagesRecovery,
    method: 'POST',
    ...params
  })
}