import url from '@/utils/URL'
import request from '@/utils/request'

export function notificationNotice(params) {
  return request({
    url: url.notificationNotice,
    method: 'POST',
    ...params
  })
}

export function notificationCenter(params) {
  return request({
    url: url.notificationCenter,
    method: 'POST',
    ...params
  })
}

export function notificationCenterAdd(params) {
  return request({
    url: url.notificationCenterAdd,
    method: 'POST',
    ...params
  })
}

export function notificationCenterUpdate(params) {
  return request({
    url: url.notificationCenterUpdate,
    method: 'POST',
    ...params
  })
}

export function notificationCenterDetail(params) {
  return request({
    url: url.notificationCenterDetail,
    method: 'POST',
    ...params
  })
}

export function notificationCenterDelete(params) {
  return request({
    url: url.notificationCenterDelete,
    method: 'DELETE',
    ...params
  })
}

export function notificationCenterRecovery(params) {
  return request({
    url: url.notificationCenterRecovery,
    method: 'POST',
    ...params
  })
}

export function notificationCenterResend(params) {
  return request({
    url: url.notificationCenterResend,
    method: 'POST',
    ...params
  })
}

export function notificationCenterNotifyList(params) {
  return request({
    url: url.notificationCenterNotifyList,
    method: 'POST',
    ...params
  })
}

export function notificationCenterAjaxGetUser(params) {
  return request({
    url: url.notificationCenterAjaxGetUser,
    method: 'POST',
    ...params
  })
}

export function notificationOverview(params) {
  return request({
    url: url.notificationOverview,
    method: 'POST',
    ...params
  })
}

export function notificationIndex(data) {
  return request({
    url: url.notificationIndex,
    method: 'POST',
    data
  })
}

export function notificationUnread() {
  return request({
    url: url.notificationUnread,
    method: 'POST'
  })
}

export function notificationSeen(data) {
  return request({
    url: url.notificationSeen,
    method: 'POST',
    data
  })
}
export function notificationSeenAll(data) {
  return request({
    url: url.notificationSeenAll,
    method: 'POST',
    data
  })
}
