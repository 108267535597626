import Menu from './Menu'
import AppLink from './AppLink'
import LEditor from './LEditor'
import LMap from './LMap'
import LImg from './LImg'
import LRanking from './LRanking'
import LGraph from './LGraph'
import LUser from './LUser'
import LEmpty from './LEmpty'
import VErings from './VErings'
import VElines from './VElines'
import HelpCenters from './HelpCenters'

const list = [
  Menu,
  AppLink,
  LEditor,
  LMap,
  LImg,
  LRanking,
  LGraph,
  LUser,
  LEmpty,
  VErings,
  VElines,
  HelpCenters,
]

export default list
