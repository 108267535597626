import Vue from 'vue'
import { 
  meetingCampaign,meetingCampaignDetail,meetingCampaignUpdate,meetingCampaignAdd,meetingCampaignSetting,meetingCampaignQrcodeview,meetingCampaignDeleteTicket,meetingCampaignRecoveryTicket,meetingCampaignCheckin,meetingCampaignUnCheckin,meetingCampaignCheckAll,meetingCampaignAjaxCheckin,meetingCampaignQrcodedownload,meetingCampaignDelete,meetingCampaignRecovery,meetingCampaignDownloadExportList,meetingCampaignDeleteNotify,meetingChannel,meetingChannelAdd,meetingChannelUpdate,meetingChannelDetail,meetingTicketsDetail,meetingTicketsFreeType,meetingTicketsAddFreeType,meetingTicketsDiscountType,meetingTicketsAddDiscountType,meetingTicketsUpdateType,meetingTickets,meetingTicketsAdd,meetingTicketsCheckin,meetingTicketsUnCheckin,meetingCheckin,meetingCheckinQrcodeview
 } from '@/api/meeting'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    meetingCampaignAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaign(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignSettingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignSetting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignQrcodeviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignQrcodeview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignDeleteTicketAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignDeleteTicket(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignRecoveryTicketAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignRecoveryTicket(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignUnCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignUnCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignCheckAllAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignCheckAll(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignAjaxCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignAjaxCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignQrcodedownloadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignQrcodedownload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignDownloadExportListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignDownloadExportList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCampaignDeleteNotifyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCampaignDeleteNotify(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingChannelAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingChannelAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingChannelUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingChannelUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingChannelDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingChannelDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsFreeTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsFreeType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsAddFreeTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsAddFreeType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsDiscountTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsDiscountType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsAddDiscountTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsAddDiscountType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsUpdateTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsUpdateType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTickets(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingTicketsUnCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingTicketsUnCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    meetingCheckinQrcodeviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        meetingCheckinQrcodeview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
