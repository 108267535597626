import url from '@/utils/URL'
import request from '@/utils/request'

export function deviceScanIndex(data) {
  return request({
    url: url.deviceScanIndex,
    method: 'POST',
    data
  })
}

export function deviceScanCheckin(data) {
  return request({
    url: url.deviceScanCheckin,
    method: 'POST',
    data
  })
}

export function deviceScanCheckinList(data) {
  return request({
    url: url.deviceScanCheckinList,
    method: 'POST',
    data
  })
}

export function deviceScanEvents(data) {
  return request({
    url: url.deviceScanEvents,
    method: 'POST',
    data
  })
}

export function deviceScanPayment(data) {
  return request({
    url: url.deviceScanPayment,
    method: 'POST',
    data
  })
}
