import Vue from 'vue'
import { weipagesRecruit,weipagesRecruitAdd,weipagesRecruitDetail,weipagesRecruitUpdate,weipagesRecruitDelete,weipagesRecruitRecovery,weipagesRecruitMember,
  weipagesRecruitExport,weipagesRecruitDeleteMember,} from '@/api/weipages'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    weipagesRecruitAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weipagesRecruitAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weipagesRecruitDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weipagesRecruitUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weipagesRecruitDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    weipagesRecruitRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weipagesRecruitMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    weipagesRecruitDeleteMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitDeleteMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    weipagesRecruitExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weipagesRecruitExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
