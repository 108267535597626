<template>
  <a-modal title="上课班级" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="50%" :zIndex='4002'
    @ok="handleOk" @cancel="handleCancel">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
          <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-input v-model.trim="searchParams.search.class_name" placeholder="请输入班级名称" style="width: 160px"/>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>

          <a-form-item>
            <a-popover overlayClassName="classesModal-pop" placement="bottom" trigger="click" v-model="searchVisible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="课程科目" >
                      <a-select v-model="searchParams.search.course_cate_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of cCategorys" :key="index" :value="item.course_cate_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select v-model="searchParams.search.tutor_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班主任" >
                      <a-select v-model="searchParams.search.cc_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of consultants" :key="index" :value="item.course_consultant_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="周几授课" >
                      <a-select v-model="searchParams.search.weekly" mode="multiple" allowClear placeholder="请选择" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of classWeeklys" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班级类型" >
                      <a-select v-model="searchParams.search.class_type" allowClear placeholder="请选择" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of classTypes" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="班级状态" >
                      <a-select v-model="searchParams.search.status" allowClear placeholder="请选择" :dropdownStyle='{zIndex:4200}'>
                        <a-select-option v-for="(item, index) of classStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>

        </a-form>
      </div>
    </div>
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="class_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
      <template slot="class_had_course_count" slot-scope="class_had_course_count,record">
        <a>{{class_had_course_count}} / {{record.class_total_course_count}}</a>
    </template>
        
    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
import tableMixins from '@/common/mixins/table'
import cItems from '@/utils/items'

const columns = [
  { title: '班级名称', width: '190px', dataIndex: 'class_name', key: 'class_name'},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'course_cate_id',ellipsis:true},
  { title: '授课老师', dataIndex: 'tutor_id', key: 'tutor_id' ,ellipsis:true,width:'80px'},
  { title: '班主任', dataIndex: 'cc_id', key: 'cc_id' ,ellipsis:true,width:'80px'},
  { title: '班级类型', dataIndex: 'class_type', key: 'class_type' ,ellipsis:true,width:'80px'},
  { title: '班级学员', dataIndex: 'class_count', key: 'class_count',align:'right',width:'80px'},
  { title: '课程进度', dataIndex: 'class_had_course_count', key: 'class_had_course_count',scopedSlots: { customRender: 'class_had_course_count' } ,align:"right",width:'80px'},
  { title: '最近排课', dataIndex: 'class_start', key: 'class_start'},
]
    export default {
        name:"classesModal",
        inject: ['parent'],
        props: {
            item: Object,
            studentId:Number,
        },
        mixins: [ tableMixins ],
        data() {
            return {
                loading:false,
                cCategorys: [],
                teachers: [],
                consultants: [],
                classTypes: cItems.classTypes,
                classStatus: cItems.class_status,
                classWeeklys: cItems.classWeeklys,
                visible: false,
                confirmLoading: false,
                columns,
                list: [],
                selectedRowKeys: [],
                localClassesList:[],
            }
        },
        created () {
            this.visible = true
            this.cCategoryDetailAction()
            this.getTeacher()
            this.getConsultant()
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.student_id = this.studentId
                await this.$store.dispatch('studentsProfileCanJoinClassesAction', {data:this.searchParams})
                .then(res=>{
                  this.list = res.items
                  this.pageParams = res._meta
                  this.loading = false
                })
                .catch(err=>{
                  this.loading = false
                })
            },
            async cCategoryDetailAction() {
              let res = await this.$store.dispatch('searchCourseTypeAction', {})
              this.cCategorys = res.data
            },
            async getTeacher() {
              let res = await this.$store.dispatch('searchTeacherAction', {})
              this.teachers = res.data
            },
            async getConsultant() {
              let res = await this.$store.dispatch('searchConsultantAction', {})
              this.consultants = res.data
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            let index = this.selectedRowKeys.indexOf(record.class_id)
                            if(index == -1){
                            this.selectedRowKeys.push(record.class_id)
                            this.selectedRecord.push(record)
                            }else{
                            this.selectedRowKeys.splice(index,1)
                            this.selectedRecord.splice(index,1)
                            }
                        }
                    }
                }
            },
            async handleOk(){
              if(this.selectedRowKeys.length == 0){
                this.$message.warning('请选择班级!')
                return false
              }
              this.confirmLoading = true
              await this.$store.dispatch('studentsProfileAddToClassesAction', {data:{student_id:this.studentId,class_id:this.selectedRowKeys}})
              .then(res=>{
                this.$message.success('操作成功~')
                this.parent.hideClassesModal(1)
              })
              .catch(err=>{
                this.confirmLoading = false
              })
            },
            handleCancel(){
                this.parent.hideClassesModal(0)
            }
        },
    }
</script>

<style lang="scss">
  .classesModal-pop{
    z-index: 4100 !important;
  }
</style>