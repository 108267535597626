import Vue from 'vue'
import { contractRefund, contractStudents, contractPendding, contractExport, contractPdf, contractRollback, contractDetail,
  financeCosttimes ,financeCosttimesExport,financeCosttimesCourse} from '@/api/contract'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    contractRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractStudentsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractStudents(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractPenddingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractPendding(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractPdfAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractPdf(params).then(response => {
          const content = response
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractRollbackAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractRollback(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    contractDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        contractDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeCosttimesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeCosttimes(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeCosttimesCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeCosttimesCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeCosttimesExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeCosttimesExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
