import Vue from 'vue'
import { marketingQrchannel,marketingQrchannelAdd,marketingQrchannelUpdate,marketingQrchannelDownload,marketingQrcodepayment,
         marketingQrcodepaymentQrcodeview,marketingQrcodepaymentPaymentlist,marketingQrcodepaymentExportPaymentlist,marketingRentBillFapiao,marketingParkRoomUpdate,marketingRoomDetail,marketingRentBillExport,marketingRentRoomDel,marketingRentRoomRecovery,marketingRentBillConfirm,marketingParkUpdate,marketingParkDetail,marketingRentBillAnalysis,marketingRentBillDel,marketingParkBillAdd,marketingParkRoomAdd,marketingRentFilterRoom,marketingRentFilterPark,marketingRentRoomlist,marketingParkAdd,marketingParklist,marketingRentBilllist,marketingQrcodepaymentSetting,marketingQrcodepaymentAdd,
         marketingQrcodepaymentDownload,marketingQrcodepaymentDeleteNotify,marketingQrcodepaymentDelete,marketingQrcodepaymentRecovery
 } from '@/api/marketing'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    marketingQrchannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrchannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrchannelAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrchannelAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrchannelUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrchannelUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrchannelDownloadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrchannelDownload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkBillAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkBillAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkRoomAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkRoomAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkRoomUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkRoomUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentFilterRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentFilterRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentFilterParkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentFilterPark(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentRoomlistAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentRoomlist(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentRoomDelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentRoomDel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentBillFapiaoAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBillFapiao(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentRoomRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentRoomRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParklistAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParklist(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRoomDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRoomDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingParkUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingParkUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepayment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentQrcodeviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentQrcodeview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentBilllistAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBilllist(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    
    marketingRentBillAnalysisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBillAnalysis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentBillConfirmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBillConfirm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentBillDelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBillDel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    marketingRentBillExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingRentBillExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentPaymentlistAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentPaymentlist(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentExportPaymentlistAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentExportPaymentlist(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentSettingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentSetting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentDownloadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentDownload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentDeleteNotifyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentDeleteNotify(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    marketingQrcodepaymentRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        marketingQrcodepaymentRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
