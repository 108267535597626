import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 校区管理-列表
 */
export function orginizationList() {
  return request({
    url: url.orginization,
    method: 'POST'
  })
}