import Vue from 'vue'
import { rateStudent,rateTeacher,rateStudentCheck,rateStudentDelete,rateStudentAjaxView,rateStudentAjaxViewComment,rateStudentCourseView,rateStudentDetail,rateStudentMultiAction,rateStudentAdd, 
  rateStudentAddComment} from '@/api/rate'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    rateStudentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentAjaxViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentAjaxView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentAjaxViewCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentAjaxViewComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentCourseViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentCourseView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentMultiActionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentMultiAction(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    rateStudentAddCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        rateStudentAddComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
