import url from '@/utils/URL'
import request from '@/utils/request'

export function achievement(params) {
  return request({
    url: url.achievement,
    method: 'POST',
    ...params
  })
}

export function studioAchievement(params) {
  return request({
    url: url.studioAchievement,
    method: 'POST',
    ...params
  })
}

export function studioAchievementExport(data) {
  return request({
    url: url.studioAchievementExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function achievementUpdate(data) {
  return request({
    url: url.achievementUpdate,
    method: 'POST',
    data
  })
}

export function studioAchievementUpdate(data) {
  return request({
    url: url.studioAchievementUpdate,
    method: 'POST',
    data
  })
}

export function achievementExport(data) {
  return request({
    url: url.achievementExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function settingCrmConfig(data) {
  return request({
    url: url.settingCrmConfig,
    method: 'POST',
    data
  })
}

export function settingCrmConfigUpdate(data) {
  return request({
    url: url.settingCrmConfigUpdate,
    method: 'POST',
    data
  })
}

export function settingCrmCustomerConfig(data) {
  return request({
    url: url.settingCrmCustomerConfig,
    method: 'POST',
    data
  })
}

export function settingCrmCustomerConfigAdd(data) {
  return request({
    url: url.settingCrmCustomerConfigAdd,
    method: 'POST',
    data
  })
}

export function settingCrmCustomerConfigUpdate(data) {
  return request({
    url: url.settingCrmCustomerConfigUpdate,
    method: 'POST',
    data
  })
}

export function settingCrmCustomerConfigDelete(data) {
  return request({
    url: url.settingCrmCustomerConfigDelete,
    method: 'POST',
    data
  })
}

export function achievementChannel(params) {
  return request({
    url: url.achievementChannel,
    method: 'POST',
    ...params
  })
}

export function audition(params) {
  return request({
    url: url.audition,
    method: 'POST',
    ...params
  })
}

export function auditionBook(params) {
  return request({
    url: url.auditionBook,
    method: 'POST',
    ...params
  })
}

export function auditionDemoSchedule(params) {
  return request({
    url: url.auditionDemoSchedule,
    method: 'POST',
    ...params
  })
}

export function auditionUpdate(params) {
  return request({
    url: url.auditionUpdate,
    method: 'POST',
    ...params
  })
}

export function auditionAdd(params) {
  return request({
    url: url.auditionAdd,
    method: 'POST',
    ...params
  })
}

export function auditionPendding(params) {
  return request({
    url: url.auditionPendding,
    method: 'POST',
    ...params
  })
}

export function auditionDetail(params) {
  return request({
    url: url.auditionDetail,
    method: 'POST',
    ...params
  })
}

export function auditionCancel(params) {
  return request({
    url: url.auditionCancel,
    method: 'POST',
    ...params
  })
}

export function auditionSign(params) {
  return request({
    url: url.auditionSign,
    method: 'POST',
    ...params
  })
}

export function auditionDelete(params) {
  return request({
    url: url.auditionDelete,
    method: 'POST',
    ...params
  })
}

export function auditionCancelDemoClass(params) {
  return request({
    url: url.auditionCancelDemoClass,
    method: 'POST',
    ...params
  })
}

export function auditionDemoTime(params) {
  return request({
    url: url.auditionDemoTime,
    method: 'POST',
    ...params
  })
}

export function auditionResend(params) {
  return request({
    url: url.auditionResend,
    method: 'POST',
    ...params
  })
}

export function auditionAddDemo(params) {
  return request({
    url: url.auditionAddDemo,
    method: 'POST',
    ...params
  })
}

export function auditionAddDemoSchedule(params) {
  return request({
    url: url.auditionAddDemoSchedule,
    method: 'POST',
    ...params
  })
}

export function auditionDemoArrage(params) {
  return request({
    url: url.auditionDemoArrage,
    method: 'POST',
    ...params
  })
}

export function client(params) {
  return request({
    url: url.client,
    method: 'POST',
    ...params
  })
}

export function clientAssign(params) {
  return request({
    url: url.clientAssign,
    method: 'POST',
    ...params
  })
}

export function clientPendding(params) {
  return request({
    url: url.clientPendding,
    method: 'POST',
    ...params
  })
}

export function clientChangeLog(params) {
  return request({
    url: url.clientChangeLog,
    method: 'POST',
    ...params
  })
}

export function clientFollowLog(params) {
  return request({
    url: url.clientFollowLog,
    method: 'POST',
    ...params
  })
}

export function clientStar(params) {
  return request({
    url: url.clientStar,
    method: 'POST',
    ...params
  })
}

export function clientAdd(data) {
  return request({
    url: url.clientAdd,
    method: 'POST',
    data
  })
}

export function clientUpdate(params) {
  return request({
    url: url.clientUpdate,
    method: 'PUT',
    ...params
  })
}

export function clientDetail(params) {
  return request({
    url: url.clientDetail,
    method: 'POST',
    ...params
  })
}

export function clientFollow(params) {
  return request({
    url: url.clientFollow,
    method: 'POST',
    ...params
  })
}

export function clientNoneWill(params) {
  return request({
    url: url.clientNoneWill,
    method: 'POST',
    ...params
  })
}

export function clientToday(params) {
  return request({
    url: url.clientToday,
    method: 'POST',
    ...params
  })
}

export function clientMultiSetting(params) {
  return request({
    url: url.clientMultiSetting,
    method: 'POST',
    ...params
  })
}

export function clientMultiExport(params) {
  return request({
    url: url.clientMultiExport,
    method: 'POST',
    ...params
  })
}

export function clientMultiAction(params) {
  return request({
    url: url.clientMultiAction,
    method: 'POST',
    ...params
  })
}

export function clientDelete(params) {
  return request({
    url: url.clientDelete,
    method: 'DELETE',
    ...params
  })
}

export function crmBrief(data) {
  return request({
    url: url.crmBrief,
    method: 'POST',
    data
  })
}

export function todoToday(data) {
  return request({
    url: url.todoToday,
    method: 'POST',
    data
  })
}

export function todoTodayLeads(data) {
  return request({
    url: url.todoTodayLeads,
    method: 'POST',
    data
  })
}

export function todoCount(data) {
  return request({
    url: url.todoCount,
    method: 'POST',
    data
  })
}

export function todoLeads(data) {
  return request({
    url: url.todoLeads,
    method: 'POST',
    data
  })
}

export function todoCustomer(data) {
  return request({
    url: url.todoCustomer,
    method: 'POST',
    data
  })
}

export function todoContract(data) {
  return request({
    url: url.todoContract,
    method: 'POST',
    data
  })
}

export function todoRemindCustomer(data) {
  return request({
    url: url.todoRemindCustomer,
    method: 'POST',
    data
  })
}

export function cWorkbench(data) {
  return request({
    url: url.cWorkbench,
    method: 'POST',
    data
  })
}

export function cWorkbenchAchievement(data) {
  return request({
    url: url.cWorkbenchAchievement,
    method: 'POST',
    data
  })
}

export function cWorkbenchFunnel(data) {
  return request({
    url: url.cWorkbenchFunnel,
    method: 'POST',
    data
  })
}

export function cWorkbenchSale(data) {
  return request({
    url: url.cWorkbenchSale,
    method: 'POST',
    data
  })
}

export function cWorkbenchHeatmap(data) {
  return request({
    url: url.cWorkbenchHeatmap,
    method: 'POST',
    data
  })
}

export function cLeads(params) {
  return request({
    url: url.cLeads,
    method: 'POST',
    ...params
  })
}

export function cLeadsBoard(data) {
  return request({
    url: url.cLeadsBoard,
    method: 'POST',
    data
  })
}

export function cLeadsTransfer(data) {
  return request({
    url: url.cLeadsTransfer,
    method: 'POST',
    data
  })
}

export function cLeadsChangeLog(data) {
  return request({
    url: url.cLeadsChangeLog,
    method: 'POST',
    data
  })
}

export function cLeadsFollowLog(data) {
  return request({
    url: url.cLeadsFollowLog,
    method: 'POST',
    data
  })
}

export function cLeadsFollow(data) {
  return request({
    url: url.cLeadsFollow,
    method: 'POST',
    data
  })
}

export function cLeadsAdd(data) {
  return request({
    url: url.cLeadsAdd,
    method: 'POST',
    data
  })
}

export function cLeadsUpdate(data) {
  return request({
    url: url.cLeadsUpdate,
    method: 'POST',
    data
  })
}

export function cLeadsUpload(data) {
  return request({
    url: url.cLeadsUpload,
    method: 'POST',
    data
  })
}

export function cLeadsDetail(data) {
  return request({
    url: url.cLeadsDetail,
    method: 'POST',
    data
  })
}

export function cLeadsTransform(data) {
  return request({
    url: url.cLeadsTransform,
    method: 'POST',
    data
  })
}

export function cLeadsDelete(data) {
  return request({
    url: url.cLeadsDelete,
    method: 'POST',
    data
  })
}

export function cLeadsTaskUpdate(data) {
  return request({
    url: url.cLeadsTaskUpdate,
    method: 'POST',
    data
  })
}

export function cLeadsRenew(data) {
  return request({
    url: url.cLeadsRenew,
    method: 'POST',
    data
  })
}

export function cLeadsStar(data) {
  return request({
    url: url.cLeadsStar,
    method: 'POST',
    data
  })
}

export function cCustomer(params) {
  return request({
    url: url.cCustomer,
    method: 'POST',
    ...params
  })
}


export function cCustomerLost(params) {
  return request({
    url: url.cCustomerLost,
    method: 'POST',
    ...params
  })
}

export function cCustomerFollow(data) {
  return request({
    url: url.cCustomerFollow,
    method: 'POST',
    data
  })
}

export function cCustomerFollowLog(data) {
  return request({
    url: url.cCustomerFollowLog,
    method: 'POST',
    data
  })
}

export function cCustomerChangeLog(data) {
  return request({
    url: url.cCustomerChangeLog,
    method: 'POST',
    data
  })
}

export function cCustomerDetail(data) {
  return request({
    url: url.cCustomerDetail,
    method: 'POST',
    data
  })
}

export function cCustomerRefresh(data) {
  return request({
    url: url.cCustomerRefresh,
    method: 'POST',
    data
  })
}

export function cCustomerUpdate(data) {
  return request({
    url: url.cCustomerUpdate,
    method: 'POST',
    data
  })
}

export function cCustomerUpload(data) {
  return request({
    url: url.cCustomerUpload,
    method: 'POST',
    data
  })
}

export function cCustomerExample(data) {
  return request({
    url: url.cCustomerExample,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cCustomerErrorFile(data) {
  return request({
    url: url.cCustomerErrorFile,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cCustomerReceive(params) {
  return request({
    url: url.cCustomerReceive,
    method: 'POST',
    ...params
  })
}

export function cCustomerTransfer(data) {
  return request({
    url: url.cCustomerTransfer,
    method: 'POST',
    data
  })
}

export function cCustomerDelete(data) {
  return request({
    url: url.cCustomerDelete,
    method: 'POST',
    data
  })
}

export function cCustomerContract(data) {
  return request({
    url: url.cCustomerContract,
    method: 'POST',
    data
  })
}

export function cCustomerAdd(data) {
  return request({
    url: url.cCustomerAdd,
    method: 'POST',
    data
  })
}

export function cCustomerTaskUpdate(data) {
  return request({
    url: url.cCustomerTaskUpdate,
    method: 'POST',
    data
  })
}

export function cCustomerStudentAccount(data) {
  return request({
    url: url.cCustomerStudentAccount,
    method: 'POST',
    data
  })
}

export function cCustomerPutinPool(data) {
  return request({
    url: url.cCustomerPutinPool,
    method: 'POST',
    data
  })
}

export function cCustomerStar(data) {
  return request({
    url: url.cCustomerStar,
    method: 'POST',
    data
  })
}

export function cCustomerRenew(data) {
  return request({
    url: url.cCustomerRenew,
    method: 'POST',
    data
  })
}

export function cCustomerWxworkContact(data) {
  return request({
    url: url.cCustomerWxworkContact,
    method: 'POST',
    data
  })
}

export function cCustomerWxworkMsg(data) {
  return request({
    url: url.cCustomerWxworkMsg,
    method: 'POST',
    data
  })
}

export function cCustomerStartEvent(data) {
  return request({
    url: url.cCustomerStartEvent,
    method: 'POST',
    data
  })
}

export function cCustomerContact(data) {
  return request({
    url: url.cCustomerContact,
    method: 'POST',
    data
  })
}

export function cCustomerAddContact(data) {
  return request({
    url: url.cCustomerAddContact,
    method: 'POST',
    data
  })
}

export function cCustomerExport(data) {
  return request({
    url: url.cCustomerExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cCustomerContactDetail(data) {
  return request({
    url: url.cCustomerContactDetail,
    method: 'POST',
    data
  })
}

export function cCustomerUpdateContact(data) {
  return request({
    url: url.cCustomerUpdateContact,
    method: 'POST',
    data
  })
}

export function cCustomerAudition(data) {
  return request({
    url: url.cCustomerAudition,
    method: 'POST',
    data
  })
}

export function cCustomerPool(data) {
  return request({
    url: url.cCustomerPool,
    method: 'POST',
    data
  })
}

export function cCustomerPoolReceive(data) {
  return request({
    url: url.cCustomerPoolReceive,
    method: 'POST',
    data
  })
}

export function cCustomerPoolExport(data) {
  return request({
    url: url.cCustomerPoolExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}


export function cCustomerPoolTransfer(data) {
  return request({
    url: url.cCustomerPoolTransfer,
    method: 'POST',
    data
  })
}

export function cContract(params) {
  return request({
    url: url.cContract,
    method: 'POST',
    ...params
  })
}

export function cContractReminder(params) {
  return request({
    url: url.cContractReminder,
    method: 'POST',
    ...params
  })
}

export function cContractReminderExport(data) {
  return request({
    url: url.cContractReminderExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cContractExport(data) {
  return request({
    url: url.cContractExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cInviteAttend(params) {
  return request({
    url: url.cInviteAttend,
    method: 'POST',
    ...params
  })
}

export function cInviteAttendAttend(params) {
  return request({
    url: url.cInviteAttendAttend,
    method: 'POST',
    ...params
  })
}

export function cInviteAttendNoShow(params) {
  return request({
    url: url.cInviteAttendNoShow,
    method: 'POST',
    ...params
  })
}

export function cInviteAttendExport(data) {
  return request({
    url: url.cInviteAttendExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cImport(params) {
  return request({
    url: url.cImport,
    method: 'POST',
    ...params
  })
}

export function cLeadsExample(params) {
  return request({
    url: url.cLeadsExample,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function cLeadsExport(data) {
  return request({
    url: url.cLeadsExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cLeadsErrorFile(data) {
  return request({
    url: url.cLeadsErrorFile,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cImportUpload(data) {
  return request({
    url: url.cImportUpload,
    method: 'POST',
    data
  })
}

export function cImportDelete(params) {
  return request({
    url: url.cImportDelete,
    method: 'POST',
    ...params
  })
}

export function cFollow(params) {
  return request({
    url: url.cFollow,
    method: 'POST',
    ...params
  })
}

export function cFollowExport(data) {
  return request({
    url: url.cFollowExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cFollowHeatmap(params) {
  return request({
    url: url.cFollowHeatmap,
    method: 'POST',
    ...params
  })
}

export function cFollowDetail(params) {
  return request({
    url: url.cFollowDetail,
    method: 'POST',
    ...params
  })
}

export function cFollowDelete(params) {
  return request({
    url: url.cFollowDelete,
    method: 'POST',
    ...params
  })
}

export function cFollowEvent(params) {
  return request({
    url: url.cFollowEvent,
    method: 'POST',
    ...params
  })
}

export function cFollowEventAdd(params) {
  return request({
    url: url.cFollowEventAdd,
    method: 'POST',
    ...params
  })
}

export function cTodo(params) {
  return request({
    url: url.cTodo,
    method: 'POST',
    ...params
  })
}

export function cTransfer(params) {
  return request({
    url: url.cTransfer,
    method: 'POST',
    ...params
  })
}

export function cTransferExport(params) {
  return request({
    url: url.cTransferExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function cChangeLog(params) {
  return request({
    url: url.cChangeLog,
    method: 'POST',
    ...params
  })
}

export function cChangeLogExport(data) {
  return request({
    url: url.cChangeLogExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function cChannel(params) {
  return request({
    url: url.cChannel,
    method: 'POST',
    ...params
  })
}

export function cChanneExport(params) {
  return request({
    url: url.cChannelExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function cChannelAdd(data) {
  return request({
    url: url.cChannelAdd,
    method: 'POST',
    data
  })
}

export function cChannelUpdate(data) {
  return request({
    url: url.cChannelUpdate,
    method: 'POST',
    data
  })
}

export function cChannelMoveTo(data) {
  return request({
    url: url.cChannelMoveTo,
    method: 'POST',
    data
  })
}

export function cChannelDetail(data) {
  return request({
    url: url.cChannelDetail,
    method: 'POST',
    data
  })
}

export function cChannelDelete(data) {
  return request({
    url: url.cChannelDelete,
    method: 'POST',
    data
  })
}

export function cChannelCount(params) {
  return request({
    url: url.cChannelCount,
    method: 'POST',
    ...params
  })
}

export function cChannelLevel(params) {
  return request({
    url: url.cChannelLevel,
    method: 'POST',
    ...params
  })
}

export function auditionSignin(params) {
  return request({
    url: url.auditionSignin,
    method: 'POST',
    ...params
  })
}

export function auditionNoShow(params) {
  return request({
    url: url.auditionNoShow,
    method: 'POST',
    ...params
  })
}

export function auditionSchedule(params) {
  return request({
    url: url.auditionSchedule,
    method: 'POST',
    ...params
  })
}

export function auditionArrange(params) {
  return request({
    url: url.auditionArrange,
    method: 'POST',
    ...params
  })
}

export function missionSingle(params) {
  return request({
    url: url.missionSingle,
    method: 'POST',
    ...params
  })
}


export function missionSingleCount(params) {
  return request({
    url: url.missionSingleCount,
    method: 'POST',
    ...params
  })
}

export function missionAddSingle(params) {
  return request({
    url: url.missionAddSingle,
    method: 'POST',
    ...params
  })
}

export function missionSyncSingle(params) {
  return request({
    url: url.missionSyncSingle,
    method: 'POST',
    ...params
  })
}

export function missionUploadmedia(params) {
  return request({
    url: url.missionUploadmedia,
    method: 'POST',
    ...params
  })
}

export function missionGroup(params) {
  return request({
    url: url.missionGroup,
    method: 'POST',
    ...params
  })
}


export function missionAddGroup(params) {
  return request({
    url: url.missionAddGroup,
    method: 'POST',
    ...params
  })
}

export function missionSyncGroup(params) {
  return request({
    url: url.missionSyncGroup,
    method: 'POST',
    ...params
  })
}

export function missionMoment(params) {
  return request({
    url: url.missionMoment,
    method: 'POST',
    ...params
  })
}

export function missionSyncMoment(params) {
  return request({
    url: url.missionSyncMoment,
    method: 'POST',
    ...params
  })
}

export function missionMomentUserList(params) {
  return request({
    url: url.missionMomentUserList,
    method: 'POST',
    ...params
  })
}

export function missionMomentLikes(params) {
  return request({
    url: url.missionMomentLikes,
    method: 'POST',
    ...params
  })
}

export function missionMomentComments(params) {
  return request({
    url: url.missionMomentComments,
    method: 'POST',
    ...params
  })
}

export function missionSingleUserList(params) {
  return request({
    url: url.missionSingleUserList,
    method: 'POST',
    ...params
  })
}

export function missionGroupList(params) {
  return request({
    url: url.missionGroupList,
    method: 'POST',
    ...params
  })
}
