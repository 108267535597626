import url from '@/utils/URL'
import request from '@/utils/request'


export function analysisBoard(params) {
  return request({
    url: url.analysisBoard,
    method: 'POST',
    ...params
  })
}

export function analysisMainpanel(params) {
  return request({
    url: url.analysisMainpanel,
    method: 'POST',
    ...params
  })
}

export function analysisMainpanelCrm(params) {
  return request({
    url: url.analysisMainpanelCrm,
    method: 'POST',
    ...params
  })
}

export function analysisMainpanelEdu(params) {
  return request({
    url: url.analysisMainpanelEdu,
    method: 'POST',
    ...params
  })
}

export function analysisClients(params) {
  return request({
    url: url.analysisClients,
    method: 'POST',
    ...params
  })
}

export function analysisClientsChannel(params) {
  return request({
    url: url.analysisClientsChannel,
    method: 'POST',
    ...params
  })
}

export function analysisClientsTag(params) {
  return request({
    url: url.analysisClientsTag,
    method: 'POST',
    ...params
  })
}

export function analysisClientsDeal(params) {
  return request({
    url: url.analysisClientsDeal,
    method: 'POST',
    ...params
  })
}

export function analysisClientsFollowUp(params) {
  return request({
    url: url.analysisClientsFollowUp,
    method: 'POST',
    ...params
  })
}

export function analysisRefund(params) {
  return request({
    url: url.analysisRefund,
    method: 'POST',
    ...params
  })
}

export function analysisKnowledge(params) {
  return request({
    url: url.analysisKnowledge,
    method: 'POST',
    ...params
  })
}

export function analysisCampus(params) {
  return request({
    url: url.analysisCampus,
    method: 'POST',
    ...params
  })
}

export function analysisCampusCount(data) {
  return request({
    url: url.analysisCampusCount,
    method: 'POST',
    data
  })
}

export function analysisCampusRank(data) {
  return request({
    url: url.analysisCampusRank,
    method: 'POST',
    data
  })
}

export function analysisCampusCommision(data) {
  return request({
    url: url.analysisCampusCommision,
    method: 'POST',
    data
  })
}

export function analysisCampusDebt(data) {
  return request({
    url: url.analysisCampusDebt,
    method: 'POST',
    data
  })
}

export function analysisCampusAchievement(data) {
  return request({
    url: url.analysisCampusAchievement,
    method: 'POST',
    data
  })
}

export function analysisCampusAchievementList(data) {
  return request({
    url: url.analysisCampusAchievementList,
    method: 'POST',
    data
  })
}

export function analysisCampusTaskHeatmap(data) {
  return request({
    url: url.analysisCampusTaskHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusCourseHeatmap(data) {
  return request({
    url: url.analysisCampusCourseHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusFollowHeatmap(data) {
  return request({
    url: url.analysisCampusFollowHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusRevenueHeatmap(data) {
  return request({
    url: url.analysisCampusRevenueHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusRevenueTotalHeatmap(data) {
  return request({
    url: url.analysisCampusRevenueTotalHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusContractHeatmap(data) {
  return request({
    url: url.analysisCampusContractHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusContractTotalHeatmap(data) {
  return request({
    url: url.analysisCampusContractTotalHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusBookHeatmap(data) {
  return request({
    url: url.analysisCampusBookHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusRefundHeatmap(data) {
  return request({
    url: url.analysisCampusRefundHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCampusInvitedHeatmap(data) {
  return request({
    url: url.analysisCampusInvitedHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCrmLeads(data) {
  return request({
    url: url.analysisCrmLeads,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsAmount(data) {
  return request({
    url: url.analysisCrmLeadsAmount,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsStock(data) {
  return request({
    url: url.analysisCrmLeadsStock,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsChannel(data) {
  return request({
    url: url.analysisCrmLeadsChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsSubChannel(data) {
  return request({
    url: url.analysisCrmLeadsSubChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsTransfer(data) {
  return request({
    url: url.analysisCrmLeadsTransfer,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomer(data) {
  return request({
    url: url.analysisCrmCustomer,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerChannel(data) {
  return request({
    url: url.analysisCrmCustomerChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerSubChannel(data) {
  return request({
    url: url.analysisCrmCustomerSubChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerChannelStock(data) {
  return request({
    url: url.analysisCrmCustomerChannelStock,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerChannelStockExport(data) {
  return request({
    url: url.analysisCrmCustomerChannelStockExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCrmCustomerCount(data) {
  return request({
    url: url.analysisCrmCustomerCount,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerAchievement(data) {
  return request({
    url: url.analysisCrmCustomerAchievement,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerAchievementChannel(data) {
  return request({
    url: url.analysisCrmCustomerAchievementChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerContractCycle(data) {
  return request({
    url: url.analysisCrmCustomerContractCycle,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerCycle(data) {
  return request({
    url: url.analysisCrmCustomerCycle,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelAchievement(data) {
  return request({
    url: url.analysisCrmChannelAchievement,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelAchievementChannel(data) {
  return request({
    url: url.analysisCrmChannelAchievementChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelContractCycle(data) {
  return request({
    url: url.analysisCrmChannelContractCycle,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelCycle(data) {
  return request({
    url: url.analysisCrmChannelCycle,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerPool(data) {
  return request({
    url: url.analysisCrmCustomerPool,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerSankey(data) {
  return request({
    url: url.analysisCrmCustomerSankey,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsSankey(data) {
  return request({
    url: url.analysisCrmLeadsSankey,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsChannelStock(data) {
  return request({
    url: url.analysisCrmLeadsChannelStock,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsChannelStockExport(data) {
  return request({
    url: url.analysisCrmLeadsChannelStockExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCrmLeadsEmployee(data) {
  return request({
    url: url.analysisCrmLeadsEmployee,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsEmployeeExport(data) {
  return request({
    url: url.analysisCrmLeadsEmployeeExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCrmLeadsStudioExport(data) {
  return request({
    url: url.analysisCrmLeadsStudioExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCrmLeadsEmployeeLeadsDetail(data) {
  return request({
    url: url.analysisCrmLeadsEmployeeLeadsDetail,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsStudio(data) {
  return request({
    url: url.analysisCrmLeadsStudio,
    method: 'POST',
    data
  })
}

export function analysisCrmLeadsStudioLeadsDetail(data) {
  return request({
    url: url.analysisCrmLeadsStudioLeadsDetail,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerAmount(data) {
  return request({
    url: url.analysisCrmCustomerAmount,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerEmployee(data) {
  return request({
    url: url.analysisCrmCustomerEmployee,
    method: 'POST',
    data
  })
}


export function analysisCrmCustomerEmployeeCustomerDetail(data) {
  return request({
    url: url.analysisCrmCustomerEmployeeCustomerDetail,
    method: 'POST',
    data
  })
}

export function analysisCrmCustomerStudio(data) {
  return request({
    url: url.analysisCrmCustomerStudio,
    method: 'POST',
    data
  })
}


export function analysisCrmCustomerStudioCustomerDetail(data) {
  return request({
    url: url.analysisCrmCustomerStudioCustomerDetail,
    method: 'POST',
    data
  })
}

export function analysisCrmTransfer(data) {
  return request({
    url: url.analysisCrmTransfer,
    method: 'POST',
    data
  })
}

export function analysisCrmTransferAmount(data) {
  return request({
    url: url.analysisCrmTransferAmount,
    method: 'POST',
    data
  })
}

export function analysisCrmTransferSankey(data) {
  return request({
    url: url.analysisCrmTransferSankey,
    method: 'POST',
    data
  })
}

export function analysisCrmFollow(data) {
  return request({
    url: url.analysisCrmFollow,
    method: 'POST',
    data
  })
}

export function analysisCrmFollowExport(data) {
  return request({
    url: url.analysisCrmFollowExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}


export function analysisCrmFollowContactType(data) {
  return request({
    url: url.analysisCrmFollowContactType,
    method: 'POST',
    data
  })
}

export function analysisCrmFollowChannel(data) {
  return request({
    url: url.analysisCrmFollowChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmFollowHeatmap(data) {
  return request({
    url: url.analysisCrmFollowHeatmap,
    method: 'POST',
    data
  })
}

export function analysisCrmFollowRank(data) {
  return request({
    url: url.analysisCrmFollowRank,
    method: 'POST',
    data
  })
}

export function analysisCrmFollowCount(data) {
  return request({
    url: url.analysisCrmFollowCount,
    method: 'POST',
    data
  })
}

export function analysisEducationMessage(data) {
  return request({
    url: url.analysisEducationMessage,
    method: 'POST',
    data
  })
}

export function analysisEducationMessageExport(data) {
  return request({
    url: url.analysisEducationMessageExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisEducationSummary(data) {
  return request({
    url: url.analysisEducationSummary,
    method: 'POST',
    data
  })
}

export function analysisEducationSummaryExport(data) {
  return request({
    url: url.analysisEducationSummaryExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisAudition(data) {
  return request({
    url: url.analysisAudition,
    method: 'POST',
    data
  })
}

export function analysisAuditionChannel(data) {
  return request({
    url: url.analysisAuditionChannel,
    method: 'POST',
    data
  })
}

export function analysisAuditionExport(data) {
  return request({
    url: url.analysisAuditionExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisAuditionSankey(data) {
  return request({
    url: url.analysisAuditionSankey,
    method: 'POST',
    data
  })
}

export function analysisAuditionRank(data) {
  return request({
    url: url.analysisAuditionRank,
    method: 'POST',
    data
  })
}

export function analysisRoom(data) {
  return request({
    url: url.analysisRoom,
    method: 'POST',
    data
  })
}

export function analysisRoomSummate(data) {
  return request({
    url: url.analysisRoomSummate,
    method: 'POST',
    data
  })
}

export function analysisRoomBook(data) {
  return request({
    url: url.analysisRoomBook,
    method: 'POST',
    data
  })
}

export function analysisRoomCount(data) {
  return request({
    url: url.analysisRoomCount,
    method: 'POST',
    data
  })
}

export function analysisClasses(params) {
  return request({
    url: url.analysisClasses,
    method: 'POST',
    ...params
  })
}

export function analysisStudents(data) {
  return request({
    url: url.analysisStudents,
    method: 'POST',
    data
  })
}

export function analysisStudentsCount(data) {
  return request({
    url: url.analysisStudentsCount,
    method: 'POST',
    data
  })
}

export function analysisStudentsGender(data) {
  return request({
    url: url.analysisStudentsGender,
    method: 'POST',
    data
  })
}

export function analysisStudentsAge(data) {
  return request({
    url: url.analysisStudentsAge,
    method: 'POST',
    data
  })
}

export function analysisStudentsSummate(data) {
  return request({
    url: url.analysisStudentsSummate,
    method: 'POST',
    data
  })
}

export function analysisStudentsFee(data) {
  return request({
    url: url.analysisStudentsFee,
    method: 'POST',
    data
  })
}

export function analysisCourseBalance(data) {
  return request({
    url: url.analysisCourseBalance,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceCosts(data) {
  return request({
    url: url.analysisCourseBalanceCosts,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceCount(data) {
  return request({
    url: url.analysisCourseBalanceCount,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceStudio(data) {
  return request({
    url: url.analysisCourseBalanceStudio,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceStudioCosts(data) {
  return request({
    url: url.analysisCourseBalanceStudioCosts,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceAmount(data) {
  return request({
    url: url.analysisCourseBalanceAmount,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceRevenue(data) {
  return request({
    url: url.analysisCourseBalanceRevenue,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceExportEmployee(data) {
  return request({
    url: url.analysisCourseBalanceExportEmployee,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCourseBalanceExportStudio(data) {
  return request({
    url: url.analysisCourseBalanceExportStudio,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function analysisCourseBalanceRevenueList(data) {
  return request({
    url: url.analysisCourseBalanceRevenueList,
    method: 'POST',
    data
  })
}

export function analysisCourseBalanceRevenueListExport(data) {
  return request({
    url: url.analysisCourseBalanceRevenueListExport,
    responseType: 'blob',
    method: 'POST',
    data
  })
}

export function analysisCredits(data) {
  return request({
    url: url.analysisCredits,
    method: 'POST',
    data
  })
}

export function analysisCreditsCosts(data) {
  return request({
    url: url.analysisCreditsCosts,
    method: 'POST',
    data
  })
}

export function analysisCreditsCount(data) {
  return request({
    url: url.analysisCreditsCount,
    method: 'POST',
    data
  })
}

export function analysisStudio(params) {
  return request({
    url: url.analysisStudio,
    method: 'POST',
    ...params
  })
}

export function analysisAchievement(params) {
  return request({
    url: url.analysisAchievement,
    method: 'POST',
    ...params
  })
}

export function analysisClientsSubChannel(params) {
  return request({
    url: url.analysisClientsSubChannel,
    method: 'POST',
    ...params
  })
}

export function analysisClientsChannelStatis(params) {
  return request({
    url: url.analysisClientsChannelStatis,
    method: 'POST',
    ...params
  })
}

export function analysisClientsChannelAchievement(params) {
  return request({
    url: url.analysisClientsChannelAchievement,
    method: 'POST',
    ...params
  })
}

export function analysisClientsChannelSummate(params) {
  return request({
    url: url.analysisClientsChannelSummate,
    method: 'POST',
    ...params
  })
}

export function analysisClientsEmployeeAchievement(params) {
  return request({
    url: url.analysisClientsEmployeeAchievement,
    method: 'POST',
    ...params
  })
}

export function analysisClientsEmployeeStatis(params) {
  return request({
    url: url.analysisClientsEmployeeStatis,
    method: 'POST',
    ...params
  })
}

export function analysisClientsFollowStatis(params) {
  return request({
    url: url.analysisClientsFollowStatis,
    method: 'POST',
    ...params
  })
}

export function analysisClientsAchievementSummate(params) {
  return request({
    url: url.analysisClientsAchievementSummate,
    method: 'POST',
    ...params
  })
}

export function analysisAbsence(data) {
  return request({
    url: url.analysisAbsence,
    method: 'POST',
    data
  })
}

export function analysisAbsenceCourse(data) {
  return request({
    url: url.analysisAbsenceCourse,
    method: 'POST',
    data
  })
}

export function analysisAbsenceSummate(data) {
  return request({
    url: url.analysisAbsenceSummate,
    method: 'POST',
    data
  })
}

export function analysisAbsenceCount(data) {
  return request({
    url: url.analysisAbsenceCount,
    method: 'POST',
    data
  })
}

export function analysisAbsenceAmount(data) {
  return request({
    url: url.analysisAbsenceAmount,
    method: 'POST',
    data
  })
}

export function analysisClassesIndex(data) {
  return request({
    url: url.analysisClassesIndex,
    method: 'POST',
    data
  })
}

export function analysisClassesCount(data) {
  return request({
    url: url.analysisClassesCount,
    method: 'POST',
    data
  })
}

export function analysisTeacher(data) {
  return request({
    url: url.analysisTeacher,
    method: 'POST',
    data
  })
}

export function analysisTeacherSummate(data) {
  return request({
    url: url.analysisTeacherSummate,
    method: 'POST',
    data
  })
}

export function analysisTeacherCourse(data) {
  return request({
    url: url.analysisTeacherCourse,
    method: 'POST',
    data
  })
}

export function analysisTeacherBook(data) {
  return request({
    url: url.analysisTeacherBook,
    method: 'POST',
    data
  })
}

export function analysisTeacherCount(data) {
  return request({
    url: url.analysisTeacherCount,
    method: 'POST',
    data
  })
}

export function analysisTeacherChart(data) {
  return request({
    url: url.analysisTeacherChart,
    method: 'POST',
    data
  })
}

export function analysisTeacherHeatmap(data) {
  return request({
    url: url.analysisTeacherHeatmap,
    method: 'POST',
    data
  })
}

export function analysisTeacherStudio(data) {
  return request({
    url: url.analysisTeacherStudio,
    method: 'POST',
    data
  })
}

export function analysisStockIncome(data) {
  return request({
    url: url.analysisStockIncome,
    method: 'POST',
    data
  })
}

export function analysisStockOutcome(data) {
  return request({
    url: url.analysisStockOutcome,
    method: 'POST',
    data
  })
}

export function analysisStockCount(data) {
  return request({
    url: url.analysisStockCount,
    method: 'POST',
    data
  })
}

export function analysisCrmChannel(data) {
  return request({
    url: url.analysisCrmChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelCount(data) {
  return request({
    url: url.analysisCrmChannelCount,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelSubChannel(data) {
  return request({
    url: url.analysisCrmChannelSubChannel,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelIndexStatis(data) {
  return request({
    url: url.analysisCrmChannelIndexStatis,
    method: 'POST',
    data
  })
}

export function analysisCrmChannelSubChannelStatis(data) {
  return request({
    url: url.analysisCrmChannelSubChannelStatis,
    method: 'POST',
    data
  })
}

export function analysisRankingLeads(data) {
  return request({
    url: url.analysisRankingLeads,
    method: 'POST',
    data
  })
}

export function analysisRankingCustomer(data) {
  return request({
    url: url.analysisRankingCustomer,
    method: 'POST',
    data
  })
}

export function analysisRankingFollow(data) {
  return request({
    url: url.analysisRankingFollow,
    method: 'POST',
    data
  })
}

export function analysisRankingTeacher(data) {
  return request({
    url: url.analysisRankingTeacher,
    method: 'POST',
    data
  })
}

export function analysisRankingTeacherRevenue(data) {
  return request({
    url: url.analysisRankingTeacherRevenue,
    method: 'POST',
    data
  })
}

export function analysisRankingTeacherStudioRevenue(data) {
  return request({
    url: url.analysisRankingTeacherStudioRevenue,
    method: 'POST',
    data
  })
}

export function analysisRankingInvited(data) {
  return request({
    url: url.analysisRankingInvited,
    method: 'POST',
    data
  })
}

export function analysisGoal(data) {
  return request({
    url: url.analysisGoal,
    method: 'POST',
    data
  })
}

export function analysisGoalStudioContractRevenue(data) {
  return request({
    url: url.analysisGoalStudioContractRevenue,
    method: 'POST',
    data
  })
}

export function analysisGoalEmployeeContractRevenue(data) {
  return request({
    url: url.analysisGoalEmployeeContractRevenue,
    method: 'POST',
    data
  })
}

export function analysisGoalStudioCourseRevenue(data) {
  return request({
    url: url.analysisGoalStudioCourseRevenue,
    method: 'POST',
    data
  })
}

export function analysisFinance(data) {
  return request({
    url: url.analysisFinance,
    method: 'POST',
    data
  })
}

export function analysisWallet(data) {
  return request({
    url: url.analysisWallet,
    method: 'POST',
    data
  })
}

export function analysisWalletStudio(data) {
  return request({
    url: url.analysisWalletStudio,
    method: 'POST',
    data
  })
}

export function analysisWalletCount(data) {
  return request({
    url: url.analysisWalletCount,
    method: 'POST',
    data
  })
}

export function analysisFinanceCount(data) {
  return request({
    url: url.analysisFinanceCount,
    method: 'POST',
    data
  })
}

export function analysisFinanceRefund(data) {
  return request({
    url: url.analysisFinanceRefund,
    method: 'POST',
    data
  })
}

export function analysisMeeting(data) {
  return request({
    url: url.analysisMeeting,
    method: 'POST',
    data
  })
}

export function analysisMeetingBooked(data) {
  return request({
    url: url.analysisMeetingBooked,
    method: 'POST',
    data
  })
}

export function analysisMeetingChecked(data) {
  return request({
    url: url.analysisMeetingChecked,
    method: 'POST',
    data
  })
}

export function analysisContent(data) {
  return request({
    url: url.analysisContent,
    method: 'POST',
    data
  })
}

export function analysisSurvey(data) {
  return request({
    url: url.analysisSurvey,
    method: 'POST',
    data
  })
}

export function analysisSurveySubmit(data) {
  return request({
    url: url.analysisSurveySubmit,
    method: 'POST',
    data
  })
}

export function analysisCoupon(data) {
  return request({
    url: url.analysisCoupon,
    method: 'POST',
    data
  })
}

export function analysisWorkJournal(data) {
  return request({
    url: url.analysisWorkJournal,
    method: 'POST',
    data
  })
}

export function analysisWorkTask(data) {
  return request({
    url: url.analysisWorkTask,
    method: 'POST',
    data
  })
}

export function analysisWorkApprove(data) {
  return request({
    url: url.analysisWorkApprove,
    method: 'POST',
    data
  })
}

export function analysisAce(params) {
  return request({
    url: url.analysisAce,
    method: 'POST',
    ...params
  })
}

export function analysisAceDaily(data) {
  return request({
    url: url.analysisAceDaily,
    method: 'POST',
    data
  })
}

export function analysisAceStock(data) {
  return request({
    url: url.analysisAceStock,
    method: 'POST',
    data
  })
}

export function analysisAceTimeline(data) {
  return request({
    url: url.analysisAceTimeline,
    method: 'POST',
    data
  })
}

export function analysisAceCompare(data) {
  return request({
    url: url.analysisAceCompare,
    method: 'POST',
    data
  })
}

export function analysisAceRank(params) {
  return request({
    url: url.analysisAceRank,
    method: 'POST',
    ...params
  })
}

export function analysisAceBar(params) {
  return request({
    url: url.analysisAceBar,
    method: 'POST',
    ...params
  })
}

export function analysisAceIncome(params) {
  return request({
    url: url.analysisAceIncome,
    method: 'POST',
    ...params
  })
}


export function analysisAceIncomeCount(params) {
  return request({
    url: url.analysisAceIncomeCount,
    method: 'POST',
    ...params
  })
}

export function analysisSummate(data) {
  return request({
    url: url.analysisSummate,
    method: 'POST',
    data
  })
}

export function analysisCourseCount(data) {
  return request({
    url: url.analysisCourseCount,
    method: 'POST',
    data
  })
}

export function analysisSaleStatis(data) {
  return request({
    url: url.analysisSaleStatis,
    method: 'POST',
    data
  })
}

export function analysisContractStatis(data) {
  return request({
    url: url.analysisContractStatis,
    method: 'POST',
    data
  })
}

export function analysisRefundStatis(data) {
  return request({
    url: url.analysisRefundStatis,
    method: 'POST',
    data
  })
}

export function analysisRefundContractStatis(data) {
  return request({
    url: url.analysisRefundContractStatis,
    method: 'POST',
    data
  })
}

export function analysisChannelStatis(data) {
  return request({
    url: url.analysisChannelStatis,
    method: 'POST',
    data
  })
}