// 增删改查具体权限控制
import { mapGetters } from 'vuex'
export default {
    data() {
        return{
            
        }
    },
    computed: {
        ...mapGetters(['allAuth']),
        // 获取列表
        canIndex(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].index){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].index
        },
        // 获取详情
        canRead(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].read){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].read
        },
        // 删除
        canDelete(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].delete){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].delete
        },
        // 新增
        canSave(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].save){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].save
        },
        // 编辑
        canUpdate(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].update){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].update
        },
        // 导出
        canExcelexport(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].excelexport){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].excelexport
        },
        // 导入
        canExcelimport(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].excelimport){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].excelimport
        },
        // 转移
        canTransfer(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].transfer){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].transfer
        },
        // 转为客户
        canTransform(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].transform){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].transform
        },
        // 到访状态
        canAttend(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].attend){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].attend
        },
        //分配
        canDistribute(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].distribute){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].distribute
        },
        // 领取
        canReceive(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].receive){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].receive
        },
        // 显示企业记录
        canWxworkMsg(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]]['wxworkmsg']){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]]['wxworkmsg']
        },
        // 学员合并
        canMerge(){
            if(!this.allAuth[this.authType[0]]) return false
            if(!this.allAuth[this.authType[0]][this.authType[1]].merge){
                return false
            }
            return this.allAuth[this.authType[0]][this.authType[1]].merge
        }

    },
  }
  