<template>
  <!-- <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" -->
  <a-modal v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="75%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <div class="detail">

    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-page-header :title="detailData.class_name" sub-title="">
      <template slot="tags">
        <a-tag color="blue">
          {{detailData.status}}
        </a-tag>
      </template>
      <template slot="extra">

        <a-button key="4" type="dashed" icon="edit" @click="showClassModal">
          信息编辑
        </a-button>

        <a-button key="2" type="dashed" icon="plus" @click="showPkModal">
          添加排课
        </a-button>

        <a-button key="3" type="dashed" icon="plus" @click="showWorkEditModal">
          作业布置
        </a-button>
<!-- 
        <a-button key="1" type="dashed" icon="plus"  @click="showFeedModal($event)">
          班级动态
        </a-button> -->
        
      </template>

      <a-descriptions class="classes-descriptions" size="small" :column="5"  layout="vertical">

        <a-descriptions-item label="班级名称">
          {{ detailData.class_name }}
        </a-descriptions-item>

        <a-descriptions-item label="课程科目">
          {{ detailData.course_cate?detailData.course_cate.filter_name:'-' }}
        </a-descriptions-item>

        <a-descriptions-item label="班级学员">
          {{ detailData.class_count }} / {{ detailData.class_limit }}
        </a-descriptions-item>

        <a-descriptions-item label="课程类型">
          {{ detailData.class_type == 1? '一对多':'一对一' }}
        </a-descriptions-item>

        <a-descriptions-item label="上课校区">
          {{ detailData.studio?detailData.studio.filter_name:'-'}}
        </a-descriptions-item>

        <a-descriptions-item label="授课老师">
          {{ detailData.tutor?detailData.tutor.filter_name:'-' }}
        </a-descriptions-item>

        <a-descriptions-item label="班主任　">
          {{ detailData.cc_id?detailData.cc.filter_name:'-' }}
        </a-descriptions-item>

        <a-descriptions-item label="开班时间">
          {{ detailData.class_start?detailData.class_start:'-' }}
        </a-descriptions-item>

        <a-descriptions-item label="结束时间">
          {{ detailData.class_end?detailData.class_end:'-' }}
        </a-descriptions-item>

        <a-descriptions-item label="课程进度">
          {{detailData.class_had_course_count}} / {{detailData.class_course_count}}
        </a-descriptions-item>

        
      </a-descriptions>

      <!-- <a-row type="flex">
        <a-statistic title="开课数" :value=detailData.class_had_course_count :style="{
            margin: '0 32px',
          }" />
        <a-statistic
          title="Price"
          prefix="$"
          :value="568.08"
          :style="{
            margin: '0 32px',
          }"
        />
        
        <a-statistic title="Balance" prefix="$" :value="3345.08" :style="{
            margin: '0 32px',
          }"/>
        <a-statistic title="学员数" :value=detailData.class_count />
      </a-row> -->

    </a-page-header>
      <div class="class-body">
        <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
          <a-tab-pane key="1">
            <span slot="tab">班级学员</span>
            <bjxy :id="item.class_id" :name='item.class_name' v-if="activeKey === '1'" />
          </a-tab-pane>

          <a-tab-pane key="2">
            <span slot="tab">班级排课</span>
            <bjpk :toRefresh='toRefresh' :isTab="1" :studio_id="detailData.studio_id" :id="item.class_id" v-if="activeKey === '2'" />
          </a-tab-pane>

          <a-tab-pane key="6">
            <span slot="tab">上课记录</span>
            <bjpk :toRefresh='toRefresh' :isTab="2" :studio_id="detailData.studio_id" :id="item.class_id" v-if="activeKey === '6'" />
          </a-tab-pane>

          <a-tab-pane key="5">
            <span slot="tab">班级动态</span>
            <bjdt :toRefresh='toRefresh' :id="item.class_id" v-if="activeKey === '5'" />
          </a-tab-pane>

          <a-tab-pane key="3">
            <span slot="tab">课后作业</span>
            <khzy :toRefresh='toRefresh' :className='detailData.class_name' :id="item.class_id" v-if="activeKey === '3'" />
          </a-tab-pane>

          <a-tab-pane key="4">
            <span slot="tab">签到记录</span>
            <signIn :id="item.class_id" v-if="activeKey === '4'" />
          </a-tab-pane>

          <a-tab-pane key="8">
            <span slot="tab">进出记录</span>
            <jcjl :id="item.class_id" v-if="activeKey === '8'" />
          </a-tab-pane>

          <a-tab-pane v-if="canWxworkMsg" key="9">
            <span slot="tab">企微记录</span>
            <wxworkLog :id="item.class_id" v-if="activeKey === '9'" />
          </a-tab-pane>

          <a-tab-pane key="10">
            <span slot="tab">班级相册</span>
            <album :id="item.class_id" v-if="activeKey === '10'" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <postFeedModal v-if="postFeedVisible" :class_id='item.class_id' :item="item"/>
    <classModal v-if="isClassModal" :isEdit='1' :item="item"/>
    <pkModal v-if="isPkModal" :studio_id="detailData.studio_id" :classId="id"/>
    <workEditModal :className='detailData.class_name' :classId="item.class_id" v-if="isWorkEditModal" />
  </a-modal>
  
</template>

<script>
import C_ITEMS from '@/utils/items'
import moment from 'moment'
import bjxy from './bjxy'
import bjpk from './bjpk/index'
import jcjl from './jcjl'
import bjdk from './bjdk'
import bjdt from './bjdt'
import signIn from './signIn'
import album from './album'
import khzy from '@/views/market/xuetang/zydp'
import workEditModal from '@/views/market/xuetang/zydp/editModal'
import postFeedModal from '@/views/market/xuetang/bjdt/editModal'
import pkModal from './bjpk/editModal'
import classModal from '../editModal'
import wxworkLog from './wxworkLog'
import authority from '@/common/mixins/authority'


export default {
  name: 'DetailModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
        parent: this
    }
  },
  components: {
    postFeedModal,
    workEditModal,
    classModal,
    wxworkLog,
    pkModal,
    bjxy,
    bjpk,
    jcjl,
    khzy,
    bjdk,
    bjdt,
    signIn,
    album
  },
  
  data() {
    return {
      loading: false,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      postFeedVisible:false,
      isClassModal:false,
      isWorkEditModal:false,
      isPkModal:false,
      ModalTitle: '',
      detailData: {},
      activeKey: '1',
      toRefresh:1,
      authType:['education','classes'],
    }
  },
  mixins:[authority],

  async created() {
    this.id = this.item.class_id
    this.visible = true
    this.ModalTitle = `班级(${this.item.class_name})`
    this.activeKey = this.item.activeIndex
    this.getDatail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDatail() {
      this.loading = true
      let res = await this.$store.dispatch('classesDetailAction', { class_id: this.item.class_id })
      this.detailData = res.data
      this.loading = false
    },
    showFeedModal() {
      this.postFeedVisible = true
    },
    showClassModal(){
      this.isClassModal = true
    },
    async handleOk() {
      this.confirmLoading = true
      this.parent.hideDetailModal()
    },
    handleCancel() {
      this.parent.hideDetailModal()
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeTab(key) {
      this.activeKey = key
    },
    showPkModal(){
      this.isPkModal = true
    },
    showWorkEditModal(){
      this.isWorkEditModal = true
    },
    submitModal(num){
      if(num){
        this.activeKey = num
        this.toRefresh++
      }
      this.isPkModal = false
      this.isClassModal = false
      this.postFeedVisible = false
      this.isWorkEditModal = false
    },
    hideModal(num){
      if(num){
        this.getDatail()
      }
      this.isPkModal = false
      this.isClassModal = false
      this.postFeedVisible = false
      this.isWorkEditModal = false
    }
  }
}
</script>
<style lang="scss">
.classes-descriptions{
  // .ant-descriptions-item{
  //   display: inline-flex;
  //   .ant-descriptions-item-content{
  //     overflow: hidden;
  //     text-overflow:ellipsis;
  //     white-space: nowrap;
  //     width: 240px;
  //   }
  // }
  .ant-descriptions-item-label{
    color: rgba(69, 90, 100, 0.6);
  }
  .ant-descriptions-item-content{
      color: #323233;
  }
}
</style>
