<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" :zIndex='4001'
    cancelText="取消" @cancel="handleCancel" okText="保存" width="72.5%" :footer="null">
    <div style="width: 80%; margin: 0 auto 20px;" v-if="!isEdit">
      <a-steps type="navigation" v-model="step" :current="step" @change='changeStep'>
        <a-step :disabled='true' title="学员信息" />
        <a-step :disabled='true' title="购买项目" />
        <a-step :disabled='true' title="费用结算" />
      </a-steps>
    </div>

    <div v-show="step===0" class='studentStep xyzl'>
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form :form="form" layout="horizontal">
        <div style="padding: 0 40px;display:flex;justify-content:space-around">
          <div style="position:relative">
            <a-form-item class="xyzl-img"  :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <div style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
                <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
                <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
                <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
              </div>
            </a-form-item>
            <a-form-item label="学员手机" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:200px" v-decorator="['student_cellphone',{rules: [{required: true,message:'学员手机号不能为空！'}]}]" @blur="validatePhone"/>
            </a-form-item>
            <a-form-item label="学员姓名" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:200px" v-decorator="['student_name', {rules: [{required: true,message:'学员名称不能为空！'}]}]"/>
            </a-form-item>
            <a-form-item label="学员性别" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-radio-group v-decorator="['student_gender']">
                <a-radio :value="2">
                  女
                </a-radio>
                <a-radio :value="1">
                  男
                </a-radio>
                <a-radio :value="0">
                  未知
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="学籍号" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:200px"  v-decorator="['student_number']"/>
            </a-form-item>
            <a-form-item label="学员状态" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-select style="width:350px" :dropdownStyle="{zIndex:4002}" allowClear v-decorator="['student_class_status']" placeholder="请选择学员状态">
                <a-select-option v-for="(d, index) of studentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="出生日期" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-date-picker style="width:350px" :popupStyle="{zIndex:4002}" v-decorator="['student_birthday']" format="YYYY-MM-DD"/>
            </a-form-item>
            <a-form-item label="就读学校" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:350px" v-decorator="['school_name']" />
            </a-form-item>
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <template slot="label">
                <span>
                  <span style="margin-right:5px">年级</span>
                  <a-tooltip :overlayStyle="{zIndex:4002}">
                    <template slot="title">
                      默认每年的09月01日，学员的年级会自动升级
                    </template>
                    <a-icon type="question-circle" />
                  </a-tooltip>
                </span>
              </template>
              <a-select style="width:350px" allowClear v-decorator="['school_year_grade']" :dropdownStyle="{zIndex:4002}" placeholder="请选择年级" showSearch :filterOption="filterOption">
                  <a-select-option v-for="(d, index) of grades" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div>
            <a-form-item label="入学年份" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:100%" v-decorator="['school_grade']" />
            </a-form-item>
            <a-form-item label="证件号码" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:100%" v-decorator="['student_identity_no']" />
            </a-form-item>
            <a-form-item label="招生来源" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-cascader
                :value='channelsId'
                :options="channels"
                :popupStyle="{zIndex:4002}"
                :show-search="{ filterOption }"
                placeholder="请选择"
                @change="onChangeCascader">
                  <template slot="displayRender" slot-scope="{labels}">
                      <span>{{labels[1]}}</span>
                  </template>
                </a-cascader>
            </a-form-item>
            <a-form-item label="体重(Kg)" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input-number id="inputNumber" style="width:100%" v-decorator="['student_weight']" />
            </a-form-item>

            <!-- <a-form-item label="课程顾问" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:400px" v-decorator="['student_identity_no']" />
            </a-form-item> -->

            <a-form-item label="身高(cm)" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input-number id="inputNumber" style="width:100%" v-decorator="['student_height']" />
            </a-form-item>
            

            <a-form-item label="家庭住址" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:100%" v-decorator="['student_address']" />
            </a-form-item>
            <a-form-item label="健康状况" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-input style="width:100%" v-decorator="['student_health_status']" />
            </a-form-item>
            <a-form-item label="学生备注" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
              <a-textarea style="width:100%" v-decorator="['student_remark']" />
            </a-form-item>
          </div>
        </div>
        <a-form-item class="zcxy-next-btn">
          <a-button style="margin-right: 20px;" @click="handleCancel">取消</a-button>
          <a-button v-if="!isEdit" type="primary" @click="saveInfo()">下一步</a-button>
          <a-button v-else type="primary" @click="saveInfo()">保存</a-button>
        </a-form-item>
      </a-form>
    </div>


    <a-form v-show="step===1">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item label="所属校区" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select v-model="postData.studio_id" @change="handleStudio" showSearch :filterOption="filterOption" :dropdownStyle="{zIndex:4002}">
          <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="课程收费" :label-col="labelCol" :wrapper-col="{ span: 18 }">
        <a-button icon="plus" type="dashed" @click="showCourseFeeModal">课程收费</a-button>
        <div style="margin:5px 0" v-if='localCourseFee_month.length>0'>
          <a-table :columns="columns" :dataSource="localCourseFee_month" :bordered='false' size="small" :pagination="false" rowKey="fee_id" :scroll="{ x: 1400 }">
            <template slot="start_date" slot-scope="text,record">
              <a-date-picker :value="moment(record.start_date)" :allowClear='false' :popupStyle="{zIndex:4002}" @change="(e,time)=>handleStartDate(e,time,record)" />
            </template>
            <template slot="end_date" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-select v-model="record.end_date_type" :dropdownStyle="{zIndex:4002}" style="width:80px" @change='e=>handleEndDateType(e,record)'>
                  <a-select-option v-for="(d, index) of endDateTypes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                </a-select>
                <a-input-number v-if='record.end_date_type == 2' v-model="record.month" @change="e=>handleMonth(e,record,'month')" :min="0" />
                <span v-if='record.end_date_type == 2' style="margin-left:5px"> 月</span>
                <a-date-picker v-if='record.end_date_type == 1' :value="record.end_date" :allowClear='false' :popupStyle="{zIndex:4002}" @change="(e,time)=>handleEndDate(e,time,record)" />
              </div>
            </template>
            <template slot="gift_day" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.gift_day" @change="e=>handleMonth(e,record,'gift_day')" :min="0" />
                <span style="margin-left:5px">天</span>
              </div>
            </template>
            <template slot="times" slot-scope="text,record">
              <a-input-number v-model="record.times" @change="e=>handleMonth(e,record,'times')" :min="0" :step="0.5" />
            </template>
            <template slot="gift_times" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.gift_times" @change="e=>handleMonth(e,record,'gift_times')" :min="0" :step="0.5"/>
              </div>
            </template>
            <template slot="course_price" slot-scope="text,record">
              <span> {{record.course_price?record.course_price:'--'}}</span><span v-if='record.course_price' style="margin-left:5px"> 元</span>
            </template>
            <template slot="price" slot-scope="text,record">
              <span> {{record.price?record.price:'--'}}</span><span v-if='record.price' style="margin-left:5px"> 元</span>
            </template>
            <template slot="discount" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.discount" @change="e=>handleMonth(e,record,'discount')" :min="0" :max="record.course_price" />
                <span style="margin-left:5px"> 元</span>
              </div>
            </template>
            <template slot="action" slot-scope="text,record,index">
              <a href="javascript:;" @click="deleteCourseItem(record,index,1)">删除</a>
            </template>
          </a-table>
        </div>

        <div style="margin:5px 0" v-if='localCourseFee_course.length>0'>
          <a-table :columns="columnsF" :dataSource="localCourseFee_course" :bordered='false' size="small" :pagination="false" rowKey="fee_id" :scroll="{ x: 850 }">

            <template slot="gift_day" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.gift_day" @change="e=>handleMonth(e,record,'gift_day')" :min="0" />
                <span> 天</span>
              </div>
            </template>
            <template slot="times" slot-scope="text,record">
              <a-input-number v-model="record.times" @change="e=>handleMonth(e,record,'times')" :min="0" :step="0.5" />
            </template>
            <template slot="gift_times" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.gift_times" @change="e=>handleMonth(e,record,'gift_times')" :min="0" :step="0.5"/>
              </div>
            </template>
            <template slot="course_price" slot-scope="text,record">
              <span> {{record.course_price?record.course_price:'--'}}</span><span v-if='record.course_price' style="margin-left:5px"> 元</span>
            </template>
            <template slot="price" slot-scope="text,record">
              <span> {{record.price?record.price:'--'}}</span><span v-if='record.price' style="margin-left:5px"> 元</span>
            </template>
            <template slot="discount" slot-scope="text,record">
              <div style="display:flex;align-items:center">
                <a-input-number v-model="record.discount" @change="e=>handleMonth(e,record,'discount')" :min="0" :max="Number(record.course_price)" />
                <span style="margin-left:5px"> 元</span>
              </div>
            </template>
            <template slot="end_date" slot-scope="text,record">
              <a-date-picker :value="moment(record.end_date)" :allowClear='false' @change="(e,time)=>handleStartDateF(e,time,record)" :popupStyle="{zIndex:4002}"/>
            </template>
            <template slot="action" slot-scope="text,record,index">
              <a href="javascript:;" @click="deleteCourseItem(record,index,2)">删除</a>
            </template>
          </a-table>
        </div>

      </a-form-item>
      <a-form-item label="其他收费" :label-col="labelCol" :wrapper-col="{ span: 18 }">
        <a-button icon="plus" type="dashed" @click="showIncidentalFeeModal">其他收费</a-button>
        <a-table v-if="localIncidentalFeeList.length > 0" :columns="columns2" :dataSource="localIncidentalFeeList" :bordered='false' size="small" :pagination="false" rowKey="stock_id">
          <template slot="input-sale_price" slot-scope="text,record">
            <a-input-number class="input-width" v-model="record.sale_price" @change="handleIncidental" :min="0"/>
          </template>
          <template slot="input-product_count" slot-scope="text,record">
            <a-input-number class="input-width" v-model="record.product_count" :max="Number(record.product_stock)" :min="1" @change="handleIncidental" />
            <!-- <a-input-number v-model="record.discount" @change="e=>handleMonth(e,record,'discount')" :min="0"/> -->
          </template>
          <template slot="input-total" slot-scope="text,record">
            <span>{{ record.sale_price * record.product_count }}</span>
          </template>
          <template slot="action" slot-scope="text,record,index">
            <a href="javascript:;" @click="deleteIncidentalItem(record,index)">删除</a>
          </template>
        </a-table>
      </a-form-item>

      <a-form-item label="上课班级" :label-col="labelCol" :wrapper-col="{ span: 18 }">
        <a-button icon="plus" type="dashed" @click="shwoClassesModal">上课班级</a-button>
        <div>
          <a-tag closable @close="handleCloseClasses(index)" v-for="(item,index) in classesList" :key='item.class_id'>{{item.class_name}}</a-tag>
        </div>
      </a-form-item>
      <!-- <a-form-item label="优惠金额" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-model="postData.discount_price"></a-input> 
      </a-form-item>
      <a-form-item label="实收金额" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-model="postData.price"></a-input>
      </a-form-item> -->

      <a-form-item label="预存金额" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="localCourseFeeList.length == 0 && localIncidentalFeeList.length == 0">
        <a-input-number style="width:100%" id="inputNumber" v-model="postData.reserve_money" :precision="2" :min="0.0"/>
      </a-form-item>

      <a-form-item :label="`预存抵扣(${student_detail.student_left_wallet_reserve})`" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="localCourseFeeList.length > 0 || localIncidentalFeeList.length > 0">
        <a-input-number style="width:100%" id="inputNumber" v-model="postData.reserve_money_charge" :max="Number(student_detail.student_left_wallet_reserve)" :min="0.0" :precision="2" @change="updatePayfact()" />
      </a-form-item>

      <a-form-item label="课程顾问" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select v-model="postData.course_consultant_id" @change="handleConsults" showSearch :filterOption="filterOption" :dropdownStyle="{zIndex:4002}">
          <a-select-option v-for="(d, index) of consults" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="实际支付" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input-number style="width:100%" id="inputNumber" v-model="postData.payfact" :min="0.0" :precision="2"/>
      </a-form-item>

      <a-form-item label="支付方式" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-model="postData.payment_type" @change="handlePaymentType">
          <a-radio v-for="(d, index) of paymentTypes" :key="index" :value="d['payment_type']">
            <span v-if="d['payment_type'] == 'WALLETS'">
              {{d['filter_name']}}（{{student_left_wallet_reserve}}）
            </span>
            <span v-else>{{d['filter_name']}}</span>
          </a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="合同类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group v-model="postData.contract_type" @change="handleContractType">
          <a-radio v-for="(d, index) of contractTypes" :key="index" :value="d['contract_type']">
            {{d['filter_name']}}
          </a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="来源渠道" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-cascader
          :value="parentChannels_id"
          :options="parentChannels"
          :show-search="{ filterOption }"
          placeholder="请选择"
          :popupStyle="{zIndex:4002}"
          @change="onChangeChannel">
            <template slot="displayRender" slot-scope="{labels}">
                <span>{{labels[1]}}</span>
            </template>
          </a-cascader>
      </a-form-item>
      <a-form-item label="办理时间" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-date-picker :value="postData.payment_datetime" @change="handleTime" :popupStyle="{zIndex:4002}" format="YYYY-MM-DD HH:mm"  style="width:100%"/>
      </a-form-item>
      <a-form-item label="办理备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-model="postData.remark"></a-textarea>
      </a-form-item>
      <a-form-item style="text-align: center;">
        <a-button type="primary" style="margin-right: 20px;" @click="toStep(0)">上一步</a-button>
        <a-button type="primary" @click="addStudent(2)">下一步</a-button>
      </a-form-item>
    </a-form>

    <div v-show="step===2">
        <print :exportNum="exportNum" v-if="step===2"/>
        <a-form>
          <a-form-item style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="toStep(1)">上一步</a-button>
            <a-button type="primary" style="margin-right: 20px;" :loading="btnLoading" @click="finish">完成</a-button>
            <!-- <a-button type="primary" :loading="downLoading" @click="exportNum++;downLoading=true">下载pdf</a-button> -->
          </a-form-item>
        </a-form>
    </div>

    <ImageModal v-if="imagesVisible"/>
    
    <courseFeeModal v-if="courseFeeVisible" :item="modalData"/>
    <incidentalFeeModal v-if="incidentalFeeVisible" :item="modalData"/>
    <checkStudent v-if="checkVisible" :item="modalData"/>
    <classesModal v-if="isClassesModal" :item="modalData"/>
  </a-modal>
</template>
<style lang="scss" scoped>
.input-width {
  width: 70px;
}
</style>


<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import moment from 'moment'
import ImageModal from '@/views/modal/images'
import courseFeeModal from './courseFeeModal'
import classesModal from './classesModal'
import incidentalFeeModal from './incidentalFeeModal'
import checkStudent from './checkStudent'
import print from './print'

const formData = [
  {
    name: 'student_avatar',
    label: '学员头像',
    type: 'upload',
    rules: [{ required: true, message: '请上传学员头像!' }]
  },
  {
    name: 'student_cellphone',
    label: '手机号码',
    type: 'input-phone',
    rules: [
      { required: true, message: '请输入手机号!', },
    ]
  },
  {
    name: 'student_name',
    label: '学员名称',
    type: 'input',
    rules: [{ required: true, message: '请输入学员名称!' }]
  },
  {
    name: 'student_gender',
    label: '学员性别',
    type: 'radio',
    rules: [],
  },
  {
    name: 'student_birthday',
    label: '学员生日',
    type: 'date',
    rules: []
  },
  {
    name: 'student_channel_id',
    label: '来源渠道',
    type: 'cascader',
    rules: [],
    items: {
      data: 'sourceChannels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
]

const columns = [
    { title: '课程名称', dataIndex: 'fee_name_short', },
    { title: '收费单价', width: 100, dataIndex: 'unit_price', scopedSlots: { customRender: 'input-price' }, },
    { title: '开始时间', dataIndex: 'start_date', scopedSlots: { customRender: 'start_date' }, },
    { title: '结束时间', width: 210, dataIndex: 'end_date', scopedSlots: { customRender: 'end_date' }, },
    { title: '赠送时间', dataIndex: 'gift_day', scopedSlots: { customRender: 'gift_day' }, },
    { title: '课程价格', dataIndex: 'course_price', scopedSlots: { customRender: 'course_price' },},
    { title: '优惠金额', dataIndex: 'discount', scopedSlots: { customRender: 'discount' }, },
    { title: '合计金额', dataIndex: 'price',scopedSlots: { customRender: 'price' }, },
    { title: '操作', key: 'operation', fixed: 'right', width: 46, scopedSlots: { customRender: 'action' }}
  ]
const columnsF = [
    { title: '课程名称', width: 120,dataIndex: 'fee_name_short', ellipsis:true},
    { title: '课时单价', width: 100, dataIndex: 'unit_price', scopedSlots: { customRender: 'input-price' }, },
    { title: '购买课时', width: 100, dataIndex: 'times', scopedSlots: { customRender: 'times' }, },
    { title: '赠送课时', width: 100,  dataIndex: 'gift_times', scopedSlots: { customRender: 'gift_times' }, },
    // { title: '课程价格', width: 100, dataIndex: 'course_price', scopedSlots: { customRender: 'course_price' },},
    { title: '优惠金额', width: 100, dataIndex: 'discount', scopedSlots: { customRender: 'discount' }, },
    { title: '合计金额', width: 130, dataIndex: 'price',scopedSlots: { customRender: 'price' }, },
    { title: '有效期至', dataIndex: 'end_date',scopedSlots: { customRender: 'end_date' },},
    { title: '操作', key: 'operation', fixed: 'right', width: 46, scopedSlots: { customRender: 'action' }}
  ]
const columns2 = [
    { title: '物品名称', dataIndex: 'incidental_name', },
    { title: '单价', dataIndex: 'sale_price', scopedSlots: { customRender: 'input-sale_price' }, },
    { title: '数量', dataIndex: 'product_count', scopedSlots: { customRender: 'input-product_count' }, },
    { title: '实收金额', dataIndex: 'total', scopedSlots: { customRender: 'input-total' }, },
    { title: '操作', key: 'operation', fixed: 'right', width: 46, scopedSlots: { customRender: 'action' }}
  ];

 const data = [];

export default {
  name: 'EditModal',
  mixins: [ editModal ],
  props: {
    item: Object,
    tab:Number,
    studentCellPhone:String
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
    courseFeeModal,
    incidentalFeeModal,
    print,
    checkStudent,
    classesModal,
  },

  data() {
    return {
      student_id: '',
      pData: {},
      postData: {
        reserve_money:0.0,
        reserve_money_charge:0.0,
        payfact:0.0,
      },
      student_detail:{
        student_left_wallet_reserve:0.0
      },
      studios: [],
      consults: [],
      paymentTypes: [],
      contractTypes: [],
      data,
      columns,
      columnsF,
      columns2,
      step: 0,
      exportNum:0,
      formData,
      seleteItems: {
        sexs: C_ITEMS.sexs,
        sourceChannels: [],
      },
      grades:C_ITEMS.grade,
      studentStatus: C_ITEMS.studentStatus,
      endDateTypes:C_ITEMS.endDateTypes,
      uploadUrl: url.uploadQiNiu, 
      imageRoot: url.imageRoot,
      uploadParams: {},
      loading: false,
      imageUrl: '',
      imagePath: '',
      btnLoading:false,
      downLoading:false,
      imagesVisible: false,
      imageKey: '',
      modalData: {},
      courseFeeVisible: false,
      incidentalFeeVisible: false,
      checkVisible: false,
      isClassesModal: false,
      student_channel_id:'',
      channels:[],
      channelsId:[],
      localCourseFee: [],
      localCourseFee_course: [],
      localCourseFee_month: [],
      localIncidentalFee: [],
      classesList:[],
      parentChannels: [],
      parentChannels_id: [],
      student_left_wallet_reserve:'0',
      is_new_student:0,
    }
  },

  computed: {
    localCourseFeeList() {
      return this.localCourseFee
    },
    localIncidentalFeeList() {
      let list = []
      for (let k of Object.keys(this.localIncidentalFee)) {
        list.push(this.localIncidentalFee[k])
      }
      return list
    },
  },

  async created() {
    this.$ls.remove('localCourseFee')
    this.$ls.remove('localIncidentalFee')
    this.$ls.remove('localClassesList')
    this.postData.payment_datetime = moment()
    this.postData.course_consultant_id = this.$store.getters.employee_id
    this.visible = true
    await this.$nextTick()
    if (this.item.student_id) {
      this.ModalTitle = `业务办理 (${this.item.student_name})`
      if(this.studentCellPhone){
        this.validatePhone({target:{value:this.studentCellPhone}})
      }
      if(this.tab == 2){
        this.step = 0
      }else{
        this.step = 1
      }
      this.getDetail(this.item.student_id)
    } else {
      this.getSourceChannel()
      this.ModalTitle = '学员业务办理'
      this.form.setFieldsValue({student_gender:2})
    }
    this.getStudio()
    this.getConsult()
    this.getPaymentType()
    this.getContractType()
    // this.getSourceChannels()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getDetail(student_id) {
      this.student_id = student_id
      this.loading = true
      let res = await this.$store.dispatch('studentsDetailAction', { data: { student_id } })
      this.loading = false
      if (res) {
        this.getSourceChannel(res.data.student_channel_id)
        let d = res.data
        this.student_detail = res.data;
        this.student_left_wallet_reserve = res.data.student_left_wallet_reserve
        this.imageUrl = d['student_avatar']
        this.imagePath = d['student_avatar']
        this.form.setFieldsValue({
          student_name: d.student_name,
          student_gender: d.student_gender,
          student_birthday: d.student_birthday && moment(d.student_birthday, 'YYYY-MM-DD'),
          student_height: d.student_height,
          student_weight: d.student_weight,
          student_cellphone: d.student_cellphone,
          student_health_status: d.student_health_status,
          student_identity_no: d.student_identity_no,
          student_number: d.student_number,
          school_name: d.school_name,
          school_grade: d.school_grade,
          student_address: d.student_address,
          student_remark: d.student_remark,
          student_class_status: d.student_class_status,
        })
      }
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    async getSourceChannel(id) {
      var that = this;
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data[0].children
      this.seleteItems.sourceChannels = res.data[0].children
      this.channels = res.data[0].children
      if(id){
        res.data[0].children.forEach((item)=>{
          item.children.forEach((it)=>{
            if(it.value == id){
              that.channelsId = [item.value,it.value]
              that.parentChannels_id = [item.value,it.value]
              that.onChangeCascader([item.value,it.value])
              that.onChangeChannel([item.value,it.value])
            }
          })
        })
      }
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getConsult() {
      let res = await this.$store.dispatch('searchConsultantAction', {data: {active:true}})
      this.consults = res.data
    },
    async getPaymentType() {
      let res = await this.$store.dispatch('searchPaymentTypeAction', {})
      this.paymentTypes = res.data
      this.postData.payment_type = 'POS'
    },
    async getContractType() {
      let res = await this.$store.dispatch('searchContractTypeAction', {})
      this.contractTypes = res.data
      this.postData.contract_type = 1
    },
    async getSourceChannels() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data[0].children
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    changeFile(file) {
      this.imageUrl = this.uploadParams.key
      this.form.setFieldsValue({"student_avatar": this.imageUrl})
    },

    async validatePhone(e) {
      // console.log(e)
      let { value: cellphone } = e.target
      if (!cellphone) {
        return
      }
      if(!(/^1[3456789]\d{9}$/.test(cellphone))){ 
        this.$message.warning('请输入正确手机号!')
        this.form.setFieldsValue({student_cellphone:null})
        return false; 
      } 
      let res = await this.$store.dispatch('studentsStudentCheckAction', {data: {cellphone}})
      if (res.data.length > 0) {
        this.showCheckModal(res.data)
      }
    },
    async saveInfo() {
      try {
        const { form } = this
        let params = await form.validateFields()
        params.student_avatar =  this.imageUrl
        params.student_channel_id = this.channelsId[1]
        params.student_birthday = params.student_birthday ? moment(params.student_birthday).format('YYYY-MM-DD'):undefined
        this.postData = Object.assign(this.postData, params)
        if (this.isEdit) {
          let data = Object.assign(this.item, this.postData)
          let res = await this.$store.dispatch('studentsUpdateAction', { data })
          if (res) {
            this.handleCancel()
            this.parent.getList()
          }
        } else {
          this.toStep(1)
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    async addStudent() {
      if (!this.postData.studio_id) {
        this.$message.error('请选择所属校区');
        return
      }

      if (this.localCourseFeeList.length <= 0) {
        if(this.postData.reserve_money){

        }else{
          this.$message.error('请选择收费课程！');
          return
        }
      }

      if (!this.postData.course_consultant_id) {
        this.$message.error('请选择课程顾问');
        return
      }
      if (!this.postData.payment_type) {
        this.$message.error('请选择费用支付类型');
        return
      }
      // if(this.postData.payment_type == 'WALLET' && Number(this.totalPrice()) > Number(this.student_left_wallet_reserve.replace(/,/g,''))){
      //   this.$message.error('电子钱包余额不足！')
      //   return
      // }
      if (!this.postData.contract_type) {
        this.$message.error('请选择合同类型');
        return
      }
      let pData = Object.assign({}, this.postData)
      pData.student_id = this.student_id
      pData.is_new_student = this.is_new_student
      pData.localCourseFee_month = this.localCourseFee_month
      pData.localCourseFee_course = this.localCourseFee_course
      pData.payment_datetime = moment(pData.payment_datetime).format('YYYY-MM-DD HH:mm')
      pData.fee = this.localCourseFeeList
      for (let item of this.localIncidentalFeeList) {
        item.product_price = item.sale_price
      }
      let class_id = []
      this.classesList.forEach(item=>{
        class_id.push(item.class_id)
      })
      pData.class_id = class_id
      if(this.item.student_name){
        pData.student_name = this.item.student_name
      }
      pData.incidental = this.localIncidentalFeeList
      pData.student_avatar = this.imagePath || ''
      pData.student_channel_id = this.student_channel_id
      if(this.item.task_id){
        pData.task_id = this.item.task_id
      }
      this.pData = pData
      this.toStep(2)
    },
    async finish() {
      this.btnLoading = true
      await this.$store.dispatch('studentsAddAction', { data: this.pData })
      .then(res=>{
        this.btnLoading = false
          this.$message.success('操作成功!')
          if(this.tab == 1){
            this.parent.getList()
            this.handleCancel(1)
          }else{
            this.parent.hideModal(1)
          }
      })
      .catch(err=>{
        console.log(err)
        this.btnLoading = false
      })
      
    },
    toStep(step) {
      this.step = step
      if (step === 1) {
        this.localCourseFee = this.$ls.get('localCourseFee') || []
        this.localIncidentalFee = this.$ls.get('localIncidentalFee') || []
      }
    },
    handleStudio(){
      this.localCourseFee = []
      this.localIncidentalFee = []
      this.classesList = []
      this.$ls.remove('localCourseFee')
      this.$ls.remove('localIncidentalFee')
      this.$ls.remove('localClassesList')
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
        this.imagePath = image.path
      }
      this.imagesVisible = false
    },

    showCourseFeeModal(item) {
      if (!this.postData.studio_id) {
        this.$message.error('请先选择所属校区');
        return
      }
      this.modalData = {
        studio_id: this.postData.studio_id
      }
      this.courseFeeVisible = true
    },
    hideCourseFeeModal(type) {
      if (type === 1) {
        this.localCourseFee = this.$ls.get('localCourseFee') || []
        let localCourseFee_month = []
        let localCourseFee_course = []
        this.localCourseFee.forEach((item,index)=>{
          item.indexNum = index
          if(item.fee_type == 20 || item.fee_type == 30 ){
            localCourseFee_month.push(item)
          }else{
            localCourseFee_course.push(item)
          }
        })
        this.localCourseFee_month = localCourseFee_month
        this.localCourseFee_course = localCourseFee_course
      }
      this.updateCharge();
      this.courseFeeVisible = false
    },
    updatePayfact(){
      if(this.postData.reserve_money){
        this.postData.payfact = this.postData.reserve_money;
      }else{
        let _total = Number(this.totalPrice());
        if(this.postData.reserve_money_charge>=_total){
          this.postData.reserve_money_charge = _total
        }
        this.postData.payfact = _total - this.postData.reserve_money_charge;
      }
    },
    updateCharge(){
      let _total = Number(this.totalPrice());
      let _left = Number(this.student_detail.student_left_wallet_reserve);
      if(_left >= _total){
        this.postData.reserve_money_charge = _total
      }else{
        this.postData.reserve_money_charge = _left
      }
      this.updatePayfact();
    },
    shwoClassesModal(){
      if (!this.postData.studio_id) {
        this.$message.error('请先选择所属校区');
        return
      }
      if (this.localCourseFeeList.length <= 0) {
        this.$message.error('请选择收费课程！');
        return
      }
      let fee_id = []
      this.localCourseFeeList.forEach(item=>{
        fee_id.push(item.fee_id)
      })
      fee_id = Array.from(new Set(fee_id))
      this.modalData = {
        studio_id: this.postData.studio_id,
        fee_id:fee_id
      }
      this.isClassesModal = true
    },
    hideClassesModal(type){
      if(type == 1){
        let classesList = this.$ls.get('localClassesList') || []
        let list = []
        if(classesList.length > 0){
          classesList.forEach(item=>{
            list.push(item.class_id)
          })
          list = Array.from(new Set(list))
          list.forEach((item,index)=>{
            classesList.forEach(it=>{
              if(item == it.class_id){
                list[index] = it
              }
            })
          })
          this.$ls.set('localClassesList', list)
          this.classesList = list
        }
      }
      this.isClassesModal = false
    },
    handleCloseClasses(index){
      this.classesList.splice(index,1)
      this.$ls.set('localClassesList', this.classesList)
    },
    showIncidentalFeeModal(item) {
      if (!this.postData.studio_id) {
        this.$message.error('请先选所属择校区');
        return
      }
      this.modalData = {
        studio_id: this.postData.studio_id
      }
      this.incidentalFeeVisible = true
    },
    hideIncidentalFeeModal(type) {
      if (type === 1) {
        this.localIncidentalFee = this.$ls.get('localIncidentalFee') || []
        console.log(this.localIncidentalFee,'this.localIncidentalFee')
      }
      this.incidentalFeeVisible = false
    },

    showCheckModal(item) {
      this.modalData = item
      this.checkVisible = true
    },
    hideCheckModal(type, student_id) {
      if (type === 1) {
        this.getDetail(student_id.student_id)
        this.is_new_student = 0
      }else{
        this.is_new_student = 1
      }
      this.checkVisible = false
    },

    deleteCourseItem(record,index,num) {
      if(num == 1){
        this.localCourseFee_month.splice(index,1)
      }else{
        this.localCourseFee_course.splice(index,1)
      }
      this.localCourseFee.splice(record.indexNum, 1)
      this.$ls.set('localCourseFee', this.localCourseFee)
      this.localCourseFee = this.$ls.get('localCourseFee') || []
    },

    deleteIncidentalItem(record,index) {
      let name = record.stock_id
      delete this.localIncidentalFee[name]
      this.$ls.set('localIncidentalFee', this.localIncidentalFee)
      this.localIncidentalFee = this.$ls.get('localIncidentalFee') || []
    },

    changeStep(current){
      console.log(current);
    },
    onChange(value, selectedOptions) {
      this.channelsId = value;
    },
    onChangeCascader(value, selectedOptions) {
      this.channelsId = value
      if(value.length != 0){
        this.student_channel_id = value[1]
      }else{
        this.student_channel_id = ''
      }
    },
    onChangeChannel(value){
      console.log(value)
      this.parentChannels_id = value
      if(value.length != 0){
        this.postData.channel_id = value[1];
      }else{
        this.postData.channel_id = '';
      }
    },
    handleTime(e){
      this.postData.payment_datetime = e
      this.$forceUpdate()
    },
    handleConsults(){
      this.$forceUpdate()
    },
    handleStartDate(e,time,record){
      record.start_date = time
      record.end_date = null
      let arr = [...this.localCourseFee_month,...this.localCourseFee_course]
      this.localCourseFee = arr
      this.$ls.set('localCourseFee',this.localCourseFee)
    },
    handleStartDateF(e,time,record){
      record.end_date = time
      let arr = [...this.localCourseFee_month,...this.localCourseFee_course]
      this.localCourseFee = arr
      this.$ls.set('localCourseFee',this.localCourseFee)
    },

    handleEndDateType(e,record){
      record.end_date_type = e
      if(e === 1){
        if(record.end_date){
          let start = record.start_date
          let end = moment(record.end_date).format('YYYY-MM-DD')
          let dayNum = moment(end).diff(moment(start),'days')
          let unit_price = Number(record.unit_price)/30
          unit_price = unit_price.toFixed(2)
          record.course_price = ( Number(record.unit_price) * Number(num) ).toFixed(2)
          record.price = (Number(record.course_price) - Number(record.discount)).toFixed(2)
        }else{
          record.course_price = null
          record.price = null
        }
      }else{
        record.course_price = ( Number(record.unit_price) * Number(num) ).toFixed(2)
        record.price = (Number(record.course_price) - Number(record.discount)).toFixed(2)
      }
      let arr = [...this.localCourseFee_month,...this.localCourseFee_course]
      this.localCourseFee = arr
      this.$forceUpdate()
      this.$ls.set('localCourseFee',this.localCourseFee)
    },
    handleMonth(e,record,name){
      let num
      if(record.fee_type == 20 || record.fee_type == 30){
        if(name === 'month'){
          num = e || 0
          record.course_price = ( Number(record.unit_price) * Number(num) ).toFixed(2)
          record.price = (Number(record.course_price) - Number(record.discount)).toFixed(2)
        }else{
          num = e || 0
        }
        if(name === 'discount'){
          record.price = (Number(record.course_price) - Number(num)).toFixed(2)
        }
      }else{
        num = e || 0
        if(name === 'discount'){
          if(record.times == Number(record.timesF)){
            record.course_price = record.priceF
            record.price = (Number(record.priceF) - Number(num)).toFixed(2)
          }else{
            record.price = (Number(record.course_price) - Number(num)).toFixed(2)
          }
        }
        if(name === 'times'){
          num = e || 0
          if(num == Number(record.timesF)){
            record.course_price = Number(record.priceF)
            record.price = (Number(record.course_price) - Number(record.discount)).toFixed(2)
          }else{
            record.course_price = (Number(record.unit_price) * Number(num)).toFixed(2)
            record.price = (Number(record.course_price) - Number(record.discount)).toFixed(2)
          }
        }
      }
      record[name] = num
      let arr = [...this.localCourseFee_month,...this.localCourseFee_course]
      this.localCourseFee = arr
      //更新电子钱包
      this.updateCharge();
      this.$forceUpdate()
      this.$ls.set('localCourseFee',this.localCourseFee)
    },
    handleEndDate(e,time,record){
      record.end_date = e
      let start = record.start_date
      let end = time
      let dayNum = moment(end).diff(moment(start),'days')
      let unit_price = Number(record.unit_price)/30
      unit_price = unit_price.toFixed(2)
      record.course_price = (Number(unit_price) * 100) * (Number(dayNum) * 100)/10000
      record.price = ((Number(record.course_price) * 100) - (Number(record.discount) * 100))/100
      let arr = [...this.localCourseFee_month,...this.localCourseFee_course]
      this.localCourseFee = arr
      this.$forceUpdate()
      this.$ls.set('localCourseFee',this.localCourseFee)
    },
    handleIncidental(e){
      this.updateCharge();
      console.log(this.totalPrice());
      this.$forceUpdate()
    },
    handlePaymentType(e){
      // if (this.localCourseFeeList.length <= 0) {
      //   this.$message.error('请选择收费课程！')
      //   return
      // }
      // if(e.target.value == 'WALLET' && Number(this.totalPrice()) > Number(this.student_left_wallet_reserve.replace(/,/g,''))){
      //   this.$message.error('电子钱包余额不足！')
      //   return
      // }
      this.postData.payment_type = e.target.value
      this.$forceUpdate()
    },
    handleContractType(e){
      this.postData.contract_type = e.target.value
      this.$forceUpdate()
    },
    totalPrice() {
      let p = 0

      for (let item of this.localCourseFeeList) {
        p = Number(p) + Number(item.price) || 0
        // p = p.toFixed(2)
      }
      // console.log(this.localIncidentalFeeList)
      for (let item of this.localIncidentalFeeList) {
        p += (Number(item.sale_price) * Number(item.product_count) || 0)
      }

      return p.toFixed(2)
    },
  },
  beforeDestroy(){
    this.form.setFieldsValue({})
  },
}
</script>

<style lang="scss">
.studentStep{
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item label {
    display: inline;
  }
  .ant-col-10{
    width: 22%;
  }
  .ant-form-item-control{
    width: 400px;
  }
  .zcxy-next-btn{
    .ant-form-item-control{
      width: 100%;
      text-align: center;
    }
  }
  .xyzl-img{
    position: absolute;
    width: 132px;
    height: 132px;
    right: 10px;
    z-index: 10;
    overflow: hidden;
    img{
      width: 132px;
      height: 132px;
    }
  }
}
.ant-message{
  z-index: 8000 !important;
}
</style>