import url from '@/utils/URL'
import request from '@/utils/request'

export function settingSystem(params) {
  return request({
    url: url.settingSystem,
    method: 'POST',
    ...params
  })
}
export function settingSystemSms(data) {
  return request({
    url: url.settingSystemSms,
    method: 'POST',
    data
  })
}

export function settingSystemSmsPay(data) {
  return request({
    url: url.settingSystemSmsPay,
    method: 'POST',
    data
  })
}

export function settingSystemSmsAnalysis(data) {
  return request({
    url: url.settingSystemSmsAnalysis,
    method: 'POST',
    data
  })
}

export function settingSystemLongpoll(data) {
  return request({
    url: url.settingSystemLongpoll,
    method: 'POST',
    data
  })
}

export function settingSystemMonitor(data) {
  return request({
    url: url.settingSystemMonitor,
    method: 'POST',
    data
  })
}

export function settingSystemMonitorUpdate(data) {
  return request({
    url: url.settingSystemMonitorUpdate,
    method: 'POST',
    data
  })
}


export function settingSystemEducation(data) {
  return request({
    url: url.settingSystemEducation,
    method: 'POST',
    data
  })
}

export function settingSystemEducationUpdate(data) {
  return request({
    url: url.settingSystemEducationUpdate,
    method: 'POST',
    data
  })
}

export function settingSystemEducationAchievement(data) {
  return request({
    url: url.settingSystemEducationAchievement,
    method: 'POST',
    data
  })
}

export function settingSystemEducationAchievementUpdate(data) {
  return request({
    url: url.settingSystemEducationAchievementUpdate,
    method: 'POST',
    data
  })
}

export function settingSystemEducationAppointment(data) {
  return request({
    url: url.settingSystemEducationAppointment,
    method: 'POST',
    data
  })
}

export function settingSystemEducationAddAppointment(data) {
  return request({
    url: url.settingSystemEducationAddAppointment,
    method: 'POST',
    data
  })
}

export function settingSystemEducationDeleteAppointment(data) {
  return request({
    url: url.settingSystemEducationDeleteAppointment,
    method: 'POST',
    data
  })
}

export function settingSystemAudition(params) {
  return request({
    url: url.settingSystemAudition,
    method: 'POST',
    ...params
  })
}

export function settingStudioNotice(params) {
  return request({
    url: url.settingStudioNotice,
    method: 'POST',
    ...params
  })
}

export function settingStudioNoticeAdd(params) {
  return request({
    url: url.settingStudioNoticeAdd,
    method: 'POST',
    ...params
  })
}

export function settingStudioNoticeUpdate(params) {
  return request({
    url: url.settingStudioNoticeUpdate,
    method: 'POST',
    ...params
  })
}

export function settingStudioNoticeDelete(params) {
  return request({
    url: url.settingStudioNoticeDelete,
    method: 'POST',
    ...params
  })
}

export function settingStudioNoticeAccount(params) {
  return request({
    url: url.settingStudioNoticeAccount,
    method: 'POST',
    ...params
  })
}

export function settingScanCheckin(params) {
  return request({
    url: url.settingScanCheckin,
    method: 'POST',
    ...params
  })
}

export function settingScanCheckinAdd(params) {
  return request({
    url: url.settingScanCheckinAdd,
    method: 'POST',
    ...params
  })
}

export function settingScanCheckinDelete(params) {
  return request({
    url: url.settingScanCheckinDelete,
    method: 'POST',
    ...params
  })
}

export function settingPaymentNotice(params) {
  return request({
    url: url.settingPaymentNotice,
    method: 'POST',
    ...params
  })
}

export function settingPaymentNoticeAdd(params) {
  return request({
    url: url.settingPaymentNoticeAdd,
    method: 'POST',
    ...params
  })
}

export function settingPaymentNoticeDelete(params) {
  return request({
    url: url.settingPaymentNoticeDelete,
    method: 'POST',
    ...params
  })
}

export function settingPaymentNoticeAccount(params) {
  return request({
    url: url.settingPaymentNoticeAccount,
    method: 'POST',
    ...params
  })
}

export function settingAliPayment(params) {
  return request({
    url: url.settingAliPayment,
    method: 'POST',
    ...params
  })
}

export function settingAliPaymentUpdate(params) {
  return request({
    url: url.settingAliPaymentUpdate,
    method: 'POST',
    ...params
  })
}

export function settingAgreement(params) {
  return request({
    url: url.settingAgreement,
    method: 'POST',
    ...params
  })
}

export function settingBanner(params) {
  return request({
    url: url.settingBanner,
    method: 'POST',
    ...params
  })
}

export function settingBannerAdd(params) {
  return request({
    url: url.settingBannerAdd,
    method: 'POST',
    ...params
  })
}

export function settingBannerUpdate(params) {
  return request({
    url: url.settingBannerUpdate,
    method: 'POST',
    ...params
  })
}

export function settingBannerDetail(params) {
  return request({
    url: url.settingBannerDetail,
    method: 'GET',
    ...params
  })
}

export function settingBannerDelete(params) {
  return request({
    url: url.settingBannerDelete,
    method: 'POST',
    ...params
  })
}

export function settingBannerRecovery(params) {
  return request({
    url: url.settingBannerRecovery,
    method: 'POST',
    ...params
  })
}

export function settingWxwork(params) {
  return request({
    url: url.settingWxwork,
    method: 'POST',
    ...params
  })
}

export function settingWxworkInstall(params) {
  return request({
    url: url.settingWxworkInstall,
    method: 'POST',
    ...params
  })
}

export function settingWxworkCallback(params) {
  return request({
    url: url.settingWxworkCallback,
    method: 'POST',
    ...params
  })
}

export function settingWxworkUpdate(params) {
  return request({
    url: url.settingWxworkUpdate,
    method: 'POST',
    ...params
  })
}

export function settingWxworkSetKeywords(params) {
  return request({
    url: url.settingWxworkSetKeywords,
    method: 'POST',
    ...params
  })
}

export function settingWxworkAddTags(params) {
  return request({
    url: url.settingWxworkAddTags,
    method: 'POST',
    ...params
  })
}

export function settingWxworkDelTags(params) {
  return request({
    url: url.settingWxworkDelTags,
    method: 'POST',
    ...params
  })
}

export function settingWxworkTagGroup(params) {
  return request({
    url: url.settingWxworkTagGroup,
    method: 'POST',
    ...params
  })
}

export function settingWxworkTagSync(params) {
  return request({
    url: url.settingWxworkTagSync,
    method: 'POST',
    ...params
  })
}

export function settingWxworkInvalidKeywords(params) {
  return request({
    url: url.settingWxworkInvalidKeywords,
    method: 'POST',
    ...params
  })
}

export function loggingIndex(params) {
  return request({
    url: url.loggingIndex,
    method: 'POST',
    ...params
  })
}

export function loggingExport(data) {
  return request({
    url: url.loggingExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function settingCreditUpdate(data) {
  return request({
    url: url.settingCreditUpdate,
    method: 'POST',
    data
  })
}

export function settingCreditIndex(params) {
  return request({
    url: url.settingCreditIndex,
    method: 'POST',
    ...params
  })
}

export function settingfinanceConfig(params) {
  return request({
    url: url.settingfinanceConfig,
    method: 'POST',
    ...params
  })
}

export function settingfinanceConfigUpdate(params) {
  return request({
    url: url.settingfinanceConfigUpdate,
    method: 'POST',
    ...params
  })
}

export function settingfinanceFiscal(params) {
  return request({
    url: url.settingfinanceFiscal,
    method: 'POST',
    ...params
  })
}

export function settingfinanceSetFiscal(params) {
  return request({
    url: url.settingfinanceSetFiscal,
    method: 'POST',
    ...params
  })
}

export function settingfinanceLockFiscal(params) {
  return request({
    url: url.settingfinanceLockFiscal,
    method: 'POST',
    ...params
  })
}

export function settingfinanceUnlockFiscal(params) {
  return request({
    url: url.settingfinanceUnlockFiscal,
    method: 'POST',
    ...params
  })
}

export function smartserviceIndex(params) {
  return request({
    url: url.smartserviceIndex,
    method: 'POST',
    ...params
  })
}

export function smartserviceAdd(params) {
  return request({
    url: url.smartserviceAdd,
    method: 'POST',
    ...params
  })
}

export function smartserviceUpdate(params) {
  return request({
    url: url.smartserviceUpdate,
    method: 'POST',
    ...params
  })
}

export function smartserviceDetail(params) {
  return request({
    url: url.smartserviceDetail,
    method: 'POST',
    ...params
  })
}

export function smartserviceClose(params) {
  return request({
    url: url.smartserviceClose,
    method: 'POST',
    ...params
  })
}

export function smartserviceVersions(params) {
  return request({
    url: url.smartserviceVersions,
    method: 'POST',
    ...params
  })
}

export function smartserviceAddReply(params) {
  return request({
    url: url.smartserviceAddReply,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrder(params) {
  return request({
    url: url.enterpriseWorkOrder,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderMine(params) {
  return request({
    url: url.enterpriseWorkOrderMine,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderDetail(params) {
  return request({
    url: url.enterpriseWorkOrderDetail,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderWorker(params) {
  return request({
    url: url.enterpriseWorkOrderWorker,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderAssign(params) {
  return request({
    url: url.enterpriseWorkOrderAssign,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderFinish(params) {
  return request({
    url: url.enterpriseWorkOrderFinish,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderVersions(params) {
  return request({
    url: url.enterpriseWorkOrderVersions,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderAddReply(params) {
  return request({
    url: url.enterpriseWorkOrderAddReply,
    method: 'POST',
    ...params
  })
}

export function enterpriseWorkOrderAnalysis(params) {
  return request({
    url: url.enterpriseWorkOrderAnalysis,
    method: 'POST',
    ...params
  })
}

export function enterpriseFilterApp(params) {
  return request({
    url: url.enterpriseFilterApp,
    method: 'POST',
    ...params
  })
}

export function enterpriseFilterBelongStudio(params) {
  return request({
    url: url.enterpriseFilterBelongStudio,
    method: 'POST',
    ...params
  })
}
