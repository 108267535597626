import url from '@/utils/URL'
import request from '@/utils/request'

export function imageToken(params) {
  return request({
    url: url.imageToken,
    method: 'POST',
    ...params
  })
}

export function uploadQiNiu(data) {
  return request({
    url: url.uploadQiNiu,
    method: 'POST',
    data
  })
}

export function massToken(params) {
  return request({
    url: url.massToken,
    method: 'POST',
    ...params
  })
}

export function material(params) {
  return request({
    url: url.material,
    method: 'POST',
    ...params
  })
}

export function materialUpToken(params) {
  return request({
    url: url.materialUpToken,
    method: 'POST',
    ...params
  })
}

export function materialVideoToken(params) {
  return request({
    url: url.materialVideoToken,
    method: 'POST',
    ...params
  })
}

export function materialFolder(params) {
  return request({
    url: url.materialFolder,
    method: 'POST',
    ...params
  })
}

export function materialAjaxAddFolder(params) {
  return request({
    url: url.materialAjaxAddFolder,
    method: 'POST',
    ...params
  })
}

export function materialAjaxDelete(params) {
  return request({
    url: url.materialAjaxDelete,
    method: 'POST',
    ...params
  })
}

export function materialDelete(params) {
  return request({
    url: url.materialDelete,
    method: 'POST',
    ...params
  })
}

export function materialUpload(params) {
  return request({
    url: url.materialUpload,
    method: 'POST',
    ...params
  })
}
