import url from '@/utils/URL'
import request from '@/utils/request'

export function rateStudent(params) {
  return request({
    url: url.rateStudent,
    method: 'POST',
    ...params
  })
}

export function rateTeacher(params) {
  return request({
    url: url.rateTeacher,
    method: 'POST',
    ...params
  })
}

export function rateStudentCheck(params) {
  return request({
    url: url.rateStudentCheck,
    method: 'POST',
    ...params
  })
}

export function rateStudentDelete(params) {
  return request({
    url: url.rateStudentDelete,
    method: 'POST',
    ...params
  })
}

export function rateStudentAjaxView(params) {
  return request({
    url: url.rateStudentAjaxView,
    method: 'POST',
    ...params
  })
}

export function rateStudentAjaxViewComment(params) {
  return request({
    url: url.rateStudentAjaxViewComment,
    method: 'POST',
    ...params
  })
}

export function rateStudentCourseView(params) {
  return request({
    url: url.rateStudentCourseView,
    method: 'POST',
    ...params
  })
}

export function rateStudentDetail(params) {
  return request({
    url: url.rateStudentDetail,
    method: 'POST',
    ...params
  })
}

export function rateStudentMultiAction(params) {
  return request({
    url: url.rateStudentMultiAction,
    method: 'POST',
    ...params
  })
}

export function rateStudentAdd(params) {
  return request({
    url: url.rateStudentAdd,
    method: 'POST',
    ...params
  })
}

export function rateStudentAddComment(params) {
  return request({
    url: url.rateStudentAddComment,
    method: 'POST',
    ...params
  })
}
