import url from '@/utils/URL'
import request from '@/utils/request'

export function meetingCampaign(params) {
  return request({
    url: url.meetingCampaign,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignDetail(params) {
  return request({
    url: url.meetingCampaignDetail,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignUpdate(params) {
  return request({
    url: url.meetingCampaignUpdate,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignAdd(params) {
  return request({
    url: url.meetingCampaignAdd,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignSetting(params) {
  return request({
    url: url.meetingCampaignSetting,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignQrcodeview(params) {
  return request({
    url: url.meetingCampaignQrcodeview,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignDeleteTicket(params) {
  return request({
    url: url.meetingCampaignDeleteTicket,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignRecoveryTicket(params) {
  return request({
    url: url.meetingCampaignRecoveryTicket,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignCheckin(params) {
  return request({
    url: url.meetingCampaignCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignUnCheckin(params) {
  return request({
    url: url.meetingCampaignUnCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignCheckAll(params) {
  return request({
    url: url.meetingCampaignCheckAll,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignAjaxCheckin(params) {
  return request({
    url: url.meetingCampaignAjaxCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignQrcodedownload(params) {
  return request({
    url: url.meetingCampaignQrcodedownload,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignDelete(params) {
  return request({
    url: url.meetingCampaignDelete,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignRecovery(params) {
  return request({
    url: url.meetingCampaignRecovery,
    method: 'POST',
    ...params
  })
}

export function meetingCampaignDownloadExportList(data) {
  return request({
    url: url.meetingCampaignDownloadExportList,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function meetingCampaignDeleteNotify(params) {
  return request({
    url: url.meetingCampaignDeleteNotify,
    method: 'POST',
    ...params
  })
}

export function meetingChannel(params) {
  return request({
    url: url.meetingChannel,
    method: 'POST',
    ...params
  })
}

export function meetingChannelAdd(params) {
  return request({
    url: url.meetingChannelAdd,
    method: 'POST',
    ...params
  })
}

export function meetingChannelUpdate(params) {
  return request({
    url: url.meetingChannelUpdate,
    method: 'POST',
    ...params
  })
}

export function meetingChannelDetail(params) {
  return request({
    url: url.meetingChannelDetail,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsDetail(params) {
  return request({
    url: url.meetingTicketsDetail,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsFreeType(params) {
  return request({
    url: url.meetingTicketsFreeType,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsAddFreeType(params) {
  return request({
    url: url.meetingTicketsAddFreeType,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsDiscountType(params) {
  return request({
    url: url.meetingTicketsDiscountType,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsAddDiscountType(params) {
  return request({
    url: url.meetingTicketsAddDiscountType,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsUpdateType(params) {
  return request({
    url: url.meetingTicketsUpdateType,
    method: 'POST',
    ...params
  })
}

export function meetingTickets(params) {
  return request({
    url: url.meetingTickets,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsAdd(params) {
  return request({
    url: url.meetingTicketsAdd,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsCheckin(params) {
  return request({
    url: url.meetingTicketsCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingTicketsUnCheckin(params) {
  return request({
    url: url.meetingTicketsUnCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingCheckin(params) {
  return request({
    url: url.meetingCheckin,
    method: 'POST',
    ...params
  })
}

export function meetingCheckinQrcodeview(params) {
  return request({
    url: url.meetingCheckinQrcodeview,
    method: 'POST',
    ...params
  })
}