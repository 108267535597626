<template>
  <div class="bjxy">
    <div class="bj-table">
      <div class="clearfix table-tools">
        <div class="buttons">
          <div class="tit">本班学员（{{list.length}}人）</div>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="getMemberById">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名/手机号</span>
                </template>
                <a-input v-model.trim="searchParams.q" placeholder="学员名/手机号" style="width: 140px" allowClear/>
              </a-tooltip>
            </a-form-item>

            <a-form-item>
              <a-button type="primary" html-type="submit" @click="getMemberById">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="class_member_id"
          :selection="{key: 'key'}"
          :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
          :columns="columns" :dataSource="list" :scroll="{ y: 500 }">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="name" slot-scope="text, record">
            <div style="display:flex;align-items:center">
              <span>{{text}}</span>
              <svg style="width: 12px;height: 12px;margin-left: 6px;" aria-hidden="true">
                  <use v-show="record.student_gender == 2" xlink:href="#icon-sex_woman"></use>
                  <use v-show="record.student_gender == 1" xlink:href="#icon-sex_man"></use>
              </svg>
            </div>
            <!-- <LUser :istoProfile='1' :data="record"/> -->
          </template>
          <template slot="wechat" slot-scope="text, record">
            <svg style="width: 12px;height: 12px;margin-left: 2px;" aria-hidden="true">
                <use v-show="record.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
                <use v-show="record.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
            </svg>
          </template>
        </a-table>
      </div>
    </div>
    <div class="bj-tools">
      <a-button :disabled="!selectedRowKeys.length" @click="joinRight" class="btn" type="primary"><a-icon type="double-right" /></a-button>
      <a-button :disabled="!selectedRowKeys2.length" @click="joinLeft" class="btn" type="primary"><a-icon type="double-left" /></a-button>
      <!-- <a-checkbox v-model="isJoin">同时加入该班</a-checkbox> -->
    </div>
    <div class="bj-table">
      <div class="clearfix table-tools">
        <div class="buttons" style="display: flex;">
          <div class="tit">非本班学员</div>
          <a-form layout='inline'>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>类似班级</span>
                </template>

                <template slot="index" slot-scope="text, record , index">
                  <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>

                <a-select show-search :filter-option="filterOption" @search="handleSearchClass" v-model="activeClass" @change="changeActiveClass"  placeholder="请选择班级" style="width: 160px">
                  <a-select-option v-for="(d, index) of activeClasses" :key="index" :value="d.id || d.class_id">{{ d.class_name || d.className }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="getMemberById2">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名/手机号</span>
                </template>
                <a-input v-model.trim="searchParams2.q" placeholder="学员名/手机号" style="width: 140px" allowClear/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="getMemberById2">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading2" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
          :selection="{key: 'key'}"
          :rowSelection="{selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2}"
          :columns="columns2" :dataSource="list2" :scroll="{ y: 500 }">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="name" slot-scope="text, record">
            <div style="display:flex;align-items:center">
              <span>{{text}}</span>
              <svg style="width: 12px;height: 12px;margin-left: 6px;" aria-hidden="true">
                  <use v-show="record.student_gender == 2" xlink:href="#icon-sex_woman"></use>
                  <use v-show="record.student_gender == 1" xlink:href="#icon-sex_man"></use>
              </svg>
            </div>
          </template>

          <template slot="wechat" slot-scope="text, record">
            <svg style="width: 12px;height: 12px;margin-left: 2px;" aria-hidden="true">
                <use v-show="record.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
                <use v-show="record.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
            </svg>
          </template>

        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'50px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', width:'120px', dataIndex: 'student_name', key: '1' , scopedSlots: { customRender: 'name' },ellipsis:true},
  { title: '手机号码', dataIndex: 'cellphone', key: '3',ellipsis:true},
  { title: '', width: '10px', key: '2' , scopedSlots: { customRender: 'wechat' }},
  { title: '学员权益', dataIndex: 'left_times', key: '4',align:'center' },
]

const columns2 = [
  { title: '#', width:'50px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', width:'120px', dataIndex: 'student_name', key: '1' , scopedSlots: { customRender: 'name' },ellipsis:true},
  // { title: '性别',  dataIndex: 'student_sex', key: '2' },
  { title: '手机号码', dataIndex: 'cellphone', key: '3',ellipsis:true},
  // { title: '', width: '10px', key: '2' , scopedSlots: { customRender: 'wechat' }},
  { title: '学员权益', dataIndex: 'left_times', key: '4' ,align:'center' },
]

export default {
  name: 'bjxy',
  props: {
    id: Number || String,
    name:String,
  },

  data() {
    return {
      loading: false,
      loading2: false,
      exportLoading:false,
      columns,
      selectedRowKeys: [],
      searchParams: {
        search: {},
        class_id: this.id
      },
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams2: {
        search: {},
        class_id: ''
      },
      list: [],
      activeClasses: [],
      activeClass: '',
      columns2,
      selectedRowKeys2: [],
      list2: [],
      isJoin: false,
      exportLoading: false,
    }
  },

  created() {
    this.getMemberById()
    this.getActiveClass(this.id)
    this.getMemberById2()
  },

  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearchClass(val){
        this.getActiveClass(this.id,val)
    },
    async getMemberById(e) {
      e?e.preventDefault():''
      this.loading = true
      let res = await this.$store.dispatch('classesMemberAction', this.searchParams)
      this.list = res.items
      this.loading = false
    },
    async getMemberPotentialById(class_id) {
      this.loading2 = true
      if (class_id == -1) {
        class_id = this.id
      }
      // let res = await this.$store.dispatch('classesMemberAction', { class_id })
      // this.list2 = res.items
      this.searchParams2.class_id = class_id
      let res = await this.$store.dispatch('classesMemberPotentialAction', this.searchParams2)
      this.list2 = res.items
      this.loading2 = false
    },
    async getMemberById2(e) {
      e?e.preventDefault():''
      this.loading2 = true
      this.searchParams2.class_id = this.id
      let res = await this.$store.dispatch('classesMemberPotentialAction', this.searchParams2)
      this.list2 = res.items
      this.loading2 = false
    },
    async getActiveClass(class_id,class_name='') {
      let res = await this.$store.dispatch('classesActiveClassAction', { class_id,class_name })
      this.activeClasses = res.data
      if (this.activeClasses.length > 1) {
        this.activeClass = this.activeClasses[0].id || this.activeClasses[0].class_id
        // this.  (this.activeClass)
      }
    },
    changeActiveClass(value) {
      this.activeClass = value
      this.getMemberPotentialById(this.activeClass)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelectChange2(selectedRowKeys) {
      this.selectedRowKeys2 = selectedRowKeys
    },
    async joinRight() {
      let class_id = this.id
      this.loading = true
      this.loading2 = true
      await this.$store.dispatch('classesMemberDeleteAction', { class_id, class_member_id: this.selectedRowKeys })
      .then(res=>{
        this.selectedRowKeys = []
        this.getMemberById()
        this.getMemberById2()
        this.$message.success('操作成功~')
      })
      .catch(err=>{
        this.$message.error('操作失败~')
      })
    },
    async joinLeft() {
      let class_id = this.id
      this.loading = true
      this.loading2 = true
      let res = await this.$store.dispatch('classesMemberAddAction', { class_id, student_id: this.selectedRowKeys2 })
      .then(res=>{
        this.selectedRowKeys2 = []
        this.getMemberById()
        this.getMemberById2()
        this.$message.success('操作成功~')
      })
      .catch(err=>{
        this.$message.error('操作失败~')
        this.loading = false
        this.loading2 = false
      })
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('classesExportAction', {class_id:[this.id]})
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `${this.name}.xls`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
