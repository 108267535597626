<template>
  <a-modal title="合同预览" v-model="visible" okText="下载" width="50%"
    @ok="handleOk" @cancel="handleCancel" >
        <div>
            <pdf
                ref="pdf"
                v-for="i in numPages" 
                :key="i"  
                :src="url" 
                :page="i"
            ></pdf>
        </div>
  </a-modal>
</template>

<script>
    import pdf from 'vue-pdf'
    export default {
        name:'qrcodeModal',
        inject: ['parent'],
        data() {
            return {
                visible:true,
                url:"",
                numPages:null,
            }
        },
        props:{
            item:Object,
            pdfPath:String,
            isImg:Number
        },
        components: {
            pdf,
        },
        created(){
            this.visible=true
            this.$nextTick(() => {
                this.url=this.pdfPath
                this.getNumPages()
            })
        },
        methods: {
            getNumPages() {
                let loadingTask = pdf.createLoadingTask(this.url)
                loadingTask.promise.then(pdf => {
                    this.numPages = pdf.numPages
                }).catch(err => {
                    console.error('pdf 加载失败', err);
                })
            },handleCancel(){
                this.$parent.isContractPdf=false
            }
            ,handleOk(){
                this.$parent.isContractPdf=false
                this.downloadExportFile(this.url)
            },
            downloadExportFile(blob) {
                let downloadElement = document.createElement('a')
                let href = blob
                if (typeof blob == 'string') {
                    downloadElement.target = '_blank'
                } else {
                    href = window.URL.createObjectURL(blob) //创建下载的链接
                }
                downloadElement.href = href
                document.body.appendChild(downloadElement)
                downloadElement.click() //点击下载
                document.body.removeChild(downloadElement) //下载完成移除元素
            },
        }
    }
</script>
<style lang="scss">
</style>