<template>
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-descriptions layout="vertical" title="" :column='3'>
            <a-descriptions-item v-for="(item,index) in detailData" :key="index" :label="item.lable">
                <span v-if="item.dataIndex !='gender' && item.dataIndex !='referer_customer_id'">{{item.value?item.value:'-'}}</span>
                <span v-if="item.dataIndex ==='gender'">
                    <span v-if="item.value">{{item.value==1?'男':'女'}}</span>
                    <span v-else>未知</span>
                </span>
                <span v-if="item.dataIndex ==='referer_customer_id'">{{item.value?item.value.customer_name:'-'}}</span>
                <!-- <span @click="toEdit(item,index)" style="cursor:pointer" v-if="clickIndex != index">{{item.value}}</span> -->
                <!-- <a-input ref="input" v-if="clickIndex == index && item.type == 'input'" placeholder="请输入" @blur="toBlue(index)" v-model="item.value"/> -->
                <!-- <a-select v-if="clickIndex == index && item.type == 'select'" showSearch placeholder="请选择校区" :filterOption="filterOption" style="width: 200px">
                    <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
                <a-textarea ref="textarea" @blur="toBlue(index)" v-if="clickIndex == index && item.type == 'textarea'" v-model="item.value" style="width:300px" placeholder="请输入" :rows="4" />
                <a-date-picker v-if="clickIndex == index && item.type == 'DatePicker'" show-time placeholder="请选择时间" @change="onChange" @ok="onOk" /> -->
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
    export default {
        name:'message',
        props: {
            item: Object,
            leadsId: Number,
            messageNum: Number,
            isTab:String
        },
        data() {
            return {
                loading: false,
                detailData: [
                    {lable:'线索名称',value:'',type:'input',dataIndex:'realname'},
                    {lable:'来源渠道',value:'',type:'select',dataIndex:'source_channel'},
                    {lable:'联系电话',value:'',type:'input',dataIndex:'cellphone'},
                    {lable:'所在校区',value:'',type:'select',dataIndex:'studio_name'},
                    {lable:'意向课程',value:'',type:'select',dataIndex:'course_cate_name'},
                    {lable:'性别',value:'',type:'select',dataIndex:'gender'},
                    {lable:'出生年月',value:'',type:'select',dataIndex:'birthday'},
                    {lable:'在读学校',value:'',type:'',dataIndex:'school'},
                    {lable:'家庭地址',value:'',type:'',dataIndex:'address'},
                    {lable:'负责人',value:'',type:'',dataIndex:'distribute_to'},
                    {lable:'转化时间',value:'',type:'input',dataIndex:'create_time'},
                    {lable:'添加时间',value:'',type:'input',dataIndex:'created_at'},
                    {lable:'添加人',value:'',type:'input',dataIndex:'created_by'},
                    {lable:'备注',value:'',type:'textarea',dataIndex:'remark'},
                ],
                studios:[],
                clickIndex:-1,  
            }
        },
        created(){
            if(this.isTab != '0'){
                this.detailData.splice(8,1)
            }
            this.getDetail()
            this.getStudio()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getDetail(){
                this.loading = true
                let res 
                if(this.isTab == '0'){
                    res = await this.$store.dispatch('cLeadsDetailAction', {task_id:this.item.task_id})
                    this.detailData.forEach((item)=>{
                        item.value = res.data.leads_detail[item.dataIndex]
                    })
                }else{
                    this.detailData[0].lable = '客户名称'
                    this.detailData[0].dataIndex = 'name'
                    this.detailData[1].dataIndex = 'channel_name'
                    this.detailData[8].dataIndex = 'course_consultant_name'
                    this.detailData[10].dataIndex = 'origin_create_time'

                    this.detailData.push({lable:'推荐人',value:'',type:'',dataIndex:'referer_customer_id'})
                    res = await this.$store.dispatch('cCustomerDetailAction', {customer_id:this.item.customer_id})
                    this.detailData.forEach((item)=>{
                        item.value = res.data.customer_detail[item.dataIndex]
                    })
                    console.log(this.detailData,'this.detailData')
                }
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            toEdit(item,index){
                return true;

                if(index == 4){
                    return false
                }
                this.clickIndex = index
                if(item.type == 'input'){
                    this.$nextTick(()=>{
                        const input = this.$refs.input;
                        input[0].focus();
                    })
                }
                if(item.type == 'textarea'){
                    this.$nextTick(()=>{
                        const textarea = this.$refs.textarea;
                        textarea[0].focus();
                    })
                }
            },
            onChange(value, dateString) {
                console.log('Selected Time: ', value);
                console.log('Formatted Selected Time: ', dateString);
            },
            onOk(value) {
                console.log('onOk: ', value);
            },
            async toBlue(index){
                return true;
                if(!this.detailData[index].value){
                    this.clickIndex = -1
                    return false
                }
                let dataIndex = this.detailData[index].dataIndex
                let obj= {
                    task_id:this.item.task_id,
                    [dataIndex]:this.detailData[index].value
                }
                let res 
                if(this.isTab == '0'){
                    res = await this.$store.dispatch('cLeadsUpdateAction', obj) 
                }else{
                    res = await this.$store.dispatch('cCustomerUpdateAction', obj)
                }
                if(res.code ==200){
                    this.$message.success('操作成功!')
                    this.clickIndex = -1
                    this.getDetail();
                }else{
                    this.$message.error('操作失败!')
                }
            }
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.getDetail()
            },
            messageNum(){
                this.getDetail()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>