import url from '@/utils/URL'
import request from '@/utils/request'

export function stockWarehouse(params) {
  return request({
    url: url.stockWarehouse,
    method: 'POST',
    ...params
  })
}

export function stockWarehouseAdd(params) {
  return request({
    url: url.stockWarehouseAdd,
    method: 'POST',
    ...params
  })
}

export function stockWarehouseDetail(params) {
  return request({
    url: url.stockWarehouseDetail,
    method: 'POST',
    ...params
  })
}

export function stockWarehouseUpdate(params) {
  return request({
    url: url.stockWarehouseUpdate,
    method: 'POST',
    ...params
  })
}

export function stockWarehouseDelete(params) {
  return request({
    url: url.stockWarehouseDelete,
    method: 'POST',
    ...params
  })
}

export function stockWarehouseRecovery(params) {
  return request({
    url: url.stockWarehouseRecovery,
    method: 'POST',
    ...params
  })
}

export function stockProduct(params) {
  return request({
    url: url.stockProduct,
    method: 'POST',
    ...params
  })
}

export function stockProductAdd(params) {
  return request({
    url: url.stockProductAdd,
    method: 'POST',
    ...params
  })
}
export function stockProductUpdate(params) {
  return request({
    url: url.stockProductUpdate,
    method: 'POST',
    ...params
  })
}
export function stockProductDetail(params) {
  return request({
    url: url.stockProductDetail,
    method: 'POST',
    ...params
  })
}

export function stockProductStock(params) {
  return request({
    url: url.stockProductStock,
    method: 'POST',
    ...params
  })
}

export function stockProductRecords(params) {
  return request({
    url: url.stockProductRecords,
    method: 'POST',
    ...params
  })
}

export function stockProductIncome(params) {
  return request({
    url: url.stockProductIncome,
    method: 'POST',
    ...params
  })
}

export function stockProductMinus(params) {
  return request({
    url: url.stockProductMinus,
    method: 'POST',
    ...params
  })
}

