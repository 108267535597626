import url from '@/utils/URL'
import request from '@/utils/request'

export function roomIndex(params) {
  return request({
    url: url.roomIndex,
    method: 'POST',
    ...params
  })
}

export function roomDetail(params) {
  return request({
    url: url.roomDetail,
    method: 'POST',
    ...params
  })
}

export function roomUpdate(params) {
  return request({
    url: url.roomUpdate,
    method: 'POST',
    ...params
  })
}

export function roomAdd(params) {
  return request({
    url: url.roomAdd,
    method: 'POST',
    ...params
  })
}

export function roomDelete(params) {
  return request({
    url: url.roomDelete,
    method: 'POST',
    ...params
  })
}

export function roomRecovery(params) {
  return request({
    url: url.roomRecovery,
    method: 'POST',
    ...params
  })
}
