<template>
        <a-modal title="输入名称" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="确认" width="400px" :zIndex='4200'
            @ok="handleOk" @cancel="handleCancel">
            <a-form :form="form" layout="vertical">
                <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
const formData = [
  {
    name: 'event_name',
    label: '事件名称',
    type: 'input',
    rules: [{ required: true, message: '请输入事件名称!' }]
  },
]
    export default {
        name:"eventModal",
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        created () {
            this.visible = true
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                this.confirmLoading = true
                await this.$store.dispatch('cFollowEventAddAction',{data:params})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.parent.hideEventModal(1)
                })
                .catch(err=>{
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEventModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>