<template>
  <div class="dashboard">
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="wallet_id"
        :columns="columns" :dataSource="list" @expand='handleExpand'>
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="pdf" slot-scope="text, record">
            <a-icon @click="toPreviewPDF(record)" v-show="record.contract_pdf" type="file-pdf" style="font-size: 18px;vertical-align: middle;cursor: pointer;"  />
          </template>
        <template slot="balance" slot-scope="text,record">
          <span>{{ record.wallet_balacne - record.wallet_costs_blance }}</span>
        </template>
        <!-- <a-table v-if='text.childList.length !=0' size="small" slot="expandedRowRender" slot-scope="text" :pagination="false" :bordered='false' rowKey="contract_id"
        :columns="columnsChild" :dataSource="text.childList">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
        </a-table> -->
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <contractPdf v-if="isContractPdf" :pdfPath="pdfPath" />
  </div>
</template>

<script>
// const columns = [
//   { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
//   { title: '课程科目', width: '180px', dataIndex: 'course_cate_name', key: 'name' },
//   { title: '签约总课时', dataIndex: 'walle_sign_total_times', key: 'age',align:"right"},
//   { title: '已耗课时', dataIndex: 'walle_cost_total_times', key: '1' ,align:"right"},
//   { title: '剩余课时', dataIndex: 'wallet_times', key: '2' ,align:"right"},
//   { title: '总赠课', dataIndex: 'wallet_total_gift_times', key: '3' ,align:"right"},
//   { title: '剩余赠课', dataIndex: 'wallet_gift_times', key: '4' ,align:"right"},
//   { title: '剩余请假', dataIndex: 'wallet_cancel_times', key: '5',align:"right" }
// ]
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '签约校区',width:'160px', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true},
  { title: '签约合同号',width:'120px', dataIndex: 'contract_no', key: 'contract_no',ellipsis: true},
  { title: '', width:'40px', scopedSlots: { customRender: 'pdf' }, key: 'contract_pdf', fixed: 'left',align:'center'},
  { title: '课程科目',width:'80px', dataIndex: 'course_cate_id', key: '1' ,ellipsis: true},
  { title: '签课',width:'70px', dataIndex: 'times', key: '4' ,align:"right"},
  { title: '签赠',width:'70px', dataIndex: 'gift_times', key: '5' ,align:"right"},
  { title: '余课',width:'70px', dataIndex: 'left_times', key: '2' ,align:"right"},
  { title: '余赠',width:'70px', dataIndex: 'left_gift_times', key: '3' ,align:"right"},
  { title: '课时单价', dataIndex: 'course_balance', key: 'course_balance' ,align:"right"},
  // { title: '剩余请假', dataIndex: 'left_cancel_times', key: '6' ,align:"right"},
  { title: '合同金额', dataIndex: 'contract_costs', key: '7' ,align:"right"},
  // { title: '优惠金额', dataIndex: 'contract_discount', key: '8' },
  // { title: '课程花费', dataIndex: 'created_date', key: '9' },
  { title: '课程顾问', dataIndex: 'course_consultant_id', key: '10' ,align:'right'},
  { title: '合同类型', dataIndex: 'contract_type', key: '11' ,align:'right'},
  // { title: '合同金额', dataIndex: 'contract_costs', key: 'contract_costs' ,align:"right"},
  { title: '备注', dataIndex: 'contract_remark', key: 'contract_remark' ,align:"right"},
  { title: '签约日期',width:'100px', dataIndex: 'created_at', key: '12' ,align:"right"},
]
import tableMixins from '@/common/mixins/table'
import contractPdf from '../../finance/contract/contractPdf'
export default {
  name: 'xybj',
  props: {
    studentId: Number
  },
  components: {
    contractPdf,
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      // columnsChild,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      childList:[],
      searchData: {},
      isContractPdf:false,
      pdfPath:'',
      visible: false,
      refundVisible: false,
    }
  },

  mixins:[tableMixins],

  methods: {
    async toPreviewPDF(item){
        this.isContractPdf=true;
        this.pdfPath = item.contract_pdf;
        // console.log(item,this.pdfPath)      
    },
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileWalletContractAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
      this.$forceUpdate()

      // this.loading = true
      // this.searchParams.student_id = this.studentId
      // let res = await this.$store.dispatch('studentsProfileWalletAction', { data: this.searchParams })
      // this.list = res.items
      // this.list.forEach(item=>{
      //   item.childList = []
      // })
      
      // this.loading = false
    },
    async getChildList(obj,record){
      this.loading = true
      let res = await this.$store.dispatch('studentsProfileWalletContractAction', { data: obj })
      this.childList = res.items
      this.list.forEach(item=>{
        if(item.wallet_id === obj.wallet_id){
          item.childList = res.items
        }
      })
      this.loading = false
      this.$forceUpdate()
    },
    handleExpand(expanded,record){
      let obj = {
        student_id:this.studentId,
        wallet_id:record.wallet_id
      }
      this.getChildList(obj,record)
    },

  }
}
</script>
