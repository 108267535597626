const Search = () => import('@/views/user/search')
const Layout = () => import('@/views/layout')
const Dashboard = () => import('@/views/dashboard')
const Students = () => import('@/views/students')
const Classes = () => import('@/views/classes')
const Invite = () => import('@/views/work/invite')
const Attend = () => import('@/views/work/attend')
const Journal = () => import('@/views/work/journal')
const Announce = () => import('@/views/work/announce')
const Approve = () => import('@/views/work/approve')
const Scan = () => import('@/views/work/scan')
const Cashier= () => import('@/views/work/cashier')
const Task = () => import('@/views/work/task')
const Notification = () => import('@/views/notification/index')
const UpgradeLog = () => import('@/views/dashboard/upgradeLog')

const adminRouter =   
{
    path: '/work',
    component: Layout,
    redirect: '/work/dashboard',
    parent:'adminRouter',
    meta:{
      requiresAuth: false,
      type:'work',
      menusKey:0
    },
    children: [{
      path: 'dashboard',
      component: Dashboard,
      hide: false,
      parent:'dashboard',
      meta: { title: '控制面板', icon: '#icon-SchemeOutline', parent: 'work'}
    }, {
      path: 'students',
      component: Students,
      hide: true,
      parent:'dashboard',
      meta: { title: '在籍学员', icon: '#icon-PersonalOutline', parent: 'work'}
    },{
      path: 'classes',
      component: Classes,
      hide: true,
      parent:'dashboard',
      meta: { title: '班级管理', icon: '#icon-ScheduleOutline', parent: 'work'}
    },{
      path: 'invite-today',
      component: Invite,
      hide: false,
      parent:'dashboard',
      meta: { title: '到访登记', icon: '#icon-CompleteOutline', parent: 'work'}
    },{
      path: 'attend-today',
      component: Attend,
      hide: false,
      parent:'dashboard',
      meta: { title: '上课签到', icon: '#icon-ScanOutline', parent: 'work'}
    }
    // ,
    // {
    //   path: 'attend-today',
    //   component: Attend,
    //   hide: false,
    //   parent:'dashboard',
    //   meta: { title: '上课学员', icon: '#icon-ScanOutline', parent: 'work'}
    // }
    ,{
      path: 'task',
      component: Task,
      hide: false,
      parent:'dashboard',
      meta: { title: '任务OKR', icon: '#icon-CalendarOutline', parent: 'work'}
    }
    ,{ 
      path: 'announce',
      component: Announce,
      hide: false,
      parent:'dashboard',
      meta: { title: '公告', icon: '#icon-VoiceOutline', parent: 'work'}
    },{
      path: 'journal',
      component: Journal,
      hide: false,
      parent:'dashboard',
      meta: { title: '日报', icon: '#icon-LetterOutline', parent: 'work'}
    },
    {
      path: 'approve',
      component: Approve,
      hide: false,
      parent:'dashboard',
      meta: { title: '审批', icon: '#icon-AddressOutline', parent: 'work'}
    },
    {
      path: 'scan',
      component: Scan,
      hide: false,
      parent:'dashboard',
      meta: { title: '扫码', icon: '#icon-PocketcameraOutline', parent: 'work'}
    },{
      path: 'cashier',
      component: Cashier,
      hide: true,
      parent:'dashboard',
      meta: { title: '收银', icon: '#icon-MoneyOutline', parent: 'work'}
    },{ 
      path: 'search/:keywords',
      component: Search,
      hide: true,
      meta: { title: '搜索', parent: 'work'}
    }, {
      path: 'notification',
      component: Notification,
      hide: true,
      meta: { title: '通知中心', parent: 'work'}
    }, {
      path: 'upgradeLog',
      component: UpgradeLog,
      hide: true,
      meta: { title: '更新日志', parent: 'work'}
    }]
}

export default adminRouter