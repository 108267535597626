import url from '@/utils/URL'
import request from '@/utils/request'

export function paymentEnquiry(params) {
  return request({
    url: url.paymentEnquiry,
    method: 'POST',
    ...params
  })
}
export function paymentInquiry(params) {
  return request({
    url: url.paymentInquiry,
    method: 'POST',
    ...params
  })
}
export function paymentReceive(params) {
  return request({
    url: url.paymentReceive,
    method: 'POST',
    ...params
  })
}
export function paymentReceiveExport(data) {
  return request({
    url: url.paymentReceiveExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}
export function paymentRefund(params) {
  return request({
    url: url.paymentRefund,
    method: 'POST',
    ...params
  })
}
export function paymentRefundApply(params) {
  return request({
    url: url.paymentRefundApply,
    method: 'POST',
    ...params
  })
}
export function paymentRefundQuery(params) {
  return request({
    url: url.paymentRefundQuery,
    method: 'POST',
    ...params
  })
}
export function paymentReceiveQuery(params) {
  return request({
    url: url.paymentReceiveQuery,
    method: 'POST',
    ...params
  })
}