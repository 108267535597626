import url from '@/utils/URL'
import request from '@/utils/request'

export function surveyCenter(params) {
  return request({
    url: url.surveyCenter,
    method: 'POST',
    ...params
  })
}

export function surveyCenterAdd(params) {
  return request({
    url: url.surveyCenterAdd,
    method: 'POST',
    ...params
  })
}

export function surveyCenterDetail(params) {
  return request({
    url: url.surveyCenterDetail,
    method: 'POST',
    ...params
  })
}

export function surveyCenterUpdate(params) {
  return request({
    url: url.surveyCenterUpdate,
    method: 'POST',
    ...params
  })
}

export function surveyCenterDelete(params) {
  return request({
    url: url.surveyCenterDelete,
    method: 'POST',
    ...params
  })
}

export function surveyCenterFormdata(data) {
  return request({
    url: url.surveyCenterFormdata,
    method: 'POST',
    data
  })
}

export function surveyCenterExport(data) {
  return request({
    url: url.surveyCenterExport,
    method: 'POST',
    data
  })
}

export function surveyQuestionAdd(params) {
  return request({
    url: url.surveyQuestionAdd,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionDetail(params) {
  return request({
    url: url.surveyQuestionDetail,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionUpdate(params) {
  return request({
    url: url.surveyQuestionUpdate,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionType(params) {
  return request({
    url: url.surveyQuestionType,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionValidate(params) {
  return request({
    url: url.surveyQuestionValidate,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionDelete(params) {
  return request({
    url: url.surveyQuestionDelete,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionAddAnswer(params) {
  return request({
    url: url.surveyQuestionAddAnswer,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionUpdateAnswer(params) {
  return request({
    url: url.surveyQuestionUpdateAnswer,
    method: 'POST',
    ...params
  })
}

export function surveyQuestionDeleteAnswer(params) {
  return request({
    url: url.surveyQuestionDeleteAnswer,
    method: 'POST',
    ...params
  })
}