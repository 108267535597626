import axios from 'axios'
import Vue from 'vue'
import conf from '@/utils/const'
import store from "@/store/loading"
import { message } from 'ant-design-vue'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 120000, // 请求超时时间
  crossDomain: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// request拦截器
service.interceptors.request.use(config => {
    if (!config.hideLoading) {
        store.state.loadding = true
    }
    const token = Vue.ls.get(conf.ACCESS_TOKEN)
    if (token) {
        config.headers['Access-Token'] = token
    }
    // config.headers['Debug'] = 1
    if (config.isForm) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['x-request-with'] = 'XMLHttpRequest'
        config.transformRequest = function (obj) {
            const str = []
            for (const p in obj) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
            return str.join('&')
        }
    }
  return config
}, error => {
  // Do something with request error
  // Promise.reject(error)
  return Promise.error(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
      // console.log(response)
    store.state.loadding = false
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res) {
        if (response.config.responseType == 'blob') {
           return Promise.resolve(res)
        }
        if (res.success) {
            return Promise.resolve(res)
        } else {
            if(response['headers']['x-debug-tag']){
              if((typeof res.msg=='string')&&res.msg.constructor==String){
                message.error(res.msg+'【'+response['headers']['x-debug-tag']+'】')
              }else{
                for (let k in res.msg) {
                  message.error(res.msg[k]+'【'+response['headers']['x-debug-tag']+'】')
                }
              }
            }else{
              if((typeof res.msg=='string')&&res.msg.constructor==String){
                message.error(res.msg)
              }else{
                for (let k in res.msg) {
                  message.error(res.msg[k])
                }
              }
            }
            return Promise.reject(res)
        }
    }
    return res
  },
  error => {
    console.log(error.response,'44444')
    store.state.loadding = false
    if (error.response) {
        switch (error.response.status) {
            case 401:
                sessionStorage.setItem("menusKey",0)
                location.href = '/user/login'
                break
              case 500:
                if(error.response['headers']['x-debug-tag']){
                  message.error('请求失败，请稍后重试！'+'【'+error.response['headers']['x-debug-tag']+'】')
                }else{
                  message.error('请求失败，请稍后重试！')
                }
                break
            case 504:
              if(error.response['headers']['x-debug-tag']){
                message.error('请求超时，请稍后重试！'+'【'+error.response['headers']['x-debug-tag']+'】')
              }else{
                message.error('请求超时，请稍后重试！')
              }
              
              break
        }
    }
    return Promise.reject(error)
  }
)

export default service
