import Vue from 'vue'
import { importImport,importImportUpload,importImportImportLog,importImportCheckImport,importImportDeleteImport,importImportImportTimeExample,
  importImportImportDateExample,importImportImportExample, importImportTemplate,} from '@/api/sImport'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    importImportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportTemplateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportTemplate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportImportLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportImportLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportCheckImportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportCheckImport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportDeleteImportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportDeleteImport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportImportTimeExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportImportTimeExample(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportImportDateExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportImportDateExample(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    importImportImportExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        importImportImportExample(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
