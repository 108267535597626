import Vue from 'vue'
import { courseSchedule,courseScheduleAddSchedule,courseScheduleCalendar, courseScheduleDetail, courseScheduleMultiSetting,courseWareDelCateLevel,courseWareCourseContent,courseWareAddCourseContent,
  courseScheduleSubstitute, courseScheduleAdd,courseScheduleExport,courseScheduleNextday,courseScheduleBookRemark,courseScheduleCancelCourse,courseWareAddCateLevel,courseWareAddCateLevelContent,courseWareUpdateCateLevel,
  courseConflict,courseConflictView,courseConflictEditAjax,courseConflictDelete,courseConflictCancel,courseConflictAjaxCancel,courseConflictSubstitute,courseWareCateLevel,courseWareCateLevelContent,courseWareCateLevelContentDetail,
  courseWare,courseWareAdd,courseWareDetail,courseWareUpdate,courseWareDownload,courseWareDelete,courseWareRecovery,courseCertificate,courseCertificateList,courseWareUpdateCateLevelContent,courseWareDelCateLevelContent,
  courseCertificateSendCertificate,courseCertificateSendList,courseCertificateAdd,courseCertificateEdit,courseCertificateView,courseCertificateViewCertificate,courseWareUpdateCourseContent,courseWareCateLevelContentAddComment,
  courseCertificateDelete,courseCertificateRecovery,courseAchievement,courseAchievementAdd,courseAchievementUpdate,courseAchievementDetail,courseAchievementRecord,courseScheduleAdjustMember,
  courseAchievementAddIcon,courseAchievementViewIcon,courseAchievementDetailIcon,courseAchievementUpdateIcon,courseAchievementAjaxSortMadel,courseAchievementDelete,courseWareCateLevelContentComment,
  courseAchievementRecovery,courseAchievementDeleteIcon,coursePrepare,coursePrepareMy,coursePrepareCheck,coursePrepareAdd,coursePrepareAnalysis,coursePrepareView,courseRecallClip,courseClipIndex,courseClipDelete,
  coursePrepareRespond,coursePrepareAjaxSendNotice,coursePrepareAjaxIgnore,coursePrepareSendNotice,analysisPrepare,courseWareCate,courseCouponGrant,courseRecallIndex,courseRecallFinish,
  courseFee,courseFeeDelete,courseFeeAdd,courseCoupon,courseCouponAdd,courseCouponUpdate,courseCouponDelete,courseCouponSendList,courseCouponCancelCoupon,courseScheduleLiveRecord,courseScheduleAddHighlight,courseScheduleHighlight,courseScheduleDelHighlight,
  courseCouponExport,courseFeeUpdate,courseCertificateCancalCertificate,courseCouponDetail,courseScheduleSummary,courseScheduleSetSummary,courseScheduleAvailableRoom,
  courseScheduleAvailableTeacher,courseScheduleGenerateDate,courseScheduleXgjCheck,courseScheduleCleanCheckin,courseScheduleRemoveMember,courseCouponSendListGraph} from '@/api/course'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    courseScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleAddScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAddSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleCancelCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleCancelCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleNextdayAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleNextday(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleBookRemarkAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleBookRemark(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleSummaryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleSummary(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleSetSummaryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleSetSummary(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleAvailableTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAvailableTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleAvailableRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAvailableRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleGenerateDateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleGenerateDate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseScheduleXgjCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleXgjCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseScheduleCleanCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleCleanCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseScheduleRemoveMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleRemoveMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleAdjustMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAdjustMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseScheduleCalendarAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleCalendar(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseClipIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseClipIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseClipDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseClipDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseRecallIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseRecallIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseRecallClipAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseRecallClip(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseRecallFinishAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseRecallFinish(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleHighlightAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleHighlight(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleAddHighlightAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAddHighlight(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleDelHighlightAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleDelHighlight(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleLiveRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleLiveRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseScheduleMultiSettingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleMultiSetting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleSubstituteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleSubstitute(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    courseScheduleAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseScheduleAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflict(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictEditAjaxAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictEditAjax(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictCancelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictCancel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictAjaxCancelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictAjaxCancel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseConflictSubstituteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseConflictSubstitute(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWare(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareAddCateLevelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareAddCateLevel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareUpdateCateLevelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareUpdateCateLevel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareAddCateLevelContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareAddCateLevelContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareDownloadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareDownload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseWareRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareCateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareCateLevelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCateLevel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareCourseContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCourseContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareAddCourseContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareAddCourseContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareUpdateCourseContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareUpdateCourseContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareCateLevelContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCateLevelContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareUpdateCateLevelContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareUpdateCateLevelContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareDelCateLevelContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareDelCateLevelContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    courseWareDelCateLevelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareDelCateLevel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    
    courseWareCateLevelContentDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCateLevelContentDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    courseWareCateLevelContentCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCateLevelContentComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseWareCateLevelContentAddCommentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseWareCateLevelContentAddComment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateSendCertificateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateSendCertificate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCertificateCancalCertificateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateCancalCertificate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateSendListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateSendList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateEditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateEdit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateViewCertificateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateViewCertificate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCertificateRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCertificateRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementAddIconAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementAddIcon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementViewIconAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementViewIcon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementDetailIconAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementDetailIcon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementUpdateIconAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementUpdateIcon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementAjaxSortMadelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementAjaxSortMadel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseAchievementDeleteIconAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseAchievementDeleteIcon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepare(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareMyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareMy(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareAnalysisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareAnalysis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareRespondAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareRespond(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareAjaxSendNoticeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareAjaxSendNotice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareAjaxIgnoreAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareAjaxIgnore(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    coursePrepareSendNoticeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        coursePrepareSendNotice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisPrepareAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisPrepare(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseFeeDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseFeeDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseFeeAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseFeeAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseFeeUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseFeeUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCouponAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCoupon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCouponAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCouponUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    courseCouponDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponSendListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponSendList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponGrantAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponGrant(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponCancelCouponAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponCancelCoupon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    courseCouponSendListGraphAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        courseCouponSendListGraph(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
