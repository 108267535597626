<template>
    <a-modal v-model="visible" :footer='null' class="videoModal"
    cancelText="取消" okText="保存" width="700px" :closable='false'
    @ok="handleOk" @cancel="handleCancel">
        <video :src="video" autoplay="autoplay" width="100%" controls="controls">
            Your browser does not support the video tag.
        </video>
  </a-modal>
</template>

<script>
    export default {
        name:'videoModal',
        inject: ['parent'],
        data() {
            return {
                visible: false
            }
        },
        props:{
            video:String
        },
        created(){
            this.visible = true
        },
        methods:{
            handleOk(){},
            handleCancel(){
                this.parent.hideVideoModal()
            }
        }
    }
</script>

<style lang="scss">
    .videoModal .ant-modal-content{
        background-color: rgba(0,0,0,0);
        box-shadow:none;
    }
</style>