<template>
    <div 
        style="overflow:scroll;height:550px;padding-top:20px"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-timeline>
            <a-timeline-item v-for='(item,index) in list' :key="index">
                <div style="color:rgba(69, 90, 100, 0.6);">
                    <img style="width:30px;height:30px;border-radius:50%" :src="item.employee_avatar">
                    <span style="margin-left:10px;">{{item.employee_name}}</span>
                    <span style="margin-left:10px">{{item.created_at}}</span>
                </div>
                <div style="margin-left:30px;">
                    <span v-html="item.action_attributes"></span>
                </div>
            </a-timeline-item>
        </a-timeline>
        <LEmpty v-if="list.length == 0"/>
    </div>
</template>

<script>
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'editLog',
        directives: { infiniteScroll },
        data() {
            return {
                list: [],
                busy: false,
                loading:false,
                pageParams: {
                    'page': 0,
                    'per-page':10,
                },
            }
        },
        props: {
            item: Object,
            leadsId: Number,
            isTab:String,
            isEditLog:Number
        },
        created () {
            this.getLog(this.pageParams)
        },
        methods: {
            async getLog(obj) {
                this.loading = true
                let res
                if(this.isTab == '0'){
                    obj.task_id = this.item.task_id
                    res = await this.$store.dispatch('cLeadsChangeLogAction', obj)
                }else{
                    obj.customer_id = this.item.customer_id
                    res = await this.$store.dispatch('cCustomerChangeLogAction', obj)
                }
                if(this.list.length == 0){
                    this.list = res.items
                }else{
                    if(res.items.length == 0){
                        this.$message.warning('没有更多数据了!')
                        this.busy = true;
                    }else{
                        this.list = this.list.concat(res.items);
                    }
                }
                this.loading = false
            },
            handleInfiniteOnLoad() {
                if(this.pageParams.page != 0){
                    if(!this.busy){
                        this.getLog(this.pageParams);
                    }
                }
                this.pageParams.page++
            },
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.pageParams.page = 1;
                this.list = []
                this.getLog({})
            },
            isEditLog(){
                this.pageParams.page = 1;
                this.list = []
                this.getLog({})
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>