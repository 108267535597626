import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 市场应用-列表
 */
export function marketList() {
  return request({
    url: url.market,
    method: 'POST'
  })
}

/**
 * 资料共享
 * @param {*} params 
 */
export function zlShareList(params) {
  return request({
    url: url.zlShare,
    method: 'POST',
    ...params
  })
}

/**
 * 获取资料分类列表
 * @param {*} params 
 */
export function zlCategoryList(params) {
  return request({
    url: url.zlCategory,
    method: 'POST',
    ...params
  })
}

/**
 * 添加资料分类
 * @param {*} data 
 */
export function zlCategoryAdd(data) {
  return request({
    url: url.zlCategoryAdd,
    method: 'POST',
    data
  })
}

/**
 * 编辑资料分类
 * @param {*} data 
 */
export function zlCategoryEdit(data) {
  return request({
    url: url.zlCategoryEdit,
    method: 'POST',
    data
  })
}

/**
 * 删除资料分类
 * @param {*} data 
 */
export function zlCategoryDelete(data) {
  return request({
    url: url.zlCategoryDelete,
    method: 'POST',
    data
  })
}

/**
 * 添加资料
 * @param {*} data 
 */
export function zlShareAdd(data) {
  return request({
    url: url.zlShareAdd,
    method: 'POST',
    data
  })
}

/**
 * 编辑资料
 * @param {*} data 
 */
export function zlShareEdit(data) {
  return request({
    url: url.zlShareEdit,
    method: 'PUT',
    data
  })
}

/**
 * 删除资料
 * @param {*} params 
 */
export function zlShareDelete(params) {
  return request({
    url: url.zlShareDelete,
    method: 'DELETE',
    params
  })
}

/**
 * 资料详情
 * @param {*} data 
 */
export function zlShareDetail(data) {
  return request({
    url: url.zlShareDetail,
    method: 'POST',
    data
  })
}

export function zlShareMoveTo(data) {
  return request({
    url: url.zlShareMoveTo,
    method: 'POST',
    data
  })
}

export function zlShareChangeOrder(data) {
  return request({
    url: url.zlShareChangeOrder,
    method: 'POST',
    data
  })
}

export function xtHelp(params) {
  return request({
    url: url.xtHelp,
    method: 'POST',
    ...params
  })
}

export function xtHelpCateAdd(params) {
  return request({
    url: url.xtHelpCateAdd,
    method: 'POST',
    ...params
  })
}

export function xtHelpAdd(params) {
  return request({
    url: url.xtHelpAdd,
    method: 'POST',
    ...params
  })
}

export function xtHelpView(params) {
  return request({
    url: url.xtHelpView,
    method: 'POST',
    ...params
  })
}

export function xtHelpEdit(params) {
  return request({
    url: url.xtHelpEdit,
    method: 'POST',
    ...params
  })
}

export function xtHelpCateEdit(params) {
  return request({
    url: url.xtHelpCateEdit,
    method: 'POST',
    ...params
  })
}

export function xtHelpCateDelete(params) {
  return request({
    url: url.xtHelpCateDelete,
    method: 'POST',
    ...params
  })
}

export function xtContact(params) {
  return request({
    url: url.xtContact,
    method: 'POST',
    ...params
  })
}

export function xtContactDetail(params) {
  return request({
    url: url.xtContactDetail,
    method: 'POST',
    ...params
  })
}

export function poolIndex(params) {
  return request({
    url: url.poolIndex,
    method: 'POST',
    ...params
  })
}

export function poolTransfer(params) {
  return request({
    url: url.poolTransfer,
    method: 'POST',
    ...params
  })
}

export function poolExport(data) {
  return request({
    url: url.poolExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}