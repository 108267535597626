<template>
        <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" centered okText="转移" width="400px" :zIndex='4200'
            @ok="handleOk" @cancel="handleCancel">
            <a-form layout="vertical">
                <a-form-item  label="请选择校区">
                    <a-select :not-found-content="fetching ? undefined : null" style="width: 100%" :dropdownStyle='{zIndex:4300}' v-model='studio_id' @change="handleChange" @search='handleSearch' showSearch placeholder="请选择校区" :filterOption="filterStudio">
                        <template slot="notFoundContent">
                            <div style="height:50px">
                                <vue-element-loading :active="fetching" color="#00cca2" spinner="spinner"/>
                            </div>
                        </template>
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="变更负责人：">
                    <a-select :not-found-content="fetchingS ? undefined : null" v-model="course_consultant_id" :dropdownStyle='{zIndex:4300}' showSearch :filterOption="filterOption" style="width: 100%" :placeholder="studio_id?'请选择':'请先选择校区'">
                        <template slot="notFoundContent">
                            <div style="height:50px">
                                <vue-element-loading :active="fetchingS" color="#00cca2" spinner="spinner"/>
                            </div>
                        </template>
                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">
                            <div>
                                <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['employee_avatar']"/>
                                <span>{{ d['filter_name'] }}</span>
                            </div>
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="跟进状态">
                    <a-radio-group v-model='task_step'>
                        <a-radio :value="-1">不做变更</a-radio>
                        <a-radio :value="0">变未跟进</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>
</template>

<script>
    export default {
        name:'transfer',
        inject: ['parent'],
        props:['distribute_id','item','isTab','search'],
        data() {
            return {
                visible:false,
                fetching:false,
                fetchingS:false,
                ModalTitle: '线索转移',
                confirmLoading:false,
                studios:[],
                consultants:[],
                task_step:-1,
                studio_id:undefined,
                course_consultant_id:undefined,

            }
        },
        created () {
            this.visible = true
            if(this.isTab == 1){
                this.ModalTitle = '客户转移'
            }
            this.getStudio()
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            filterStudio(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].children[1].children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getStudio(val) {
                this.fetching = true
                let res = await this.$store.dispatch('searchAllStudioAction', {params:val})
                this.studios = res.data
                this.fetching = false
            },
            async getConsultant(id) {
                this.fetchingS = true
                let res = await this.$store.dispatch('searchConsultantAction', {params:{studio_id:id},data:{active:true}})
                this.consultants = res.data
                this.fetchingS = false
            },
            async handleOk() {
                if(!this.studio_id){
                    this.$message.warning('请选择校区!')
                    return false
                }
                if(!this.course_consultant_id){
                    this.$message.warning('请选择负责人!')
                    return false
                }
                this.confirmLoading=true
                let res 
                if(this.isTab == '0'){
                    let obj = {
                        task_id:[this.item.task_id],
                        employee_id:[this.course_consultant_id],
                        studio_id:this.studio_id,
                        search:this.search,
                        task_step:this.task_step
                    }
                    res = await this.$store.dispatch('cLeadsTransferAction', obj)
                }else{
                    let obj = {
                        task_id:[this.item.task_id],
                        employee_id:[this.course_consultant_id],
                        customer_id:this.item.customer_id,
                        studio_id:this.studio_id,
                        task_step:this.task_step
                    }
                    res = await this.$store.dispatch('cCustomerTransferAction', obj)
                }
                if(res.code == 200){
                    this.$message.success('操作成功!')
                    this.visible = false
                    this.parent.hideTransfer(1)
                }
            },
            handleCancel(){
                this.parent.hideTransfer(0);
            },
            handleSearch(e){
                let obj = {q:e}
                this.getStudio(obj)
            },
            handleChange(val){
                this.getConsultant(val)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>