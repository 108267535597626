import Vue from 'vue'
import { sopIndex, sopAdd, sopDelete, sopDetail, sopGroup, sopGroupSort, sopAddGroup, sopUpdateGroup, sopDeleteGroup } from '@/api/sop'
import config from '@/utils/const'

export default {
    state: {

    },
    mutations: {
    },
    actions: {
        sopIndexAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopIndex(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopAddAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopAdd(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopDeleteAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopDelete(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopDetailAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopDetail(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopGroupAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopGroup(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopGroupSortAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopGroupSort(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopAddGroupAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopAddGroup(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopUpdateGroupAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopUpdateGroup(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
        sopDeleteGroupAction({ commit }, params) {
            return new Promise((resolve,reject) => {
                sopDeleteGroup(params).then(response => {
                    resolve(response)
                }).catch(reason => {
                    reject(reason)
                })
            })
        },
    }
}