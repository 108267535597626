import Vue from 'vue'
import { 
  classesList, classesAdd, classesUpdate, classesDetail,classesExport,classesClose,classesAttend,classesWxworkStaff,classesWxworkContact,classesWxworkMsg,
  classesMultiSetting, classesMember, classesMemberAdd, classesMemberDelete,
  classesMemberPotential, classesActiveClass, classesMemberRecord, 
  classesSchedule, classesScheduleAdd, classesScheduleAddDate, classesCancelCourse,
  classesManager, classesManagerAdd, classesManagerRemove, classesMerge,classesClassesAlbum,
  classesConfirmApply, classesDeleteApply,classesAlbumDeleteImages,classesAlbumImagesShow,
  classesSubstitute, classesAddTempMember,classesAlbum,classesAlbumImages,classesAlbumAdd,classesAlbumUpdate,classesAlbumDelete,classesAlbumUpload,
} from '@/api/classes'
import config from '@/utils/const'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    classesListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesCloseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesClose(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesWxworkStaffAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesWxworkStaff(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesWxworkContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesWxworkContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesWxworkMsgAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesWxworkMsg(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMergeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMerge(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesClassesAlbumAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesClassesAlbum(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMultiSettingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMultiSetting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMemberAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMemberAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMemberDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMemberDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMemberPotentialAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMemberPotential(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesActiveClassAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesActiveClass(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesMemberRecordAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesMemberRecord(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesScheduleAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesScheduleAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesScheduleAddDateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesScheduleAddDate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesCancelCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesCancelCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesManagerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesManager(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesManagerAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesManagerAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesManagerRemoveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesManagerRemove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesConfirmApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesConfirmApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesDeleteApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesDeleteApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesSubstituteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesSubstitute(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAddTempMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAddTempMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    classesAlbumAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbum(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumImagesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumImages(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumDeleteImagesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumDeleteImages(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumImagesShowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumImagesShow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    classesAlbumUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        classesAlbumUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
