<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认合并" width="520px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div class="students-qrcode">
            <div style="font-weight: bold;font-size:16px">使用微信扫一扫即可自动绑定学员账号</div>
            <div>绑定后家长可在公众号中使用课表、家校互通等功能</div>
            <div>
                <img style="width:200px;height:200px" :src="qrcode_url" alt="">
            </div>
            <div>二维码将于{{moment().add(3,'day').format('MM月DD号 HH:mm')}}失效</div>
            <!-- <div>失效后需要联系学校重新获取二维码</div> -->
        </div>
        <div class="students-student">
            <div>已绑定微信账户（{{weixn_account_list.length}}个）</div>
            <div class="students-student-list">
                <div v-for="(item,index) in weixn_account_list" :key="index" class="students-student-item">
                    <div class="students-student-item-img">
                        <img :src="item.headimgurl" alt="">
                    </div>
                    <a-tooltip>
                        <template slot="title">
                            {{item.nickname}}
                        </template>
                        <div>{{item.nickname}}</div>
                        <div>
                            <svg style="width: 12px;height: 12px;" aria-hidden="true">
                                <use v-show="item.fans_info.subscribe == 1" xlink:href="#icon-weixin"></use>
                                <use v-show="item.fans_info.subscribe == 0" xlink:href="#icon-weixin1"></use>
                            </svg>
                        </div>
                    </a-tooltip>
                    
                </div>
            </div>
        </div>
  </a-modal>
</template>


<script>
    import moment from 'moment'
    export default {
        name:"qrcodeModal",
        inject: ['parent'],
        data() {
            return {
                modalTitle: '',
                visible: false,
                loading: false,
                qrcode_url: '',
                confirmLoading: false,
                weixn_account_list: [],
            }
        },
        props:{
            item: Object
        },
        created () {
            this.visible = true
            this.modalTitle = `学员关联-${this.item.student_name}`
            this.getqrcode()
        },
        methods: {
            moment,
            async getqrcode(){
                this.loading = true
                await this.$store.dispatch('studentsWeixinQrcodeAction',{data:{student_id:this.item.student_id}})
                .then(res=>{
                    this.qrcode_url = res.data.qrcode_url
                    this.weixn_account_list = res.data.weixn_account_list
                    this.loading = false
                })
            },
            handleOk() {},
            handleCancel(){
                this.parent.hideQrcodeModal()
            }
        },
    }
</script>

<style lang="scss" scoped>
    .students-qrcode{
        text-align:center;
        div{
            margin-bottom: 10px;
        }
    }
    .students-student{
        padding-top: 10px;
        border-top: 1px solid #eee;
        &-list{
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
        }
        &-item{
            display: inline-block;
            margin-right: 7px;
            &-img{
                width: 60px;
                height: 45px;
                text-align: center;
                line-height: 45px;
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }
            div{
                width: 60px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>