import url from '@/utils/URL'
import request from '@/utils/request'

export function employeeAccount(params) {
  return request({
    url: url.employeeAccount,
    method: 'POST',
    ...params
  })
}

export function employeeAccountAdd(params) {
  return request({
    url: url.employeeAccountAdd,
    method: 'POST',
    ...params
  })
}

export function employeeAccountDetail(params) {
  return request({
    url: url.employeeAccountDetail,
    method: 'POST',
    ...params
  })
}

export function employeeAccountFace(params) {
  return request({
    url: url.employeeAccountFace,
    method: 'POST',
    ...params
  })
}

export function employeeAccountSetFace(params) {
  return request({
    url: url.employeeAccountSetFace,
    method: 'POST',
    ...params
  })
}

export function employeeAccountCleanFace(params) {
  return request({
    url: url.employeeAccountCleanFace,
    method: 'POST',
    ...params
  })
}

export function employeeAccountUpdate(params) {
  return request({
    url: url.employeeAccountUpdate,
    method: 'POST',
    ...params
  })
}

export function employeeAccountRoles(params) {
  return request({
    url: url.employeeAccountRoles,
    method: 'POST',
    ...params
  })
}

export function employeeAccountRecovery(params) {
  return request({
    url: url.employeeAccountRecovery,
    method: 'POST',
    ...params
  })
}

export function employeeAccountDelete(params) {
  return request({
    url: url.employeeAccountDelete,
    method: 'DELETE',
    ...params
  })
}

export function employeeAccountResetPassword(params) {
  return request({
    url: url.employeeAccountResetPassword,
    method: 'POST',
    ...params
  })
}

export function employeeAccountQrcode(params) {
  return request({
    url: url.employeeAccountQrcode,
    method: 'POST',
    ...params
  })
}

export function employeeAccountMoveTo(params) {
  return request({
    url: url.employeeAccountMoveTo,
    method: 'POST',
    ...params
  })
}

export function employeeAccountTreeGraph(params) {
  return request({
    url: url.employeeAccountTreeGraph,
    method: 'POST',
    ...params
  })
}

export function employeeApply(params) {
  return request({
    url: url.employeeApply,
    method: 'POST',
    ...params
  })
}

export function employeeApplyConfirm(params) {
  return request({
    url: url.employeeApplyConfirm,
    method: 'POST',
    ...params
  })
}

export function employeeApplyDelelte(params) {
  return request({
    url: url.employeeApplyDelelte,
    method: 'POST',
    ...params
  })
}

export function employeeOrganization(params) {
  return request({
    url: url.employeeOrganization,
    method: 'POST',
    ...params
  })
}

export function employeeOrganizationSave(data) {
  return request({
    url: url.employeeOrganizationSave,
    method: 'POST',
    data
  })
}

export function employeeOrganizationUpdate(data) {
  return request({
    url: url.employeeOrganizationUpdate,
    method: 'POST',
    data
  })
}

export function employeeOrganizationDel(data) {
  return request({
    url: url.employeeOrganizationDel,
    method: 'POST',
    data
  })
}

export function employeeOrganizationMove(data) {
  return request({
    url: url.employeeOrganizationMove,
    method: 'POST',
    data
  })
}

export function employeeOrganizationTreeGraph(data) {
  return request({
    url: url.employeeOrganizationTreeGraph,
    method: 'POST',
    data
  })
}

export function employeeRole(params) {
  return request({
    url: url.employeeRole,
    method: 'POST',
    ...params
  })
}

export function employeeRolePermission(params) {
  return request({
    url: url.employeeRolePermission,
    method: 'POST',
    ...params
  })
}

export function employeeRoleAdd(params) {
  return request({
    url: url.employeeRoleAdd,
    method: 'POST',
    ...params
  })
}

export function employeeRoleUpdate(params) {
  return request({
    url: url.employeeRoleUpdate,
    method: 'POST',
    ...params
  })
}

export function employeeRoleAssign(params) {
  return request({
    url: url.employeeRoleAssign,
    method: 'POST',
    ...params
  })
}

export function employeeRoleRemove(params) {
  return request({
    url: url.employeeRoleRemove,
    method: 'POST',
    ...params
  })
}

export function employeeGroup(params) {
  return request({
    url: url.employeeGroup,
    method: 'POST',
    ...params
  })
}

export function employeeGroupAdd(params) {
  return request({
    url: url.employeeGroupAdd,
    method: 'POST',
    ...params
  })
}

export function employeeRule(params) {
  return request({
    url: url.employeeRule,
    method: 'POST',
    ...params
  })
}

export function employeeGroupUpdate(params) {
  return request({
    url: url.employeeGroupUpdate,
    method: 'POST',
    ...params
  })
}

export function employeeGroupDelete(params) {
  return request({
    url: url.employeeGroupDelete,
    method: 'POST',
    ...params
  })
}

export function employeeGroupRemove(params) {
  return request({
    url: url.employeeGroupRemove,
    method: 'POST',
    ...params
  })
}

export function employeeGroupRelatedTo(params) {
  return request({
    url: url.employeeGroupRelatedTo,
    method: 'POST',
    ...params
  })
}

export function employeeGroupType(params) {
  return request({
    url: url.employeeGroupType,
    method: 'POST',
    ...params
  })
}

export function employeePosition(params) {
  return request({
    url: url.employeePosition,
    method: 'POST',
    ...params
  })
}

export function employeePositionAdd(params) {
  return request({
    url: url.employeePositionAdd,
    method: 'POST',
    ...params
  })
}

export function employeePositionUpdate(params) {
  return request({
    url: url.employeePositionUpdate,
    method: 'POST',
    ...params
  })
}

export function employeePositionDelete(params) {
  return request({
    url: url.employeePositionDelete,
    method: 'POST',
    ...params
  })
}