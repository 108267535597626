import url from '@/utils/URL'
import request from '@/utils/request'

export function homework(params) {
  return request({
    url: url.homework,
    method: 'POST',
    ...params
  })
}

export function homeworkPublish(params) {
  return request({
    url: url.homeworkPublish,
    method: 'POST',
    ...params
  })
}

export function homeworkClasses(params) {
  return request({
    url: url.homeworkClasses,
    method: 'POST',
    ...params
  })
}

export function homeworkDetail(params) {
  return request({
    url: url.homeworkDetail,
    method: 'POST',
    ...params
  })
}

export function homeworkReissue(params) {
  return request({
    url: url.homeworkReissue,
    method: 'POST',
    ...params
  })
}

export function homeworkResend(params) {
  return request({
    url: url.homeworkResend,
    method: 'POST',
    ...params
  })
}

export function homeworkRecord(params) {
  return request({
    url: url.homeworkRecord,
    method: 'POST',
    ...params
  })
}

export function homeworkRate(params) {
  return request({
    url: url.homeworkRate,
    method: 'POST',
    ...params
  })
}

export function homeworkAddComment(params) {
  return request({
    url: url.homeworkAddComment,
    method: 'POST',
    ...params
  })
}

export function homeworkAddReply(params) {
  return request({
    url: url.homeworkAddReply,
    method: 'POST',
    ...params
  })
}

export function homeworkUpdateReply(params) {
  return request({
    url: url.homeworkUpdateReply,
    method: 'POST',
    ...params
  })
}

export function homeworkDeleteComment(params) {
  return request({
    url: url.homeworkDeleteComment,
    method: 'POST',
    ...params
  })
}

export function homeworkDelete(params) {
  return request({
    url: url.homeworkDelete,
    method: 'POST',
    ...params
  })
}

export function homeworkReceipted(params) {
  return request({
    url: url.homeworkReceipted,
    method: 'POST',
    ...params
  })
}