import url from '@/utils/URL'
import request from '@/utils/request'

export function searchEngine(params) {
  return request({
    url: url.searchEngine,
    method: 'POST',
    ...params
  })
}
export function searchEmployee(params) {
  return request({
    url: url.searchEmployee,
    method: 'POST',
    ...params
  })
}
export function searchEngineClasses(params) {
  return request({
    url: url.searchEngineClasses,
    method: 'POST',
    ...params
  })
}
export function searchStudent(params) {
  return request({
    url: url.searchStudent,
    method: 'POST',
    ...params
  })
}
export function searchWarehouse(params) {
  return request({
    url: url.searchWarehouse,
    method: 'POST',
    ...params
  })
}
export function searchWarehouseProduct(params) {
  return request({
    url: url.searchWarehouseProduct,
    method: 'POST',
    ...params
  })
}
export function searchStudentContactType(params) {
  return request({
    url: url.searchStudentContactType,
    method: 'POST',
    ...params
  })
}
export function searchFeeType(params) {
  return request({
    url: url.searchFeeType,
    method: 'POST',
    ...params
  })
}
export function searchTradeType(params) {
  return request({
    url: url.searchTradeType,
    method: 'POST',
    ...params
  })
}
export function searchPaymentType(params) {
  return request({
    url: url.searchPaymentType,
    method: 'POST',
    ...params
  })
}

export function searchBelongStudio(params) {
  return request({
    url: url.searchBelongStudio,
    method: 'POST',
    params
  })
}

export function searchProduct(params) {
  return request({
    url: url.searchProduct,
    method: 'POST',
    ...params
  })
}

export function searchSuppliers(params) {
  return request({
    url: url.searchSuppliers,
    method: 'POST',
    ...params
  })
}

export function search(params) {
  return request({
    url: url.searchStudio,
    method: 'POST',
    params
  })
}

export function searchStudio(params) {
  return request({
    url: url.searchStudio,
    method: 'POST',
    ...params
  })
}
export function searchAllStudio(params) {
  return request({
    url: url.searchAllStudio,
    method: 'POST',
    ...params
  })
}
export function searchStudioRoom(params) {
  return request({
    url: url.searchStudioRoom,
    method: 'POST',
    ...params
  })
}
export function searchSourceChannel(params) {
  return request({
    url: url.searchSourceChannel,
    method: 'POST',
    ...params
  })
}
export function searchSourceChannelCascader(params) {
  return request({
    url: url.searchSourceChannelCascader,
    method: 'POST',
    ...params
  })
}
export function searchCourseType(params) {
  // console.log(params);
  return request({
    url: url.searchCourseType,
    method: 'POST',
    ...params
  })
}
export function searchCourse(params) {
  return request({
    url: url.searchCourse,
    method: 'POST',
    ...params
  })
}
export function searchDemoCourse(params) {
  return request({
    url: url.searchDemoCourse,
    method: 'POST',
    ...params
  })
}
export function searchLibrary(params) {
  return request({
    url: url.searchLibrary,
    method: 'POST',
    ...params
  })
}

export function searchLibraryCascader(params) {
  return request({
    url: url.searchLibraryCascader,
    method: 'POST',
    ...params
  })
}

export function searchGetTags(params) {
  return request({
    url: url.searchGetTags,
    method: 'POST',
    ...params
  })
}

export function searchLibraryCategory(params) {
  return request({
    url: url.searchLibraryCategory,
    method: 'POST',
    ...params
  })
}
export function searchMessageType(params) {
  return request({
    url: url.searchMessageType,
    method: 'POST',
    ...params
  })
}
export function searchHelperType(params) {
  return request({
    url: url.searchHelperType,
    method: 'POST',
    ...params
  })
}
export function searchContractType(params) {
  return request({
    url: url.searchContractType,
    method: 'POST',
    ...params
  })
}
export function searchCreatedBy(params) {
  return request({
    url: url.searchCreatedBy,
    method: 'POST',
    ...params
  })
}
export function searchConsultant(params) {
  return request({
    url: url.searchConsultant,
    method: 'POST',
    ...params
  })
}
export function searchAccountEmployee(params) {
  //console.log(params);
  return request({
    url: url.searchAccountEmployee,
    method: 'POST',
    ...params
  })
}
export function searchTeacher(params) {
  return request({
    url: url.searchTeacher,
    method: 'POST',
    ...params
  })
}
export function searchTeacherCascader(params) {
  return request({
    url: url.searchTeacherCascader,
    method: 'POST',
    ...params
  })
}
export function searchClasses(params) {
  return request({
    url: url.searchClasses,
    method: 'POST',
    ...params
  })
}
export function searchMeeting(params) {
  return request({
    url: url.searchMeeting,
    method: 'POST',
    ...params
  })
}
export function searchTicketType(params) {
  return request({
    url: url.searchTicketType,
    method: 'POST',
    ...params
  })
}
export function searchStudents(params) {
  return request({
    url: url.searchStudents,
    method: 'POST',
    ...params
  })
}
export function searchQyweEmployee(params) {
  return request({
    url: url.searchQyweEmployee,
    method: 'POST',
    ...params
  })
}
export function searchKnowledgeContent(params) {
  return request({
    url: url.searchKnowledgeContent,
    method: 'POST',
    ...params
  })
}
export function searchKnowledgePackage(params) {
  return request({
    url: url.searchKnowledgePackage,
    method: 'POST',
    ...params
  })
}
export function searchCourseFee(params) {
  return request({
    url: url.searchCourseFee,
    method: 'POST',
    ...params
  })
}
export function searchClassRecruitFee(params) {
  return request({
    url: url.searchClassRecruitFee,
    method: 'POST',
    ...params
  })
}
export function searchIncidentalFee(params) {
  return request({
    url: url.searchIncidentalFee,
    method: 'POST',
    ...params
  })
}
export function searchRole(params) {
  return request({
    url: url.searchRole,
    method: 'POST',
    ...params
  })
}
export function searchSurvey(params) {
  return request({
    url: url.searchSurvey,
    method: 'POST',
    ...params
  })
}
export function searchClassesList(params) {
  return request({
    url: url.searchClassesList,
    method: 'POST',
    ...params
  })
}
export function searchCampaignTicketType(params) {
  return request({
    url: url.searchCampaignTicketType,
    method: 'POST',
    ...params
  })
}
export function searchGetGradeType(params) {
  return request({
    url: url.searchGetGradeType,
    method: 'POST',
    ...params
  })
}

export function searchGetDepartment(params) {
  return request({
    url: url.searchGetDepartment,
    method: 'POST',
    ...params
  })
}

export function searchPosition(params) {
  return request({
    url: url.searchPosition,
    method: 'POST',
    ...params
  })
}

export function searchCustomer(params) {
  return request({
    url: url.searchCustomer,
    method: 'POST',
    ...params
  })
}
