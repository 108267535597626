import Vue from 'vue'
import { roomIndex,roomDetail,roomUpdate,roomAdd,roomDelete,roomRecovery } from '@/api/room'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    roomIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    roomDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    roomUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    roomAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    roomDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    roomRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        roomRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
