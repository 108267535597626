import Vue from 'vue'
import { imageToken, uploadQiNiu, massToken, material,materialUpToken,materialVideoToken,materialFolder,materialAjaxAddFolder,materialAjaxDelete,materialDelete,materialUpload, } from '@/api/material'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    imageTokenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        imageToken(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    uploadQiNiuAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        uploadQiNiu(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          resolve(reason)
        })
      })
    },
    massTokenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        massToken(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    materialAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        material(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialUpTokenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialUpToken(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialVideoTokenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialVideoToken(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialFolderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialFolder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialAjaxAddFolderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialAjaxAddFolder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialAjaxDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialAjaxDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    materialUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        materialUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
