import url from '@/utils/URL'
import request from '@/utils/request'

export function weipagesRecruit(params) {
  return request({
    url: url.weipagesRecruit,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitAdd(params) {
  return request({
    url: url.weipagesRecruitAdd,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitDetail(params) {
  return request({
    url: url.weipagesRecruitDetail,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitUpdate(params) {
  return request({
    url: url.weipagesRecruitUpdate,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitDelete(params) {
  return request({
    url: url.weipagesRecruitDelete,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitRecovery(params) {
  return request({
    url: url.weipagesRecruitRecovery,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitMember(params) {
  return request({
    url: url.weipagesRecruitMember,
    method: 'POST',
    ...params
  })
}

export function weipagesRecruitDeleteMember(data) {
  return request({
    url: url.weipagesRecruitDeleteMember,
    method: 'POST',
    data
  })
}

export function weipagesRecruitExport(data) {
  return request({
    url: url.weipagesRecruitExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}
