/**
 * 常量配置
 */
const config = {
  ACCESS_TOKEN: 'Access-Token',
  ACCESS_TIME: 7 * 24 * 60 * 60 * 1000,
  STORAGE_CONFIG: {
    namespace: 'pro__',
    name: 'ls',
    storage: 'local'
  }
}

export default config