import Vue from 'vue'
import { 
  daMessages, daMessagesClasses, daMessagesPublish, 
  daMessagesAddCate, daMessagesDetail, daMessagesRecommand,
  daMessagesUpdateCate, daMessagesStatus, daMessagesDeleteCate,
  daMessagesRecoveryCate, daMessagesDelete, daMessagesRecovery
 } from '@/api/archive'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    daMessagesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessages(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesPublishAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesPublish(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesAddCateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesAddCate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesRecommandAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesRecommand(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesUpdateCateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesUpdateCate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesStatusAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesStatus(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesDeleteCateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesDeleteCate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesRecoveryCateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesRecoveryCate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    daMessagesRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        daMessagesRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
