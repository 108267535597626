import url from '@/utils/URL'
import request from '@/utils/request'
export function importImport(params) {
  return request({
    url: url.importImport,
    method: 'POST',
    ...params
  })
}

export function importImportUpload(params) {
  return request({
    url: url.importImportUpload,
    method: 'POST',
    ...params
  })
}

export function importImportTemplate(params) {
  return request({
    url: url.importImportTemplate,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function importImportImportLog(params) {
  return request({
    url: url.importImportImportLog,
    method: 'POST',
    ...params
  })
}

export function importImportCheckImport(params) {
  return request({
    url: url.importImportCheckImport,
    method: 'POST',
    ...params
  })
}

export function importImportDeleteImport(params) {
  return request({
    url: url.importImportDeleteImport,
    method: 'POST',
    ...params
  })
}

export function importImportImportTimeExample(params) {
  return request({
    url: url.importImportImportTimeExample,
    method: 'POST',
    ...params
  })
}

export function importImportImportDateExample(params) {
  return request({
    url: url.importImportImportDateExample,
    method: 'POST',
    ...params
  })
}

export function importImportImportExample(params) {
  return request({
    url: url.importImportImportExample,
    method: 'POST',
    ...params
  })
}