import Vue from 'vue'
import { toolsQrcode, toolsClick , toolsShorturl, toolsSharePosterAdd, toolsSharePosterDetail } from '@/api/tools'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    toolsQrcodeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        toolsQrcode(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    toolsClickAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        toolsClick(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    toolsShorturlAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        toolsShorturl(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    toolsSharePosterAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        toolsSharePosterAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    toolsSharePosterDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        toolsSharePosterDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
