import url from '@/utils/URL'
import request from '@/utils/request'


export function discourseGroup(data) {
  return request({
    url: url.discourseGroup,
    method: 'POST',
    data
  })
}

export function discourseAddGroup(data) {
    return request({
      url: url.discourseAddGroup,
      method: 'POST',
      data
    })
}

export function discourseUPdateGroup(data) {
    return request({
      url: url.discourseUPdateGroup,
      method: 'POST',
      data
    })
}

export function discourseDeleteGroup(data) {
    return request({
      url: url.discourseDeleteGroup,
      method: 'POST',
      data
    })
}

export function discourseGroupSort(data) {
  return request({
    url: url.discourseGroupSort,
    method: 'POST',
    data
  })
}

export function discourseIndex(data) {
    return request({
      url: url.discourseIndex,
      method: 'POST',
      data
    })
}

export function discourseAdd(data) {
    return request({
      url: url.discourseAdd,
      method: 'POST',
      data
    })
}

export function discourseDetail(data) {
    return request({
      url: url.discourseDetail,
      method: 'POST',
      data
    })
}

export function discourseUpdate(data) {
    return request({
      url: url.discourseUpdate,
      method: 'POST',
      data
    })
}

export function discourseDelete(data) {
    return request({
      url: url.discourseDelete,
      method: 'POST',
      data
    })
}

export function discourseClick(data) {
  return request({
    url: url.discourseClick,
    method: 'POST',
    data
  })
}

export function discourseLeads(data) {
  return request({
    url: url.discourseLeads,
    method: 'POST',
    data
  })
}


export function discourseLeadsAdd(data) {
  return request({
    url: url.discourseLeadsAdd,
    method: 'POST',
    data
  })
}

export function discourseCustomer(data) {
  return request({
    url: url.discourseCustomer,
    method: 'POST',
    data
  })
}

export function discourseCustomerAdd(data) {
  return request({
    url: url.discourseCustomerAdd,
    method: 'POST',
    data
  })
}

export function collectIndex(data) {
  return request({
    url: url.collectIndex,
    method: 'POST',
    data
  })
}

export function collectAdd(data) {
  return request({
    url: url.collectAdd,
    method: 'POST',
    data
  })
}

export function collectDetail(data) {
  return request({
    url: url.collectDetail,
    method: 'POST',
    data
  })
}

export function collectUpdate(data) {
  return request({
    url: url.collectUpdate,
    method: 'POST',
    data
  })
}

export function collectLeads(data) {
  return request({
    url: url.collectLeads,
    method: 'POST',
    data
  })
}

