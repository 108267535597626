import Vue from 'vue'
import { paymentEnquiry, paymentInquiry, paymentReceive, paymentReceiveExport, paymentRefund, paymentRefundApply, paymentRefundQuery,paymentReceiveQuery } from '@/api/payment'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    paymentEnquiryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentEnquiry(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentInquiryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentInquiry(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentReceiveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentReceive(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentReceiveExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentReceiveExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentRefundApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentRefundApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentRefundQueryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentRefundQuery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentReceiveQueryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentReceiveQuery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
