<template>
  <div class="l-map">
    <a-input v-model="newValue" @blur="handleChange" :id="`mapInput`"/>
    <div class="map-wrap" :id="`map`"></div>
  </div>
</template>

<style scoped>
.map-wrap {
  height: 150px;
}
</style>


<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap';

export default {
  name: 'LMap',
  inject: ['parent'],
  props: {
    valueData: Object,
  },
  data() {
    return {
      newValue: ''
    }
  },
  watch: {
    valueData() {
      this.newValue = this.valueData.address
    }
  },
  mounted() {
    let { valueData } = this
    const that = this
    lazyAMapApiLoaderInstance.load().then(() => {
      let pos = []
      const autocomplete = new AMap.Autocomplete({
          input: `mapInput`
      })
      if (valueData.lng) {
        this.newValue = this.valueData.address
        pos = [valueData.lng, valueData.lat]
        const map = new AMap.Map(`map`, {
          resizeEnable: true,
          center:pos
        })
        var clickHandler = function(e) {
          console.log(e)
          const geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress(e.lnglat, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
                that.newValue = result.regeocode.formattedAddress
                marker.setPosition([e.lnglat.lng,e.lnglat.lat])
                that.parent.changeMap({
                  name: result.regeocode.formattedAddress,
                  lng: e.lnglat.lng,
                  lat: e.lnglat.lat
                })
            }
          })
        };
        // 绑定事件
        map.on('click', clickHandler);

        const marker = new AMap.Marker({
          position: pos,
          draggable: true,
          cursor: 'move',
          raiseOnDrag: true
        });
        map.setZoom(16)
        marker.setMap(map)

        // 选择搜索
        AMap.event.addListener(autocomplete, 'select', e => {
          this.newValue = e.poi.name
          map.setZoom(15)
          map.setCenter(e.poi.location)
          marker.setPosition(e.poi.location)
          this.parent.changeMap({
            name: e.poi.name,
            lng: e.poi.location.lng,
            lat: e.poi.location.lat
          })
        })
        // 拖放地址
        AMap.event.addListener(marker, 'dragend', e => {
          const geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress(e.lnglat, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              this.newValue = result.regeocode.formattedAddress
              this.parent.changeMap({
                name: result.regeocode.formattedAddress,
                lng: e.lnglat.lng,
                lat: e.lnglat.lat
              })
            }
          })
        })
      }else{
        console.log('lallalallallalal')
        const geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,
            timeout: 10000,
            zoomToAccuracy: true,     
            buttonPosition: 'RB'
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', (a)=>{
            pos = [a.position.lng,a.position.lat]
            this.newValue = a.formattedAddress
            that.parent.changeMap({
              name: a.formattedAddress,
              lng: a.position.lng,
              lat: a.position.lat
            })
            const map = new AMap.Map(`map`, {
                resizeEnable: true,
                center:pos
            })
            const marker = new AMap.Marker({
                position: pos,
                cursor: 'move',
            });
            map.setZoom(16)
            marker.setMap(map)
            var clickHandler = function(e) {
              const geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: 'all'
              })
              geocoder.getAddress(e.lnglat, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                    that.newValue = result.regeocode.formattedAddress
                    marker.setPosition([e.lnglat.lng,e.lnglat.lat])
                    that.parent.changeMap({
                      name: result.regeocode.formattedAddress,
                      lng: e.lnglat.lng,
                      lat: e.lnglat.lat
                    })
                }
              })
            }
            // 绑定事件
            map.on('click', clickHandler);

            // 选择搜索
            AMap.event.addListener(autocomplete, 'select', e => {
              this.newValue = e.poi.name
              map.setZoom(15)
              map.setCenter(e.poi.location)
              marker.setPosition(e.poi.location)
              this.parent.changeMap({
                name: e.poi.name,
                lng: e.poi.location.lng,
                lat: e.poi.location.lat
              })
            })
            // 拖放地址
            AMap.event.addListener(marker, 'dragend', e => {
              const geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: 'all'
              })
              geocoder.getAddress(e.lnglat, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  this.newValue = result.regeocode.formattedAddress
                  this.parent.changeMap({
                    name: result.regeocode.formattedAddress,
                    lng: e.lnglat.lng,
                    lat: e.lnglat.lat
                  })
                }
              })
            })
        })
        AMap.event.addListener(geolocation, 'error', (a)=>{
          console.log(a)
            this.newValue = a.formattedAddress
            that.parent.changeMap({
              name: '',
              lng: '',
              lat: ''
            })
            const map = new AMap.Map(`map`, {
                resizeEnable: true
            })
            const marker = new AMap.Marker({
                cursor: 'move',
            });
            map.setZoom(16)
            marker.setMap(map)
            var clickHandler = function(e) {
              const geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: 'all'
              })
              geocoder.getAddress(e.lnglat, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                    that.newValue = result.regeocode.formattedAddress
                    marker.setPosition([e.lnglat.lng,e.lnglat.lat])
                    that.parent.changeMap({
                      name: result.regeocode.formattedAddress,
                      lng: e.lnglat.lng,
                      lat: e.lnglat.lat
                    })
                }
              })
            }
            // 绑定事件
            map.on('click', clickHandler);

            // 选择搜索
            AMap.event.addListener(autocomplete, 'select', e => {
              this.newValue = e.poi.name
              map.setZoom(15)
              map.setCenter(e.poi.location)
              marker.setPosition(e.poi.location)
              this.parent.changeMap({
                name: e.poi.name,
                lng: e.poi.location.lng,
                lat: e.poi.location.lat
              })
            })
            // 拖放地址
            AMap.event.addListener(marker, 'dragend', e => {
              const geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: 'all'
              })
              geocoder.getAddress(e.lnglat, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  this.newValue = result.regeocode.formattedAddress
                  this.parent.changeMap({
                    name: result.regeocode.formattedAddress,
                    lng: e.lnglat.lng,
                    lat: e.lnglat.lat
                  })
                }
              })
            })
        })
      }
    })
  },
  methods: {
    handleChange(e) {
      // console.log(this.parent.mapData.name)
      // this.newValue = this.parent.mapData.name
    }
  },
}
</script>
