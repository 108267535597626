import { render, staticRenderFns } from "./bjdt.vue?vue&type=template&id=4ef8c782&scoped=true&"
import script from "./bjdt.vue?vue&type=script&lang=js&"
export * from "./bjdt.vue?vue&type=script&lang=js&"
import style0 from "./bjdt.vue?vue&type=style&index=0&id=4ef8c782&lang=scss&scoped=true&"
import style1 from "./bjdt.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef8c782",
  null
  
)

export default component.exports