import Vue from 'vue'
import { surveyCenter,surveyCenterAdd,surveyCenterDetail,surveyCenterUpdate,surveyCenterDelete,surveyCenterFormdata,surveyQuestionAdd,
  surveyQuestionDetail,surveyQuestionUpdate,surveyQuestionType,surveyQuestionValidate,surveyQuestionDelete,surveyQuestionAddAnswer,surveyQuestionUpdateAnswer,
  surveyQuestionDeleteAnswer, surveyCenterExport, } from '@/api/survey'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    surveyCenterAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenter(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyCenterAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyCenterDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyCenterUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyCenterDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    surveyCenterFormdataAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterFormdata(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    surveyCenterExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyCenterExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionValidateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionValidate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionAddAnswerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionAddAnswer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionUpdateAnswerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionUpdateAnswer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    surveyQuestionDeleteAnswerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        surveyQuestionDeleteAnswer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
