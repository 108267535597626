import url from '@/utils/URL'
import request from '@/utils/request'

export function sopIndex(data) {
  return request({
    url: url.sopIndex,
    method: 'POST',
    data
  })
}

export function sopAdd(data) {
  return request({
    url: url.sopAdd,
    method: 'POST',
    data
  })
}

export function sopDelete(data) {
  return request({
    url: url.sopDelete,
    method: 'POST',
    data
  })
}

export function sopDetail(data) {
  return request({
    url: url.sopDetail,
    method: 'POST',
    data
  })
}

export function sopGroup(data) {
    return request({
      url: url.sopGroup,
      method: 'POST',
      data
    })
}

export function sopGroupSort(data) {
    return request({
      url: url.sopGroupSort,
      method: 'POST',
      data
    })
}

export function sopAddGroup(data) {
    return request({
      url: url.sopAddGroup,
      method: 'POST',
      data
    })
}

export function sopUpdateGroup(data) {
    return request({
      url: url.sopUpdateGroup,
      method: 'POST',
      data
    })
}


export function sopDeleteGroup(data) {
    return request({
      url: url.sopDeleteGroup,
      method: 'POST',
      data
    })
}