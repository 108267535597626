import url from '@/utils/URL'
import request from '@/utils/request'

export function labsRss(data) {
  return request({
    url: url.labsRss,
    method: 'POST',
    data
  })
}

export function labsRssAdd(data) {
  return request({
    url: url.labsRssAdd,
    method: 'POST',
    data
  })
}

export function labsRssDelete(data) {
  return request({
    url: url.labsRssDelete,
    method: 'POST',
    data
  })
}

export function labsRssClick(data) {
  return request({
    url: url.labsRssClick,
    method: 'POST',
    data
  })
}

export function labsRssChannel(data) {
  return request({
    url: url.labsRssChannel,
    method: 'POST',
    data
  })
}

export function labsRssDeleteChannel(data) {
  return request({
    url: url.labsRssDeleteChannel,
    method: 'POST',
    data
  })
}