<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer="null" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          
          <a-form-item v-if="order.contract_can_rollback && isFinance">
            <a-button icon="close" @click="deleteItem">订单作废</a-button>
          </a-form-item>

          <!-- <a-form-item>
            <a-button icon="file" type="primary">打印收款收据</a-button>
          </a-form-item> -->
          <a-form-item v-if="isFinance">
            <a-button icon="download" :loading="downLoading" @click="download" type="primary">下载pdf</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div ref="printMe" class="receipt_detail" >
      <div class="hide_info" style="display: block; padding-top: 15px;">
          <div class="print_info">
              <div class="clearfix row receipt_title">
                  <div class="col-sm-4">
                      <div>
                          学员名称：<span class="cost_nickname" v-text="order.student_name"></span>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div>
                          联系电话: <span class="cost_saleuser" v-text="order.student_cellphone"></span>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div>
                          经办人：<span class="cost_saleuser" v-text="order.course_consultant_name"></span>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div>
                          收据编号：<span class="cost_saleuser" v-text="order.contract_no"></span>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div>
                          签约校区：<span class="cost_saleuser" v-text="order.studio_name"></span>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div>
                          备注：<span class="cost_saleuser" v-text="order.contract_remark"></span>
                      </div>
                  </div>
              </div>
              <div class="table-responsive receipt_table">
                  <p>课程信息</p>
                  <table border="1" class="table">
                      <thead>
                      <tr>
                          <th>课程名称</th>
                          <th>应收金额</th>
                          <th>优惠金额</th>
                          <th>实收金额</th>
                          <th>实收金额（大写）</th>
                          <th>业绩归属</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) of order.contract" :key="index">
                          <td><span class="cost_cardtitle">{{ item.course_cate_name }}</span></td>
                          <td><span class="cost_cardtimes">{{ item.contract_origin_costs }}</span></td>
                          <td><span class="cost_card_balances">{{ item.contract_discount }}</span></td>
                          <td><span class="cost_cardgift">{{ item.contract_costs || 0 }}</span></td>
                          <td><span class="cost_cardsign">{{ item.contract_costs_cn }}</span></td>
                          <td><span class="cost_card_start_time">{{ item.coursecon_sultant }}</span></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div class="table-responsive receipt_table" v-if="item.length>0">
                  <p>其他物品信息</p>
                  <table border="1" class="table">
                      <thead>
                      <tr>
                          <th>收费名称</th>
                          <th>个数</th>
                          <th>单价</th>
                          <th>优惠金额</th>
                          <th>实收金额</th>
                          <th>实收金额（大写）</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) of order.incidental" :key="index">
                          <td><span class="cost_cardtitle">{{ item.incidental_name }}</span></td>
                          <td><span class="cost_cardtimes">{{ item.incidental_count }}</span></td>
                          <td><span class="cost_card_balances">{{ item.incidental_origin_price }}</span></td>
                          <td><span class="cost_cardgift">{{ item.incidental_discount }}</span></td>
                          <td><span class="cost_cardgift">{{ item.incidental_price }}</span></td>
                          <td><span class="cost_cardgift">{{ item.incidental_price_cn }}</span></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div class="table-responsive receipt_table">
                  <p>交费记录</p>
                  <table border="1" class="table">
                      <thead>
                      <tr>
                          <th>交费时间</th>
                          <th>付款方式</th>
                          <th>总金额</th>
                          <th>总金额（大写）</th>
                          <th>经办人</th>
                          <th>状态</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <td><span class="creat_time">{{ order.trade_date }}</span></td>
                          <td><span class="payment_type">{{ order.trade_type }}</span></td>
                          <td><span class="cost_saleuser">{{ order.total_price }}</span></td>
                          <td><span class="cost_totalprice">{{ order.total_price_cn }}</span></td>
                          <td><span class="cost_totalprice">{{ order.created_by }}</span></td>
                          <td>{{ order.trade_status }}</td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import C_ITEMS from '@/utils/items'
import moment from 'moment'
import htmlToPdf from '@/utils/htmlToPdf'

export default {
  name: 'OrderModal',
  inject: ['parent'],
  props: {
    item: Object,
    isFinance:Number
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      loading:false,
      confirmLoading: false,
      downLoading: false,
      visible: false,
      ModalTitle: '订单详情',
      order: {
          contract: [],
          incidental: [],
      },
    }
  },

  created() {
    this.visible = true
    this.getDetail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail() {
      this.loading=true;
      let { out_trade_no, contract_no } = this.item // 202004092311256360
      let res = await this.$store.dispatch('contractDetailAction', {data: {contract_no: out_trade_no||contract_no }})
      this.order = res.data.order
      this.loading=false;
    },
    async handleOk() {
      this.confirmLoading=true;
      this.parent.hideOrderModal(0)
    },
    handleCancel() {
      this.parent.hideOrderModal(0)
    },
    
    deleteItem() {
      let {item} = this
      let that = this
      this.$confirm({
        title: `您确定要废除吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        zIndex:'4210',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('contractRollbackAction', { data: { out_trade_no: item.out_trade_no || item.contract_no } })
            if (res) {
              resolve(res)
              that.parent.hideOrderModal(1)
              that.$message.success('操作成功!')
            }
          }).catch(error => console.log(error))
        }
      })
    },

    download() {
      let that = this
      this.downLoading = true
      setTimeout(()=>{
        htmlToPdf.downloadPDF(that.$refs.printMe, `${that.item.student_name}-${that.item.contract_no}`)
        that.downLoading = false
      },100)
    },
  }
}
</script>
