<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
      <div class="messages-send">
        <div style="margin-bottom:10px" v-if="!classId">
            <a-select style='width:100%' placeholder="请选择班级" v-model="class_id" showSearch :filterOption="filterOption" mode="multiple" @search="handleSearch">
                <a-select-option v-for="(item, index) of category" :key="index" :value="item.class_id">{{ item.filter_name }}</a-select-option>
            </a-select> 
        </div>
        <div style="margin-bottom:10px">
            <a-input v-model="homework_title" placeholder="请输入作业标题"/>
        </div>
        <div>
            <a-mentions v-model="mentionsValue" rows="4" placeholder="请输入作业内容"></a-mentions>
        </div>
        <div class="messages-send-icon">
            <div class="messages-send-iconL">
                <a-upload
                    name="file" 
                    multiple
                    class="messages-send-upload"
                    :data="uploadParams"
                    :action="uploadUrl" 
                    :beforeUpload="beforeImageUpload"
                    :showUploadList="false"
                    listType="picture"
                    @change="changeFile">
                    <a-button>
                        <a-icon type="picture" /> 图片
                    </a-button>
                </a-upload> 
                <a-upload 
                    name="file"
                    multiple
                    class="messages-send-upload"
                    :data="uploadParams"
                    :action="uploadUrl" 
                    :beforeUpload="beforeUpload"
                    :showUploadList="false"
                    @change="changeFile2">
                    <a-button>
                        <a-icon type="sound" /> 音频
                    </a-button>
                </a-upload>
                <a-upload 
                    name="file"
                    multiple
                    class="messages-send-upload"
                    :data="uploadParams"
                    :action="uploadUrl" 
                    :beforeUpload="beforeUpload"
                    :showUploadList="false"
                    @change="changeFile3">
                    <a-button>
                        <a-icon type="video-camera" /> 视频
                    </a-button>
                </a-upload>
                <div>
                    <a-popover v-model="zlVisible" title="" trigger="click" placement="bottom">
                        <div slot="content">
                            <a-select allowClear placeholder="请选择" v-model="messageKnowledge" style="width:150px" showSearch :filterOption="filterOption" @search="handleSearchZL">
                                <a-select-option v-for="(item, index) of zlList" :key="index" :value="item.id">{{ item.file_name }}</a-select-option>
                            </a-select>
                        </div>
                        <a-button>
                            <a-icon type="file" /> 资料
                        </a-button>
                    </a-popover>
                </div>
            </div>
            <div>
                <a-button @click="toSend" :loading="btnLoading" type="primary">发布</a-button>
            </div>
        </div>
        <div>
            <div v-for="(item, idx) of fileList2" :key="idx" class="messages-send-audio">
                <audio :src="`http://v01.wedots.cn/${item.response.key}`" controls />
                <a-icon @click="delUpload(idx,'fileList2')" class="messages-send-audio-icon" type="delete" />
            </div>
            <div v-if="audioLoading" class="messages-send-audio-loading"><vue-element-loading :active="audioLoading" color="#00cca2" spinner="spinner"/></div>
        </div>
        <div class="messages-send-img">
            <div class="messages-send-imgBox" v-for="(item,index) in fileList" :key="index">
                <img :preview="index" :src="`http://i01.wedots.cn/${item.response.key}`">
                <div @click.stop="delUpload(index,'fileList')" class="messages-send-close">
                    <a-icon type="close" />
                </div>
            </div>
            <div @click="showVideoModal(`http://v01.wedots.cn/${video.response.key}`)" v-for="(video, idx) of fileList3" :key="idx + 100"  class="messages-send-video">
                <video style="background:#000;border-radius:2px" :src="`http://v01.wedots.cn/${video.response.key}`"  width="60" height="60">
                    Your browser does not support the video tag.
                </video>
                <div class="messages-send-video-icon">
                    <a-icon color='#fff' theme='filled' :style="{ fontSize: '30px', color: '#fff' }" type="play-circle" />
                </div>
                <div @click.stop="delUpload(index,'fileList3')" class="messages-send-close">
                    <a-icon type="close" />
                </div>
            </div>
            <div v-if="upLoadLoading" class="messages-send-loading">
                <vue-element-loading :active="upLoadLoading" color="#00cca2" spinner="spinner"/>
            </div>
        </div>
      </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    className:String,
    classId:Number
  },

  data() {
    return {
      btnLoading: false,
      upLoadLoading: false,
      audioLoading: false,
      confirmLoading: false,
      visible: false,
      zlVisible: false,
      ModalTitle: '新增作业',
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      fileList2: [],
      fileList3: [],
      zlList: [],
      mentionsValue:'',
      homework_title:`${moment().format('MM月DD日')}作业`,
      messageKnowledge:undefined,
      class_id:[],
      category:[],
    }
  },

  async created() {
    this.visible = true
    this.getCate()
    this.getZlList()
  },
  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getZlList(val) {
      let res = await this.$store.dispatch('searchLibraryAction', {params:{q:val}})
      this.zlList = res.data
    },
    async getCate(val) {
      let res = await this.$store.dispatch('searchClassesAction',{params:{q:val}})
      this.category = res.data
    },
    handleOk(){},
    handleCancel() {
      this.parent.hideModal()
    },
    async beforeImageUpload(file) {
        if (!file) { return false }
        let res = await this.$store.dispatch('imageTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            return true
        }
        return false
    },
    async beforeUpload(file) {
        if (!file) { return false }
        let res = await this.$store.dispatch('massTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            return true
        }
        return false
    },
    changeFile({ file, fileList }) {
        this.upLoadLoading = true
        if(file.status == "done"){
            this.fileList.push(file)
            this.upLoadLoading = false
        }
        console.log(file,fileList)
    },
    changeFile2({ file, fileList }) {
        this.audioLoading = true
        if(file.status == "done"){
            this.fileList2.push(file)
            this.audioLoading = false
        }
        console.log(fileList)
    },
    changeFile3({ file, fileList }) {
        this.upLoadLoading = true
        if(file.status == "done"){
            this.fileList3.push(file)
            this.upLoadLoading = false
        }
        console.log(fileList)
    },
    delUpload(index,name){
        this[name].splice(index,1)
    },
    handleSearch(val){
      this.getCate(val)
    },
    handleSearchZL(val){
      this.getZlList(val)
    },
    async toSend(){
        let params = {}
        if(!this.classId && this.class_id.length == 0){
            this.$message.warning('请选择班级!!')
            return false
        }
        if(!this.homework_title){
            this.$message.warning('请输入作业标题!!')
            return false
        }
        if(!this.mentionsValue){
            this.$message.warning('请输入作业内容!!')
            return false
        }
        params.homework_title = this.homework_title
        params.homework_desc = this.mentionsValue
        if (this.fileList.length) {
            let arr = []
            for (let file of this.fileList) {
                if (file.response.key) {
                    arr.push(file.response.key)
                }
            }
            params.homework_images = arr
        }
        if (this.fileList2.length) {
            let arr = []
            for (let file of this.fileList2) {
                if (file.response.key) {
                    arr.push(file.response.key)
                }
            }
            params.homework_audios = arr
        }
        if (this.fileList3.length) {
            let arr = []
            for (let file of this.fileList3) {
            if (file.response.key) {
                arr.push(file.response.key)
            }
            }
            params.homework_videos = arr
        }
        if(this.messageKnowledge){
            params.homework_knowledge = this.messageKnowledge
        }
        if(this.classId){
            params.class_id = this.classId
        }else{
            params.class_id = this.class_id
        }
        this.btnLoading = true
        await this.$store.dispatch('homeworkPublishAction', { data: params })
        .then(res=>{
            this.$message.success('操作成功~')
            this.btnLoading = false
            if(this.classId){
                this.parent.submitModal('3')
            }else{
                this.parent.hideModal(1)
            }
            
        })
        .catch(err=>{
            this.btnLoading = false
            this.$message.error('操作失败~')
        })
    },
  }
}
</script>
<style lang="scss" scoped>
  .messages-send{
    width: 100%;
    margin: 0 auto;
    background: #fff;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    textarea{
        resize: none;
    }
    &-icon{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
    }
    &-iconL{
        display: flex;
        align-items: center;
        .ant-btn{
            border: none;
            box-shadow:none;
            padding: 0px;
        }
    }
    &-upload{
        margin-right: 15px;
    }
    &-img{
        display: flex;
        flex-wrap: wrap;
        img{
            height: 60px;
        }
    }
    &-imgBox{
        margin-right: 5px;
        margin-bottom: 5px;
        position: relative;
    }
    &-audio{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        &-icon{
            margin-left: 10px;
            font-size: 24px;
            cursor: pointer;
        }
        &-loading{
            width: 300px;
            height: 54px;
        }
    }
    &-video{
        display: inline-block;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }
    &-video-icon{
        position: absolute;
        top: 15px;
        left: 15px;
        background: #000;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
    }
    &-close{
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(250,250,250,.5);
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
    }
    &-loading{
        width: 60px;
        height: 60px;
    }
  }
</style>