<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>上课日期</span>
                </template>
                  <a-range-picker style="width:240px" :ranges="rangesData" allowClear v-model="searchData.class_date" @canplay="handleTime" :placeholder="['开始时间', '结束时间']"/>
                </a-tooltip>
            </a-form-item>

            <a-form-item>
              <a-radio-group :value="searchParams.search.book_status">
                <a-radio-button @click="handleTypeButChange('1')" value="1">
                    待上课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('2')" value="2">
                    已上课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('0')" value="0">
                    已请假
                </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
            </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{index+1}}</span>
        </template>
        <template slot="classDate" slot-scope="text, record">
            <span>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
        </template>
        <template slot="images" slot-scope="text, record">
            <LImg :preview="0" v-for="(image, index) of record.cancel_image" :key="index"  
                :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
          </template>
          </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '上课时间',width: '200px', dataIndex: 'class_start',scopedSlots: { customRender: 'classDate' } , key: 'class_start'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name',ellipsis:true},
  { title: '班级名称', dataIndex: 'classes_name', key: 'classes_name',ellipsis:true},
  { title: '课程名称', dataIndex: 'course_name', key: '1' ,ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_name', key: '3' ,align:"center"},
  { title: '课时消耗', dataIndex: 'book_costs', key: '7',align:"center"},
  { title: '上课状态', dataIndex: 'book_status', key: '8',align:"center"},
  { title: '请假理由', dataIndex: 'cancel_reason', key: '9' },
  { title: '请假详情', dataIndex: 'cancel_remark', key: '10' },
  { title: '请假凭证', dataIndex: 'cancel_image', key: '11', scopedSlots: { customRender: 'images' } },
  { title: '请假时间', dataIndex: 'canceled_at', key: '12' },
//   { title: '备注', dataIndex: 'book_remark', key: '5' ,ellipsis:true},
]

import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'

export default {
  name: 'bjgl',
  mixins: [ tableMixins, ranges ],

  props: {
    id: Number
  },

  data() {
    return {
      loading: false,
      columns,
      searchData:{},
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
    }
  },

  created() {
  },

  methods: {
    moment,
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.class_id = this.id
      let res = await this.$store.dispatch('classesAttendAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleTypeButChange(e){
      if(this.searchData.book_status == e){
        this.searchData.book_status = undefined
      }else{
        this.searchData.book_status = e
      }
      this.getList()
    },
    handleTime(){
      this.$forceUpdate()
    },
  }
}
</script>
