<template>
  <a-modal title="新增DEMO课" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="450px" :zIndex='4101'
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
        <a-form-item v-for="(item, index) of formData" :key="index" 
            :label="item.label">
            <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
            <a-select :dropdownStyle='{zIndex:4200}' v-else-if="item.type === 'select'" style='width:100%' placeholder="请选择" @focus="handleRoom"
            v-decorator="[item.name, { rules: item.rules }]" allowClear showSearch :filterOption="filterOption">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>
            <a-select :dropdownStyle='{zIndex:4200}' v-else-if="item.type === 'select_studio'" @change="handleStudio" style='width:100%' placeholder="请选择"
            v-decorator="[item.name, { rules: item.rules }]" allowClear showSearch :filterOption="filterOption">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>
            <a-date-picker style='width:100%' :popupStyle='{zIndex:4102}' v-else-if="item.type === 'time'" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }" v-decorator="[item.name, { rules: item.rules }]" />
        </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import moment from 'moment'

const formData = [
   {
    name: 'studio_id',
    label: '所属校区',
    type: 'select_studio',
    rules: [{ required: true, message: '请选择校区!' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'course_id',
    label: '课程名称',
    type: 'select',
    rules: [{ required: true, message: '请选择课程!' }],
    items: {
      data: 'courses',
      label: 'filter_name',
      value: 'course_id'
    }
  },
  {
    name: 'teacher_id',
    label: '授课老师',
    type: 'select',
    rules: [{ required: true, message: '请选择授课老师!' }],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'room_id',
    label: '上课教室',
    type: 'select',
    rules: [{ required: true, message: '请选择上课教室!' }],
    items: {
      data: 'rooms',
      label: 'filter_name',
      value: 'room_id'
    }
  },
  {
    name: 'start_date',
    label: '上课时间',
    type: 'time',
    rules: [{ required: true, message: '请选择上课时间!' }],
  },
]
export default {
  name: 'EditModal',
  mixins: [ editModal ],
  props:['classId'],
  data() {
    return {
      formData,
      confirmLoading:false,
      studio_id:'',
      seleteItems: {
        studios:[],
        courses: [],
        teachers: [],
        rooms: [],
      },
    }
  },
  async created() {
    this.visible = true
    this.getStudio()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.studios = res.data
    },
    async getCourseType() {
      let res = await this.$store.dispatch('searchDemoCourseAction', {params:{studio_id:this.studio_id}})
      this.seleteItems.courses = res.data
    },
    async getTeachers() {
      let res = await this.$store.dispatch('searchTeacherAction', {params:{studio_id:this.studio_id}})
      this.seleteItems.teachers = res.data
    },
    async getRoom(item) {
      let res = await this.$store.dispatch('searchStudioRoomAction', {params:{studio_id:this.studio_id}})
      this.seleteItems.rooms = res.data
    },
    async handleOk() {
      const { item, form } = this
      let params = await form.validateFields()
      if(params.start_date){
        params.start_date = moment(params.start_date).format('YYYY-MM-DD HH:mm')
      }
      this.confirmLoading = true
      await this.$store.dispatch('auditionAddDemoScheduleAction', {data:params})
        .then(res=>{
            this.$message.success('操作成功~')
            this.parent.hideEditModal(1)
        })
        .catch(err=>{
            this.$message.error('操作失败~')
            this.confirmLoading = false
        })
    },
    handleCancel(){
        this.parent.hideEditModal()
    },
    handleStudio(e){
        if(e){
            this.studio_id = e
            this.getRoom()
            this.getCourseType()
            this.getTeachers()
        }
    },
    handleRoom(){
        if(!this.studio_id){
            this.$message.warning('请先选择校区!!')
        }
    }

  }
}
</script>

<style lang="scss">
.slots-example {
  &__meeting-selector {
    max-width: 542px;
  }
}
.title {
  margin: 0 0px;
}
.meeting{
  .meeting__button {
    display: inline-block;
    padding: 5px;
    margin: 5px 0;
    background-color: #845EC2;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    &--selected {
      background-color: #B39CD0;
    }
    &--empty {
      display: inline-block;
      padding: 5px;
      margin: 5px 0;
      cursor: not-allowed;
    }
  }
}
.button-pagination {
  border: none;
  padding: 0;
  width: 30px;
}
// since our scss is scoped we need to use ::v-deep
::v-deep .loading-div {
  top: 32px!important;
}
.addSelf{
  border:1px solid #d9d9d9;
  border-radius:4px;
  margin-bottom:10px;
  position:relative;
  .ant-form-item{
    margin-bottom:4px
  };
  .addSelf-del{
    position:absolute;
    right:-2px;
    top:-9px;
    display:flex;
    background-color:#fff;
    cursor:pointer;
  }
}
</style>