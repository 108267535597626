<template>
    <a-drawer placement="right" title="" :footer="null" :visible="visible" :mask="false" wrapClassName="crmDrawer"
     cancelText="取消" okText="保存" width="50%" @close="handleCancel" :zIndex='4000'>
        <div @click="colseTime" class='crmfollow'>
            <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div class="crmfollow-nav">
                <a-row>
                    <a-col :span="8">
                        <span style="font-size:24px" v-if="isTab == '0'">
                            <span v-if="leads_detail.year">{{detailData.cule_name}}({{leads_detail.year}}岁)</span>
                            <span v-else>{{detailData.cule_name}}</span>
                            <svg v-if="detailData.leads_detail" style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="detailData.leads_detail.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="detailData.leads_detail.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg>
                        </span>
                        <span style="font-size:24px"  v-if="isTab == '1'">
                            <span v-if="customer_detail.year">{{detailData.customer_name}}({{customer_detail.year}}岁)</span>
                            <span v-else>{{detailData.customer_name}}</span>
                            <svg v-if="detailData.customer_detail" style="width: 16px;height: 16px;margin-left: 6px;" aria-hidden="true">
                                <use v-show="detailData.customer_detail.gender == 2" xlink:href="#icon-sex_woman"></use>
                                <use v-show="detailData.customer_detail.gender == 1" xlink:href="#icon-sex_man"></use>
                            </svg>
                        </span>
                    </a-col>
                    <a-col style="text-align:right;padding-right:20px" :span="16">
                        <a-button @click="handleMenuClick({key:'1'})" v-if="isTab == '0' && canTransform && leads_detail.is_transform !=1" type="primary">转为客户</a-button>
                
                        <a-tooltip v-if="canTransfer" placement="top" :overlayStyle="{zIndex:4200}">
                            <template slot="title">
                            转移
                            </template>
                            <a-button  @click="showTransfer" v-if="canTransfer" type="dashed" icon="retweet" style="margin-right: 10px;" ></a-button>
                        </a-tooltip>

                        <a-tooltip v-if="canTransfer" placement="top" :overlayStyle="{zIndex:4200}">
                            <template slot="title">
                            推送提醒
                            </template>
                            <a-button  @click="showRenew" v-if="canTransfer" type="dashed" style="margin-right: 10px;" icon="wechat"></a-button>
                        </a-tooltip>

                        <a-tooltip v-if="canUpdate" placement="top" :overlayStyle="{zIndex:4200}">
                            <template slot="title">
                            编辑
                            </template>
                            <a-button @click="showEditModal" v-if="canUpdate" type="dashed" icon="edit" style="margin-right: 10px;"></a-button>
                        </a-tooltip>
                        
                        
                        <a-tooltip v-if="isTab == '1'" placement="top" :overlayStyle="{zIndex:4200}">
                            <template slot="title">
                            试听排期
                            </template>
                            <a-button @click="showAuditionModal" type="dashed" icon="clock-circle" style="margin-right: 10px;">
                            </a-button>
                        </a-tooltip>

                        <a-tooltip placement="top" :overlayStyle="{zIndex:4200}">
                            <template slot="title">
                            置顶
                            </template>
                            <a-button @click="toTop(1)" v-if="is_star == 0" icon='star' type="dashed" style="margin-right: 10px;"></a-button>
                            <a-button @click="toTop(2)" v-if="is_star == 1" icon='star' class="crmfollow-nav-star" type="dashed" style="margin-right: 10px;"></a-button>
                        </a-tooltip>
                        
                        

                        <a-dropdown :overlayStyle='{zIndex:4200}'>
                            <a-menu slot="overlay" @click="handleMenuClick">
                                <a-menu-item @click="showEditModalYW" v-if="isTab == '1'" key="2">
                                    业务办理
                                </a-menu-item>

                                <a-menu-item @click="toPool" v-if="isTab == '1'" key="8">放入公海</a-menu-item>
                                
                                <a-menu-item v-if="isTab == '1'" @click="showAuditionModal" key="4">试听排期</a-menu-item>

                                <a-menu-item v-if="canDelete && isTab == '1'" key="7">
                                    账户同步
                                </a-menu-item>

                                <a-menu-item v-if="canDelete && isTab == '1'" key="3">
                                    删除客户
                                </a-menu-item>

                                <a-menu-item v-if="canDelete && isTab == '0'" key="3">
                                    删除线索
                                </a-menu-item>
                            </a-menu>
                            <a-button>更多<a-icon type="down" /> </a-button>
                        </a-dropdown>
                    </a-col>
                </a-row>
            </div>
            <div style="margin:15px 0">
                <a-descriptions title="" :column='4' layout="vertical">
                    <a-descriptions-item label="手机号码">
                    {{detailData.cellphone}}
                    </a-descriptions-item>

                    <a-descriptions-item label="负责人">
                        {{course_consultant_name}}
                    </a-descriptions-item>

                    <a-descriptions-item label="所属校区">
                    {{detailData.studio_name || detailData.belong_studio}}
                    </a-descriptions-item>

                    <a-descriptions-item :label="isTab == '0'?'线索来源':'来源渠道'">
                    {{detailData.source_channel}}
                    </a-descriptions-item>

                    <a-descriptions-item label="回访时间">
                        <div @click.stop="showNextTime()" v-if="!isNextTime" :class="activeIndex == 1?'crmfollow-bg':''" style="cursor:pointer;padding:0 5px" @mouseover="handleOver(1)" @mouseout="handleOut(1)">
                            {{detailData.next_time|| '--'}}
                        </div>
                        <a-date-picker
                            @click.stop
                            v-if="isNextTime"
                            :value="detailData.next_time?moment(detailData.next_time):''"
                            :show-time="{ format: 'HH:mm' }"
                            :open="isNextTime"
                            format="YYYY-MM-DD HH:mm"
                            placeholder="请选择下次回访时间"
                            @change="(a,b)=>handlePanelChange(a,b,'detailData','next_time')"
                            @ok="handleOk('detailData','next_time')"
                            :popupStyle='{zIndex:4003}'>
                                <template slot="renderExtraFooter">
                                    <a-tag @click="handleTimeTag(7,'detailData','next_time')">7天后</a-tag>
                                    <a-tag @click="handleTimeTag(30,'detailData','next_time')">30天后</a-tag>
                                    <a-tag @click="handleTimeTag(60,'detailData','next_time')">60天后</a-tag>
                                    <a-tag @click="handleTimeTag(90,'detailData','next_time')">90天后</a-tag>
                                </template>
                        </a-date-picker>
                    </a-descriptions-item>

                    <a-descriptions-item label="诺访时间" v-if="isTab == '1'">
                        <div @click.stop="showAuditionModal" v-if="!isPromiseDateTime" :class="activeIndex == 2?'crmfollow-bg':''" style="cursor:pointer;padding:0 5px" @mouseover="handleOver(2)" @mouseout="handleOut(2)">
                            {{task_detail.promise_date_time || '--'}}
                        </div>

                        <!-- <a-date-picker
                            @click.stop
                            v-if="isPromiseDateTime"
                            :value="task_detail.promise_date_time?moment(task_detail.promise_date_time):''"
                            :show-time="{ format: 'HH:mm' }"
                            :open="isPromiseDateTime"
                            format="YYYY-MM-DD HH:mm"
                            placeholder="请选择诺访时间"
                            @change="(a,b)=>handlePanelChange(a,b,'task_detail','promise_date_time')"
                            @ok="handleOk('task_detail','promise_date_time')"
                            :popupStyle='{zIndex:4003}'>
                                <template slot="renderExtraFooter">
                                    <a-tag @click="handleTimeTag(7,'task_detail','promise_date_time')">7天后</a-tag>
                                    <a-tag @click="handleTimeTag(30,'task_detail','promise_date_time')">30天后</a-tag>
                                    <a-tag @click="handleTimeTag(60,'task_detail','promise_date_time')">60天后</a-tag>
                                    <a-tag @click="handleTimeTag(90,'task_detail','promise_date_time')">90天后</a-tag>
                                </template>
                        </a-date-picker> -->

                    </a-descriptions-item>

                    <a-descriptions-item label="到访时间" v-if="isTab == '1'">
                        <div @click.stop="showAttendTime" v-if="!isAttendTime" :class="activeIndex == 3?'crmfollow-bg':''" style="cursor:pointer;padding:0 5px" @mouseover="handleOver(3)" @mouseout="handleOut(3)">
                            {{task_detail.attend_time || '--'}}
                        </div>
                        <a-date-picker
                            @click.stop
                            v-if="isAttendTime"
                            :value="task_detail.attend_time?moment(task_detail.attend_time):''"
                            :show-time="{ format: 'HH:mm' }"
                            :open="isAttendTime"
                            format="YYYY-MM-DD HH:mm"
                            placeholder="请设置实际到访时间"
                            @change="(a,b)=>handlePanelChange(a,b,'task_detail','attend_time')"
                            @ok="handleOk('task_detail','attend_time')"
                            :popupStyle='{zIndex:4003}'>
                                <template slot="renderExtraFooter">
                                    <a-tag @click="handleTimeTag(7,'task_detail','attend_time')">7天后</a-tag>
                                    <a-tag @click="handleTimeTag(30,'task_detail','attend_time')">30天后</a-tag>
                                    <a-tag @click="handleTimeTag(60,'task_detail','attend_time')">60天后</a-tag>
                                    <a-tag @click="handleTimeTag(90,'task_detail','attend_time')">90天后</a-tag>
                                </template>
                        </a-date-picker>
                    </a-descriptions-item>

                    <a-descriptions-item label="更新时间">
                        <div>
                            {{detailData.updated_time || '--'}}
                        </div>
                    </a-descriptions-item>
                </a-descriptions>

                <a-alert v-for="(item,index) in customer_detail.audition" :key="index" :show-icon="false" :message='item.short_txt' banner  />
                
            </div>

            </div>


            <div>
                <a-tabs :animated="false" :activeKey="activeKey" @change="callback">

                    <a-tab-pane key="1" tab="跟进记录">
                        <followRecord :item="items" :task_step="task_step" :taskData="task_detail" :detailData="detailData" :isRefresh="isRefresh" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>

                    <!-- <a-tab-pane v-if="isTab == '1'" key="2" tab="跟进状态">
                        <followStatus :item="items" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane> -->

                    <a-tab-pane key="3" tab="基本信息">
                        <message :item="items" :messageNum="messageNum" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>

                    <a-tab-pane key="4" tab="变更历史">
                        <editLog :item="items" :isEditLog='isEditLog' :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>

                    <a-tab-pane v-if="isTab == '1'" key="6" tab="上课记录">
                        <audition :auditionNum='auditionNum' :item="items" :leadsId='leadsId' :isTab='isTab' />
                    </a-tab-pane>

                    <a-tab-pane v-if="isTab == '1'" key="5" tab="联系人">
                        <contacts :item="items" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>

                    <a-tab-pane v-if="isTab == '1'" key="7" tab="签约记录">
                        <contract :item="items" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>

                    <a-tab-pane v-if="canWxworkMsg && isTab == '1'" key="8">
                        <span slot="tab">
                            <svg style="width:16px;height: 16px;vertical-align: middle;" aria-hidden="true">
                                <use v-show="customer_detail.is_bind_qywx == 1" xlink:href="#icon-qiyeweixin"></use>
                                <use v-show="customer_detail.is_bind_qywx == 0" xlink:href="#icon-qiyeweixin-unbind"></use>
                            </svg>
                            企微记录
                        </span>
                        <wxworkLog :item="items" :leadsId='leadsId' :isTab='isTab'/>
                    </a-tab-pane>
                    
                </a-tabs>
            </div>
        </div>
        <transfer :distribute_id='detailData.distribute_id' :search='search' :isTab='isTab' :item='items' v-if="isTransfer"/>
        <editModalCl v-if='isEditModalCl' :item='detailData' :isEdit='1' />
        <editModalCu v-if='isEditModalCu' :item='detailData' :isEdit='1' />
        <editModalYW v-if="isEditModalYW" :tab="2" :item="edModalData" :studentCellPhone="studentCellPhone"/>
        <auditionModal v-if="isAuditionModal" :item="[detailData.customer_id]" isTab="1" />
    </a-drawer>
</template>

<script>
    import moment from 'moment'
    import followRecord from './followRecord'
    import followStatus from './followStatus'
    import message from './message'
    import transfer from './transfer'
    import editLog from './editLog'
    import contract from './contract'
    import wxworkLog from './wxworkLog'
    import auditionModal from './auditionModal/index'
    import contacts from './contacts/index'
    import audition from './audition/index'
    import editModalCl from '../../clue/editModal'
    import editModalCu from '../../customer/editModal'
    import editModalYW from '@/views/students/zcxy/editModal'
    import authority from '@/common/mixins/authority'
    import C_ITEMS from '@/utils/items'


    export default {
        name:'followModal',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        props: {
            item: Object,
            leadsId: Number,
            isTab:String,
            authType:Array,
            search:Object,
        },
        mixins: [ authority ],
        components:{
            message,
            transfer,
            editLog,
            contacts,
            contract,
            audition,
            wxworkLog,
            auditionModal,
            editModalCl,
            editModalCu,
            followRecord,
            followStatus,
            editModalYW,
        },
        data(){
            return{
                loading:false,
                visible:false,
                visiblePop:false,
                isTransfer:false,
                isEditModalCl:false,
                isEditModalCu:false,
                isEditModalYW:false,
                isAuditionModal:false,
                activeKey:'1',
                detailData:{},
                isEditLog:0,
                auditionNum:1,
                messageNum:1,
                edModalData:null,
                momide:true,
                task_detail:{},
                isNextTime:false,
                isPromiseDateTime:false,
                isAttendTime:false,
                activeIndex:-1,
                course_consultant_name:"",
                intentionLevelsId:0,
                intentionLevels:C_ITEMS.intentionLevelsF,
                leads_detail:{},
                customer_detail:{},
                task_id:'',
                items:{},
                isRefresh:0,
                task_step:0,
                is_star:0,
                studentCellPhone:''
            }
        },
        async created() {
            this.task_id = this.item.task_id
            this.items = {...this.item}
            this.visible = true
            this.getDetail()
        },
        beforeDestroy() {
            this.visible = false
        },
        methods: {
            moment,
            async getDetail(){
                this.loading = true
                this.colseTime()
                let res
                if(this.isTab == '0'){
                    res = await this.$store.dispatch('cLeadsDetailAction', {task_id:this.task_id})
                    this.course_consultant_name = res.data.leads_detail.distribute_to
                    this.leads_detail = res.data.leads_detail
                    this.customer_detail=[];
                    this.task_step = res.data.task_step
                    this.is_star = res.data.leads_detail.is_star
                }else{
                    res = await this.$store.dispatch('cCustomerDetailAction', {customer_id:this.item.customer_id})
                    this.course_consultant_name = res.data.customer_detail.course_consultant_name
                    this.intentionLevelsId = res.data.task_detail.intention_level || 0
                    this.customer_detail = res.data.customer_detail
                    this.task_step = res.data.task_detail.task_step
                    this.is_star = res.data.customer_detail.is_star
                    this.wxwork_msg = res.data.customer_detail.is_star
                }
                this.task_id = res.data.task_id
                this.items.task_id = res.data.task_id
                this.detailData = res.data
                this.task_detail = res.data.task_detail
                this.loading = false
            },
            async getStudents(){
                await this.$store.dispatch('cCustomerStudentAccountAction', {task_id:this.task_id})
                .then(res=>{
                    this.edModalData = {student_id: res.data.student_id,student_name:res.data.student_name,task_id:this.task_id}
                    this.studentCellPhone = res.data.student_cellphone
                    this.isEditModalYW = true
                })
                
            },
            async toTop(num){
                let that = this
                let title = ''
                if(that.isTab == 1){
                    title = num == 1 ? '您确定将该客户置顶??':'您确定取消客户置顶??'
                }else{
                    title = num == 1 ? '您确定将该线索置顶??':'您确定取消线索置顶??'
                }
                this.$confirm({
                    title: title,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    zIndex: 4200,
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch(that.isTab == 1?'cCustomerStarAction':'cLeadsStarAction', {task_id:that.task_id})
                        .then(res=>{
                            that.$message.success('操作成功~')
                            that.getDetail()
                            that.parent.getList()
                            that.isRefresh++
                            resolve(res)
                        })
                        .catch(err=>{
                            that.$message.error('操作失败~')
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleCancel() {
                this.parent.hideFollowModal(0)
            },
            handleMenuClick(e){
                let that = this
                if(e.key == 1){
                    this.$confirm({
                        title: `确定将这些线索转换为客户吗?`,
                        okText: '确定',
                        okType: 'danger',
                        cancelText: '取消',
                        zIndex: 4200,
                        onOk() {
                        return new Promise(async (resolve, reject) => {
                            await that.$store.dispatch('cLeadsTransformAction', {task_id:that.task_id})
                            .then(res=>{
                                that.$message.success('操作成功!')
                                that.parent.hideFollowModal(1)
                                resolve(res)
                            })
                            .catch(err=>{
                                reject(err)
                            })
                        }).catch(error => console.log(error))
                        }
                    })
                }
                if(e.key == 3){
                    let title = this.isTab == '0'?'您确定删除该线索吗？':'您确定删除该客户吗？'
                    this.$confirm({
                        title: title,
                        okText: '确定',
                        okType: 'danger',
                        cancelText: '取消',
                        zIndex: 4200,
                        onOk() {
                        return new Promise(async (resolve, reject) => {
                            let res
                            if(that.isTab == '0'){
                                res = await that.$store.dispatch('cLeadsDeleteAction', {task_id:[that.task_id]})
                            }else{
                                res = await that.$store.dispatch('cCustomerDeleteAction', {task_id:[that.task_id]})
                            }
                            if (res) {
                            that.$message.success('操作成功!')
                            that.parent.hideFollowModal(1)
                            resolve(res)
                            }
                        }).catch(error => console.log(error))
                        }
                    })
                }
                if(e.key=='7'){
                    return new Promise(async (resolve, reject) => {
                        await this.$store.dispatch('cCustomerRefreshAction', {task_id:this.task_id})
                        .then(res=>{
                            that.$message.success('同步成功~')
                        })
                    }).catch(error => console.log(error))
                    
                }
            },
            callback(key) {
                this.activeKey = key
            },
            showTransfer(){
                this.isTransfer = true
            },
            showRenew(){
                let that = this
                let name = ''
                let urlApi = ''
                if(this.isTab == '0'){
                    name = '线索'
                    urlApi = 'cLeadsRenewAction'
                }else{
                    name = '客户'
                    urlApi = 'cCustomerRenewAction'
                }
                return new Promise(async (resolve, reject) => {
                            await that.$store.dispatch(urlApi, { task_id:[that.items.task_id] })
                            .then(res=>{
                                that.$message.success('操作成功~')
                                that.parent.getList()
                                // this.getDetail()
                                that.isRefresh++
                                resolve(res)
                            })
                            .catch(err=>{
                                that.$message.error('操作失败~')
                                reject(err)
                            })
                        }).catch(error => console.log(error))

                // this.$confirm({
                //         title: `您确定renew该${name}？`,
                //         okText: '确定',
                //         okType: 'danger',
                //         cancelText: '取消',
                //         zIndex: 4200,
                //         onOk() {
                //         return new Promise(async (resolve, reject) => {
                //             await that.$store.dispatch(urlApi, { task_id:[that.items.task_id] })
                //             .then(res=>{
                //                 that.$message.success('操作成功~')
                //                 that.parent.getList()
                //                 resolve(res)
                //             })
                //             .catch(err=>{
                //                 that.$message.error('操作失败~')
                //                 reject(err)
                //             })
                //         }).catch(error => console.log(error))
                //         }
                //     })
            },
            hideTransfer(num){
                this.isTransfer = false
                if(num == 1){
                    this.parent.hideFollowModal(1)
                }
            },
            showEditModal(){
                if(this.isTab == '0'){
                    this.isEditModalCl = true
                }else{
                    this.isEditModalCu = true
                }
            },
            hideEditModal(val){
                if(val){
                    this.getDetail()
                    this.parent.getList()
                    this.isEditLog++
                    this.messageNum++
                }
                if(this.isTab == '0'){
                    this.isEditModalCl = false
                }else{
                    this.isEditModalCu = false
                }
            },
            showEditModalYW(){
                this.getStudents()
            },
            hideModal(val){
                if(val){
                    this.isRefresh++
                }
                this.isEditModalYW = false
            },
            getList(){},
            showNextTime(){
                this.isNextTime = true
            },
            showPromiseDateTime(){
                this.isPromiseDateTime = true
            },
            showAttendTime(){
                this.isAttendTime = true
            },
            handlePanelChange(a,b,name,val){
                this[name][val] = b
            },
            colseTime(){
                this.isNextTime = false
                this.isPromiseDateTime = false
                this.isAttendTime = false
            },
            async handleOk(name,val){
                this.isNextTime = false
                this.isPromiseDateTime = false
                this.isAttendTime = false
                if(this[name][val]){

                let res = await this.$store.dispatch(this.isTab == '0'?'cLeadsTaskUpdateAction':'cCustomerTaskUpdateAction', {[val]:this[name][val],task_id:this.task_id}).then(res=>{
                  this.$message.success('操作成功~')
                  this.getDetail()
                  this.confirmLoading = false
                })
                .catch(err=>{
                  this.$message.error('操作失败~')
                  this.confirmLoading = false
                })}
            },
            handleTimeTag(num,name,val){
                this[name][val] = moment().add(num, 'd').format("YYYY-MM-DD HH:mm:ss")
            },
            handleOver(val){
                this.activeIndex = val
            },
            handleOut(){
                this.activeIndex = -1
            },
            async changeintentionLevels(item){
                this.intentionLevelsId = item.value
                let params = {
                    intention_level:item.value,
                    task_id:this.task_id
                }
                let res = await this.$store.dispatch('cCustomerTaskUpdateAction', params)
                this.parent.getList()
                this.$message.success('操作成功~')
            },
            toPool(){
                let that = this
                this.$confirm({
                    title: '您确定把该客户放入公海吗?',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    zIndex: 4200,
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('cCustomerPutinPoolAction', {task_id:[that.task_id]})
                        if (res) {
                        that.$message.success('操作成功!')
                        that.parent.getList()
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            showAuditionModal(){
                this.isAuditionModal = true
            },
            hideAuditionModal(val){
                if(val){
                    this.auditionNum++
                    this.getDetail()
                    this.isRefresh++
                }
                this.isAuditionModal = false
            },
            refreshList(name,value){
                if(name){
                    this.parent.refreshList(name,value)
                }else{
                    this.parent.getList()
                }
                
            }
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.task_id = this.item.task_id
                this.items = {...this.item}
                this.getDetail()
            }
        },
    }
</script>

<style lang="scss" >
    .crmfollow{

        .crmfollow-nav{
           .ant-btn-primary{
                margin-right: 10px;
            } 
            &-star{
                .anticon-star{
                    color: #f6d553;
                }
            }
        }
        .crmfollow-bg{
            background: #e6fff6;
        }
        .ant-descriptions-item-label{
            color: rgba(69, 90, 100, 0.6);
        }
        .ant-descriptions-item-content{
            color: #323233;
        }
        .ant-tabs-bar{
            margin: 0 0 10px 0;
        }
    }
    .crmDrawer{
        width: 50% !important;
        .ant-drawer-open{
            width: 50% !important;
        }
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
        .ant-drawer-mask{
            height: 0 !important;
        }
    }
    .follow-intentionLevels{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        border: 1px solid;
        margin: 0 5px;
        cursor: pointer;
    }
</style>