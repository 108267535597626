import Vue from 'vue'
import { extendingAlert, extendingLimit } from '@/api/extending'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    extendingAlertAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        extendingAlert(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    extendingLimitAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        extendingLimit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
