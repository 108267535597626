const Layout = () => import('@/views/layout')
const Workservice = () => import('@/views/workservice')
const WorkserviceMine = () => import('@/views/workservice/mine')
const WorkserviceCustomer = () => import('@/views/workservice/customer')
const WorkserviceList = () => import('@/views/workservice/list')
const WorkserviceAnalysis = () => import('@/views/workservice/analysis')
const WorkserviceDetail = () => import('@/views/workservice/list/detailModal')
const WorkserviceUpgradeLog = () => import('@/views/workservice/upgradeLog')
const WorkserviceUpgradeLogEditModal = () => import('@/views/workservice/upgradeLog/editModal')

const financeRouter = {
    path: '/workservice',
    component: Layout,
    hide: true,
    parent:'workserviceRouter',
    meta: {
      title: '工单服务',
      requiresAuth: false,
      type:'workservice',
      icon: '#icon-MoneyOutline',
      menusKey:10,
    },
    children:[
          {
            path: 'mine',
            component: WorkserviceMine,
            hide:false,
            parent:'workservice',
            meta: { title: '我的工单', icon: '#icon-MoneyOutline' ,parent:'workservice'}
          },
          {
            path: 'customer',
            component: WorkserviceCustomer,
            hide:false,
            parent:'workservice',
            meta: { title: '我的客户', icon: '#icon-LikeOutline' ,parent:'workservice'}
          },
           {
            path: 'list',
            component: WorkserviceList,
            hide:false,
            parent:'workservice',
            meta: { title: '工单列表', icon:'#icon-OrderOutline' ,parent:'workservice'}
          },{
            path: 'analysis',
            component: WorkserviceAnalysis,
            hide:false,
            parent:'workservice',
            meta: { title: '工单统计', icon:'#icon-OperateOutline' ,parent:'workservice'}
          }, {
            path: 'detail',
            component: WorkserviceDetail,
            hide:true,
            parent:'workservice',
            meta: { title: '工单详情', icon:'#icon-OrderOutline' ,parent:'workservice'}
          }, {
            path: 'upgradeLog',
            component: WorkserviceUpgradeLog,
            hide:false,
            parent:'workservice',
            meta: { title: '更新日志' , icon: '#icon-QueryOutline' ,parent:'workservice',}
          }, {
            path: 'upgradeLog/add',
            component: WorkserviceUpgradeLogEditModal,
            hide:true,
            parent:'workservice',
            meta: { title: '新增日志' , icon: '#icon-QueryOutline' ,parent:'workservice',}
          }, {
            path: 'upgradeLog/update',
            component: WorkserviceUpgradeLogEditModal,
            hide:true,
            parent:'workservice',
            meta: { title: '编辑日志' , icon: '#icon-QueryOutline' ,parent:'workservice',}
          }, 
    ]
}

export default financeRouter