<template>
  <a-modal class="profileModal" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="1251px" :footer="null" 
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <template slot="title">
      <div>
        {{`学员信息 ${detail.student_name?`(${detail.student_name})`:''}`}}
        <svg @click="showQrcodeModal()" style="width: 12px;height: 12px;margin-left: 5px;cursor: pointer;" aria-hidden="true">
          <use v-show="detail.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
          <use v-show="detail.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
        </svg>
      </div>
    </template>
    <div class="clearfix table-tools" style="margin-top: -13px;">

      <a-row :gutter="16">
        
        <a-col :span="3">
          <a-statistic title="有效/赠送课时" :value="detail.available_times" class="demo-class">
            <template #suffix>
              <span> / {{detail.available_gift_times}}</span>
            </template>
          </a-statistic>
        </a-col>
        
        <a-col :span="3">
          <a-statistic title="到课/缺勤" :value="detail.student_had_course_count" class="demo-class">
            <template #suffix>
              <span> / {{detail.student_cancel_course_count}}</span>
            </template>
          </a-statistic>
        </a-col>

        <a-col :span="3">
          <a-statistic title="有效天数" :value="detail.available_date" class="demo-class">
            <template #suffix>
              <span>天</span>
            </template>
          </a-statistic>
        </a-col>

        <a-col :span="3">
          <a-statistic title="可请假课时" :value="detail.available_cancel_times" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col>

        <a-col :span="3">
          <a-statistic title="学员积分" :value="detail.student_credits" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col>

        <a-col :span="3">
          <a-statistic title="报读课程" :value="detail.active_contract_count" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col>

        <!-- <a-col :span="3">
          <a-statistic title="消费金额" :value="detail.student_total_costs" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col> -->

        <a-col :span="3">
          <a-statistic title="账户余额" :value="detail.student_left_wallet_balance" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col>

        <a-col :span="3">
          <a-statistic title="电子钱包" :value="detail.student_left_wallet_reserve" class="demo-class">
            <template #suffix>
            </template>
          </a-statistic>
        </a-col>

      </a-row>

      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="dashed" icon="stop" v-if="student_class_status == 1 || student_class_status == 2" @click="pendding">停课</a-button>
            <a-button type="dashed"  icon="check-circle" v-if="student_class_status == 3" @click="pendding">恢复停课</a-button>
            <a-button type="dashed"  icon="clock-circle" v-if="student_class_status == 4" @click="pendding">历史学员</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="dashed" icon="exclamation-circle" @click="showRefundModal">退费办理</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="dashed" icon="reload" @click="refresh">缓存刷新</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="dashed" icon="tool" @click="showJfcz">积分操作</a-button>
          </a-form-item>

          <a-form-item>
            <a-button type="dashed" icon="plus" @click="showClassesModal">操作排班</a-button>
          </a-form-item>

          <!-- <a-form-item>
            <a-button type="dashed" icon="plus" @click="showEditModal($event)">成长档案</a-button>
          </a-form-item> -->

          <a-form-item v-if="leadsId">
            <a-button type="dashed" icon="plus" @click="showFollow($event)">跟进记录</a-button>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" icon="plus" @click="showModal">业务办理</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
      </div>
    </div>
    <a-tabs :animated="false" type="card" :activeKey="activeKey" @change="changeTab">
      <a-tab-pane :key="1">
        <span slot="tab">学员资料</span>
        <material :studentId="studentId" v-if="activeKey===1"/>
      </a-tab-pane>

      <a-tab-pane :key="2">
        <span slot="tab">学员班级</span>
        <classes :studentId="studentId" :toRefresh='toRefresh' v-if="activeKey===2"/>
      </a-tab-pane>

      <a-tab-pane :key="15">
        <span slot="tab">学员档案</span>
        <archives :studentId="studentId" :toRefresh='toRefresh' v-if="activeKey===15"/>
      </a-tab-pane>

      <a-tab-pane :key="5">
        <span slot="tab">学生作业</span>
        <homework :studentId="studentId" v-if="activeKey===5"/>
      </a-tab-pane>
      <a-tab-pane :key="6">
        <span slot="tab">上课记录</span>
        <attendClassLog :studentId="studentId" v-if="activeKey===6"/>
      </a-tab-pane>
      <a-tab-pane :key="7">
        <span slot="tab">请假记录</span>
        <askForLeaveLog :studentId="studentId" v-if="activeKey===7"/>
      </a-tab-pane>

      <a-tab-pane :key="3">
        <span slot="tab">报读课程</span>
        <addClass :studentId="studentId" v-if="activeKey===3"/>
      </a-tab-pane>

      <a-tab-pane :key="8">
        <span slot="tab">消费记录</span>
        <costLog :studentId="studentId" v-if="activeKey===8"/>
      </a-tab-pane>

      <a-tab-pane :key="9">
        <span slot="tab">退费记录</span>
        <refundLog :studentId="studentId" v-if="activeKey===9"/>
      </a-tab-pane>

      <a-tab-pane :key="10">
        <span slot="tab">联系信息</span>
        <contact :studentId="studentId" v-if="activeKey===10"/>
      </a-tab-pane>
      <a-tab-pane :key="11">
        <span slot="tab">学员证书</span>
        <certificate :studentId="studentId" v-if="activeKey===11"/>
      </a-tab-pane>
      <a-tab-pane :key="12">
        <span slot="tab">积分记录</span>
        <integral ref="xfjlS" :studentId="studentId" v-if="activeKey===12"/>
      </a-tab-pane>

      <a-tab-pane :key="13">
        <span slot="tab">优惠券</span>
        <coupon :studentId="studentId" v-if="activeKey===13"/>
      </a-tab-pane>

    </a-tabs>
    <qrcodeModal v-if="isQrcodeModal" :item="modalData"/>
    <integralModal v-if="addJfcz" :studentId="studentId"/>
    <editModal v-if="addVisible" :studentId="studentId" :tab="3" :item="modalData"/>
    <refundModal v-if="isrefundModal" :studentId="studentId" :tab="2" :item="modalData" :contract_no='contract_no'/>
    <xsdaModal v-if="xsdaVisible" :studentId="studentId" :item="modalData"/>
    <classesModal v-if="isClassesModal" :studentId="studentId" />
    <followModal :item="modalData" :authType="authType" :leadsId='leadsId' :isTab='isTab' v-if="followVisible"/>
  </a-modal>
</template>

<script>
import classesModal from './classesModal'
import coupon from '@/views/user/profile/coupon'
import material from '@/views/user/profile/material'
import classes from '@/views/user/profile/classes'
import addClass from '@/views/user/profile/addClass'
import archives from '@/views/user/profile/archives'
import homework from '@/views/user/profile/homework'
import attendClassLog from '@/views/user/profile/attendClassLog'
import askForLeaveLog from '@/views/user/profile/askForLeaveLog'
import costLog from '@/views/user/profile/costLog'
import refundLog from '@/views/user/profile/refundLog'
import contact from '@/views/user/profile/contact/index'
import certificate from '@/views/user/profile/certificate'
import integral from '@/views/user/profile/integral'
import integralModal from '@/views/user/profile/integralModal'
import editModal from '@/views/students/zcxy/editModal'
import xsdaModal from '@/views/market/xuetang/xsda/editModal'
import refundModal from '@/views/students/zcxy/refundModal'
import qrcodeModal from '@/views/students/zcxy/qrcodeModal'
import followModal from '@/views/clientresource/modal/followModal/index'
export default {
  name: 'profile',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    studentId: Number,
    student_name:String,
    contract_no:String,
    isRefresh:Number,
  },

  components: {
    coupon,
    material,
    classes,
    addClass,
    archives,
    homework,
    attendClassLog,
    askForLeaveLog,
    costLog,
    refundLog,
    contact,
    certificate,
    integral,
    integralModal,
    editModal,
    xsdaModal,
    refundModal,
    classesModal,
    followModal,
    qrcodeModal
  },

  data() {
    return {
      activeKey: 1,
      xsdaVisible:false,
      visible: false,
      loading: false,
      confirmLoading: false,
      addVisible: false,
      addJfcz:false,
      isrefundModal:false,
      isClassesModal:false,
      modalData: {},
      student_class_status:0,
      detail: {},
      toRefresh:1,
      followVisible:false,
      isQrcodeModal:false,
      leadsId:'',
      isTab:'1',
      student_customer_id:{},
      authType:['crm','customer'],
    }
  },
  created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail(obj) {
      this.detail = obj
      this.student_class_status = obj.student_class_status
      this.leadsId = obj.student_customer_id.customer_id?obj.student_customer_id.customer_id:''
      this.student_customer_id = obj.student_customer_id
    },
    async getRefresh(){
      let student_id = this.studentId
      let res = await this.$store.dispatch('studentsDetailAction', { data: { student_id } })
      this.getDetail(res.data)
    },
    changeTab(key) {
      this.activeKey = key
    },
    handleOk() {
      this.parent.hideProfileModal(1)
    },
    handleCancel() {
      this.parent.hideProfileModal()
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.xsdaVisible = true
    },
    showFollow(e){
      e.stopPropagation()
      if(this.student_customer_id){
        this.modalData = this.student_customer_id
        this.followVisible = true
      }
    },
    hideFollowModal(){
      this.followVisible = false
    },
    toUpdataList(){
      this.parent.toParent()
    },
    async showModal() {
      this.modalData = {student_id: this.studentId,student_name:this.student_name}
      this.addVisible = true
    },
    hideModal(val) {
      if(val){
        this.getRefresh()
      }
      if(val && this.isRefresh){
        this.parent.getList()
      }
      this.addVisible = false
      this.xsdaVisible = false
    },
    showJfcz(){
      this.addJfcz = true
    },
    hideJfcz(num) {
      if(num){
        if(this.activeKey == 12){
          this.$refs.xfjlS.getList()
        }else{
          this.activeKey = 12
        }
      }
      this.addJfcz = false
    },

    async pendding() {
      let that = this
      this.$confirm({
        title: `您确定要停课吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('studentsUpdateStatusAction', { data: { student_id: that.studentId } })
            if (res) {
              that.$message.success('操作成功！')
              resolve(res)
              that.getRefresh()
            }
          }).catch(error => console.log(error))
        }
      })
    },
    async refresh() {
      this.loading = true
      await this.$store.dispatch('studentsProfileRefreshAction', { data: { student_id: this.studentId } })
      .then(res=>{
        this.getRefresh()
        this.$message.success('刷新成功！')
        this.loading = false
      })
      .catch(err=>{
        this.loading = false
      })
    },
    showRefundModal(){
      this.modalData = this.detail
      this.isrefundModal = true
    },  
    hideRefundModal(val){
      if(val){
        this.getRefresh()
      }
      if(val && this.isRefresh){
        this.parent.getList()
      }
      this.isrefundModal = false
    },
    submitModal(val){
      if(val){
        this.activeKey = 15
        this.toRefresh++
      }
      this.xsdaVisible = false
    },
    showClassesModal(){
      this.isClassesModal = true
    },
    hideClassesModal(val){
      if(val){
        this.activeKey = 2
        this.toRefresh++
      }
      this.isClassesModal = false
    },
    showQrcodeModal(item){
      this.modalData = this.detail
      this.isQrcodeModal = true
    },
    hideQrcodeModal(val){
      this.isQrcodeModal = false
    },
  }
}
</script>
<style lang="scss">
  .profileModal{
    .ant-modal-content{
      z-index: 4000;
    }
  }
</style>