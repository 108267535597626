import Vue from 'vue'
import { 
  weixinSeting,weixinSetingMaterial,weixinSetingRefreshMaterial,weixinPayment,weixinPaymentUpdate,weixinTemplate,weixinTemplateColor,weixinAuthorization,wxworkMsgDetailExport,wxworkMsgChats,
  weixinTemplateRefresh,wxworkMsgDetail,wxworkMsgStaff,wxworkMsg,wxworkMsgSearch,wxworkMsgGroupMember,wxworkExternalContactDetil,wxworkStaffIndex,wxworkMsgInvalid,wxworkMsgAnalysisExport,
  wxworkMsgAnalysis,wxworkMsgHeatmap,wxworkMsgContact,wxworkMsgContactHistory,wxworkMsgUnfollowHistory,wxworkStaffDepart,wxworkMsgTag,wxworkMsgRoom} from '@/api/weixin'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    weixinSetingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinSeting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinSetingMaterialAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinSetingMaterial(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinSetingRefreshMaterialAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinSetingRefreshMaterial(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    weixinAuthorizationAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinAuthorization(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinPaymentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinPayment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinPaymentUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinPaymentUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinTemplateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinTemplate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    weixinTemplateColorAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinTemplateColor(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    weixinTemplateRefreshAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        weixinTemplateRefresh(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsg(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgDetailExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgDetailExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgStaffAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgStaff(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgSearchAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgSearch(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgInvalidAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgInvalid(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgChatsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgChats(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgAnalysisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgAnalysis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgAnalysisExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgAnalysisExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkMsgHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkMsgContactHistoryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgContactHistory(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkMsgUnfollowHistoryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgUnfollowHistory(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkMsgGroupMemberAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgGroupMember(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkMsgTagAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgTag(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkMsgRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkMsgRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkExternalContactDetilAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkExternalContactDetil(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    wxworkStaffIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkStaffIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    wxworkStaffDepartAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        wxworkStaffDepart(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
