import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import students from './modules/students'
import material from './modules/material'
import search from './modules/search'
import classes from './modules/classes'
import studio from './modules/studio'
import extending from './modules/extending'
import crm from './modules/crm'
import courseCategory from './modules/courseCategory'
import course from './modules/course'
import absence from './modules/absence'
import education from './modules/education'
import enterprise from './modules/enterprise'
import market from './modules/market'
import archive from './modules/archive'
import orginization from './modules/orginization'
import payment from './modules/payment'
import contract from './modules/contract'
import finance from './modules/finance'
import setting from './modules/setting'
import employee from './modules/employee'
import rate from './modules/rate'
import weipages from './modules/weipages'
import meeting from './modules/meeting'
import marketing from './modules/marketing'
import homework from './modules/homework'
import knowledge from './modules/knowledge'
import weixin from './modules/weixin'
import notification from './modules/notification'
import stock from './modules/stock'
import room from './modules/room'
import dashboard from './modules/dashboard'
import analysis from './modules/analysis'
import sImport from './modules/sImport'
import survey from './modules/survey'
import store from './modules/store'
import tools from './modules/tools'
import custom from './modules/custom'
import permission from './modules/permission'
import journal from './modules/journal'
import rss from './modules/rss'
import monitor from './modules/monitor'
import sop from './modules/sop'
import device from './modules/device'
import discourse from './modules/discourse'
import getters from './getters'
import loading from './loading'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    students,
    material,
    search,
    classes,
    studio,
    extending,
    crm,
    courseCategory,
    course,
    absence,
    education,
    enterprise,
    market,
    archive,
    orginization,
    payment,
    permission,
    contract,
    finance,
    setting,
    employee,
    rate,
    weipages,
    meeting,
    marketing,
    homework,
    knowledge,
    weixin,
    notification,
    stock,
    room,
    dashboard,
    analysis,
    sImport,
    survey,
    store,
    tools,
    custom,
    journal,
    rss,
    monitor,
    device,
    sop,
    discourse,
    loading,
  },
  getters
})
