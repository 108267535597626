import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 登录
 * @param {*} data 
 */
export function login(data) {
  return request({
    url: url.login,
    method: 'POST',
    data
  })
}

export function getAuth(data) {
  return request({
    url: url.getAuth,
    method: 'POST',
    data
  })
}

export function faceLogin(data) {
  return request({
    url: url.faceLogin,
    method: 'POST',
    data
  })
}

export function loginQrcode(params) {
  return request({
    url: url.loginQrcode,
    method: 'POST',
    ...params
  })
}

export function qrLogin(params) {
  return request({
    url: url.qrLogin,
    method: 'POST',
    ...params
  })
}

export function wxworkQrLogin(params) {
  return request({
    url: url.wxworkQrLogin,
    method: 'POST',
    ...params
  })
}

export function logout(params) {
  return request({
    url: url.logout,
    method: 'GET',
    ...params
  })
}

export function accout(params) {
  return request({
    url: url.accout,
    method: 'POST',
    ...params
  })
}

export function accoutUpdate(params) {
  return request({
    url: url.accoutUpdate,
    method: 'POST',
    ...params
  })
}
export function accoutSubordinate(params) {
  return request({
    url: url.accoutSubordinate,
    method: 'POST',
    ...params
  })
}
export function accoutChangePassword(params) {
  return request({
    url: url.accoutChangePassword,
    method: 'POST',
    ...params
  })
}