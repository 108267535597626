import url from '@/utils/URL'
import request from '@/utils/request'

export function weixinSeting(params) {
  return request({
    url: url.weixinSeting,
    method: 'POST',
    ...params
  })
}

export function weixinSetingMaterial(params) {
  return request({
    url: url.weixinSetingMaterial,
    method: 'POST',
    ...params
  })
}

export function weixinSetingRefreshMaterial(params) {
  return request({
    url: url.weixinSetingRefreshMaterial,
    method: 'POST',
    ...params
  })
}


export function weixinAuthorization(data) {
  return request({
    url: url.weixinAuthorization,
    method: 'POST',
    data
  })
}

export function weixinPayment(data) {
  return request({
    url: url.weixinPayment,
    method: 'POST',
    data
  })
}

export function weixinPaymentUpdate(data) {
  return request({
    url: url.weixinPaymentUpdate,
    method: 'POST',
    data
  })
}

export function weixinTemplate(params) {
  return request({
    url: url.weixinTemplate,
    method: 'POST',
    ...params
  })
}

export function weixinTemplateColor(params) {
  return request({
    url: url.weixinTemplateColor,
    method: 'POST',
    ...params
  })
}

export function weixinTemplateRefresh(params) {
  return request({
    url: url.weixinTemplateRefresh,
    method: 'POST',
    ...params
  })
}

export function wxworkMsg(params) {
  return request({
    url: url.wxworkMsg,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgDetail(params) {
  return request({
    url: url.wxworkMsgDetail,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgDetailExport(params) {
  return request({
    url: url.wxworkMsgDetailExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function wxworkMsgStaff(params) {
  return request({
    url: url.wxworkMsgStaff,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgSearch(params) {
  return request({
    url: url.wxworkMsgSearch,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgInvalid(params) {
  return request({
    url: url.wxworkMsgInvalid,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgChats(params) {
  return request({
    url: url.wxworkMsgChats,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgAnalysis(params) {
  return request({
    url: url.wxworkMsgAnalysis,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgAnalysisExport(params) {
  return request({
    url: url.wxworkMsgAnalysisExport,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function wxworkMsgHeatmap(params) {
  return request({
    url: url.wxworkMsgHeatmap,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgContact(params) {
  return request({
    url: url.wxworkMsgContact,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgContactHistory(params) {
  return request({
    url: url.wxworkMsgContactHistory,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgUnfollowHistory(params) {
  return request({
    url: url.wxworkMsgUnfollowHistory,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgGroupMember(params) {
  return request({
    url: url.wxworkMsgGroupMember,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgTag(params) {
  return request({
    url: url.wxworkMsgTag,
    method: 'POST',
    ...params
  })
}

export function wxworkMsgRoom(params) {
  return request({
    url: url.wxworkMsgRoom,
    method: 'POST',
    ...params
  })
}

export function wxworkExternalContactDetil(params) {
  return request({
    url: url.wxworkExternalContactDetil,
    method: 'POST',
    ...params
  })
}

export function wxworkStaffIndex(params) {
  return request({
    url: url.wxworkStaffIndex,
    method: 'POST',
    ...params
  })
}

export function wxworkStaffDepart(params) {
  return request({
    url: url.wxworkStaffDepart,
    method: 'POST',
    ...params
  })
}
