import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 班级管理-列表
 */
export function classesList(data) {
  return request({
    url: url.classesList,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesAdd(data) {
  return request({
    url: url.classesAdd,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesUpdate(data) {
  return request({
    url: url.classesUpdate,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesDetail(data) {
  return request({
    url: url.classesDetail,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-导出
 */
export function classesExport(data) {
  return request({
    url: url.classesExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

/**
 * 班级管理-结业
 */
export function classesClose(data) {
  return request({
    url: url.classesClose,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-到课
 */
export function classesAttend(data) {
  return request({
    url: url.classesAttend,
    method: 'POST',
    data
  })
}

export function classesWxworkStaff(data) {
  return request({
    url: url.classesWxworkStaff,
    method: 'POST',
    data
  })
}

export function classesWxworkContact(data) {
  return request({
    url: url.classesWxworkContact,
    method: 'POST',
    data
  })
}


export function classesWxworkMsg(data) {
  return request({
    url: url.classesWxworkMsg,
    method: 'POST',
    data
  })
}

export function classesMerge(data) {
  return request({
    url: url.classesMerge,
    method: 'POST',
    data
  })
}

export function classesClassesAlbum(data) {
  return request({
    url: url.classesClassesAlbum,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesMultiSetting(data) {
  return request({
    url: url.classesMultiSetting,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesMember(data) {
  return request({
    url: url.classesMember,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesMemberAdd(data) {
  return request({
    url: url.classesMemberAdd,
    method: 'POST',
    data
  })
}

/**
 * 班级管理-列表
 */
export function classesMemberDelete(data) {
  return request({
    url: url.classesMemberDelete,
    method: 'POST',
    data
  })
}

/**
 * 可进班级学员
 */
export function classesMemberPotential(data) {
  return request({
    url: url.classesMemberPotential,
    method: 'POST',
    data
  })
}

export function classesActiveClass(data) {
  return request({
    url: url.classesActiveClass,
    method: 'POST',
    data
  })
}

/**
 * 班级学员进出记录
 */
export function classesMemberRecord(data) {
  return request({
    url: url.classesMemberRecord,
    method: 'POST',
    data
  })
}

/**
 * 班级开课记录
 */
export function classesSchedule(data) {
  return request({
    url: url.classesSchedule,
    method: 'POST',
    data
  })
}
export function classesScheduleAdd(data) {
  return request({
    url: url.classesScheduleAdd,
    method: 'POST',
    data
  })
}
export function classesScheduleAddDate(data) {
  return request({
    url: url.classesScheduleAddDate,
    method: 'POST',
    data
  })
}
export function classesCancelCourse(data) {
  return request({
    url: url.classesCancelCourse,
    method: 'POST',
    data
  })
}

/**
 * 班级管理员
 */
export function classesManager(data) {
  return request({
    url: url.classesManager,
    method: 'POST',
    data
  })
}
export function classesManagerAdd(data) {
  return request({
    url: url.classesManagerAdd,
    method: 'POST',
    data
  })
}
export function classesManagerRemove(data) {
  return request({
    url: url.classesManagerRemove,
    method: 'POST',
    data
  })
}

export function classesConfirmApply(data) {
  return request({
    url: url.classesConfirmApply,
    method: 'POST',
    data
  })
}

export function classesDeleteApply(data) {
  return request({
    url: url.classesDeleteApply,
    method: 'POST',
    data
  })
}

export function classesSubstitute(data) {
  return request({
    url: url.classesSubstitute,
    method: 'POST',
    data
  })
}

export function classesAddTempMember(data) {
  return request({
    url: url.classesAddTempMember,
    method: 'POST',
    data
  })
}

export function classesAlbum(data) {
  return request({
    url: url.classesAlbum,
    method: 'POST',
    data
  })
}

export function classesAlbumImages(data) {
  return request({
    url: url.classesAlbumImages,
    method: 'POST',
    data
  })
}

export function classesAlbumDeleteImages(data) {
  return request({
    url: url.classesAlbumDeleteImages,
    method: 'POST',
    data
  })
}

export function classesAlbumImagesShow(data) {
  return request({
    url: url.classesAlbumImagesShow,
    method: 'POST',
    data
  })
}

export function classesAlbumAdd(data) {
  return request({
    url: url.classesAlbumAdd,
    method: 'POST',
    data
  })
}

export function classesAlbumUpdate(data) {
  return request({
    url: url.classesAlbumUpdate,
    method: 'POST',
    data
  })
}

export function classesAlbumDelete(data) {
  return request({
    url: url.classesAlbumDelete,
    method: 'POST',
    data
  })
}

export function classesAlbumUpload(data) {
  return request({
    url: url.classesAlbumUpload,
    method: 'POST',
    data
  })
}
