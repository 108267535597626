<template>
  <div class="zcxy">
    <div class="dashboard">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="primary" icon="plus" @click="showEditModal()">新增</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="showEditModal(record)">编辑</a>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '关系', dataIndex: 'contact_type', key: '1' },
  { title: '联系名称', dataIndex: 'contact_name', key: '2' },
  { title: '联系电话', dataIndex: 'contact_tellphone', key: '3' },
  { title: '联系微信', dataIndex: 'contact_wechat', key: '4' },
  { title: '联系邮箱', dataIndex: 'contact_email', key: '5' },
  { title: '添加时间', dataIndex: 'created_at', key: '6' },
  { title: '操作', key: 'operation', width: 46, scopedSlots: { customRender: 'action' }}
]

import tableMixins from '@/common/mixins/table'
import editModal from './editModal'

export default {
  name: 'contacts',
  mixins: [ tableMixins ],
  components: {
    editModal
  },
  props: {
    item: Object,
    leadsId: Number,
    isTab:String
  },
  data() {
    return {
      loading: false,
      columns,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

    }
  },
  methods: {
    async getList() {
      this.loading = true
      this.searchParams.customer_id = this.item.customer_id
      let res = await this.$store.dispatch('cCustomerContactAction',this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    }
  },
  watch: {
    leadsId(newValue, oldValue) {
        this.pageParams.page = 1;
        this.list = []
        this.getList()
    }
  },
}
</script>
