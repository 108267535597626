import Vue from 'vue'
import { marketList, zlShareList, zlCategoryList, zlCategoryAdd, zlCategoryEdit, zlCategoryDelete, 
  zlShareAdd, zlShareEdit, zlShareDelete, zlShareDetail,zlShareMoveTo,zlShareChangeOrder,
  xtHelp,xtHelpCateAdd,xtHelpAdd,xtHelpView,xtHelpEdit,xtHelpCateEdit,xtHelpCateDelete,
  xtContact,xtContactDetail,poolIndex,poolExport,poolTransfer } from '@/api/market'
import config from '@/utils/const'

export default {
  state: {
    category: []
  },
  mutations: {
    SAVE_CATEGORY(state, response) {
      state.category = response.data
    },
  },
  actions: {
    marketListAction({ commit }) {
      return new Promise((resolve,reject) => {
        marketList().then(response => {
          const { data } = response
          resolve(data)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlShareListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlCategoryListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlCategoryList(params).then(response => {
          commit('SAVE_CATEGORY', response)
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlCategoryAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlCategoryAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlCategoryEditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlCategoryEdit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlCategoryDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlCategoryDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlShareAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlShareEditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareEdit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlShareDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    zlShareDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    zlShareMoveToAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareMoveTo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    zlShareChangeOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        zlShareChangeOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    xtHelpAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelp(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpCateAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpCateAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpEditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpEdit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpCateEditAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpCateEdit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtHelpCateDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtHelpCateDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    xtContactDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        xtContactDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    poolIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        poolIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    poolTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        poolTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    poolExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        poolExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
