<template>
  <a-card title="">
        <div>
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <ve-line 
          judge-width
          :toolbox="toolbox"
          height="400px"
          :title="{
            text:`${title}（${item.title?item.title:'0'}）`,
            subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
          }"
          :data="item" :mark-line="markLine" :grid="{right: '2.75%'}" 
          :extend="lineChartExtend" 
          :legend="{show:false}" 
          :settings="{ 
              lineStyle: {
                  normal: {
                      width: 1
                  }
              },
              areaStyle:{
                  area: false,
                  normal:{
                      color:{
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [{
                              offset: 1, color: '#ffffff' // 0% 处的颜色
                          }, {
                              offset: 0, color: '#23d9ae' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      },
                      shadowColor: 'rgba(0, 0, 0, 0.1)',
                      shadowBlur: 10
                  }
              },
              label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB']}"></ve-line>
              <LEmpty v-if="first>0 && item.rows.length == 0"/>
        </div>
  </a-card>
</template>

<script>
export default {
  name: 'VElines',
  props:['item' , 'loading','start_date','end_date','title'],
  data(){
      this.toolbox = {
        feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
        }
      },
      this.markLine = {
        symbol: ['none', 'none'], //去掉箭头
        data: [
          {
            name: '平均线',
            type: 'average'
          }
        ]
      }
    return{
      first:0,
      lineChartExtend:{
          series(item){
              if(item.length > 0){
                  item[0].type = 'bar'
                  item[0].barMaxWidth = 10
                  item[0].itemStyle = Object.assign({}, {
                      normal:{
                          color: function(params) {
                              return {
                                  type: 'linear',
                                  x:1,
                                  y:1,
                                  x2:0,
                                  y2:0,
                                  colorStops: [{
                                      offset: 0,
                                      color: '#ffffff'
                                      
                                  }, {
                                      offset: 1,                    
                                      color: '#23d9ae'
                                  }],
                              }
                          },
                          barBorderRadius:[30,30,0,0]
                      }
                  })
                  if(item[1]){
                    item[1].type = 'bar'
                    item[1].barMaxWidth = 10
                    item[1].itemStyle = Object.assign({}, {
                        normal:{
                            color: function(params) {
                                return {
                                    type: 'linear',
                                    x:1,
                                    y:1,
                                    x2:0,
                                    y2:0,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#ffffff'
                                        
                                    }, {
                                        offset: 1,                    
                                        color: '#F8AC59'
                                    }],
                                }
                            },
                            barBorderRadius:[30,30,0,0]
                        }
                    })
                  }

              }
              return item
          },
          yAxis:{
              splitLine: {
                  lineStyle: {
                      type: 'dotted'
                  }
              }
          },
          dataZoom:[{type:'slider'}]
      }
    }
  },
  watch: {
      loading(newValue, oldValue) {
          this.first++;
      }
  },
}
</script>
