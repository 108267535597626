<template>
  <a-modal title="试听排期" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="62.5%" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
        <a-tabs v-model="activeIndex" @change="handleChange">
            <a-tab-pane key="1" tab="随堂试听">
                <audition :item="item" ref='audition' />
            </a-tab-pane>
            <a-tab-pane key="2" tab="DEMO课">
                <demo :item="item" ref='demo' />
            </a-tab-pane>
        </a-tabs>
  </a-modal>
</template>

<script>
    import audition from './audition'
    import demo from './demo'
    export default {
        name:'auditionModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                confirmLoading: false,
                activeIndex:"1",
                customer_id:[]
            }
        },
        props: {
            item: Array,
            isTab:String,
        },
        components: {
            demo,
            audition
        },
        created () {
            this.visible = true
            if(this.isTab === '1'){
                this.customer_id = this.item
            }else{
                let customer_id = []
                this.item.forEach(it=>{
                    customer_id.push(it.customer_id)
                })
                this.customer_id = customer_id
            }

        },
        methods: {
            handleChange(e){
                this.activeIndex = e
            },
            async handleOk() {
                let selectedRowKeys = []
                if(this.activeIndex === '1'){
                    selectedRowKeys = this.$refs.audition.selectedRowKeys
                }else{
                    selectedRowKeys = this.$refs.demo.selectedRowKeys
                }
                if(selectedRowKeys.length == 0){
                    this.$message.warning('请选择!!')
                    return false
                }
                this.confirmLoading = true
                await this.$store.dispatch('auditionArrangeAction',{data:{customer_id:this.customer_id,course_class_id:selectedRowKeys}})
                    .then(res=>{
                        this.$message.success('操作成功~')
                        this.parent.hideAuditionModal(1)
                    })
                    .catch(err=>{
                        this.$message.warning('操作失败~')
                        this.confirmLoading = false
                    })
            },
            handleCancel(){
                this.parent.hideAuditionModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>