<template>
    <div class="crm-messages">
        <div class="crm-messages-left">
            <contacts ref="contacts" :item="item" :leadsId='leadsId' :isTab='isTab' />
        </div>
        <div class="crm-messages-right">
            <chatRecord :item="item" :leadsId='leadsId' :chatItem="chatItem" />
        </div>
    </div>
</template>

<script>
    import contacts from './contacts'
    import chatRecord from './chatRecord'
    export default {
        name:'wxworkLog',
        provide() {
            return {
                parent: this
            }
        },
        components: {
            contacts,
            chatRecord
        },
        props: {
            item: Object,
            leadsId: Number,
            isTab:String
        },
        data() {
            return {
                chatItem:{},
            }
        },
        methods: {
            changeValue(item) {
                this.chatItem = item
            },
        },

    }
</script>

<style lang="scss" scoped>
    .crm-messages{
        display: flex;
        .crm-messages-left{
            width: 300px;
        }
        .crm-messages-right{
            flex: 1;
        }
    }
</style>