import url from '@/utils/URL'
import request from '@/utils/request'

export function knowledgePackage(params) {
  return request({
    url: url.knowledgePackage,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageAdd(params) {
  return request({
    url: url.knowledgePackageAdd,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageDetail(params) {
  return request({
    url: url.knowledgePackageDetail,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageContent(params) {
  return request({
    url: url.knowledgePackageContent,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageMember(params) {
  return request({
    url: url.knowledgePackageMember,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageUpdate(params) {
  return request({
    url: url.knowledgePackageUpdate,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageDelete(params) {
  return request({
    url: url.knowledgePackageDelete,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageRecovery(params) {
  return request({
    url: url.knowledgePackageRecovery,
    method: 'POST',
    ...params
  })
}

export function knowledgePackageCanRelatedContent(params) {
  return request({
    url: url.knowledgePackageCanRelatedContent,
    method: 'POST',
    ...params
  })
}

export function knowledgeContent(params) {
  return request({
    url: url.knowledgeContent,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentAdd(params) {
  return request({
    url: url.knowledgeContentAdd,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentDetail(params) {
  return request({
    url: url.knowledgeContentDetail,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentRelatedTo(params) {
  return request({
    url: url.knowledgeContentRelatedTo,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentCancelRelated(params) {
  return request({
    url: url.knowledgeContentCancelRelated,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentChangeOrder(params) {
  return request({
    url: url.knowledgeContentChangeOrder,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentUpdate(params) {
  return request({
    url: url.knowledgeContentUpdate,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentDelete(params) {
  return request({
    url: url.knowledgeContentDelete,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentRecovery(params) {
  return request({
    url: url.knowledgeContentRecovery,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentComment(params) {
  return request({
    url: url.knowledgeContentComment,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentCommentComfirm(params) {
  return request({
    url: url.knowledgeContentCommentComfirm,
    method: 'POST',
    ...params
  })
}

export function knowledgeContentCommentRefuse(params) {
  return request({
    url: url.knowledgeContentCommentRefuse,
    method: 'POST',
    ...params
  })
}