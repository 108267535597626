<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="1000px" :zIndex='4002'
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-input v-model='searchParams.search.fee_title'  style="width: 160px" allowClear placeholder="收费名称"></a-input>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>课程科目</span>
              </template>
              <a-select :dropdownStyle="{zIndex:4002}" v-model="searchParams.search.course_cate_id" showSearch allowClear placeholder="请选择课程类别" style="width: 140px">
                <a-select-option v-for="(item, index) of courseCates" :key="index" :value="item.course_cate_id">{{ item.filter_name }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>收费类型</span>
              </template>
              <a-select :dropdownStyle="{zIndex:4002}" v-model="searchParams.search.fee_type" showSearch allowClear placeholder="请选择收费类型" style="width: 140px">
                <a-select-option v-for="(item, index) of feeTypes" :key="index" :value="item.fee_type">{{ item.filter_name }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="fee_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="fee_title" slot-scope="text, record , index">
          <div>
            <div>{{text}}</div>
            <div v-if="record.is_trial">
                <img style="width:37.5px;position:absolute;right:0;top:0" src="@/assets/experience.png" alt="">
            </div>
          </div>
        </template>

        <template slot="buy" slot-scope="text,record">
          <span v-if="record.fee_type == 10 || record.fee_type == 40">{{record.times}} 课时</span>
          <span v-else>{{record.month}} 月</span>
        </template>
        <template slot="gift_times" slot-scope="text,record">
          <span v-if="record.fee_type == 10 || record.fee_type == 40">{{record.gift_times}} 课时</span>
          <span v-else>{{record.gift_day}} 天</span>
        </template>
    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '收费名称', width:'150px', dataIndex: 'fee_title', scopedSlots: { customRender: 'fee_title' }, ellipsis:true},
  { title: '课程科目', width:'150px', dataIndex: 'course_cate.course_cate_name',ellipsis:true},
  { title: '收费类型', width:'100px', dataIndex: 'fee_type_name'},
  { title: '购买', dataIndex: 'buy',key: 'buy', scopedSlots: { customRender: 'buy' }},
  { title: '赠送', dataIndex: 'gift_times', key: 'gift_times' , scopedSlots: { customRender: 'gift_times' }},
  { title: '单价', dataIndex: 'unit_price', key: 'unit_price'},
  { title: '收费金额', width:'100px', dataIndex: 'price', key: 'price'},
]
import moment from 'moment'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'CourseFeeModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      ModalTitle: '选择课程',
      visible: false,
      confirmLoading: false,
      columns,
      list: [],
      selectedRowKeys: [],
      localCourseFee: [],

      courseCates: [],
      feeTypes: [],
      
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
    }
  },
  mixins:[tableMixins],
  created() {
    this.visible = true
    this.getCourseCate()
    this.getFeeType()
    this.localCourseFee = this.$ls.get('localCourseFee') || []
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getCourseCate() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.courseCates = res.data
    },
    async getFeeType() {
      let res = await this.$store.dispatch('searchFeeTypeAction', {})
      this.feeTypes = res.data
    },
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('searchCourseFeeAction', { params: this.item, data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleOk() {
      for (let item of this.list) {
        if (this.selectedRowKeys.indexOf(item.fee_id) !== -1) {
          this.localCourseFee.push(item)
        }
      }
      this.localCourseFee.forEach(item=>{
        if(!item.discount){
          item.discount = 0
        }
        if(item.fee_type == 20 || item.fee_type == 30){
          if(!item.end_date_type){
            item.end_date_type = 2
            item.end_date = null
            item.course_price = ( Number(item.unit_price) * Number(item.month) ).toFixed(2)
          }
          if(!item.start_date){
            item.start_date = moment().format('YYYY-MM-DD')
          }
        }else{
          item.priceF = item.priceF || item.price
          item.timesF = item.timesF || item.times
          item.end_date = moment().add(1,'y').format("YYYY-MM-DD")
          item.items = item.items || 0
          if(!item.course_price){
            item.course_price = ( Number(item.unit_price) * Number(item.times) ).toFixed(2)
          }
        }
      })
      this.$ls.set('localCourseFee', this.localCourseFee)
      this.parent.hideCourseFeeModal(1)
    },
    handleCancel() {
      this.parent.hideCourseFeeModal(0)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    let index = this.selectedRowKeys.indexOf(record.fee_id)
                    if(index == -1){
                      this.selectedRowKeys.push(record.fee_id)
                    }else{
                      this.selectedRowKeys.splice(index,1)
                    }
                }
            }
        }
    },
  }
}
</script>

<style lang="scss">
  .ant-table-row{
      td{
          position: relative;
      }
  }
</style>