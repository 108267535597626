<template>
  <div class="applink">
    <div class="title">{{ title }}</div>
    <div class="cont">
      <div class="link-item" :id="`${parentKey}-${item.module_id}`" @click="item.isPath?toClean():toPage(`/${parentKey}/${item.module_id}`)" v-for="(item, index) of  list" :key="index">
        <div class="icon">
          <a-avatar v-if="isTab" shape="square" :size="46">{{item.name}}</a-avatar>
          <img v-else :src="item.icon" style="border-radius: 5px;"/>
          <div class="number"><a-badge :count="item.notice" /></div>
        </div>
        <div class="text">
          <div class="name">{{ item.name }}</div>
          <div class="sub">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import comp from "@/common/mixins/comp"

export default {
  name: 'AppLink',
  mixins: [comp],

  props: {
    parentModule: String,
    title: String,
    list: Array,
    parentKey:String,
    isTab:Number
  },

  created() {
  },
  methods:{
    async toClean(){
      await this.$store.dispatch('enterpriseSystemCleanAction', { })
      .then(res=>{
        this.$message.success('操作成功~')
      })
      .catch(err=>{
        this.$message.success('操作失败~')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .applink{
    .sub{
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
  }
</style>
