import Vue from 'vue'
import { analysisMainpanel, analysisClients,analysisClientsChannel,analysisClientsTag,analysisClientsDeal,analysisClientsFollowUp,analysisRefund,analysisStudentsFee,
  analysisKnowledge,analysisCampus,analysisCampusCount,analysisCampusRank,analysisCrmLeads,analysisCrmCustomer,analysisCrmCustomerCount,analysisCrmTransfer,analysisCrmTransferAmount,analysisCrmTransferSankey,analysisCrmFollow,analysisCrmFollowRank,analysisCrmFollowCount,
  analysisCrmFollowContactType,analysisAudition,analysisAuditionSankey,analysisAuditionRank,analysisRoom,analysisStudio,analysisAchievement,analysisClasses,analysisStudents,analysisStudentsCount,analysisStudentsGender,
  analysisClientsSubChannel,analysisClientsChannelStatis,analysisClientsChannelAchievement,analysisClientsChannelSummate,analysisClassesIndex,analysisClassesCount,analysisWallet,analysisWalletStudio,
  analysisClientsEmployeeAchievement,analysisClientsEmployeeStatis,analysisClientsFollowStatis,analysisClientsAchievementSummate,analysisAce,analysisCampusDebt,analysisAceCompare,analysisAceStock,analysisAceTimeline,
  analysisAceBar,analysisAceRank,analysisAceIncome,analysisAceIncomeCount,analysisSummate,analysisCourseCount,analysisSaleStatis,analysisContractStatis,analysisRefundStatis,analysisWalletCount,
  analysisRefundContractStatis,analysisRoomSummate,analysisRoomBook,analysisRoomCount,analysisAceDaily,analysisAbsence,analysisAbsenceCourse,analysisAbsenceSummate,analysisAbsenceCount,
  analysisTeacher,analysisTeacherSummate,analysisTeacherCourse,analysisTeacherBook,analysisTeacherCount,analysisStockIncome,analysisStockOutcome,analysisStockCount,analysisCrmChannel,
  analysisCrmChannelSubChannel,analysisCrmChannelIndexStatis,analysisCrmChannelSubChannelStatis,analysisCrmChannelCount,analysisCourseBalance,analysisCourseBalanceCosts,
  analysisCourseBalanceCount,analysisCourseBalanceStudio,analysisCourseBalanceStudioCosts,analysisCredits,analysisCreditsCosts,analysisCreditsCount,analysisRankingLeads,analysisAuditionChannel,
  analysisRankingCustomer,analysisRankingFollow,analysisRankingTeacher,analysisGoal,analysisFinance,analysisFinanceCount,analysisFinanceRefund,analysisCrmLeadsAmount,analysisCrmLeadsChannel,analysisCrmLeadsSubChannel,
  analysisCrmCustomerChannel,analysisCrmCustomerSubChannel,analysisCrmLeadsTransfer,analysisRankingInvited,analysisCrmCustomerAchievement,analysisCrmCustomerCycle,analysisBoard,
  analysisCrmCustomerPool,analysisStudentsAge,analysisCrmCustomerAchievementChannel,analysisCourseBalanceAmount,analysisCrmFollowChannel,analysisCrmCustomerSankey,analysisCrmCustomerChannelStock,analysisCrmCustomerChannelStockExport,
  analysisCrmCustomerAmount,analysisCrmLeadsSankey,analysisRankingTeacherRevenue,analysisRankingTeacherStudioRevenue,analysisCrmLeadsEmployee,analysisCrmLeadsEmployeeLeadsDetail,
  analysisCrmLeadsStudio,analysisCrmLeadsStudioLeadsDetail,analysisCampusAchievement,analysisCourseBalanceRevenue,analysisGoalStudioContractRevenue,analysisGoalEmployeeContractRevenue,
  analysisGoalStudioCourseRevenue,analysisCampusCommision,analysisMainpanelCrm,analysisMainpanelEdu,analysisCrmFollowHeatmap,analysisTeacherChart,analysisTeacherHeatmap,
  analysisCrmChannelAchievement,analysisCrmChannelAchievementChannel,analysisCrmChannelContractCycle,analysisCrmChannelCycle,
  analysisChannelStatis,analysisStudentsSummate,analysisMeeting,analysisMeetingBooked,analysisMeetingChecked,analysisContent,analysisSurvey,analysisSurveySubmit,analysisCrmLeadsChannelStockExport,
  analysisTeacherStudio,analysisCrmLeadsStock,analysisCrmCustomerContractCycle,analysisCourseBalanceRevenueList,analysisCourseBalanceRevenueListExport,analysisCrmCustomerEmployee,analysisCrmLeadsChannelStock,
  analysisCrmCustomerEmployeeCustomerDetail,analysisCrmCustomerStudio,analysisCrmCustomerStudioCustomerDetail,analysisAbsenceAmount,analysisCoupon,analysisWorkJournal,analysisEducationMessage,analysisEducationMessageExport,
  analysisWorkTask,analysisWorkApprove,analysisCampusAchievementList,analysisCampusRevenueHeatmap,analysisCampusContractHeatmap,analysisCampusBookHeatmap,analysisEducationSummaryExport,analysisCrmFollowExport,
  analysisCampusContractTotalHeatmap,analysisCampusRevenueTotalHeatmap,analysisCampusTaskHeatmap,analysisCampusCourseHeatmap,analysisCampusFollowHeatmap,analysisCrmLeadsEmployeeExport,analysisCrmLeadsStudioExport,analysisAuditionExport,analysisEducationSummary,
  analysisCourseBalanceExportEmployee,analysisCourseBalanceExportStudio,
  analysisCampusRefundHeatmap,analysisCampusInvitedHeatmap} from '@/api/analysis'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    analysisBoardAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisBoard(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisMainpanelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMainpanel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisMainpanelCrmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMainpanelCrm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisMainpanelEduAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMainpanelEdu(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisClientsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClients(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsTagAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsTag(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsDealAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsDeal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsFollowUpAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsFollowUp(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisKnowledgeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisKnowledge(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampus(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusRankAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusRank(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusCommisionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusCommision(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusDebtAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusDebt(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusAchievementListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusAchievementList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusTaskHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusTaskHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusCourseHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusCourseHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusFollowHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusFollowHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusRevenueHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusRevenueHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusRevenueTotalHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusRevenueTotalHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusContractHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusContractHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusContractTotalHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusContractTotalHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusBookHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusBookHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCampusRefundHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusRefundHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCampusInvitedHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCampusInvitedHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsAmountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsAmount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmLeadsStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsSubChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsSubChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerChannelStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerChannelStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerChannelStockExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerChannelStockExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerSubChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerSubChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerAchievementChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerAchievementChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmCustomerContractCycleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerContractCycle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerCycleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerCycle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerPoolAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerPool(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerSankeyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerSankey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsSankeyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsSankey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsChannelStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsChannelStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsChannelStockExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsChannelStockExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsEmployeeExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsEmployeeExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    analysisCrmLeadsStudioExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsStudioExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

  
    analysisCrmLeadsEmployeeLeadsDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsEmployeeLeadsDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmLeadsStudioLeadsDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmLeadsStudioLeadsDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerAmountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerAmount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmCustomerEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmCustomerEmployeeCustomerDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerEmployeeCustomerDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisCrmCustomerStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmCustomerStudioCustomerDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmCustomerStudioCustomerDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmTransferAmountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmTransferAmount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmTransferSankeyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmTransferSankey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowRankAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowRank(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowContactTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowContactType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmFollowHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmFollowExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmFollowExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisEducationMessageAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisEducationMessage(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisEducationMessageExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisEducationMessageExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisEducationSummaryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisEducationSummary(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisEducationSummaryExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisEducationSummaryExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAuditionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAudition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAuditionChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAuditionChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAuditionExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAuditionExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAuditionSankeyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAuditionSankey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisAuditionRankAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAuditionRank(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRoomSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRoomSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRoomBookAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRoomBook(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRoomCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRoomCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStudentsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudents(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStudentsCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudentsCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStudentsGenderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudentsGender(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStudentsAgeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudentsAge(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisStudentsSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudentsSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStudentsFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStudentsFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalance(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceCostsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceCosts(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceStudioCostsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceStudioCosts(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceAmountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceAmount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceExportStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceExportStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceExportEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceExportEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCourseBalanceRevenueListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceRevenueList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseBalanceRevenueListExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseBalanceRevenueListExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCreditsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCredits(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCreditsCostsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCreditsCosts(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCreditsCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCreditsCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisClientsSubChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsSubChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsChannelStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsChannelStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsChannelAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsChannelAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsChannelSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsChannelSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsEmployeeAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsEmployeeAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsEmployeeStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsEmployeeStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsFollowStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsFollowStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClientsAchievementSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClientsAchievementSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAbsenceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAbsence(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAbsenceCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAbsenceCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAbsenceSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAbsenceSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAbsenceCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAbsenceCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAbsenceAmountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAbsenceAmount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisClassesIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClassesIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisClassesCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisClassesCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    

    analysisTeacherSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisTeacherCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisTeacherBookAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherBook(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisTeacherCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisTeacherChartAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherChart(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisTeacherHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisTeacherStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisTeacherStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStockIncomeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStockIncome(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStockOutcomeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStockOutcome(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisStockCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisStockCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelAchievementChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelAchievementChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmChannelContractCycleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelContractCycle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelCycleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelCycle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCrmChannelSubChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelSubChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelIndexStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelIndexStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCrmChannelSubChannelStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCrmChannelSubChannelStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingTeacherRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingTeacherRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingTeacherStudioRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingTeacherStudioRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRankingInvitedAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRankingInvited(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisGoalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisGoal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    analysisGoalStudioContractRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisGoalStudioContractRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisGoalEmployeeContractRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisGoalEmployeeContractRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisGoalStudioCourseRevenueAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisGoalStudioCourseRevenue(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisWalletAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWallet(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisWalletStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWalletStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisWalletCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWalletCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisFinanceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisFinance(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisFinanceCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisFinanceCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisFinanceRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisFinanceRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisMeetingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMeeting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisMeetingBookedAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMeetingBooked(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisMeetingCheckedAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisMeetingChecked(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisSurveyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisSurvey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisSurveySubmitAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisSurveySubmit(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisCouponAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCoupon(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisWorkJournalAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWorkJournal(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisWorkTaskAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWorkTask(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisWorkApproveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisWorkApprove(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAce(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceRankAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceRank(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisAceBarAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceBar(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceIncomeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceIncome(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    analysisAceIncomeCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceIncomeCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceCompareAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceCompare(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceDailyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceDaily(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceStockAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceStock(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisAceTimelineAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisAceTimeline(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisSummateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisSummate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisCourseCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisCourseCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    analysisSaleStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisSaleStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisContractStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisContractStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRefundStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRefundStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisRefundContractStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisRefundContractStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    analysisChannelStatisAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        analysisChannelStatis(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    

  }
}
