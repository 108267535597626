<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form" layout="vertical">
        <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
          <a-cascader
            v-if="item.type === 'cascader'"
            :value='class_knowledge_select'
            :options="channels"
            :popupStyle="{zIndex:4002}"
            :show-search="{ filterOption }"
            placeholder="请选择"
            @change="onChangeCascader">
              <template slot="displayRender" slot-scope="{labels}">
                  <span>{{labels[1]}}</span>
              </template>
            </a-cascader>
          <a-select allowClear placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'select'" showSearch :filterOption="filterOption" @search="handleSearchZL">
            <a-select-option v-for="(it, ind) of zlList" :key="ind" :value="it.id">{{ it.file_name }}</a-select-option>
          </a-select>
          <l-editor v-decorator="[item.name, { rules: item.rules }]" v-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
          <a-textarea v-if="item.type === 'textarea'" placeholder="请输入" :rows="2" v-decorator="[item.name, { rules: item.rules }]"/>
        </a-form-item>
      </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'class_knowledge_id',
    label: '上课资料',
    type: 'cascader',
    rules: [{ required: false, message: '请选择资料!' }],
  },
  {
    name: 'class_remark',
    label: '上课备注',
    type: 'textarea',
    rules: [{ required: false, message: '请输入上课备注!' }],
  },
  {
    name: 'class_summary',
    label: '上课内容',
    type: 'textarea2',
    rules: [{ required: false, message: '请输入上课内容!' }]
  }
]
const formData2 = [
  {
    name: 'class_knowledge_id',
    label: '标准教案',
    type: 'cascader',
    rules: [{ required: false, message: '请选择标准教案!' }],
  },
  {
    name: 'class_remark',
    label: '训练重点',
    type: 'textarea',
    rules: [{ required: false, message: '请输入训练重点!' }],
  },
  {
    name: 'class_summary',
    label: '补充内容',
    type: 'textarea2',
    rules: [{ required: false, message: '请输入补充内容!' }]
  }
]
    import moment from 'moment'
    export default {
        name:'contentModal',
        inject: ['parent'],
        data() {
            return {
                formData:[],
                channels:[],
                form: this.$form.createForm(this),
                class_knowledge_select:[],
                content: '',
                class_knowledge_id:'',
                modalTitle:'',
                editor: {},
                visible: false,
                loading: false,
                confirmLoading: false,
                zlList:[],
            }
        },
        props: {
            item: Object
        },
        provide() {
            return {
                parent: this
            }
        },
        computed: {
            app_id(){
                return this.$store.getters.brand_info.app_id
            }
        },
        async created () {
            if(this.app_id == 'mMKcL4'){
                this.formData = formData2
            }else{
                this.formData = formData
            }
            this.visible = true
            this.getZlList()
            let item = await this.getDetail()
            if(item.class_knowledge_id){
              this.class_knowledge_select = [item.class_knowledge.knowledge_cate_id,item.class_knowledge.knowledge_id]
            }
            this.modalTitle = `上课内容（${moment(this.item.class_start).format('YYYY-MM-DD【dddd】')} ${this.item.class_time}）`
            this.editor.content = item['class_summary']
            this.form.setFieldsValue({class_summary:item.class_summary,class_remark:item.class_remark,class_knowledge_id:item.class_knowledge_id})
        },
        methods: {
            onChangeCascader(value, selectedOptions) {
              this.class_knowledge_select = value
              if(value.length != 0){
                this.class_knowledge_id = value[1]
              }else{
                this.class_knowledge_id = ''
              }
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getDetail(){
                this.loading = true
                let res = await this.$store.dispatch('courseScheduleSummaryAction',{course_class_id:this.item.course_class_id})
                this.loading = false
                return res.data
            },

            async getZlList(val) {
                let channel_res = await this.$store.dispatch('searchLibraryCascaderAction', {params:{q:val}})
                this.channels = channel_res.data.cascader;
                // let res = await this.$store.dispatch('searchLibraryAction', {params:{q:val}})
                // this.zlList = res.data
            },
            async handleOk(){
                let params = await this.form.validateFields()
                params.course_class_id = this.item.course_class_id
                params.class_knowledge_id = this.class_knowledge_id;
                this.confirmLoading = true
                await this.$store.dispatch('courseScheduleSetSummaryAction', params)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.parent.hideContentModal(1)
                })
                .catch(err=>{
                    this.confirmLoading = false
                    this.$message.error('操作失败！！')
                })
            },
            handleCancel(){
                this.parent.hideContentModal()
            },
            changeEditor(key, value) {
                this.editor[key] = value
                this.form.setFieldsValue({class_summary:value})
            },
            handleSearchZL(val){
                this.getZlList(val)
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>