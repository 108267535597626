const Layout = () => import('@/views/layout')
const Orginization = () => import('@/views/orginization')
const OrginizationNotification = () => import('@/views/orginization/notification')
const OrginizationMonitor = () => import('@/views/orginization/monitor')
const OrginizationSuggestion = () => import('@/views/orginization/suggestion')
const OrginizationStock = () => import('@/views/orginization/stock')
const OrginizationRoom = () => import('@/views/orginization/room')
const OrginizationStudio = () => import('@/views/orginization/studio')
const OrginizationContract = () => import('@/views/orginization/contract')
const OrginizationLogging = () => import('@/views/orginization/logging')
const OrginizationImport = () => import('@/views/orginization/import')
const OrginizationMaterial = () => import('@/views/orginization/material')
const OrginizationEmployee = () => import('@/views/orginization/employee')
const OrginizationSalaryCalculation = () => import('@/views/orginization/salaryCalculation')
const OrginizationAcademy = () => import('@/views/orginization/academy')
const OrginizationAcademyExam = () => import('@/views/orginization/academyExam')
const EducationCourse = () => import('@/views/education/course')
const EducationAchievement = () => import('@/views/education/achievement')
const EducationCertificate = () => import('@/views/education/certificate')
// const OrginizationWxworkMsg = () => import('@/views/orginization/wxworkMsg')
const OrginizationMessages = () => import('@/views/orginization/messages')
const OrginizationKeywords = () => import('@/views/orginization/keywords')
const OrginizationZhuqiao = () => import('@/views/orginization/zhuqiao')

const orginizationRouter = {
    path: '/orginization',
    component: Layout,
    hide: true,
    parent:'orginizationRouter',
    meta: {
      title: '校区管理',
      requiresAuth: false,
      type:'orginization',
      icon: '#icon-HomeOutline',
      menusKey:5
    },
    children:[
          {
            path: 'index',
            component: Orginization,
            hide: false,
            parent:'orginizationRouter',
            meta: { title: '校区管理', icon: '#icon-HomeOutline' },
          }, {
            path: 'wxWork-keywords',
            component: OrginizationKeywords,
            hide: true,
            parent:'orginizationRouter',
            meta: { title: '关键字提醒', icon: '#icon-HomeOutline' },
          }, {
            path: 'monitor',
            component: OrginizationMonitor,
            hide: true,
            meta: { title: '校区实况' }
          }, {
            path: 'notification',
            component: OrginizationNotification,
            hide: true,
            meta: { title: '通知中心' }
          }, {
            path: 'suggestion',
            component: OrginizationSuggestion,
            hide: true,
            meta: { title: '意见反馈' }
          }, {
            path: 'stock',
            component: OrginizationStock,
            hide: true,
            meta: { title: '库存管理' }
          }, {
            path: 'room',
            component: OrginizationRoom,
            hide: true,
            meta: { title: '校区教室' }
          }, {
            path: 'studio',
            component: OrginizationStudio,
            hide: true,
            meta: { title: '校区信息' }
          }, {
            path: 'contract',
            component: OrginizationContract,
            hide: true,
            meta: { title: '签约合同' }
          }, {
            path: 'logging',
            component: OrginizationLogging,
            hide: true,
            meta: { title: '操作记录' }
          }, {
            path: 'import',
            component: OrginizationImport,
            hide: true,
            meta: { title: '数据导入' }
          }, {
            path: 'material',
            component: OrginizationMaterial,
            hide: true,
            meta: { title: '素材管理' }
          }, {
            path: 'employee',
            component: OrginizationEmployee,
            hide: true,
            meta: { title: '员工管理' }
          }, {
            path: 'salary_calculation',
            component: OrginizationSalaryCalculation,
            hide: true,
            meta: { title: '工资结算' }
          }, {
            path: 'academy',
            component: OrginizationAcademy,
            hide: true,
            meta: { title: '在线课程' }
          }, {
            path: 'academy_exam',
            component: OrginizationAcademyExam,
            hide: true,
            meta: { title: '考试题库' }
          },{
            path: 'course',
            component: EducationCourse,
            hide: true,
            meta: { title: '课程科目' }
          },{
            path: 'achievement',
            component: EducationAchievement,
            hide: true,
            meta: { title: '学员成就' }
          }, {
            path: 'certificate',
            component: EducationCertificate,
            hide: true,
            meta: { title: '学员证书' }
          },{
            path: 'wxwork-msg',
            component: OrginizationMessages,
            hide: true,
            meta: { title: '会话存档' }
          }, {
            path: 'zhuqiao',
            component: OrginizationZhuqiao,
            hide: true,
            meta: { title: '筑桥应用' }
          }
    ]
}

export default orginizationRouter