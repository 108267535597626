import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 教务中心-列表
 */
export function educationList() {
  return request({
    url: url.affairs,
    method: 'POST'
  })
}

export function scheduleCourseList(data) {
  return request({
    url: url.scheduleCourseList,
    method: 'POST',
    data
  })
}

export function scheduleLeave(data) {
  return request({
    url: url.scheduleLeave,
    method: 'POST',
    data
  })
}

export function scheduleWait(data) {
  return request({
    url: url.scheduleWait,
    method: 'POST',
    data
  })
}

export function scheduleAttend(data) {
  return request({
    url: url.scheduleAttend,
    method: 'POST',
    data
  })
}

export function scheduleExportBook(data) {
  return request({
    url: url.scheduleExportBook,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function scheduleHomework(data) {
  return request({
    url: url.scheduleHomework,
    method: 'POST',
    data
  })
}

export function scheduleAddHomework(data) {
  return request({
    url: url.scheduleAddHomework,
    method: 'POST',
    data
  })
}

export function scheduleUpdateHomework(data) {
  return request({
    url: url.scheduleUpdateHomework,
    method: 'POST',
    data
  })
}

export function scheduleDeleteHomework(data) {
  return request({
    url: url.scheduleDeleteHomework,
    method: 'POST',
    data
  })
}

export function scheduleHomeworkFinishDetail(data) {
  return request({
    url: url.scheduleHomeworkFinishDetail,
    method: 'POST',
    data
  })
}

export function scheduleMessages(data) {
  return request({
    url: url.scheduleMessages,
    method: 'POST',
    data
  })
}

export function scheduleClasses(data) {
  return request({
    url: url.scheduleClasses,
    method: 'POST',
    data
  })
}

export function scheduleIndex(data) {
  return request({
    url: url.scheduleIndex,
    method: 'POST',
    data
  })
}

export function courseRoom(data) {
  return request({
    url: url.courseRoom,
    method: 'POST',
    data
  })
}

export function scheduleHeatmap(data) {
  return request({
    url: url.scheduleHeatmap,
    method: 'POST',
    data
  })
}

export function scheduleTeachStatis(data) {
  return request({
    url: url.scheduleTeachStatis,
    method: 'POST',
    data
  })
}

export function scheduleLeaveApprove(data) {
  return request({
    url: url.scheduleLeaveApprove,
    method: 'POST',
    data
  })
}

export function scheduleAddComment(data) {
  return request({
    url: url.scheduleAddComment,
    method: 'POST',
    data
  })
}

export function scheduleAudition(data) {
  return request({
    url: url.scheduleAudition,
    method: 'POST',
    data
  })
}

export function scheduleAuditionExport(data) {
  return request({
    url: url.scheduleAuditionExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function scheduleComment(data) {
  return request({
    url: url.scheduleComment,
    method: 'POST',
    data
  })
}

export function schedulecourseExport(data) {
  return request({
    url: url.schedulecourseExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}