<template>
    <a-modal title="积分操作"  v-model="visible" :confirmLoading="confirmLoading" 
        cancelText="取消" @cancel="handleCancel()" okText="保存" width="35%" :footer="null">
            <a-form :form="form" layout='vertical'>
                <a-form-item label="类型" >
                    <a-radio-group v-decorator="rules.add_credit">
                        <a-radio :value="0">增加积分</a-radio>
                        <a-radio :value="1">减少积分</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="积分值" >
                    <a-input-number style="width:150px" v-decorator="rules.credit_points" :min="1" />
                </a-form-item>
                <a-form-item label="备注" >
                    <a-textarea
                        v-decorator="rules.remark"
                        :autoSize="{ minRows: 3, maxRows: 5 }"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="submitData"  :loading='loading'>保存</a-button>
                </a-form-item>
            </a-form>
    </a-modal>
</template>
<script>
export default {
    name:'jfcz',
    inject: ['parent'],
    props: {
        studentId: Number
    },
    data(){
        return{
            loading:false,
            confirmLoading: false,
            visible: false,
            form: this.$form.createForm(this),
            rules: {
                add_credit: ['add_credit', {valuePropName:'value', rules: [{required: true, message: '请选择类型!'}] }],
                credit_points: ['credit_points', { rules: [{ required: true, message: '请输入积分值!' }] }],
                remark: ['remark', { rules: [] }],
            },
        }
    },
    provide() {
        return {
            parent: this
        }
    },
    async created(){
        this.visible = true
        await this.$nextTick()
        this.form.setFieldsValue({add_credit: 0})
        
    },
    beforeDestroy() {
        this.visible = false
    },
    methods:{
        handleCancel(num) {
            this.parent.hideJfcz(num)
        },
        async submitData(){
            this.loading = true
            let data = await this.form.validateFields()
            data.student_id = this.studentId
            if(data.add_credit === 0){
                this.addCredit(data)
                this.parent.getRefresh()
            }else{
                this.minusCredit(data)
                this.parent.getRefresh()
            }
        },
        async addCredit(obj){
            await this.$store.dispatch('studentsAddCreditAction',obj)
            .then(res=>{
                this.$message.success('操作成功!')
                this.handleCancel(1)
            })
            .catch(err=>{
                this.loading = false
                this.$message.success('操作失败!')
            })
        },
        async minusCredit(obj){
            await this.$store.dispatch('studentsMinusCreditAction',obj)
            .then(res=>{
                this.$message.success('操作成功!')
                this.handleCancel(1)
            })
            .catch(err=>{
                this.loading = false
                this.$message.success('操作失败!')
            })
        }
    }
}
</script>