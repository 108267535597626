const Layout = () => import('@/views/layout')
const RSS = () => import('@/views/RSS')

const RSSRouter = {
    path: '/labs',
    component: Layout,
    hide: true,
    parent:'RSSRouter',
    meta: {
      title: 'RSS',
      requiresAuth: false,
      type:'labs',
      icon: '#icon-rss',
      menusKey:11,
    },
    children:[
          {
            path: 'rss',
            component: RSS,
            hide:false,
            parent:'labs',
            meta: { title: 'RSS', icon: '#icon-rss' ,parent:'labs'}
          }
    ]
}

export default RSSRouter