<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>进出班操作</span>
              </template>
              <a-select v-model="searchParams.search.action" allowClear placeholder="请选择进出班操作" style="width: 160px">
                <a-select-option v-for="(item, index) of actionStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: '1' },
  { title: '操作', dataIndex: 'action', key: '2'},
  { title: '经办人', dataIndex: 'created_by', key: '3' },
  { title: '操作时间', dataIndex: 'created_at', key: '4',align:'right' },
]

import tableMixins from '@/common/mixins/table'
import cItems from '@/utils/items'

export default {
  name: 'bjgl',
  mixins: [ tableMixins ],

  props: {
    id: Number
  },
  components: {
  },

  data() {
    return {
      loading: false,
      columns,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      actionStatus: cItems.actionStatus
    }
  },

  created() {
  },

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.class_id = this.id
      let res = await this.$store.dispatch('classesMemberRecordAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
