import Vue from 'vue'
import { financeRefund, financeRefundCard, financeRefundList, financeRefundView, financeRefundApply,
  financeInvoice, financeInvoiceUpdate, financeInvoiceCheck, financeInvoicePullback, financeInvoiceMultiAction,
  financeInvoiceSwitch, financeInvoiceDetail, financeInvoiceRollback, financeStatistics, financeSalaryCalculationRules, financeSalaryCalculationAddRules,
  financeSalaryCalculationUpdateRules, financeSalaryCalculationDeleteRules, financeSalaryCalculationCalculation, financeSalaryCalculationSetCalculation, 
  financeSalaryCalculationTeacher,financeSalaryColumns,financeSalaryAddColumns,financeSalaryUPdateColumns,financeSalaryDeleteColumns,financeSalary,
  financeSalaryUpdateOrder,financeSalaryCalculationCalculationExport,financeSalaryBill,financeSalaryDeleteBill,financeSalaryUpdate,financeSalaryDelete,
  financeSalaryConfirm,financeSalaryExport,financeSalaryExample,financeSalaryImport,financeRefundExport,paymentRefundExport,financeSalaryCalculationCommision,
  financeSalaryCalculationRe,financeSalaryCalculationExportCommision} from '@/api/finance'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    financeRefundAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefund(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    paymentRefundExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        paymentRefundExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeRefundCardAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefundCard(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeRefundListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefundList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeRefundExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefundExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeRefundViewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefundView(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeRefundApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeRefundApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceCheckAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceCheck(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoicePullbackAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoicePullback(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceMultiActionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceMultiAction(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceSwitchAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceSwitch(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeInvoiceRollbackAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeInvoiceRollback(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeStatisticsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeStatistics(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationRulesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationRules(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationAddRulesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationAddRules(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationUpdateRulesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationUpdateRules(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationDeleteRulesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationDeleteRules(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationCalculationAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationCalculation(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationCommisionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationCommision(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationCalculationExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationCalculationExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationExportCommisionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationExportCommision(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryCalculationReAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryCalculationRe(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalary(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryColumnsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryColumns(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryAddColumnsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryAddColumns(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryUPdateColumnsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryUPdateColumns(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryDeleteColumnsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryDeleteColumns(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryUpdateOrderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryUpdateOrder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryBillAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryBill(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryDeleteBillAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryDeleteBill(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryConfirmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryConfirm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryImportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryImport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    financeSalaryExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        financeSalaryExample(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
