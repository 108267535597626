<template>
  <div class="dashboard">
    <div class="clearfix table-tools">
        <div class="search">
          <a-form layout='inline'>
              <a-form-item>
                <a-tooltip placement="topLeft" >
                    <template slot="title">
                    <span>时间筛选</span>
                    </template>
                    <a-range-picker
                    style="width: 240px"
                    :ranges="rangesData"
                    v-model='searchDatas.promise'
                    allowClear
                    @change="(val,time)=>handleTime(val,time,'promise')" />
                </a-tooltip>
              </a-form-item>
              <a-form-item>
                <a-button @click="searchList" type="primary">搜索</a-button>
              </a-form-item>
          </a-form>
        </div>
    </div> 
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;">补课</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '班级名称', dataIndex: 'classes_name', key: '3' },
  { title: '授课老师', dataIndex: 'teacher_name', key: '5' },
  { title: '课程时间', dataIndex: 'course_date_time', key: '7' },
  { title: '请假理由', dataIndex: 'cancel_reason', key: '8' },
  { title: '请假详情', dataIndex: 'cancel_remark', key: '9' },
  { title: '请假凭证', dataIndex: 'homework_date', key: '10' },
  { title: '请假时间', dataIndex: 'canceled_at', key: '11' },
]
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
export default {
  name: 'qjjl',
  props: {
    studentId: Number
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchDatas: {},

      visible: false,
      refundVisible: false,
    }
  },

  mixins:[tableMixins,ranges],

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileCancelClassAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleTime(val,time,name){
        this.searchDatas[name] = val
        this.searchParams.search.start_date = time[0]
        this.searchParams.search.end_date = time[1]
    },
  }
}
</script>
