<template>
  <div id="app" class="app" style="height: 100%;">
    <a-config-provider :locale="zh_CN">
        <!-- <a-spin :spinning="loading"> -->
        <!-- <a-spin :spinning="true"> -->
          <router-view />
        <!-- </a-spin> -->
    </a-config-provider>
    <!-- <HelpCenters @showHelp="toShowHelp" v-if="helpShow" /> -->
    <!-- <v-tour name="myTour" :steps="steps" :options="tourOptions" :callbacks="myCallback"></v-tour> -->
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapState } from 'vuex'
import store from '@/store'
import {tourOptions,tour_list} from '@/utils/tours'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')


export default {
  provide() {
    return {
      parent: this
    }
  },
  watch: {
    "$route": function(val) {
      if(val.path=='/datav'){
        this.helpShow=false
      }else{
        this.helpShow=true
      }
    }
  },
  data() {
    return {
      zh_CN,
      helpShow:true,
      steps:[],
      tourOptions:tourOptions,
      myCallback:{
        onStart: this.myonStart, // 在您开始游览时调用
			  onStop: this.myonStop, //停止游览时调用
		    onPreviousStep: this.myonPreviousStep, //转到上一步时调用
		    onNextStep: this.myonNextStep //进行下一步时调用
      }
    }
  },
  computed: {
    ...mapState({
      loading: state => state.loading.loadding
    })
  },
  methods: {
    toShowHelp(arr,num) {
      let that = this
      let steps = []
      this.steps = []
      if(num == 3){
        steps = tour_list[arr[1]][arr[2]]?tour_list[arr[1]][arr[2]]:[]
      }else if(num == 4){
        steps = tour_list[arr[1]][arr[2]] && tour_list[arr[1]][arr[2]][arr[3]]?tour_list[arr[1]][arr[2]][arr[3]]:[]
      }
      if(steps.length>0){
        steps.forEach(item=>{
          if(document.querySelector(item.target)){
            this.steps.push(item)
          }
        })
        setTimeout(()=>{
          that.$tours['myTour'].start()
        },100)
      }else{
        this.$message.success(
        '帮助引导正在添加中。',
        3,
      );
      }
    },
    myonStart(a,b){
      console.log(a,b,'myonStart')
    },
    myonStop(a,b){
      console.log(a,b,'myonStop')
    },
    myonPreviousStep(a,b){
      console.log(a,b,'myonPreviousStep')
    },
    myonNextStep(a,b){
      console.log(a,b,'myonNextStep')
    },
  },
}
</script>
<style lang="scss">
  .app{
    .ant-spin-blur{
     opacity:1;
    }
    .ant-spin-blur::after{
     opacity:0;
    }
    .ant-pagination{
      .ant-pagination-total-text{
        float: left;
      }
    }
    .ant-spin-nested-loading > div > .ant-spin{
      max-height: 100%;
      z-index: 10000;
    }
  }
  .ant-tag-blue{
    color:#00cca2 !important;
    border-color:#00cca2 !important;
    background:#fff!important;
  }
  .ant-table-header-column{
    font-size: 14px;
  }
  .ant-table{
    font-size: 12px !important;
  }
  .pswp{
    z-index: 4004 !important;
  }
  .velmld-overlay{
    z-index: 10 !important;
  }
  .ant-modal-content .ant-form .ant-form-item{
      margin-bottom: 6px;
  }
  .clue-tree{
      .ant-select-tree-checkbox-disabled{
          display: none;
      }
      .ant-select-tree-treenode-disabled {
          .ant-select-tree-node-content-wrapper{
              color: rgba(0, 0, 0, 0.65) !important;
              cursor: pointer !important;
              .ant-select-tree-title{
                  color: rgba(0, 0, 0, 0.65) !important;
                  cursor: pointer !important;
              }
              
          }
      }
  }
  .clue-tree-inp{
    .ant-select-selection__choice__content{
      max-width: 95px !important;
    }
  }
</style>
