import Vue from 'vue'
import { notificationNotice,notificationCenter,notificationCenterAdd,notificationCenterUpdate,notificationCenterDetail,notificationCenterDelete,
  notificationCenterRecovery,notificationCenterResend,notificationCenterNotifyList,notificationCenterAjaxGetUser,notificationOverview,notificationIndex,
  notificationUnread,notificationSeen,notificationSeenAll} from '@/api/notification'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    notificationNoticeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationNotice(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenter(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterRecoveryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterRecovery(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterResendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterResend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterNotifyListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterNotifyList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationCenterAjaxGetUserAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationCenterAjaxGetUser(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    notificationOverviewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationOverview(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    notificationIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    notificationSeenAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationSeen(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    notificationSeenAllAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        notificationSeenAll(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    notificationUnreadAction({ commit }) {
        return new Promise((resolve,reject) => {
          notificationUnread().then(response => {
            const { data } = response
            resolve(data)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
      }
    }
}
