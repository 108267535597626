<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="450px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-date-picker v-else-if="item.type === 'date'" v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-select allowClear mode="multiple" v-else-if="item.type === 'select-multi'" placeholder="请选择"
            v-decorator="[item.name, { rules: item.rules }]">
            <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-cascader
          v-else-if="item.type === 'cascader'"
          :options="seleteItems[item.items.data]"
          :show-search="{ filterOption }"
          v-decorator="[item.name, { rules: item.rules }]"
          placeholder="请选择"
          :popupStyle='{zIndex:4200}'
          @change="onChangeCascader">
            <template slot="displayRender" slot-scope="{labels}">
                <span v-if="!showCascaderLabel">{{labels[labels.length-1]}}</span>
                <span v-if="showCascaderLabel"></span>
            </template>
        </a-cascader>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import moment from 'moment'

const formData = [
  {
    name: 'room_id',
    label: '上课教室',
    type: 'select',
    rules: [],
    items: {
      data: 'rooms',
      label: 'filter_name',
      value: 'room_id'
    }
  },
  {
    name: 'teacher_id',
    label: '授课老师',
    type: 'cascader',
    rules: [],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'teacher_assistant_ids',
    label: '助教老师',
    type: 'select-multi',
    rules: [],
    items: {
      data: 'teachers_list',
      label: 'filter_name',
      value: 'teacher_id',
      name:'getTeacher'
    }
  }
]

export default {
  name: 'SubstituteModal',
  inject: ['parent'],
  props: {
    item: Object,
    classId: String | Number,
  },
  
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      showCascaderLabel: false,
      visible: false,
      ModalTitle: '设置代课老师',

      seleteItems: {
        rooms: [],
        teachers: [],
        teachers_list:[]
      },

      form: this.$form.createForm(this),
      formData,
      teacher_id:'',
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  created() {
    this.visible = true
    this.getRoom()
    this.getTeachers()
    this.getTeacherList()
  },

  methods: {
    async getRoom() {
      let res = await this.$store.dispatch('searchStudioRoomAction', {params:{class_id:this.classId}})
      this.seleteItems.rooms = res.data
      this.form.setFieldsValue({room_id:this.item.room.room_id})
    },
    async getTeacherList() {
      let res = await this.$store.dispatch('searchTeacherAction', {params:{class_id:this.classId}})
      this.seleteItems.teachers_list = res.data
    },
    async getTeachers() {
      let res = await this.$store.dispatch('searchTeacherCascaderAction', {})
      this.seleteItems.teachers = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        this.confirmLoading=true
        if (params) {
          if (item) {
            params.course_class_id = item.course_class_id
          }
          if(this.teacher_id){
            params.teacher_id = this.teacher_id[this.teacher_id.length-1]
          }
          await this.$store.dispatch('courseScheduleSubstituteAction', {data:params})
            .then(res=>{
              this.$message.success('操作成功!')
              form.resetFields()
              this.parent.hideSubModal(1)
              // this.parent.hideTeacherModal(1)
            })
            .catch(err=>{
              this.confirmLoading=false;
            })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideSubModal()
    },
    onChangeCascader(value, selectedOptions) {
      if(selectedOptions && selectedOptions[selectedOptions.length-1].department_id){
        this.$message.warning('您选择的是部门!!')
        this.showCascaderLabel = true
        return false
      }
      this.showCascaderLabel = false
      this.teacher_id = value
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
