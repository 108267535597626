/* 产引入jsencrypt实现数据RSA加密 */
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
/* 产引入encryptlong实现数据RSA加密 */
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。
 
// 密钥对生成 http://web.chacuo.net/netrsakeypair
 
// 公钥key
const publicKey = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwGLdVSeOCv2kkWLcwsWy0jJ9N
iGt/QI86M9eMyFZY4u0Ul+lr/H/pdfDU8aqR6uw6LyyD/esgWonU3ScfI28LXCPo
KHFcRhmVXBYhbymIsAPftnQ1NO6UzvricfjeZSo7uYXMMV5BstJQK6FuRsm4JlRU
z/OpiHz9hpYxLWm3VwIDAQAB
-----END PUBLIC KEY-----
`
// 私钥key
const privateKey = `
-----BEGIN RSA PRIVATE KEY-----
MIICXQIBAAKBgQCwGLdVSeOCv2kkWLcwsWy0jJ9NiGt/QI86M9eMyFZY4u0Ul+lr
/H/pdfDU8aqR6uw6LyyD/esgWonU3ScfI28LXCPoKHFcRhmVXBYhbymIsAPftnQ1
NO6UzvricfjeZSo7uYXMMV5BstJQK6FuRsm4JlRUz/OpiHz9hpYxLWm3VwIDAQAB
AoGAW1ammUV3DZVM6v5dHpOYx9l8s5GD+IA7CjxjnHE5YGdsA1d8SnewvR5gqYMk
LwWEO/XGCDyHXcO4ZEHFjgAqEwRCQr8vGbUI/UumYEP/d2HV0g8uY5su61EWdjfd
eYz8LLSt/GJh4YO5yKA1mU6dcNLNFMBlPDtCJnNtz/FB2gkCQQDnph5G7kyGMF5A
JLwgguyA9a4jbcG1GQAtR6raZMSq1RLMTRi/4SccuSIerFgTgybQSoGmA0+KaLNV
ISK/0OgbAkEAwpuiPfpvhRbEf4b/ug82WQ3ZF9C2OhYKt5JXmpAmsVXRQJWDFZBS
2mT4Bgb2GgqSoSzuF0jnRQaMScAKEx8ZdQJBALcX/eZig+msYVKhtnCcCwMDLNij
7++MCy++7bQg4bGFKI6CgYVoGNoDGozT++mwSAVqXn8xLrVffB9VSXoDTPECQE86
P1YONAKx3zOwCUJ9WPnueliIvvds69YKfSHO2AHQV0180Q4hYKnK+3KwuNanQUTe
3tuZ8ZWXcdvAlBluytUCQQCVoh1c3G8VmM9NOgPx5Ca6pZNz0y3/iEFnzl2A0/4y
OASy/2wgFTD9yw1ozTJevnDrgmk0Ld07kr6hVN7i1G9Z
-----END RSA PRIVATE KEY-----
`
 
export default {
  /* JSEncrypt加密 */
  rsaPublicData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
  },
  /* JSEncrypt解密 */
  rsaPrivateData(data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPrivateKey(privateKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
  },
  /* 加密 */
  encrypt(data) {
    const PUBLIC_KEY = publicKey
    var encryptor = new Encrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encryptLong(data)
    return result
  },
  /* 解密 - PRIVATE_KEY - 验证 */
  decrypt(data) {
    const PRIVATE_KEY = privateKey
    var encryptor = new Encrypt()
    encryptor.setPrivateKey(PRIVATE_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = encryptor.decryptLong(data)
    return result
  }
}

