<template>
  <div class="c-side">
    <div @click="showEditModal($event)" class="material-left-nav">
        <a-icon type="plus" />
        <span style="margin-left:10px">添加分组</span>
    </div>
    <div class="list">
      <perfect-scrollbar class="m-scrollbar">
      <div class="item" :class="{ 'active': !cateId }" @click="clickCategory({path_id: '', name: '默认分类'})">
        <div class="name">默认分类</div>
      </div>
      <div class="item material-btns" :class="{ 'active': cateId === item.path_id }" 
        v-for="(item, index) of folders" :key="index" @click="clickCategory(item)">
        <div class="name">{{ item.name }}</div>
        <a-dropdown placement="bottomRight" class='material-btns-icon'>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="more" />
            </a>
            <a-menu slot="overlay">
            <a-menu-item @click="deleteCategory($event, item)">
                删除
            </a-menu-item>
            </a-menu>
        </a-dropdown>
        <span class="material-btns-count">( {{item.count}} )</span>
        <div class="btns material-btns">
        </div>
      </div>
      </perfect-scrollbar>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>

<script>
import editModal from './editModal'

export default {
  name: 'Category',
  inject: [ 'parent' ],
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal
  },

  data() {
    return {
      loading: false,
      cateId: '',
      folders: [],
      searchParams: { q: '' },

      visible: false,
      modalData: null
    }
  },

  async created() {
    await this.getList()
    this.parent.changeCategory({path_id: '', name: '默认分类'})
  },

  methods: {
    async getList() {
      let res = await this.$store.dispatch('materialFolderAction', { data: this.searchParams })
      if (res.items) {
        this.folders = res.items
      }
    },
    clickCategory(item) {
      this.cateId = item.path_id
      this.parent.changeCategory(item)
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },
    deleteCategory(e, item) {
      // e.stopPropagation()
      let that = this
      this.$confirm({
        title: `确定要删除图片分类"${item.name}"?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('materialAjaxDeleteAction', { data: { image_id: item.path_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .material-left-nav{
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  .material-btns{
    display: flex;
    align-items: center;
    &-icon{
        position: absolute;
        right: 10px;
        cursor: pointer;
    }
    &-count{
        position: absolute;
        right: 25px;
        font-size: 12px;
        color: #999;
    }
  }
</style>
