<template>
    <div class="chatrecord">
        <div v-if="keyword">
            <div @click="toBack" class="chatrecord-back">
                <a-icon type="left" />
                <span>返回</span>
            </div>
        </div>
        <div>
            <checkRecord v-if="msgID && keyword" :keyword="keyword" :staff_ext_id="staff_ext_id" :chatItem="chatItem" :wHeight="wHeight" :msgID="msgID" />
            <searchRecord v-if="keyword" v-show="!msgID" :staff_ext_id="staff_ext_id" :chatItem="chatItem" :wHeight="wHeight" :keyword="keyword" />
        </div>
        <div 
            v-show="!keyword"
            class="chatrecord-list"
            @scroll="handleScroll"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            ref="scrollbox"
        >
            <vue-element-loading :active="loading && list.length ==0" color="#00cca2" spinner="spinner"/>
            <a-list  item-layout="horizontal" :data-source="list" :split='false'>
                <a-list-item v-if="item.msgtype !='revoke'" slot="renderItem" slot-scope="item, index">
                    <div style="width:100%">
                        <div v-if="item.showTime>300000 || index == 0" class="chatrecord-item-msgtime">{{item.msgtime_txt}}</div>
                        <div class="chatrecord-item" :class="item.direction == 'receive'?'chatrecord-receive':'chatrecord-send'">
                            <div class="chatrecord-item-avatar">
                                <a-badge :dot="(item.is_staff || item.student_id)?true:false" :class="item.is_staff?'warning':'success'" >
                                    <a-avatar style="cursor:pointer" shape="square" :size="34" icon="user" :src="item.avatar" />
                                </a-badge>
                            </div>
                            <div style="width:100%">
                                <div class="chatrecord-item-username">{{item.name}}</div>
                                <div class="chatrecord-item-content">
                                    <div v-if="item.msgtype =='text' && item.text" class="chatrecord-item-text" v-html="convertText2Emoji(item.text.content)"></div>
                                    <div v-if="item.msgtype =='image' && item.image" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-emotion" :style="{height:`${item.image.height?item.image.height:260}px`,maxWidth:'460px',overflow: 'hidden'}">
                                            <img :preview="item.previewIndex? 1:0" @error="handleError(item)" class="chatrecord-item-image" :src="item.image.path" :style="{height:`${item.image.height}px`}">
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='emotion' && item.emotion" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-emotion" :style="{height:`${item.emotion.height}px`,maxWidth:'460px',overflow: 'hidden'}">
                                            <img :preview="item.previewIndex? 1:0" @error="handleError(item)" class="chatrecord-item-image" :src="item.emotion.path" :style="{height:`${item.emotion.height}px`}">
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='voice' && item.voice" class="chatrecord-item-text">
                                        <div @click="toPlayVoice(item,index,1)">
                                            <div class="chatrecord-item-voice">
                                                <img v-if="item.is_play" src="@/assets/laba.gif">
                                                <img v-else src="@/assets/laba.png">
                                                <div :style="{width:voiceLength(item.voice.play_length),marginLeft:'10px'}">{{item.voice.play_length}}s</div>
                                            </div>
                                            <div v-if="item.voice.voice_translate" style="border-top:1px solid #e8e8e8;margin-top:3px">{{item.voice.voice_translate}}</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='card' && item.card" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-card">
                                            <div class="chatrecord-item-card-nav">
                                                <div class="chatrecord-item-card-name">{{item.card.name}}</div>
                                                <div class="chatrecord-item-card-company">@{{item.card.corpname}}</div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">个人名片</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='location' && item.location" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-location">
                                            <div class="chatrecord-item-location-nav">
                                                <div class="chatrecord-item-location-icon"><a-icon theme="twoTone" two-tone-color="#00cca2" :style="{fontSize:'20px'}" type="environment" /></div>
                                                <div>
                                                    <div class="chatrecord-item-location-name">{{item.location.title}}</div>
                                                    <div class="chatrecord-item-location-company">{{item.location.address}}</div>
                                                </div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">位置</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='chatrecord' && item.chatrecord" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-card">
                                            <div class="chatrecord-item-card-nav">
                                                <div style="font-size:14px" class="chatrecord-item-card-name">{{item.chatrecord.title}}的聊天记录</div>
                                                <div class="chatrecord-item-card-company">{{item.chatrecord.item.length}}条聊天记录</div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">聊天记录</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='weapp' && item.weapp" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-weapp">
                                            <div class="chatrecord-item-weapp-nav">
                                                <div class="chatrecord-item-weapp-displayname">{{item.weapp.displayname}}</div>
                                                <div class="chatrecord-item-weapp-name">{{item.weapp.title}}</div>
                                                <div class="chatrecord-item-weapp-description">{{item.weapp.description}}</div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">小程序</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='sphfeed' && item.sphfeed" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-weapp">
                                            <div class="chatrecord-item-weapp-nav">
                                                <div class="chatrecord-item-weapp-displayname">{{item.sphfeed.sph_name}}</div>
                                                <div class="chatrecord-item-weapp-description">{{item.sphfeed.feed_desc}}</div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">视频号</div>
                                        </div>
                                    </div>
                                    <div @click="toVideo(item)" v-if="item.msgtype =='video' && item.video" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-video">
                                            <a-icon style="font-size:50px" type="play-circle" />
                                            <span>{{formatTime(item.video.play_length-1)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='redpacket' && item.redpacket" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-hongbao">
                                            <div class="chatrecord-item-hongbao-nav">
                                                <img src="@/assets/hongbao.svg">
                                                <span v-html="convertText2Emoji(item.redpacket.wish)"></span>
                                            </div>
                                            <div class="chatrecord-item-hongbao-num">
                                                红包 (共{{item.redpacket.totalcnt}}个,总金额{{item.redpacket.totalamount}}元)
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='external_redpacket' && item.redpacket" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-hongbao">
                                            <div class="chatrecord-item-hongbao-nav">
                                                <img src="@/assets/hongbao.svg">
                                                <span v-html="convertText2Emoji(item.redpacket.wish)"></span>
                                            </div>
                                            <div class="chatrecord-item-hongbao-num">
                                                外部红包 (共{{item.redpacket.totalcnt}}个,总金额{{item.redpacket.totalamount}}元)
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='mixed' && item.mixed" class="chatrecord-item-text">
                                        <div v-for="(it,j) in item.mixed" :key="j">
                                            <div v-if="it.type =='text'" v-html="convertText2Emoji(it.content.content)"></div>
                                            <div style="width: 100%;overflow: hidden;margin-bottom:5px;" v-if="it.type =='image' || it.type =='emotion'">
                                                <img :preview="item.previewIndex? 1:0" @error="handleError(item,index)" class="chatrecord-item-image" :src="it.content.path">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='calendar' && item.calendar" class="chatrecord-item-otherText">
                                        <div class="chatrecord-item-card">
                                            <div class="chatrecord-item-card-nav">
                                                <div class="chatrecord-item-card-name">{{item.calendar.title}}</div>
                                                <div class="chatrecord-item-card-calendar">{{moment(item.calendar.start).format("MM月DD日 dddd")}} {{moment(item.calendar.start).format("HH:mm")}}-{{moment(item.calendar.end).format("HH:mm")}}</div>
                                                <div class="chatrecord-item-card-calendar">组织者: {{item.calendar.creatorname}}</div>
                                                <div v-if="item.calendar.place" class="chatrecord-item-card-calendar">会议室: {{item.calendar.place}}</div>
                                                <div v-if="item.calendar.remarks" class="chatrecord-item-card-calendar">备注: {{item.calendar.remarks}}</div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">日程</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='agree'" class="chatrecord-item-text">
                                        <a-icon theme="twoTone" two-tone-color="#52c41a" type="check-circle" />
                                        <span style="margin-left:5px">同意了会话存档服务须知</span>
                                    </div>
                                    <div v-if="item.msgtype =='disagree'" class="chatrecord-item-text">
                                        <a-icon theme="twoTone" two-tone-color="#f5222d" type="close-circle" />
                                        <span style="margin-left:5px">拒绝了会话存档服务须知</span>
                                    </div>
                                    <div v-if="item.msgtype =='file' && item.file" class="chatrecord-item-otherText">
                                        <div @click="toLink(item.file.path)" class="chatrecord-item-file">
                                            <div class="chatrecord-item-file-info">
                                                <div class="chatrecord-item-file-name">{{item.file.filename}}</div>
                                                <div v-if="!item.file.path" class="chatrecord-item-file-remind">暂不支持下载大于100MB文件</div>
                                                <div class="chatrecord-item-file-size">{{RenderSize(item.file.filesize)}}</div>
                                            </div>
                                            <div class="chatrecord-item-file-icon"><a-icon type="file" two-tone-color="#00cca2" theme="twoTone" :style="{fontSize:'36px'}" /></div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='link' && item.link" class="chatrecord-item-otherText">
                                        <div @click="toLink(item.link.link_url)" class="chatrecord-item-link">
                                            <div class="chatrecord-item-link-nav">
                                                <div style="flex:1">
                                                    <div class="chatrecord-item-link-name">{{item.link.title}}</div>
                                                    <div class="chatrecord-item-link-calendar">{{item.link.description}}</div>
                                                </div>
                                                <div class="chatrecord-item-link-img" v-if="item.link.image_url">
                                                    <img :src="item.link.image_url">
                                                </div>
                                            </div>
                                            <div @click.stop v-clipboard:copy="item.link.link_url" v-clipboard:success="onCopy" v-clipboard:error="onError"  class="chatrecord-item-card-foot">复制链接</div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='meeting_voice_call'" class="chatrecord-item-otherText">
                                        <div @click="toPlayVoice(item,index,2)" v-if="item.direction == 'receive'" class="chatrecord-item-call">
                                            <div class="chatrecord-item-call-left">
                                                <a-icon :style="{fontSize:'20px',marginRight:'5px'}" type="phone" />
                                                <div>通话时长{{formatTimeF(item)}}</div>
                                            </div>
                                            <div class="chatrecord-item-call-right">
                                                <a-icon v-if="item.is_play" :style="{fontSize:'22px',marginLeft:'10px'}" theme="twoTone" type="pause-circle" />
                                                <a-icon v-else :style="{fontSize:'22px',marginLeft:'10px'}" theme="twoTone" type="play-circle" />
                                            </div>
                                        </div>
                                        <div @click="toPlayVoice(item,index,2)" v-else class="chatrecord-item-call">
                                            <div class="chatrecord-item-call-right">
                                                <a-icon v-if="item.is_play" :style="{fontSize:'22px',margin:'0 10px'}" theme="twoTone" type="pause-circle" />
                                                <a-icon v-else :style="{fontSize:'22px',margin:'0 10px'}" theme="twoTone" type="play-circle" />
                                            </div>
                                            <div class="chatrecord-item-call-left">
                                                <div>{{formatTimeF(item)}}长时话通</div>
                                                <a-icon :style="{fontSize:'20px',marginLeft:'5px'}" type="phone" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.msgtype =='docmsg' && item.doc" class="chatrecord-item-otherText">
                                        <div @click="toLink(item.doc.link_url)" class="chatrecord-item-link">
                                            <div class="chatrecord-item-link-nav">
                                                <div>
                                                    <div class="chatrecord-item-link-title">{{item.doc.title}}</div>
                                                    <div>{{item.doc.doc_creator_name}}</div>
                                                </div>
                                            </div>
                                            <div class="chatrecord-item-card-foot">微文档</div>
                                            <a-icon class="chatrecord-item-link-icon" :style="{fontSize:'30px'}" type="file-word" />
                                        </div>
                                    </div>
                                    <div class="chatrecord-item-revoke" v-if="showRevoke(item)">此消息已撤回</div>
                                </div>
                                <div v-if="item.msgtype =='voice' && item.voice && item.voice.voice_translate" :class="item.direction == 'receive'?'':'chatrecord-item-translate-r'" style="margin-top:5px">
                                    <div class="chatrecord-item-translate">
                                        <a-icon type="check" :style="{ fontSize: '10px'}"/>
                                        <span>由AI语音转文字</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-list-item>
                <div slot="header">
                    <vue-element-loading :active="loading && list.length!=0" color="#00cca2" spinner="spinner"/>
                    <div style="text-align:center" v-if="busy && list.length!=0">没有更多了~</div>
                </div>
            </a-list>
        </div>
        <!-- <videoModal v-if="isVideoModal" :videoSrc="videoSrc" /> -->
        <!-- <profileModal v-if="profileVisible" :studentId="studentId"/> -->
    </div>
</template>

<script>


    import searchRecord from '@/views/orginization/messages/searchRecord'
    import checkRecord from '@/views/orginization/messages/checkRecord'
    import videoModal from '@/views/orginization/messages/videoModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import BenzAMRRecorder from 'benz-amr-recorder'
    import { parsingEmoji } from '@/utils/emjoymethod'
    import '@/styles/emjoy.css'
    import { RenderSize } from '@/utils/timeFormat'
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    import { delay } from '@/utils/timeFormat'
    import profileModal from '@/views/modal/profile'
    
    export default {
        name:'chatrecord',
        inject: ['parent'],
        data() {
            return {
                myHeight:0,
                myWidth:'',
                changeHeight:260,
                busy:true,
                loading:false,
                isVideoModal:false,
                profileVisible:false,
                list:[],
                revokeList:[],
                timer:'',
                searchParams: {
                    "page": 1,
                    "per-page": 30,
                    "search": {},
                    staff_ext_id:'',
                    session_ext_id:''
                },
                scrollHeight:0,
                previewIndex:-1,
                keyword:'',
                voiceIndex:-1,
                amr:'',
                msgID:'',
                videoSrc:'',
                tagList:[],
                studentId:''
            }
        },
        props:{
            staff_ext_id:String,
            chatItem:Object,
            class_id: Number,
        },
        provide() {
            return {
                parent: this
            }
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 540}px`
            },
        },
        components: {
            searchRecord,
            checkRecord,
            videoModal,
            profileModal
        },
        directives: { infiniteScroll },
        mixins: [ranges],
        created () {
            this.myHeight = this.windowHeight()
            this.myWidth = this.windowWidth()
        },
        methods: {
            moment,
            RenderSize,
            async getList(){
                this.loading = true
                this.searchParams.class_id = this.class_id
                this.searchParams.staff_ext_id = this.staff_ext_id
                this.searchParams.session_ext_id = this.chatItem.session_ext_id
                this.searchParams.session_type = this.chatItem.session_type
                let res = await this.$store.dispatch('classesWxworkMsgAction', this.searchParams)
                let msgtime = 0
                res.items.forEach(item=>{
                    if(item.msgtype =='revoke' && this.revokeList.indexOf(item.revoke.pre_msgid) == -1){
                        this.revokeList.push(item.revoke.pre_msgid)
                    }
                    if(msgtime == 0){
                        msgtime = Number(item.msgtime)
                        item.showTime = 0
                    }else{
                        item.showTime = Number(item.msgtime) - msgtime
                        msgtime = Number(item.msgtime)
                    }
                })
                if(res.items.length >0){
                    this.busy = false
                    if(this.searchParams.page == 1){
                        this.list = []
                        this.list = res.items
                    }else{
                        this.list = [...res.items,...this.list]
                    }
                }else{
                    this.busy = true
                }
                if(this.$refs.scrollbox){
                    if(this.searchParams.page == 1){
                        this.$nextTick(()=>{
                            this.$refs.scrollbox.scrollTop = 1000000
                            this.scrollHeight = this.$refs.scrollbox.scrollHeight
                        })
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.scrollbox.scrollTop = this.$refs.scrollbox.scrollHeight - this.scrollHeight
                            this.scrollHeight = this.$refs.scrollbox.scrollHeight
                        })
                    }
                }
                this.$previewRefresh()
                this.loading = false
            },
            handleScroll(e){
                let num = e.target.scrollTop
                this.scrollTops = num
                if(num == 0 && !this.busy && !this.keyword){
                    this.searchParams.page ++
                    this.loading = true
                    this.getList()
                }
                
            },
            handleInfiniteOnLoad(){
            },
            showRevoke(item){
                return this.revokeList.indexOf(item.msgid) > -1
            },
            voiceLength(num){
                let length = 250
                if(num < 250){
                    length = 20 + num
                }
                return `${length}px`
            },
            toPlayVoice(item,index,num){
                let that = this
                let srcSrt = ''
                if(num === 1){
                    srcSrt = item.voice.path
                }else{
                    srcSrt = item.voiceid
                }
                if(index == this.voiceIndex){
                    if(item.is_play){
                        item.is_play = false
                        this.amr.stop()
                        this.amr.onStop(function() {
                            console.log('暂停')
                        })
                    }else{
                        this.amr = new BenzAMRRecorder()
                        item.is_play = true
                        this.voiceIndex = index
                        this.amr.initWithUrl(srcSrt).then(function() {
                            that.amr.play()
                        })
                        this.amr.onEnded(function() {
                            item.is_play = false
                        })
                    }
                }else{
                    if(this.amr){
                        this.list[this.voiceIndex].is_play = false
                        this.amr.stop()
                        this.amr.onStop(function() {
                            console.log('暂停')
                        })
                    }
                    this.amr = new BenzAMRRecorder()
                    item.is_play = true
                    this.voiceIndex = index
                    this.amr.initWithUrl(srcSrt).then(function() {
                        console.log(that.amr)
                        that.amr.play()
                    })
                    this.amr.onEnded(function() {
                        item.is_play = false
                    })
                }
            },
            convertText2Emoji(text){
                return parsingEmoji(text)
            },
            handleInput(e){
            },
            handleTime(val){
                if(val.length == 0){
                    this.searchParams.search.start_date = ''
                    this.searchParams.search.end_date = ''
                }else{
                    this.searchParams.search.start_date = moment(val[0]).format('YYYY-MM-DD')
                    this.searchParams.search.end_date = moment(val[1]).format('YYYY-MM-DD')
                }
                this.busy = true
                this.list = []
                this.searchParams.page = 1
                this.getList(1)
            },
            toVideo(item){
                this.videoSrc = item.video.path
                this.isVideoModal = true
            },
            hideVideoModal(){
                this.isVideoModal = false
            },
            toBack(){
                if(this.msgID){
                    this.msgID = ''
                }else{
                    this.keyword = ''
                }
            },
            formatTime(second){
                var secondType = typeof second
                if (secondType === "number" || secondType === "string") {
                    second = parseInt(second)
                    var mimute = Math.floor(second / 60)
                    second = second - mimute * 60
                    let mimuteStr = mimute>=10?mimute:`0${mimute}`
                    let secondStr = second>=10?second:`0${second}`
                    return `${mimuteStr}:${secondStr}`
                } else {
                    return "00:00"
                }
            },
            formatTimeF(item){
                if(item.meeting_voice_call){
                    let endtime = item.meeting_voice_call.endtime * 1000
                    let seconds = moment(endtime).diff(moment(item.msgtime),'seconds')
                    return this.formatTime(seconds)
                }else{
                   return '00:00'
                }
            },
            showDrawer(){
                this.parent.isDetailDrawer = true
            },
            handleError(item){
                item.previewIndex = true
                this.$forceUpdate()
                this.$previewRefresh()
            },
            toLink(src){
                if(src)
                    window.open(src)
            },
            onCopy(){
                this.$message.success('已复制到剪切板!')
            },
            onError(){
                this.$message.error('操作失败!')
            },
            windowWidth() {
                return document.body.offsetWidth
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            changeStaff(item){
                if(item.is_staff){
                    item.userid = item.from
                    this.parent.changeStaff(item)
                }else{
                    if(item.student_id){
                        this.studentId = item.student_id
                        this.profileVisible = true
                    }
                }
            },
            hideProfileModal() {
                this.profileVisible = false
            },
        },
        watch: {
            chatItem(newValue, oldValue) {
                this.busy = true
                this.searchParams.page = 1
                this.list = []
                this.tagList = this.chatItem.tags
                this.keyword = ''
                this.loading = true
                delay(() => {
                    this.getList()
                }, 500)
            },
            keyword(newValue){
                if(newValue){
                    this.changeHeight = 300
                }else{
                    this.changeHeight = 260
                }
            },
            leadsId(newValue, oldValue) {
                this.busy = true
                this.searchParams.page = 1
                this.list = []
            }
        },
    }
</script>

<style lang="scss" scoped>
    .chatrecord{
        .chat_tag{
            color: #999 !important;
            border-color: #f5f7fa !important;
            border-bottom-left-radius: 11px;
            border-top-left-radius: 11px;
            border-top-right-radius: 11px;
            border-bottom-right-radius: 11px;
        }
        &-nav{
            height: 55px;
            display: flex;
            align-items: center;
            border-bottom:1px solid #eee;
            position: relative;
            &-msgaudit{
                font-size: 12px;
                color: #909090;
            }
        }
        &-back{
            display: flex;
            align-items: center;
            height: 40px;
            text-align: center;
            padding-left: 5px;
            width: 60px;
            cursor: pointer;
            color: #00cca2;
        }
        &-list{
            overflow-y: scroll;
        }
        &-item{
            width: 100%;
            display: flex;
            &-username{
                margin-bottom: 5px;
            }
            &-msgtime{
                width: 100%;
                text-align: center;
                padding: 5px 0;
            }
            &-content{
                width: 100%;
                align-items: flex-end;
            }
            &-text{
                max-width: 80%;
                padding: 8px;
                white-space: pre-wrap;
                word-break: break-word;
                border-radius: 2px;
                cursor: auto;
            }
            &-otherText{
                max-width: 80%;
                white-space: pre-wrap;
                word-break: break-word;
                border-radius: 2px;
                cursor: pointer;
            }
            &-emotion{
                max-height: 260px;
            }
            &-image{
                max-height: 260px;
                border-radius: 2px;
            }
            &-revoke{
                font-size: 12px;
                font-size: 12px;
                margin: 0 10px;
                padding: 0 6px;
                border-radius: 4px;
                background: #e6f7ff;
                color: #383838;
            }
            &-voice{
                display: flex;
                align-items: center;
                img{
                    width: 14px;
                }
            }
            &-card{
                width: 260px;
                cursor: pointer;
                border: 1px solid #e8e8e8;
                font-size: 12px;
                color: #909090;
                background: #fff;
                border-radius: 2px;
                &-nav{
                    padding: 10px;
                    border-bottom: 1px solid #e8e8e8;
                }
                &-name{
                    font-size: 16px;
                    color: #222;
                }
                &-company{
                    margin-top:8px;
                }
                &-calendar{
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top:3px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &-foot{
                    padding: 5px 0px 5px 10px;
                }
            }
            &-link{
                width: 260px;
                cursor: pointer;
                border: 1px solid #e8e8e8;
                font-size: 12px;
                color: #909090;
                background: #fff;
                border-radius: 2px;
                position: relative;
                &-nav{
                    padding: 10px;
                    border-bottom: 1px solid #e8e8e8;
                    display: flex;
                }
                &-name{
                    font-size: 16px;
                    color: #222;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 3px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &-title{
                    font-size: 14px;
                    color: #222;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 3px 0;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &-calendar{
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top:3px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &-img{
                    width: 30px;
                    position: relative;
                    margin-left: 5px;
                    img{
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
                &-icon{
                    position: absolute;
                    bottom: 40px;
                    right: 10px;
                }
                &-foot{
                    padding: 5px 0px 5px 10px;
                }
            }
            &-location{
                width: 280px;
                cursor: pointer;
                border: 1px solid #e8e8e8;
                font-size: 12px;
                color: #909090;
                background: #fff;
                border-radius: 2px;
                &-nav{
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    border-bottom: 1px solid #e8e8e8;
                }
                &-icon{
                    margin-right: 8px;
                }
                &-name{
                    color: #222;
                    font-size: 14px;
                }
            }
            &-weapp{
                width: 260px;
                cursor: pointer;
                border: 1px solid #e8e8e8;
                font-size: 12px;
                color: #909090;
                background: #fff;
                border-radius: 2px;
                &-nav{
                    padding: 10px;
                    border-bottom: 1px solid #e8e8e8;
                }
                &-displayname{
                    color: #222;
                }
                &-name{
                    font-size: 14px;
                    color: #222;
                    margin: 8px 0;
                }
            }
            &-video{
                width: 120px;
                height: 120px;
                background: #454545;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                color: #fff;
                span{
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                }
            }
            &-hongbao{
                width: 260px;
                border:1px solid #e8e8e8;
                &-nav{
                    display: flex;
                    align-items: center;
                    background: #fe614e;
                    color: #fff;
                    font-size: 16px;
                    padding: 10px 10px 10px 12px;
                    img{
                        width: 28px;
                        margin-right: 10px;
                    }
                }
                &-num{
                    font-size: 12px;
                    padding: 5px 0;
                    margin-left: 10px;
                    color: #909090;
                }
            }
            &-file{
                display: flex;
                height: 80px;
                border: 1px solid #e8e8e8;
                border-radius: 2px;
                &-info{
                    position: relative;
                }
                &-name{
                    width: 200px;
                    padding: 15px 15px 0 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
                &-size{
                    position: absolute;
                    bottom: 5px;
                    left: 15px;
                    font-size: 12px;
                    color: #909090;
                }
                &-icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 5px;
                }
                &-remind{
                    font-size: 12px;
                    padding: 0 15px;
                    color: #909090;
                }
            }
            &-call{
                display: flex;
                align-items: center;
                &-left{
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    border-radius: 2px;
                }
            }
            &-translate{
                font-size: 12px;
                color: #fff;
                background: #aaa;
                display: inline-block;
                padding: 2px 5px;
                border-radius: 2px;
                display: inline-flex;
                align-items: center;
                span{
                    margin-left:5px;
                }
                &-r{
                    text-align: right;
                }
            }
        }
        &-receive{
            padding-left: 15px;
            .chatrecord-item-avatar{
                margin-right: 10px;
            }
            .chatrecord-item-content{
                display: flex;
            }
            .chatrecord-item-text{
                background: #f0f2f5;
                color: #222;
            }
            .chatrecord-item-call-left{
                background: #f0f2f5;
            }
            .chatrecord-item-otherText{
                color: #222;
            }
            .chatrecord-item-hongbao{
                border-radius: 0 4px 4px 8px;
            }
        }
        &-send{
            padding-right: 15px;
            flex-direction: row-reverse;
            .chatrecord-item-avatar{
                margin-left: 10px;
            }
            .chatrecord-item-username{
                display: flex;
                flex-direction: row-reverse;
            }
            .chatrecord-item-content{
                display: flex;
                flex-direction: row-reverse;
            }
            .chatrecord-item-text{
                background: #1992f9;
                color: #fff;
            }
            .chatrecord-item-call-left{
                background: #1992f9;
                color: #fff;
            }
            .chatrecord-item-otherText{
                color: #222;
            }
            .chatrecord-item-hongbao{
                border-radius: 4px 0 8px 4px;
            }
        }
    }
</style>

<style lang="scss">
    .chatrecord{
        .warning{
            .ant-badge-dot{
                background: #faad14 !important;
            }
        }
        .success{
            .ant-badge-dot{
                background: #52c41a !important;
            }
        }
    }
</style>