import url from '@/utils/URL'
import request from '@/utils/request'

export function contractRefund(params) {
  return request({
    url: url.contractRefund,
    method: 'POST',
    ...params
  })
}

export function contractStudents(params) {
  return request({
    url: url.contractStudents,
    method: 'POST',
    ...params
  })
}

export function contractPendding(params) {
  return request({
    url: url.contractPendding,
    method: 'POST',
    ...params
  })
}

export function contractExport(data) {
  return request({
    url: url.contractExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function contractPdf(params) {
  return request({
    url: url.contractPdf,
    method: 'POST',
    responseType: 'blob',
    ...params
  })
}

export function contractRollback(params) {
  return request({
    url: url.contractRollback,
    method: 'POST',
    ...params
  })
}

export function contractDetail(params) {
  return request({
    url: url.contractDetail,
    method: 'POST',
    ...params
  })
}

export function financeCosttimes(params) {
  return request({
    url: url.financeCosttimes,
    method: 'POST',
    ...params
  })
}

export function financeCosttimesCourse(params) {
  return request({
    url: url.financeCosttimesCourse,
    method: 'POST',
    ...params
  })
}

export function financeCosttimesExport(data) {
  return request({
    url: url.financeCosttimesExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}
