<template>
  <a-modal :title="modalTitle" v-model="visible" :footer='null' cancelText="取消" okText="保存" width="1250px" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div>
    <div style="margin-bottom:5px" v-if="class_knowledge">
      <a-button type="dashed" icon="download" @click="download(class_knowledge)">
          {{class_knowledge.knowledge_name}}
      </a-button>
    </div>

    <a-button type="primary" icon="video-camera" @click="playLiveRecord" v-if="item.is_live_record">
      上课视频
    </a-button>

    <a-collapse :bordered="false">
      <a-collapse-panel key="1" :header="`${app_id == 'mMKcL4'?'训练重点':'上课备注 - (老师可见)'}`">
        <p>{{ class_remark }}</p>
        <a-icon slot="extra" type="edit" @click.stop="handleClick" />
      </a-collapse-panel>
      <a-collapse-panel key="2" :header="`${app_id == 'mMKcL4'?'补充内容':'上课内容 - (学员可见)'}`">
        <p v-html="class_summary"></p>
        <a-icon slot="extra" type="edit" @click.stop="handleClick" />
      </a-collapse-panel>
    </a-collapse>
  </div>
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="primary" :loading="confirmLoading" icon="save" @click="mutilBook">签到保存</a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出签到</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title"> 
                <span>学员名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.fuzzy" placeholder="学员名称/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
              <a-radio-group :value="searchParams.search.book_status">
                <a-radio-button @click="handleTypeButChange('1','book_status')" value="1">
                    待签到
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('2','book_status')" value="2">
                    已到课
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('0','book_status')" value="0">
                    已请假
                </a-radio-button>
              </a-radio-group>
            </a-form-item>

          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>

          <a-form-item>
            <a-button type="dashed" @click="showAddTempModal" icon="plus">临时插班</a-button>
          </a-form-item>

        </a-form>
      </div>
    </div>
    <div class="table">
      <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
        :selection="{key: 'book_id'}"
        :columns="columns" :dataSource="list" @change="handleChange" :customRow="clickRow">

        <template slot="customTitle">
          <div @click.stop>
            <a-checkbox @change="onChange">
              上课状态
            </a-checkbox>
          </div>
        </template>

        <template slot="book_costs_title">
          <div>课时消耗 ({{cost_times}})</div>
        </template>

        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>


        <template slot="book_type" slot-scope="text, record">
          <span v-if="record.is_demo">
            <img style="width:20px" src="@/assets/demo.png">
          </span>

          <span v-if="record.book_type==0">
            <a-tag color="orange" v-if="record.book_status==4">
                  候补
            </a-tag>
            <a-tag color="green" v-if="record.book_status!=4">
              预约
            </a-tag>
          </span>

          <span v-if="record.book_type==3 && !record.is_demo">
            <a-tag color="orange">
              临加
            </a-tag>
          </span>
        </template>

        <template slot="is_demo" slot-scope="text, record">
            <a-checkbox :checked="record.is_demo?true:false" @change="onDemoChange(record)">
              试听
            </a-checkbox>
          <!-- <span v-if="record.is_demo"> -->
            
            <!-- <img style="width:20px" src="@/assets/demo.png"> -->
          <!-- </span> -->
          <!-- <span v-if="record.is_temp">
            <img style="height:20px" src="@/assets/book_temp.png">
          </span> -->
        </template>

        <template slot="user" slot-scope="text, record">
          <LUser :istoProfile='1' :data="record"/>
        </template>

        <template slot="book_costs" slot-scope="text,record">
          <div @click.stop>
            <a-input-number :min="0" :step="0.5" size="small" v-model="record.book_costs" />
          </div>
        </template>

        <template slot="left" slot-scope="text,record">
          <span v-if="record.student_available_times >= 0" style="display:inline-block;width:50px;margin-left:5px">{{record.student_available_times}}</span>
          <a-tag color="green" v-if="record.student_available_times >= 0">
                可用
              </a-tag>
              <span v-if="record.student_available_times < 0" style="display:inline-block;width:50px;margin-left:5px;color:red">{{record.student_available_times}}</span>
              <a-tag color="red" v-if="record.student_available_times < 0">
                欠
          </a-tag>
        </template>

        <template slot="book_status" slot-scope="text,record,index">
            <a-radio-group :value="record.book_status" @change="e=>handleRadio(e,index,record)" button-style="solid" size="small">
              <!-- <a-radio :value="1" disabled>
                待上课
              </a-radio> -->
              <a-radio :value="2">
                到课
              </a-radio>
              <a-radio :value="0">
                请假
              </a-radio>
            </a-radio-group>
        </template>

        <template slot="cancel_remark" slot-scope="text, record">
          <div>
            <div>{{text}}<img style="width:24px;height:24px;margin:5px 5px 5px 0" v-for="(item,index) in record.cancel_image" :key="index" :src="item" preview="0"></div>
          </div>
        </template>

        <template slot="book_remark" slot-scope="text, record">
          <editable-cell :text="text" class="studentModal-editable-cell" @change="onCellChange(record,$event)" />
        </template>

        <template slot="action" slot-scope="text,record">
          <a-dropdown v-if="record.book_status!=2">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item key="1" v-if="record.book_status != 2" @click="toDel(record)">
                取消排课
              </a-menu-item>

              <a-menu-item key="2" v-if="record.book_status != 2" @click="toAdjust(record)">
                快捷调课
              </a-menu-item>

              <a-menu-item key="3" v-if="record.book_status == 4" @click="toBook(record)">
                等位转正
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>

        <div slot="footer">
          <div>席位{{this.table_count}}人、排课{{list.length}}人、到课{{attendArr.length}}人、请假{{leaveArr.length}}人、课消{{book_costs}}课时。</div>
        </div>

      </a-table>
    </div>
    <videoModal v-if="isVideoModal" :videoSrc='video' :highlight='highlight'/>
    <addTempModal v-if="addTempVisible" :item="item"/>
    <contentModal v-if="isContentModal" :item="item"/>
    <adjustModal v-if="isAdjustModal" :studio_id="studio_id" :item="adjustItem" />
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name' ,scopedSlots: { customRender: 'user' }},
  { title: '', dataIndex: 'book_type',width: 45, key: 'book_type' ,scopedSlots: { customRender: 'book_type' }},
  { title: '试听', dataIndex: 'is_demo', key: 'is_demo' ,scopedSlots: { customRender: 'is_demo' }},
  { dataIndex: 'book_status', key: '2' , slots: { title: 'customTitle' } , scopedSlots: {customRender: 'book_status'}},
  { dataIndex: 'book_costs', key: 'book_costs', slots: { title: 'book_costs_title' } , scopedSlots: { customRender: 'book_costs' }},
  { title: '剩余课时',dataIndex: 'left', key: 'left', scopedSlots: { customRender: 'left' }},
  { title: '请假原因', width:'150px', dataIndex: 'cancel_remark', key: 'cancel_remark', scopedSlots: { customRender: 'cancel_remark' }},
  { title: '备注', width:'150px', dataIndex: 'book_remark', key: 'book_remark', scopedSlots: { customRender: 'book_remark' }},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]
import tableMixins from '@/common/mixins/table'
import videoModal from './videoModal'
import addTempModal from './addTempModal'
import adjustModal from './adjust'
import EditableCell from './EditableCell'
import contentModal from '@/views/education/mySchedule/listMode/contentModal'
import moment from 'moment'
export default {
  name: 'StudentModal',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    item: Object
  },
  watch: {
    list(newValue, oldValue) {
      let leaveArr = []
      let attendArr = []
      let book_costs = 0
      this.list.forEach(item=>{
        if(item.book_status === 0){
          leaveArr.push(item)
        }
        if(item.book_status === 2){
          attendArr.push(item)
          book_costs+=Number(item.book_costs)
        }
      })
      this.leaveArr = leaveArr
      this.attendArr = attendArr
      this.book_costs = Number(book_costs.toFixed(2))
    }
  },
  components: {
    adjustModal,
    addTempModal,
    EditableCell,
    contentModal,
    videoModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      isVideoModal:false,
      adjustItem:'',
      isAdjustModal:false,
      video:'',
      highlight:[],
      confirmLoading: false,
      exportLoading: false,
      addTempVisible: false,
      isContentModal: false,
      columns,
      selectedRowKeys: [],
      list: [],
      studio_id:'',
      is_waitlist:false,
      table_count:0,
      leaveArr: [],
      attendArr: [],
      book_costs:0,
      cost_times:0,
      class_summary:'',
      class_remark:'',
      class_knowledge:null,
      current:1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      modalTitle:''
    }
  },
  mixins: [ tableMixins ],
  computed: {
      app_id(){
          return this.$store.getters.brand_info.app_id
      }
  },
  async created() {
    this.modalTitle = `上课学员列表（${moment(this.item.class_start).format('YYYY-MM-DD【dddd】')} ${this.item.class_time}）${this.item.class_name || this.item.classes_name || this.item.class_id}`
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleTypeButChange(e,name){
      if(this.searchParams.search[name] == e){
        this.searchParams.search[name] = undefined
      }else{
        this.searchParams.search[name] = e
      }
      this.getList()
    },
    async getList() {
      this.loading = true
      this.searchParams.course_class_id = this.item.course_class_id
      let obj = {
        course_class_id : this.item.course_class_id,
        search:this.searchParams.search
      }
      let res = await this.$store.dispatch('courseScheduleDetailAction', { data: obj })
      this.list = res.items
      res.items.map((item)=>{
          if(item.book_status == 4){
            this.is_waitlist=true;
          }
      })
      this.cost_times = res._data.cost_times
      this.table_count = res._data.table_count
      this.class_summary = res._data.class_summary
      this.class_remark = res._data.class_remark
      this.class_knowledge = res._data.class_knowledge
      this.studio_id = res._data.studio_id
      this.pageParams = res._meta
      this.loading = false
    },
    async playLiveRecord() {
      let obj = {
        course_class_id : this.item.course_class_id,
      }
      await this.$store.dispatch('courseScheduleLiveRecordAction', { data: obj }).then(res=>{
        if(res.data.url){
          this.video=res.data.url;
          this.highlight=res.data.highlight
          this.isVideoModal=true;
        }else{
          this.$message.warning(`暂无上课视频。`)
        }
      })
    },
    handleCancel() {
      this.parent.hideStudentModal()
    },
    hideVideoModal(){
      this.isVideoModal=false;
    },
    hideAdjustModal(){
      this.isAdjustModal=false;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    async mutilBook() {
      let arr = []
      for(let i=0; i<this.list.length; i++){
        arr.push({book_costs:this.list[i].book_costs,book_id:this.list[i].book_id,book_status:this.list[i].book_status,is_demo:this.list[i].is_demo?1:0})
        if(this.list[i].book_status == 1){
          this.$message.warning(`${this.list[i].student_name}的上课状态没有勾选!!`)
          return false
        }
      }
      this.confirmLoading = true
      await this.$store.dispatch('absenceSigninAttendAction', { data: { course_class_id: this.item.course_class_id, course_class_book:arr } })
      .then(res=>{
        this.confirmLoading = false
        this.$message.success('操作成功~')
        this.selectedRowKeys = []
        this.getList()
        this.parent.getList()
      })
      .catch(err=>{
        this.confirmLoading = false
      })
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList();
    },
    handleRadio(e,index,record){
      record.book_status = e.target.value
      record.book_costs = e.target.value == 2?this.cost_times:0
    },
    onChange(e){
      this.list.forEach(item=>{
        if(item.book_status === 1 || item.book_status === 2){
          item.book_status = e.target.checked?2:1
          item.book_costs = e.target.checked?this.cost_times:0
        }
        if(item.book_status === 0){
          item.book_status = 0
          item.book_costs = 0
        }
      })
    },
    onDemoChange(e){
      e.is_demo=!e.is_demo;
      // console.log(e);
      // this.list.forEach(item=>{
      //   if(item.is_demo === 1 || item.book_status === 2){
      //     item.book_status = e.target.checked?2:1
      //     item.book_costs = e.target.checked?this.cost_times:0
      //   }
      // })
    },
    showAddTempModal() {
      this.addTempVisible = true
    },
    hideAddTempModal(val) {
      this.addTempVisible = false
      if(val){
        this.parent.getList()
        this.getList()
      }
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    let index = this.selectedRowKeys.indexOf(record.book_id)
                    if(index == -1){
                      this.selectedRowKeys.push(record.book_id)
                    }else{
                      this.selectedRowKeys.splice(index,1)
                    }
                }
            }
        }
    },
    async onCellChange(item,value) {
       await this.$store.dispatch('courseScheduleBookRemarkAction', { book_id:item.book_id , book_remark:value})
       .then(res=>{
         this.$message.success('操作成功~')
         item.book_remark = value
       })
       .catch(err=>{
         item.book_remark = item.book_remark
       })
    },
    handleClick(){
      this.isContentModal = true
    },
    hideContentModal(val){
      if(val){
        this.getList()
      }
      this.isContentModal = false
    },
    download(class_knowledge){
      // window.open(`${class_knowledge.knowledge_link}`);
      if(class_knowledge.file_ext_type === 'pdf'){
        let link = class_knowledge.knowledge_link.split('?attname')[0]
        window.open(link)
      }else{
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${class_knowledge.knowledge_link}`)
        // window.open(`https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(class_knowledge.knowledge_link)}`)
        // window.open(`http://api.idocv.com/view/url?url=${encodeURIComponent(class_knowledge.knowledge_link)}`)
      }
      
    },
    toAdjust(item){
      this.adjustItem = item;
      this.isAdjustModal=true;
    },
    toDel(item){
      let that = this
      this.$confirm({
        title: `确定要删除"${item.student_name}"?`,
        // okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          if(that.is_waitlist && item.book_status==1){
            that.$confirm({
                title: `当前排课包含等位学员，请确认是否自动补位?`,
                okType: 'danger',
                okText: '自动补位',
                cancelText: '不补位',
                onOk() {
                    return new Promise(async (resolve, reject) => {
                      await that.$store.dispatch('courseScheduleRemoveMemberAction', { course_class_id: that.item.course_class_id,auto_wait:1, student_id:[item.student_id],book_id:[item.book_id]})
                      .then(res=>{
                        that.$message.success('操作成功！！')
                        that.getList()
                        resolve(res)
                      })
                      .catch(err=>{
                        resolve(err)
                      })
                    }).catch(error => console.log(error))
                },
                onCancel() {
                  return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('courseScheduleRemoveMemberAction', { course_class_id: that.item.course_class_id, student_id:[item.student_id],book_id:[item.book_id]})
                    .then(res=>{
                      that.$message.success('操作成功！！')
                      that.getList()
                      resolve(res)
                    })
                    .catch(err=>{
                      resolve(err)
                    })
                  }).catch(error => console.log(error))
                },
            })

          }else{
            return new Promise(async (resolve, reject) => {
              await that.$store.dispatch('courseScheduleRemoveMemberAction', { course_class_id: that.item.course_class_id, student_id:[item.student_id],book_id:[item.book_id]})
              .then(res=>{
                that.$message.success('操作成功！！')
                that.getList()
                resolve(res)
              })
              .catch(err=>{
                resolve(err)
              })
            }).catch(error => console.log(error))
          }


        }
      })
    },
    toBook(item){
      let that = this
      this.$confirm({
        title: `确定要转正等位"${item.student_name}"?`,
        okType: 'danger',
        cancelText: '取消',
        onOk() {
            return new Promise(async (resolve, reject) => {
              await that.$store.dispatch('classesAddTempMemberAction', { course_class_id: that.item.course_class_id,book_id:[item.book_id]})
              .then(res=>{
                that.$message.success('操作成功！！')
                that.getList()
                resolve(res)
              })
              .catch(err=>{
                resolve(err)
              })
            }).catch(error => console.log(error))
        }
      })
    },
    async toExport(){
      this.exportLoading = true
      let exportData = {
        book_status:[0,1,2],
        course_class_id:this.item.course_class_id
      }
      let res = await this.$store.dispatch('scheduleExportBookAction', {search:exportData})
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `学员列表.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>

<style lang="scss">
  .studentModal-editable-cell{
    .editable-cell-input-wrapper{
      display: flex;
      align-items: center;
    }
    .anticon-edit{
      position: absolute;
      top: 0;
    }
  }
</style>