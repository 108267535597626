import Vue from 'vue'
import { absenceCancel, absenceCancelExport, absenceApply, absenceApplyConfirm, absenceApplyRefuse, absenceSignin, absenceSigninCancel, absenceSigninCheckin, absenceSigninExport, 
  absenceSigninAttend} from '@/api/absence'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    absenceCancelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceCancel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceCancelExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceCancelExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceApplyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceApply(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceApplyConfirmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceApplyConfirm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceApplyRefuseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceApplyRefuse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceSigninAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceSignin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceSigninCancelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceSigninCancel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceSigninCheckinAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceSigninCheckin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    absenceSigninAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceSigninAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    absenceSigninExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        absenceSigninExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
