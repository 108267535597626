import Vue from 'vue'
import { 
  searchEngine, searchEmployee, searchEngineClasses, searchWarehouse, searchWarehouseProduct, searchStudentContactType,
  searchStudent, searchFeeType, searchTradeType, searchPaymentType,searchProduct,searchSuppliers,
  searchBelongStudio, searchStudio, searchAllStudio, searchStudioRoom, searchSourceChannel,searchSourceChannelCascader,
  searchCourseType, searchCourse, searchDemoCourse, searchLibrary,searchLibraryCategory, searchMessageType,
  searchHelperType, searchContractType, searchCreatedBy,searchLibraryCascader,searchGetTags,
  searchConsultant, searchAccountEmployee, searchTeacher, searchClasses, searchTeacherCascader,
  searchMeeting, searchTicketType, searchStudents,searchSurvey,searchClassesList,searchQyweEmployee,
  searchKnowledgeContent, searchKnowledgePackage, searchCourseFee, searchClassRecruitFee,
  searchIncidentalFee, searchRole, searchCampaignTicketType, searchGetGradeType, searchGetDepartment,searchPosition,searchCustomer
} from '@/api/search'
import config from '@/utils/const'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    searchEngineAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchEngine(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchEngineClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchEngineClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchStudentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchStudent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchWarehouseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchWarehouse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchWarehouseProductAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchWarehouseProduct(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchStudentContactTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchStudentContactType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchFeeTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchFeeType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchTradeTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchTradeType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchPaymentTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchPaymentType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchProductAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchProduct(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchSuppliersAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchSuppliers(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchBelongStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchBelongStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchAllStudioAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchAllStudio(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchStudioRoomAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchStudioRoom(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchSourceChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchSourceChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchSourceChannelCascaderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchSourceChannelCascader(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchCourseTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCourseType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchDemoCourseAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchDemoCourse(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchLibraryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchLibrary(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchLibraryCascaderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchLibraryCascader(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchLibraryCategoryAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchLibraryCategory(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchMessageTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchMessageType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchHelperTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchHelperType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchContractTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchContractType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchCreatedByAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCreatedBy(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchConsultantAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchConsultant(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchAccountEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchAccountEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    searchTeacherCascaderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchTeacherCascader(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchClassesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchClasses(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchMeetingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchMeeting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchTicketTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchTicketType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchStudentsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchStudents(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchQyweEmployeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchQyweEmployee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchKnowledgeContentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchKnowledgeContent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchKnowledgePackageAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchKnowledgePackage(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchCourseFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCourseFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchClassRecruitFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchClassRecruitFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchIncidentalFeeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchIncidentalFee(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchRoleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchRole(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchSurveyAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchSurvey(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchClassesListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchClassesList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchCampaignTicketTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCampaignTicketType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    searchGetGradeTypeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchGetGradeType(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    searchGetTagsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchGetTags(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    searchGetDepartmentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchGetDepartment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    searchPositionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchPosition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    searchCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        searchCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
