<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" width="600px"
    @ok="handleOk" @cancel="handleCancel">
    <template slot="footer">
      <a-button v-if="isEdit === 1" key="over" @click="handleOver" style="float:left;">
        <div v-if="detail.class_status==2">
          恢复班级
        </div>
        <div v-else>
          班级结业
        </div>
      </a-button>
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="confirmLoading" type="primary" @click="handleOk">
        保存
      </a-button>
    </template>
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]" style='width:100%' :min="0" />
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>

        <a-select v-else-if="item.type === 'select'" placeholder="请选择" @change="handleChange"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" :disabled="isEdit == 1?true:false">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-select v-else-if="item.type === 'cCategorys'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]" showSearch @search="handleSearch" :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-select v-else-if="item.type === 'selects'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-select v-else-if="item.type === 'selectm'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" mode="multiple">
          <!-- <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option> -->
          <a-select-option v-for="(_data, index) of item.items" :key="index" :value="_data.value">{{ _data.label }}</a-select-option>
        </a-select>

        <a-radio-group :disabled="isEdit == 1?true:false" v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group >

        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import moment from 'moment'

const formData = [
  {
    name: 'class_name',
    label: '班级名称',
    type: 'input',
    rules: [{ required: true, message: '请输入班级名称!' }]
  },
  {
    name: 'class_type',
    label: '班级类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择班级类型!' }],
    items: {
      data: 'classTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'studio_id',
    label: '上课校区',
    type: 'select',
    rules: [{ required: true, message: '请选择上课校区!' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'course_cate_id',
    label: '班级科目',
    type: 'cCategorys',
    rules: [{ required: true, message: '请选择班级科目!' }],
    items: {
      data: 'cCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'tutor_id',
    label: '授课老师',
    type: 'selects',
    rules: [{ required: true, message: '请选择授课老师!' }],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'cc_id',
    label: '班主任',
    type: 'selects',
    rules: [],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  // {
  //   name: 'class_weekly',
  //   label: '周几授课',
  //   type: 'selectm',
  //   rules: [{ required: false, message: '请选择周几上课!' }],
  //   items: C_ITEMS.classWeeklys
  // },
  // {
  //   name: 'class_grade',
  //   label: '年级',
  //   type: 'select',
  //   items: {
  //     data: 'grade',
  //     label: 'label',
  //     value: 'value'
  //   }
  // },
  {
    name: 'class_limit',
    label: '学员上限',
    type: 'number',
    rules: [{ required: true, message: '请输入班级学员上限人数!' }]
  },
  {
    name: 'class_start',
    label: '开始时间',
    type: 'date',
    rules: []
  },
  {
    name: 'class_end',
    label: '结束时间',
    type: 'date',
    rules: []
  },
  {
    name: 'class_description',
    label: '班级备注',
    type: 'textarea',
    rules: []
  },
]

export default {
  name: 'EditModal',
  mixins: [ editModal ],
  data() {
    return {
      loading:false,
      confirmLoading:false,
      detail: {},
      formData,
      seleteItems: {
        classTypes: C_ITEMS.classTypes,
        grade: C_ITEMS.grade,
        studios: [],
        cCategorys: [],
        teachers: [],
        consultants: []
      }
    }
  },
  props:{
    isEdit:Number,
    copy:Object,
    item:Object,
  },
  async created() {
    this.visible = true
    this.ModalTitle = '新增班级'
    if(this.item || this.isEdit === 2){
      this.loading = true
    }
    await this.getStudio()
    await this.getConsultant()
    this.$nextTick()
    let { item, authArr, isEdit, copy } = this
    if (item) {
      this.ModalTitle = '编辑班级'
      let res = await this.$store.dispatch('classesDetailAction', { class_id: item.class_id })
      if (res) {
        this.detail=res.data
        if(res.data.studio && !this.filterArr(this.seleteItems.studios,'studio_id',res.data.studio.studio_id)){
          this.seleteItems.studios.push(res.data.studio)
        }
        if(res.data.course_cate && !this.filterArr(this.seleteItems.cCategorys,'course_cate_id',res.data.course_cate.course_cate_id)){
          this.seleteItems.cCategorys.push(res.data.course_cate)
        }
        await this.cCategoryDetailAction({studio_id:res.data.studio_id},res.data)
        await this.getTeacher({studio_id:res.data.studio_id,employee_id:res.data.tutor_id},res.data)

        item = res.data
        let formFields = {}
        for (let d of formData) {
          if (d.name === 'class_start' || d.name === 'class_end') {
            formFields[d.name] = item[d.name]?moment(item[d.name],'YYYY-MM-DD'):''
          } else if(d.name === 'studio_id' && !item.studio){
            formFields[d.name] = ''
            formFields['course_cate_id'] = ''
            formFields['tutor_id'] = ''
          } else if(d.name === 'course_cate_id' && !item.course_cate){
            formFields[d.name] = ''
          } else if(d.name === 'tutor_id' && item.tutor && !this.filterArr(this.seleteItems.teachers,'teacher_id',item.tutor.teacher_id)){
            formFields[d.name] = ''
          } else{
            formFields[d.name] = item[d.name]
          }
        }
        this.form.setFieldsValue(formFields)
      }
      this.loading = false
    }
    if (isEdit === 2) {
      this.ModalTitle = '复制班级'
      let res = await this.$store.dispatch('classesDetailAction', { class_id: copy.class_id })
      if (res) {
        await this.cCategoryDetailAction({studio_id:res.data.studio_id})
        await this.getTeacher({studio_id:res.data.studio_id,employee_id:res.data.tutor_id},res.data)
        copy = res.data
        this.copy=copy;
        let formFields = {}
        for (let d of formData) {
          if (d.name === 'class_start' || d.name === 'class_end') {
            formFields[d.name] = copy[d.name]?moment(copy[d.name],'YYYY-MM-DD'):''
          } else if(d.name === 'studio_id' && !this.filterArr(this.seleteItems.studios,'studio_id',copy.studio.studio_id)){
            formFields[d.name] = ''
            formFields['course_cate_id'] = ''
            formFields['tutor_id'] = ''
          } else if(d.name === 'course_cate_id' && !this.filterArr(this.seleteItems.cCategorys,'course_cate_id',copy.course_cate.course_cate_id)){
            formFields[d.name] = ''
          } else if(d.name === 'tutor_id' && copy.tutor && !this.filterArr(this.seleteItems.teachers,'teacher_id',copy.tutor.teacher_id)){
            formFields[d.name] = ''
          } else {
            formFields[d.name] = copy[d.name]
          }
        }
        this.form.setFieldsValue(formFields)
      }
      this.loading = false
    }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.studios = res.data
    },
    async cCategoryDetailAction(obj) {
      let res = await this.$store.dispatch('searchCourseTypeAction', {params:obj})
      this.seleteItems.cCategorys = res.data
    },
    async getTeacher(obj) {
      let res = await this.$store.dispatch('searchTeacherAction', this.item?{params:obj}:{params:obj,data: {active:true}})
      this.seleteItems.teachers = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', this.item?{}:{data: {active:true}})
      this.seleteItems.consultants = res.data
    },
    async handleOk() {
      try {
        const { item, form } = this
        // console.log(item);
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.class_id = item.class_id
          }
          if(params.class_start){
            params.class_start = moment(params.class_start).format('YYYY-MM-DD')
          }
          if(params.class_end){
            params.class_end = moment(params.class_end).format('YYYY-MM-DD')
          }
          if(this.copy){
            params.is_duplicate=this.copy.class_id;
          }
          this.confirmLoading = true
          await this.$store.dispatch(item ? 'classesUpdateAction' : 'classesAddAction', params)
            .then(res=>{
              form.resetFields()
              this.parent.hideModal(1)
            })
            .catch(err=>{
              this.confirmLoading = false
            })
        }
      } catch {
        this.confirmLoading = false
      }
    },
    handleSearch(val){
      this.cCategoryDetailAction({q:val})
    },
    async handleOver(){
      let that = this
      let title = `您确定该班级结业？`
      if(this.detail.class_status==2){
          title = `您确定恢复该班级？`
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('classesCloseAction', {class_id:[that.item.class_id],class_status:that.detail.class_status})
            if (res) {
              that.$message.success('操作成功~')
              that.parent.submitModal(1)
              that.parent.getDatail()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleChange(e){
      let obj = {studio_id:e}
      this.getTeacher(obj)
      this.cCategoryDetailAction(obj)
    },
    filterArr(arr,name,id){
      console.log(arr,name,id)
      let num = false
      arr.forEach(items=>{
        console.log(items[name],id)
        if(items[name] == id){
          num = true
        }
      })
      return num
    }
  }
}
</script>