<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="50%" :zIndex='4002'
    @ok="handleOk" @cancel="handleCancel">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>物品名称</span>
              </template>
              <a-input v-model.trim="searchParams.search.incidental_name" placeholder="物品名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <a-table :columns="columns" :dataSource="list" :bordered='false' :pagination="false" rowKey="stock_id" size="small"
      :selection="{key: 'key'}" :customRow="clickRow"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
    </a-table>  

    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '收费名称', dataIndex: 'incidental_name', },
  { title: '单价', dataIndex: 'sale_price', },
  { title: '库存', dataIndex: 'product_stock', },
];
export default {
  name: 'IncidentalFeeModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      ModalTitle: '选择其他收费',
      visible: false,
      confirmLoading: false,
      columns,
      list: [],
      selectedRowKeys: [],
      localIncidentalFee: {},
      
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
    }
  },

  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.visible = true
    this.getList()
    this.localIncidentalFee = this.$ls.get('localIncidentalFee') || {}
    for (let k of Object.keys(this.localIncidentalFee)) {
      this.selectedRowKeys.push(+k)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    searchList(e) {
      e?e.preventDefault():''
      this.getList()
    },
    async getList() {
      let res = await this.$store.dispatch('searchIncidentalFeeAction', { params: this.item, data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    handleOk() {
      let list = {}
      for (let item of this.list) {
        if (this.selectedRowKeys.indexOf(item.stock_id) !== -1) {
          item.product_count = 1
          list[item.stock_id] = item
        }
      }
      this.$ls.set('localIncidentalFee', list)
      this.parent.hideIncidentalFeeModal(1)
    },
    handleCancel() {
      this.parent.hideIncidentalFeeModal(0)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    clickRow(record, index) {
        return {
            on: {
                click: () => {
                    let index = this.selectedRowKeys.indexOf(record.stock_id)
                    if(index == -1){
                      this.selectedRowKeys.push(record.stock_id)
                    }else{
                      this.selectedRowKeys.splice(index,1)
                    }
                }
            }
        }
    },
  }
}
</script>