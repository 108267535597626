<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>进出班操作</span>
              </template>
              <a-select v-model="searchParams.search.action" allowClear placeholder="请选择进出班操作" style="width: 160px">
                <a-select-option v-for="(item, index) of actionStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="course_class_id"
        :columns="columns" :dataSource="list" :scroll="{ x: xWidth }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{index+1}}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>

import tableMixins from '@/common/mixins/table'
import cItems from '@/utils/items'

export default {
  name: 'bjgl',
  mixins: [ tableMixins ],

  props: {
    id: Number
  },
  components: {
  },

  data() {
    return {
      loading: false,
      columns:[],
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      actionStatus: cItems.actionStatus,
      xWidth:700
    }
  },

  created() {
  },

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.class_id = this.id
      let res = await this.$store.dispatch('classesAttendAction', this.searchParams)
      let columns = [{ title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'}]
      let num = 0
      for(let key  in res.data.columns){
        columns.push({ title: res.data.columns[key], dataIndex: key, key: key })
        num++
      }
      if(num *100 > 700){
        this.xWidth = num *110
      }
      this.columns = columns
      this.list = res.data.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
