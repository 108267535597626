<template>
  <!-- <vue-ueditor-wrap style="line-height: normal" v-model="value" :config="editorConfig"/> -->
  <div class="vueQuillEditor">
    <quill-editor
      v-maxWindow
      ref="myQuillEditor"
      :content="value"
      :options="editorOption"
      @change="onEditorChange($event)"
    />
    <a-upload style="display: none;"
      name="file"
      :data="uploadParams"
      :action="uploadUrl" 
      :beforeUpload="beforeUpload"
      @change="changeFile"
    >
      <a-button id="imgInput"> <a-icon type="upload" /> Click to Upload </a-button>
    </a-upload>
  </div>
</template>

<style lang="scss">
.quill-editor {
  line-height: normal
}
</style>


<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import * as Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
// import {ImageExtend} from 'quill-image-extend-module'
Quill.register('modules/imageResize',ImageResize)
// Quill.register('modules/ImageExtend', ImageExtend)
export default {
  name: 'LEditor',
  inject: ['parent'],
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      // editorConfig: {
      //   // autoHeightEnabled: false,
      //   initialFrameHeight: 240,
      //   initialFrameWidth: '100%',
      //   serverUrl: ``,
      //   imageUrl: ``,
      //   UEDITOR_HOME_URL: '/UEditor/',
      //   imageFieldName: 'upfile',
      //   imageActionName: 'fileUpload',
      //   imageUrlPrefix: '',
      // },
      editorOption: {
        placeholder: '请输入...',
        modules:{
            clipboard: {
              // 粘贴版，处理粘贴时候的自带样式
              // matchers: [[Node.ELEMENT_NODE, this.HandleCustomMatcher]],
            },
            toolbar: {
              container:[
                  //["link", "image", "video"],
                  // ["image","link",],
                  ["bold", "italic", "underline", "strike"],
                  // ["blockquote", "code-block"], 
                  ["blockquote"], 
                  [{"header": 1}],
                  [{"list": "ordered"},{"list": "bullet"}],
                  // [{"script": "sub"},{"script": "super"}],
                  [{"indent": "-1"}, {"indent": "+1"}],
                  [{ "direction": 'rtl' }],
                  [{"size":["small",false,"large","huge"]}],
                  [{"header":[1,2,3,4,5,6,false]}],
                  [{"color":[]},{"background":[]}],
                  [{"font":[]}],
                  [{"align":[]}],
                  // ["clean"],
                  ["link", "image"],
              ],
              // handlers: {
              //     'image': function () {  // 劫持原来的图片点击按钮事件
              //       console.log('test');
              //       //document.querySelector('.quill-image-input').click()
              //     }
              // }
            },
            imageResize:{
                displayStyles:{
                    backgroundColor:'black',
                    border:'none',
                    color:'white'
                },
                modules:['Resize','DisplaySize','Toolbar']
            }
        }
      },
      uploadType: '',
      value: '',
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: []
    }
  },

  props: {
    name: String,
    data: String,
  },

  watch: {
    data() {
      this.value = this.data
    },
    value() {
      this.parent.changeEditor(this.name, this.value)
    }
  },

  // created() {
  // },

  mounted() {
    this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)
    this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('video', this.videoHandler) 
    let quill = this.$refs.myQuillEditor.quill;
    this.$forceUpdate();
    quill.root.addEventListener(
      "paste",
      (evt) => {
        if (
          evt.clipboardData &&
          evt.clipboardData.files &&
          evt.clipboardData.files.length
        ) {
          evt.preventDefault();
          [].forEach.call(evt.clipboardData.files, (file) => {
            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
              return;
            }
            this.uploadCopy(file)
            // let formData = new FormData()
            // formData.append('key', this.uploadParams.key)
            // formData.append('token', this.uploadParams.token)
            // formData.append("file", file)
            // this.$store.dispatch('uploadQiNiuAction', formData)
            //         .then(result=>{
            //             console.log(result)
            //             // let fileObj = blob
            //             // fileObj.originFileObj = blob
            //             // fileObj.response = result
            //             // fileObj.uid = '-1'
            //             // fileObj.status = 'done'
            //             // this.fileList = [fileObj]
            //             // this.upLoadLoading = false
            //         })

            // this.changeFile(file);
            // const formData = new FormData();
            // formData.append("pictureFile", file);
            // console.log(formData);
            // makdwnImg(formData)
            //   .then((res) => {
            //     let quill = this.$refs.myQuillEditor.quill;
            //     if (res.data.code == 200) {
            //       // const formdata = _.extend({}, this.formdata)
            //       let length = quill.getSelection().index; //光标位置
            //       // 插入图片  图片地址
            //       quill.insertEmbed(length, "image", res.data.data);
            //       // 调整光标到最后
            //       quill.setSelection(length + 1); //光标后移一位
            //     }
            //   })
            //   .catch((err) => {
            //     console.error(err);
            //   });

          });
        }
      },
      false
    );
  },
  methods: {
    // handleAvatarSuccess(res, file) {
    //   // 图片上传成功后的回调
    //   console.log(res, file)
    // },
    // beforeAvatarUpload(data) {
    //   // 思路：上传图片至服务后，拿到返回的图片地址。直接创建image标签插入光标所在的位置
    //   // 图片上传服务(本地服务或者阿里云服务)
    //   // 获取富文本组件实例
    //   let quill = this.$refs.myQuillEditor.quill
    //   // 上传服务成功后，按根据光标位置把图片插入编辑器中
    //   if (data.url) {
    //     // 获取光标所在位置,data.url表示上传服务后返回的图片地址
    //     let length = quill.getSelection().index
    //     // 插入图片，data.url为服务返回的图片链接地址
    //     quill.insertEmbed(length, 'image', data.url)
    //     // 调整光标到最后
    //     quill.setSelection(length + 1)
    //   } else {
    //     this.$message.closeAll()
    //     this.$message.error('图片插入失败')
    //   }
    // },
    // handelEditorChange(el) {
    //   console.log(el, 'el')
    // },
    // HandleCustomMatcher(node, Delta) {
    //   // 文字、图片等，从别处复制而来，清除自带样式，转为纯文本
    //   let ops = []
    //   Delta.ops.forEach(op => {
    //     if (op.insert && typeof op.insert === 'string') {
    //       ops.push({
    //         insert: op.insert,
    //       })
    //     }
    //   })
    //   Delta.ops = ops
    //   return Delta
    // },
    onEditorChange({ quill, html, text }) {
      this.value = html
    },
    imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection()
      if (state) {
        let fileInput = document.getElementById('imgInput')
        fileInput.click() // 加一个触发事件
      }
      this.uploadType = 'image'
    },
    videoHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection()
      if (state) {
        let fileInput = document.getElementById('imgInput')
        fileInput.click() // 加一个触发事件
      }
      this.uploadType = 'video'
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch(this.uploadType === 'video' ? 'massTokenAction':'imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    async uploadCopy(file) {
      if (!file) { return false }
      if(!this.uploadParams.token){
        let res = await this.$store.dispatch('imageTokenAction', {})
        if(res){
          this.uploadParams.token = res.data.uptoken
        }
      }
      if (this.uploadParams.token) {
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []

        let formData = new FormData()
        formData.append('key', this.uploadParams.key)
        formData.append('token', this.uploadParams.token)
        formData.append("file", file)
        await this.$store.dispatch('uploadQiNiuAction', formData)
        .then(result=>{
            // console.log(result)
            let quill = this.$refs.myQuillEditor.quill;
            if (result.key) {
              // const formdata = _.extend({}, this.formdata)
              console.log(url.imageRoot+ result.key)
              let length = quill.getSelection().index; //光标位置
              this.$refs.myQuillEditor.quill.insertEmbed(length, "image",url.imageRoot+ result.key, Quill.sources.USER)
              // 插入图片  图片地址
              // quill.insertEmbed(length, "image", result.key);
              // 调整光标到最后
              quill.setSelection(length + 1); //光标后移一位
            }
            // let fileObj = blob
            // fileObj.originFileObj = blob
            // fileObj.response = result
            // fileObj.uid = '-1'
            // fileObj.status = 'done'
            // this.fileList = [fileObj]
            // this.upLoadLoading = false
        })

        return true
      }
      return false
    },
    changeFile(file) {
      if (file.file.status === 'done') {
        if (!this.fileList[0]) {
          this.fileList[0] = {
            uid: '1',
            name: this.uploadParams.key,
            status: 'done',
          }
          this.fileList[0].name = this.uploadParams.key
          let value = (this.uploadType === 'video'? url.fileRoot : url.imageRoot) + this.fileList[0].name
          this.addRange = this.$refs.myQuillEditor.quill.getSelection()
          this.$refs.myQuillEditor.quill.insertEmbed(this.addRange !== null ? this.addRange.index : 0, this.uploadType, value, Quill.sources.USER)
        }
      }
    }
  }
}
</script>
<style lang="scss">
// 富文本汉化css
.vueQuillEditor{
   .editor {
  line-height: normal !important;
}
.ql-editor{
  min-height: 250px;
}
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: '等宽字体';
}

}

</style>
