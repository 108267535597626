<template>
  <div class="dashboard">
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="student_coupon_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="showWithdraw(record)" v-if="record.coupon_status==1">撤回</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>
<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '优惠券名称', dataIndex: 'coupon_title', key: 'name' },
  { title: '优惠券类型', dataIndex: 'coupon_type', key: 'coupon_type' },
  { title: '开始时间', dataIndex: 'coupon_start_time', key: '3' },
  { title: '结束时间', dataIndex: 'coupon_end_time', key: '4' },
  { title: '有效状态', dataIndex: 'coupon_status_name', key: '2' ,align:"center"},
  { title: '发放时间', dataIndex: 'created_at', key: 'created_at' ,align:"right"},
  { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' },align:"right"},
]
import tableMixins from '@/common/mixins/table'
export default {
  name: 'yhj',
  props: {
    studentId: Number
  },
    data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},

      visible: false,
      refundVisible: false,
    }
  },

  mixins:[tableMixins],

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsCouponsAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async showWithdraw(item){
      let that = this
      this.$confirm({
        title: `您确定撤回该优惠券？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseCouponCancelCouponAction', {student_coupon_id:[item.student_coupon_id]})
            if (res) {
              that.getList()
              that.$message.success('操作成功')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>