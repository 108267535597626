<template>
  <a-modal title="退费办理" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="1200px"
    @ok="handleOk" @cancel="handleCancel">
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="contract_id"
                :columns="columns" :dataSource="list" :scroll="{ x: 600 }">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                
                <template slot="refund" slot-scope="text, record , index">
                    <a-input-number :id='record.inputId' :min='0' :max="Number(record.left_times)" @change="e=>handleBlur(e,index,'refund_times')" :value="record.refund_times"/>
                </template>

                <template slot="refund_gift_times" slot-scope="text, record , index">
                    <a-input-number :id='record.inputId' :min='0' :max="Number(record.refund_gift_times)" @change="e=>handleBlur(e,index,'left_gift_times')" :value="record.left_gift_times"/>
                </template>
                <template slot="refund_reduce" slot-scope="text, record , index">
                    <a-input-number :id='record.inputId' :min='0' :step="0.01" :max="Number(record.refund_total)" @change="e=>handleBlur(e,index,'refund_reduce')" :value="record.refund_reduce"/>
                </template>
            </a-table>
        </div>
        <a-form :form="form" layout="horizontal">
            <a-form-item v-for="(item,index) in formData" :key="index" :label="item.label">
                <a-radio-group v-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of paymentTypes" :key="d['payment_type']" :value="d['payment_type']">
                        {{d['filter_name']}}
                    </a-radio>
                </a-radio-group>
                <a-input-number v-if="item.type === 'input-number'" :max="Number(item.student_left_wallet_reserve)" @change="handleTotalPrice" style="width:400px" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-input-number :disabled="true" v-if="item.type === 'input'" style="width:400px" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-textarea v-if="item.type === 'textarea'" style="width:400px" v-decorator="[item.name, { rules: item.rules }]" />
            </a-form-item>
        </a-form>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '合同号', width:'160px', dataIndex: 'contract_no', key: 'contract_no' ,ellipsis:true},
  { title: '所属校区', dataIndex: 'studio_name', key: 'studio_name' ,ellipsis:true},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'course_cate_id'  ,ellipsis:true},
  { title: '课时单价', dataIndex: 'course_balance', key: 'course_balance' ,align:'center',width:'80px' },
  { title: '可退课时', dataIndex: 'left_times', key: 'left_times' ,align:'center' ,width:'80px'},
  { title: '退课时', dataIndex: 'refund_times', key: 'refund_times',scopedSlots: { customRender: 'refund' },align:'left' },
  { title: '退赠课', dataIndex: 'refund_gift_times', key: 'refund_gift_times',scopedSlots: { customRender: 'refund_gift_times' },align:'left' },
  { title: '应退', dataIndex: 'refund_total', key: 'refund_total',align:'center' },
  { title: '扣款', dataIndex: 'refund_reduce', key: 'refund_reduce',scopedSlots: { customRender: 'refund_reduce' },align:'left' },
  { title: '实退', dataIndex: 'refund_after_reduce', key: 'refund_after_reduce',align:'center' },
//   { title: '付费日期', dataIndex: 'created_at', key: 'created_at' },
]

const formData = [
  {
    name: 'refund_wallet_reserve',
    label: '退电子钱包',
    type: 'input-number',
    rules: [{ required: true, message: '请输入退款余额!' }]
  },
  {
    name: 'refund_type',
    label: '退款方式',
    type: 'radio',
    rules: [{ required: true, message: '请选择退款方式!' }]
  },
  {
    name: 'refund_total',
    label: '实退总额(元)',
    type: 'input',
    rules: [{ required: true, message: '请输入退款总额!' }]
  },
//   {
//     name: 'refund_reduce',
//     label: '扣款金额(元)',
//     type: 'input',
//     rules: [{ required: true, message: '请输入退款总额!' }]
//   },
//   {
//     name: 'refund_totals',
//     label: '实际退款(元)',
//     type: 'input',
//     rules: [{ required: true, message: '请填写实际退款金额!' }]
//   },
  {
    name: 'refund_remark',
    label: '退费备注',
    type: 'textarea',
    rules: [{ required: true, message: '请输入退款备注!' }]
  }
]

    export default {
        name:'refundModal',
        inject: ['parent'],
        props: {
            studentId: Number,
            contract_no: String,
            item:Object
        },
        data() {
            return {
                visible: false,
                loading:false,
                confirmLoading:false,
                list:[],
                columns,
                formData,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                form: this.$form.createForm(this),
                paymentTypes:[],
                refund_total:'',
            }
        },
        created() {
            this.visible = true
            this.getList()
            this.getPaymentType()
            // console.log(this.item)
            // console.log(this.item.student_left_wallet_reserve)
            // this.form.setFieldsValue('refund_wallet_reserve','120.32');
        },
        beforeDestroy() {
            this.visible = false
        },
        methods: {
            async getList(){
                this.loading = true
                let obj = { student_id:this.studentId }
                if(this.contract_no){
                    obj.contract_no = this.contract_no
                }
                let res = await this.$store.dispatch('studentsProfileContractAction', { data:  obj})
                res.items.forEach((item,index)=>{
                    item.refund_times = 0
                    item.refund_total = 0
                    item.refund_reduce = 0
                    item.refund_after_reduce = 0
                    item.inputId = `input${index}`
                })
                this.list = res.items
                this.form.setFieldsValue({refund_wallet_reserve:this.item.student_left_wallet_reserve})
                this.form.setFieldsValue({refund_total:0})
                this.loading = false
            },
            async getPaymentType() {
                let res = await this.$store.dispatch('searchPaymentTypeAction', {})
                this.paymentTypes = res.data
            },
            async handleOk(){
                try {
                    const { form ,list } = this
                    let data = await form.validateFields()
                    let arr = [];
                    list.forEach(item=>{
                        if(item.refund_times > 0){
                            arr.push({contract_id:item.contract_id,refund_times:item.refund_times,refund_gift_times:item.left_gift_times,refund_total:item.refund_total,refund_reduce:item.refund_reduce})
                        }
                    })
                    data.apply = arr
                    data.student_id = this.studentId
                    this.confirmLoading=true
                    if(this.contract_no){
                        data.contract_no = this.contract_no
                    }
                    await this.$store.dispatch('contractRefundAction', {data})
                    .then(res=>{
                        this.$message.success('操作成功！')
                        this.form.resetFields()
                        this.parent.hideRefundModal(1)
                    })
                    .catch(err=>{
                        this.confirmLoading=false;
                    })
                } catch {
                }

            },
            handleCancel(){
                this.parent.hideRefundModal()
            },
            async handleTotalPrice(){
                // let _total = Number(this.form.getFieldsValue().refund_wallet_reserve) + Number(this.form.getFieldsValue().refund_total)
                // this.form.setFieldsValue({refund_total:_total})
            },
            handleBlur(e,index,name){
                this.list[index][name] = e
                this.list[index].refund_total = ( Number(this.list[index].refund_times) * Number(this.list[index].course_balance) ).toFixed(2) > Number(this.list[index].left_amount)?Number(this.list[index].left_amount):( Number(this.list[index].refund_times) * Number(this.list[index].course_balance) ).toFixed(2)
                this.list[index].refund_after_reduce = ( this.list[index].refund_total  - Number(this.list[index].refund_reduce) ).toFixed(2)
                let refund_total = 0
                this.list.forEach(item=>{
                    refund_total = ( Number(refund_total) + Number(item.refund_after_reduce) ).toFixed(2)
                })
                this.form.setFieldsValue({refund_total:refund_total})
                // this.handleTotalPrice();
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>