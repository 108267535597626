 const bi_tour=[
  {
    target: '#bi-market-clue',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
  {
    target: '#bi-market-clue-stock',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
  {
    target: '#bi-market-clue',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
  {
    target: '#bi-market-clue',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
  {
    target: '#bi-market-clue',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
  {
    target: '#bi-market-clue',
    header: {
      title: '线索跟进' ,
    },
    content: `第一步`,
    params: {
      placement: 'left'
    }
  },
];

export const tour_list = {
  crm : {
    todo:[
      {
        target: '#menu-1',
        header: {
          title: '线索跟进' ,
        },
        content: `第一步`
      },
      {
        target: '#crm-todo',
        header: {
          title: '线索跟进' ,
        },
        content: '第二步',
        params: {
          placement: 'left'
        }
      },
      {
        target: '#customerBtn',
        header: {
          title: '线索跟进' ,
        },
        content: '第三步'
      }
    ],
    clue:[],
  },
  work : {
    dashboard:[
      {
        target: '#work-dashboard',
        header: {
          title: '工作台' ,
        },
        content: `控制面板`,
        params: {
          placement: 'left'
        }
      },
      {
        target: '#work-invite-today',
        header: {
          title: '工作台' ,
        },
        content: `到访登记`,
        params: {
          placement: 'left'
        }
      },
      {
        target: '#work-announce',
        header: {
          title: '工作台' ,
        },
        content: `公告`,
        params: {
          placement: 'left'
        }
      },
      {
        target: '#work-journal',
        header: {
          title: '工作台' ,
        },
        content: `日报`,
        params: {
          placement: 'left'
        }
      },
      {
        target: '#work-approve',
        header: {
          title: '工作台' ,
        },
        content: `审批`,
        params: {
          placement: 'left'
        }
      },
    ],
    'invite-today':[
    ],
  },
  students : {},
  education : {},
  market : {
    index:[
      {
        target: '#market-course_fee',
        header: {
          title: '市场应用' ,
        },
        content: `优惠券/阿三开的饭卡地方阿的时空里发酵的索科洛夫健康/大家开始放假啊库卡大煞风景离开大树/阿的是付款辣酱豆腐科技啊咖啡`,
      },
      {
        target: '#market-huidong_apply12312',
        header: {
          title: '市场应用' ,
        },
        content: `课程专栏但是刘可夫家啊收到了房间大发金卡是大发/阿的大家看法阿达打算发大发/换蓝大家疯狂的世界范德萨快乐金卡戴假发接口 `,
      },
      {
        target: '#market-content',
        header: {
          title: '市场应用' ,
        },
        content: `课程专栏但是刘可夫家啊收到了房间大发金卡是大发/阿的大家看法阿达打算发大发/换蓝大家疯狂的世界范德萨快乐金卡戴假发接口 `,
      },
      
    ],
  },
  orginization : {},
  bi : {
    market:{
      clue:bi_tour
    }
  },
  finance : {},
  manager : {}
}
export const tourOptions = {
    useKeyboardNavigation: false,
    labels: {
    buttonSkip: '跳过',
    buttonPrevious: '上一步',
    buttonNext: '下一步',
    buttonStop: '完成'
    }
}