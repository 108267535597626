export default {
  inject: ['parent'],
  props: {
    item: Object,
    isEdit:Number,
    copy:Object,
  },
  
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '',
      form: this.$form.createForm(this),
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleCancel(val) {
      // this.parent.hideModal(val)
      this.parent.hideModal(0)
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
