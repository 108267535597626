<template>
  <div class="dashboard">
    <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="class_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1050 }">
            <template slot="index" slot-scope="text, record , index">
              <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template> 

            <template slot="class_name" slot-scope="class_name, record">
            <a @click="showDetailModal(record,'1')">{{ class_name }}</a>
            </template>           

            <template slot="class_attend_count" slot-scope="text, record">
               {{text}}
            </template>

            <template slot="class_had_course_count" slot-scope="text, record">
               {{text}}
            </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount} 条`"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
      <detailModal v-if="detailVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '班级名称',width:'160px', dataIndex: 'class_name', key: 'class_name',scopedSlots: { customRender: 'class_name' }},
  { title: '所属校区',width:'160px', dataIndex: 'studio_id', key: 'name',ellipsis:true},
  { title: '课程科目',width:'160px', dataIndex: 'course_cate_id', key: '1' ,ellipsis:true},
  { title: '授课老师',width:'100px', dataIndex: 'tutor_id', key: '2' },
  { title: '课程类型',width:'80px', dataIndex: 'class_type', key: '3' ,align:'center'},
  { title: '到课数',width:'80px', dataIndex: 'class_attend_count', key: 'class_attend_count',align:"right" , scopedSlots: { customRender: 'class_attend_count' }},
  { title: '开课数',width:'80px', dataIndex: 'class_had_course_count', key: '4',align:"right" , scopedSlots: { customRender: 'class_had_course_count' }},
  { title: '经办人',width:'80px', dataIndex: 'created_by', key: 'created_by' ,align:"right"},
  { title: '加入时间',width:'120px', dataIndex: 'created_date', key: '8' ,align:"right"},
]


import tableMixins from '@/common/mixins/table'
import detailModal from '@/views/classes/bjgl/detail/index'
export default {
  name: 'xybj',
  props: {
    studentId: Number,
    toRefresh: Number,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},

      visible: false,
      refundVisible: false,
      detailVisible: false,
      modalData:{}
    }
  },

  mixins:[tableMixins],

  components: {
    detailModal,
  },

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileClassesAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showDetailModal(item,num) {
      this.modalData = item
      this.modalData.activeIndex = num
      this.detailVisible = true
    },
    hideDetailModal() {
      this.detailVisible = false
    },
  },
  watch: {
    toRefresh() {
      this.getList()
    }
  }
}
</script>
