const ITEMS = {
  // 性别
  sexs: [
    { label: '男', value: 1 },
    { label: '女', value: 2 },
    { label: '未知', value: 0 },
  ],
  // 班级类型
  classTypes: [
    { label: '一对多', value: 1 },
    { label: '一对一', value: 2 },
    // { label: '试听班', value: 0 },
  ],
  // 班级状态
  class_status: [
    { label: '全部', value: '' },
    { label: '开班中', value: 1 },
    { label: '已结业', value: 2 },
    { label: '待开班', value: 3 },
  ],
  // 周几
  classWeeklys: [
    { label: '周日', value: 7 },
    { label: '周一', value: 1 },
    { label: '周二', value: 2 },
    { label: '周三', value: 3 },
    { label: '周四', value: 4 },
    { label: '周五', value: 5 },
    { label: '周六', value: 6 },
  ],
  // 文件类型
  fileExtTypes: [
    { label: 'PDF', value: 'pdf' },
    { label: 'Word', value: 'docx' },
    { label: '音频', value: 'mp3' },
    { label: 'Excel', value: 'xlsx' },
    // { label: '图文', value: 'article' },
    { label: '视频', value: 'video' },
  ],
  // 文件状态
  fileStatus: [
    { label: '已废除', value: 0 },
    { label: '正常', value: 1 },
  ],
  // 进出班状态
  actionStatus: [
    { label: '进班', value: 1 },
    { label: '出班', value: 2 },
  ],
  fileAuths: [
    { label: '全员可见', value: 0 },
    { label: '仅学员可见', value: 1 },
    { label: '不对外可见', value: 2 },
  ],
  // 咨询状态
  dealStatus: [
    { label: '未跟进', value: 0 , color:'#1ab394'},
    { label: '跟进中', value: 1 , color:'#23c6c8'},
    { label: '已预约', value: 2 , color:'#10aeff'},
    { label: '已到访', value: 3 , color:'#f8ac59'},
    { label: '已成单', value: 4 , color:'#f76260'},
    { label: '暂搁', value: 5 , color:'#ababab'},
    { label: '无效', value: 10 , color:'#ababab'},
    { label: '未接通', value: 6 , color:'#ababab'},
    { label: '长期跟进', value: 15 , color:'#ababab'},
    { label: '已退款', value: 88 , color:'#000'},
  ],
  // 意向程度
  intentionLevels: [
    { label: '其他', value: 0 },
    // { label: '其他', value: 3 },
    // { label: '其他', value: 6 },
    { label: '靶子', value: 9 },
  ],
  intentionLevelsF: [
    { label: '靶子', value: 9 , color:'#ED6363'},
    // { label: '其他', value: 6 , color:'#FF9668'},
    // { label: '其他', value: 3 , color:'#8BB5F0'},
    { label: '其他', value: 0 , color:'#cccccc'},
  ],
  // 市场评估
  assessLevels: [
    { label: '好', value: 1 },
    { label: '中', value: 2 },
    { label: '差', value: 3 },
  ],
  // 到访状态
  isAttends: [
    { label: '未到访', value: 0 },
    { label: '已到访', value: 1 },
    { label: '鸽子', value: 2 },
  ],
  isAttendsF: [
    { label: '全部', value: '' },
    { label: '未到访', value: 0 },
    { label: '已到访', value: 1 },
  ],
  // 跟进方式
  contactTypes: [
    { label: '电话', value: 1 },
    { label: '短信', value: 2 },
    { label: '微信', value: 3 },
    { label: '面咨', value: 4 },
    { label: '其他', value: 0 },
  ],
  // 联系人关系
  studentContactTypes: [
    { label: '无', value: 0 },
    { label: '爸爸', value: 1 },
    { label: '妈妈', value: 2 },
    { label: '姐姐', value: 3 },
    { label: '弟弟', value: 4 },
    { label: '哥哥', value: 5 },
    { label: '朋友', value: 6 },
    { label: '爷爷', value: 7 },
    { label: '奶奶', value: 8 },
    { label: '外公', value: 20 },
    { label: '外婆', value: 30 },
    { label: '本人', value: 9 },
    { label: '保姆', value: 10 },
    { label: '其他', value: 11},
  ],
  // 学员状态
  studentStatus: [
    { label: '注册用户', value: -1 },
    { label: '潜在学员', value: 0 },
    { label: '在读学员', value: 1 },
    { label: '待排班', value: 2 },
    { label: '停课学员', value: 3 },
    { label: '历史学员', value: 4 },
    // { label: '潜在学员', value: 0 },
  ],
  studentTypes: [
    { label: '全部', value: '' },
    { label: '潜在学员', value: 0 },
    { label: '在读学员', value: 1 },
  ],
  // 微信绑定
  wechatBindStatus: [
    { label: '未绑定', value: 1 },
    { label: '已绑定', value: 2 },
  ],
  // 支付状态
  paymentStatus: [
    { label: '支付成功', value: 'SUCCESS' },
    { label: '作废成功', value: 'INVALID' },
    { label: '退款成功', value: 'REFUND' },
  ],
  // 支票类型
  invoiceTypes: [
    { label: '个人', value: 1 },
    { label: '公司', value: 2 },
  ],
  // 是否邮寄
  isSends: [
    { label: '已邮寄', value: 1 },
    { label: '未开发票', value: 0 },
  ],
  // 课程状态
  classStatus: [
    { label: '待上课', value: 1 },
    { label: '已上课', value: 2 },
  ],
  // 课后反馈
  isTrates: [
    { label: '已总结', value: 1 },
    { label: '待总结', value: 0 },
  ],
  // 排课方式
  courseTypes: [
    { label: '循环排课', value: 1 },
    { label: '单次排课', value: 2 },
  ],
  // 课程模式
  classModes: [
    { label: '自主选课', value: 1 },
    { label: '班级排课', value: 2 },
    { label: '一对一', value: 3 },
    { label: '试听课', value: 4 },
  ],
  // 老师请假 操作状态
  optStatus: [
    { label: '未操作', value: 0 },
    { label: '审核通过', value: 1 },
    { label: '审核不通过', value: 2 },
  ],
  // 是否绑定微信
  userWechat: [
    { label: '未绑定', value: 1 },
    { label: '已绑定', value: 2 },
  ],
  // 科目状态
  categoryStatus: [
    { label: '正常', value: 1 },
    { label: '已禁用', value: 0 },
  ],
  // 是否对外公开
  isPrivate: [
    { label: '否', value: 0 },
    { label: '是', value: 1 },
  ],
  // 成就状态
  cateStatus: [
    { label: '正常', value: 1 },
    { label: '已禁用', value: 0 },
  ],
  // 备课状态
  prepareId: [
    { label: '待备课', value: 1 },
    { label: '已备课', value: 2 },
    { label: '被驳回', value: -1 },
  ],
  // 收费类型
  feeType: [
    { label: '课时收费', value: 1 },
    { label: '按期收费', value: 2 },
    { label: '体验课程', value: 4 },
  ],
  // 收费状态
  feeStatus: [
    { label: '上线中', value: 1 },
    { label: '已下线', value: 0 },
  ],
  // 优惠券类型
  couponTypes: [
    { label: '满减（课程）', value: 2 },
    { label: '满减（商品）', value: 1 },
    { label: '满减（活动）', value: 3 },
  ],
  // 优惠券状态
  couponStatus: [
    { label: '有效', value: 1 },
    { label: '失效', value: 0 },
  ],
  // 优惠券状态2
  couponStatus2: [
    { label: '有效', value: 1 },
    { label: '已失效', value: 0 },
    { label: '已使用', value: 2 },
  ],
  // 学员状态
  userStudentStatus: [
    { label: '在读', value: 1 },
    { label: '待排班', value: 2 },
    { label: '停课', value: 3 },
    { label: '历史', value: 4 },
  ],
  // 招募状态
  recruitStatus: [
    { label: '招募中', value: 1 },
    { label: '已下线', value: 2 },
  ],
  // 招募状态
  meetingStatus: [
    { label: '进行中', value: 1 },
    { label: '已下线', value: 3 },
  ],
  // 是否私密
  meetingShow: [
    { label: '公开', value: 0 },
    { label: '私密', value: 1 },
  ],
  // 是否私密
  fixedStatus: [
    { label: '固定金额', value: 1 },
    { label: '自定义金额', value: 0 },
  ],
  // 专栏状态
  packageStatus: [
    { label: '下线中', value: 0 },
    { label: '使用中', value: 1 },
  ],
  // 内容类型
  knowledgeTypes: [
    { label: '视频', value: 2 },
    { label: '音频', value: 3 },
  ],
  // 内容状态
  contentStatus: [
    { label: '下线中', value: 0 },
    { label: '上线中', value: 1 },
  ],
  // 评论状态
  commentStatus: [
    { label: '已审核', value: 1 },
    { label: '已删除', value: 0 },
    { label: '待审核', value: 2 },
  ],
  // 评论状态
  isRead: [
    { label: '未读', value: 0 },
    { label: '已读', value: 1 },
  ],
  // 物品类型
  productType: [
    { label: '教材教具', value: 2 },
    { label: '办公用品', value: 1 },
    { label: '礼品', value: 3 },
    { label: '其他', value: 4 },
  ],
  // 物品操作
  productOpt: [
    { label: '入库', value: '+' },
    { label: '出库', value: '-' },
  ],
  // 校区状态
  studioStatus: [
    { label: '营业中', value: 1 },
    { label: '闭馆中', value: 0 }
  ],
  // 校区类型
  studioType: [
    { label: '自营', value: 1 },
    { label: '加盟', value: 2 },
  ],
  // 焦点图状态
  bannerStatus: [
    { label: '有效', value: 1 },
    { label: '失效', value: 0 },
  ],
  // 焦点图状态
  operationTypes: [
    // { label: '学员课程变更', value: 'add_membercard' },
    { label: '数据添加', value: 'create' },
    { label: '数据删除', value: 'delete' },
    { label: '数据编辑', value: 'update' },
    { label: '数据导出', value: 'export' },
  ],
  // 证书级别
  certificateLevels: [
    { label: '一级证书', value: 1 },
    { label: '二级证书', value: 2 },
    { label: '三级证书', value: 3 },
    { label: '四级证书', value: 4 },
    { label: '五级证书', value: 5 },
    { label: '六级证书', value: 6 },
    { label: '七级证书', value: 7 },
    { label: '八级证书', value: 8 },
    { label: '九级证书', value: 9 },
    { label: '十级证书', value: 10 },
  ],
  // 商品状态
  productStatus: [
    { label: '售卖中', value: 1 },
    { label: '下架中', value: 0 },
  ],
  // 是否参与红人推广计划
  productCom: [
    { label: '不参与红人计划', value: 0 },
    { label: '参与红人计划', value: 1 },
  ],
  achievementTarget: [
    { label: '合同金额', value: 0 },
    { label: '回款金额', value: 1 },
  ],
  // 仪表盘筛选
  typeOptions: [
    { label: '今天', value: 'today' },
    { label: '昨天', value: 'yesterday' },
    { label: '本周', value: 'week' },
    { label: '上周', value: 'lastWeek' },
    { label: '本月', value: 'month' },
    { label: '上月', value: 'lastMonth' },
    { label: '本季度', value: 'quarter' },
    { label: '上季度', value: 'lastQuarter' },
    { label: '本年', value: 'year' },
    { label: '去年', value: 'lastYear' }
  ],
  commissionType: [
    { label: '授课课次', value: 1 },
    { label: '授课课时', value: 2 },
    { label: '学员课时', value: 3 },
    { label: '消课金额', value: 4 },
    { label: '到课人次', value: 5 },
  ],
  gradientRule: [
    { label: '分段梯度', value: 1 },
    { label: '最高梯度', value: 2 },
  ],
  commisionType: [
    { label: '课时绩效', value: 1 },
    { label: '合同绩效', value: 2 },
  ],
  // 结束日期分类
  endDateTypes: [
    { label: '按天', value: 1 },
    { label: '按月', value: 2 },
  ],
  // 结算状态
  salaryStatus: [
    { label: '待确认', value: 0 },
    { label: '已确认', value: 1 },
  ],
  // 日报类型
  journalType: [
    {label:'日报', value:1},
    {label:'周报', value:2},
    {label:'月报', value:3},
  ],
  // 教具
  ballType: [
    {label:'足球-4号球', value:'足球-4号球'},
    {label:'足球-5号球', value:'足球-5号球'},
    {label:'篮球-5号球', value:'篮球-5号球'},
    {label:'篮球-7号球', value:'篮球-7号球'},
    {label:'足球', value:'足球'},
    {label:'篮球', value:'篮球'},
    {label:'未定', value:'未定'},

  ],
  // 衣服尺码
  suitType: [
    {label:'足球服-XS', value:'足球服-XS'},
    {label:'足球服-M', value:'足球服-M'},
    {label:'足球服-L', value:'足球服-L'},
    {label:'足球服-XL', value:'足球服-XL'},
    {label:'足球服-2XL', value:'足球服-2XL'},
    {label:'篮球服-XS', value:'篮球服-XS'},
    {label:'篮球服-M', value:'篮球服-M'},
    {label:'篮球服-L', value:'篮球服-L'}, 
    {label:'篮球服-XL', value:'篮球服-XL'},
    {label:'篮球服-2XL', value:'篮球服-2XL'},
    {label:'篮球服-3XL', value:'篮球服-3XL'},
  ],
  // 衣服尺码
  suitTypeNew: [
    {label:'篮球服-S', value:'篮球服-S'},
    {label:'篮球服-M', value:'篮球服-M'},
    {label:'篮球服-L', value:'篮球服-L'},
    {label:'篮球服-XL', value:'篮球服-XL'},
    {label:'篮球服-2XL（成人L）', value:'篮球服-2XL（成人L）'},
    {label:'篮球服-3XL', value:'篮球服-3XL'},
    {label:'足球服-18码（XS）', value:'足球服-18码（XS）'},
    {label:'足球服-20码（M）', value:'足球服-20码（M）'},
    {label:'足球服-22码（L）', value:'足球服-22码（L）'},
    {label:'足球服-24码（XL）', value:'足球服-24码（XL）'},
    {label:'足球服-26码（2XL）', value:'足球服-26码（2XL）'},
    {label:'足球服-成人S码（3XL）', value:'足球服-成人S码（3XL）'},
    // {label:'足球服-XS', value:'足球服-XS'},
    // {label:'足球服-M', value:'足球服-M'},
    // {label:'足球服-L', value:'足球服-L'},
    // {label:'足球服-XL', value:'足球服-XL'},
    // {label:'足球服-2XL', value:'足球服-2XL'},
    // {label:'篮球服-XS', value:'篮球服-XS'},
    // {label:'篮球服-2XL', value:'篮球服-2XL'},
  ],
  // 请假类型
  leaveType:[
    {label:'年假', value:'年假'},
    {label:'事假', value:'事假'},
    {label:'病假', value:'病假'},
    {label:'产假', value:'产假'},
    {label:'调休', value:'调休'},
    {label:'婚假', value:'婚假'},
    {label:'丧假', value:'丧假'},
    {label:'其他', value:'其他'},
  ],
  // 交通工具
  vehicle:[
    {label:'飞机', value:'飞机'},
    {label:'火车', value:'火车'},
    {label:'汽车', value:'汽车'},
    {label:'其他', value:'其他'}
  ],
  // 往返
  trip:[
    {label:'单程', value:'单程'},
    {label:'往返', value:'往返'},
  ],
  // 审核状态
  approveStatus:[
    {label:'待审核',value:0,color:'#F36A33',icon:'plus-circle'},
    {label:'审批中',value:1,color:'#F36A33',icon:'clock-circle'},
    {label:'通过',value:2,color:'#93E06D',icon:'check-circle'},
    {label:'失败',value:3,color:'#FF0000',icon:'close-circle'},
    {label:'撤回',value:4,color:'#FF0000',icon:'rollback'},
  ],
  approveFlow:[
    {label:'申请',value:'flow_apply',icon:'plus-circle'},
    {label:'待审核',value:'flow_pendding',icon:'clock-circle'},
    {label:'通过',value:'flow_approve',icon:'check-circle'},
    {label:'失败',value:'flow_failure',icon:'close-circle'},
    {label:'撤回',value:'flow_revoke',icon:'rollback'},
  ],
  // 审核内容
  approveContent:[
    {label:'普通审批',value:1,show:'isOrdinaryModal'},
    {label:'请假审批',value:2,show:'isLeaveModal'},
    {label:'出差审批',value:3,show:'isTravelModal'},
    {label:'加班审批',value:4,show:'isOvertimeModal'},
    {label:'差旅报销',value:5,show:'isTravelExpensesModal'},
    // {label:'借款申请',value:6,show:'isLoanModal'},
    // {label:'合同退费审批流程',value:100,show:'isLoanModal'},
  ],
  // 年级
  grade:[
    {label:'小班',value:1},
    {label:'中班',value:2},
    {label:'大班',value:3},
    {label:'一年级',value:4},
    {label:'二年级',value:5},
    {label:'三年级',value:6},
    {label:'四年级',value:7},
    {label:'五年级',value:8},
    {label:'六年级',value:9},
    {label:'初一',value:10},
    {label:'初二',value:11},
    {label:'初三',value:12},
    {label:'高一',value:13},
    {label:'高二',value:14},
    {label:'高三',value:15},
    {label:'大一',value:16},
    {label:'大二',value:17},
    {label:'大三',value:18},
    {label:'大四',value:19},
    {label:'大学毕业',value:20},
    {label:'其他',value:666},
  ],
  clipSeconds:[
    {label:'5秒',value:5},
    {label:'10秒',value:10},
    {label:'15秒',value:15},
    {label:'20秒',value:20},
    {label:'25秒',value:25},
    {label:'30秒',value:30},
  ],
}

export default ITEMS
