<template>
  <div class="dashboard">
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="refund_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '退款合同', width:'150px', dataIndex: 'contract_no', key: 'contract_no'},
  { title: '退款校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis: true},
  { title: '退款科目', dataIndex: 'course_cate_id', key: 'course_cate_id',ellipsis: true},
  { title: '退费金额', dataIndex: 'refund_total', key: 'refund_total',align:"right"},
  { title: '退费课时', dataIndex: 'refund_times', key: 'refund_times',align:"right"},
  { title: '扣费金额', dataIndex: 'refund_reduce', key: 'refund_reduce',align:"right"},
  { title: '实退金额', dataIndex: 'refund_actual_amount', key: 'refund_actual_amount',align:"right"},
  { title: '签单顾问', dataIndex: 'course_consultant_id', key: 'course_consultant_id' },
  { title: '经办人', dataIndex: 'created_by', key: '8' },
  { title: '退费备注', dataIndex: 'refund_remark', key: '10' ,ellipsis: true},
  { title: '退款时间', dataIndex: 'created_at', key: '11' }
]
import tableMixins from '@/common/mixins/table'
export default {
  name: 'xybj',
  props: {
    studentId: Number
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},

      visible: false,
      refundVisible: false,
    }
  },

  mixins:[tableMixins],

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileContractRefundAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
