<template>
  <div>
    <a-menu theme="dark" v-model="menusKey" mode="inline" :defaultSelectedKeys="[currentRoute]"
     :open-keys.sync="openKeys" @click="handleMenu"
      @openChange="onOpenChange">
      <template v-for="(menu,index) of list">
        <template v-if="!menu.hide">
        <a-menu-item :id="`${parentKey}-${menu.path}`" v-if="!menu.children" :key="`/${parentKey}/${menu.path}`">
          <router-link :to="`/${parentKey}/${menu.path}`">
            <div style="display:flex;align-items:center">
              <svg style="width:14px;height:14px;margin-right:10px" fill='#fff' aria-hidden="true">
                <use :xlink:href="menu.meta.icon"></use>
              </svg>
              <div style='margin-right:20px'>{{ menu.meta.title }}</div>
              <a-badge v-if="menu.path == 'todo'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.total_count" />
              <a-badge v-if="menu.path == 'invite'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.invite_attend_count" />
              <a-badge v-if="menu.path == 'approve'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.approve_count" />
              <a-badge v-if="menu.path == 'invite-today'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.invite_today_count" />
              <a-badge v-if="menu.path == 'attend-today'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.today_course_count" />
              <a-badge v-if="menu.path == 'mine/schedule'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.today_course_count" />
              <a-badge v-if="menu.path == 'mine/audition'" :number-style="{ backgroundColor: '#ed5565'}" :count="counts.today_audition_count" />
              
            </div>
          </router-link>
        </a-menu-item>

        <a-sub-menu :id="`${parentKey}-${menu.path}`" v-else :open-keys="[`/${parentKey}/${menu.path}`]" :key="`/${parentKey}/${menu.path}`">
          <span slot="title">
            <!-- <a-icon type="user" /><span>{{ menu.meta.title }}</span> -->
             <div style="display:flex;align-items:center">
              <svg style="width:16px;height:16px;margin-right:10px" fill='#fff' aria-hidden="true">
                <use :xlink:href="menu.meta.icon"></use>
              </svg>
              <span>{{ menu.meta.title }}</span>
            </div>
          </span>
          <a-menu-item :id="`${parentKey}-${menu.path}-${m.path}`" @click="setNumBer(index)" v-for="m of menu.children" :key="`/${parentKey}/${menu.path}/${m.path}`">
            <router-link :to="`/${parentKey}/${menu.path}/${m.path}`">{{ m.meta.title }}</router-link>
          </a-menu-item>
        </a-sub-menu>


        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  name: 'LMenu',

  data() {
    return {
      rootSubmenuKeys: [],
      menus: [],
      menusKey:[],
      openKeys:[],
      list:[]
    }
  },
  props:['routerKey','menuopenKeys','routerData','parentKey','selectKeys'],
  computed: {
    currentRoute() {
      return this.$route.path
    },
    counts(){
      return this.$store.state.loading.count
    }
  },

  created() {
    this.list = this.routerData.children
    let menuIndex = localStorage.getItem('menuIndex')?localStorage.getItem('menuIndex'):0
    let openKeys = `/${this.parentKey}/${this.routerData.children[menuIndex].path}`
    if(this.openKeys.indexOf(openKeys) == -1){
      this.openKeys.push(openKeys)
    }
    const { routes } = this.$router.options
    for (let routeItem of routes) {
      if (routeItem.path === '/work') {
        this.menus = routeItem.children
        break
      }
    }
    let keys = []
    for (let menu of this.menus) {
      keys.push(`/work/${menu.path}`)
    }
    this.rootSubmenuKeys = keys
    this.menusKey = this.routerKey
  },
  watch:{
    routerKey(){
      let openKeys = `/${this.parentKey}/${this.routerData.children[0].path}`
      if(this.openKeys.indexOf(openKeys) == -1){
        this.openKeys.push(openKeys)
      }
      
      this.list = this.routerData.children
      this.menusKey = this.routerKey
    },
    menuopenKeys(){
      this.openKeys = this.menuopenKeys
    },
    selectKeys(){
      this.menusKey = this.selectKeys
    }
  },
  methods: {
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    handleMenu(key){
    },
    setNumBer(index){
      localStorage.setItem('menuIndex',index)
    }
  },
}
</script>
