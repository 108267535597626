import url from '@/utils/URL'
import request from '@/utils/request'


export function oaIndex(data) {
  return request({
    url: url.oaIndex,
    method: 'POST',
    data
  })
}

export function oaAdd(data) {
  return request({
    url: url.oaAdd,
    method: 'POST',
    data
  })
}

export function oaDetail(data) {
  return request({
    url: url.oaDetail,
    method: 'POST',
    data
  })
}

export function oaUpdate(data) {
  return request({
    url: url.oaUpdate,
    method: 'POST',
    data
  })
}

export function oaDelete(data) {
  return request({
    url: url.oaDelete,
    method: 'POST',
    data
  })
}

export function oaSeen(data) {
  return request({
    url: url.oaSeen,
    method: 'POST',
    data
  })
}

export function oaAddComment(data) {
  return request({
    url: url.oaAddComment,
    method: 'POST',
    data
  })
}

export function journalTodayCrm(data) {
  return request({
    url: url.journalTodayCrm,
    method: 'POST',
    data
  })
}

export function journalRecord(data) {
  return request({
    url: url.journalRecord,
    method: 'POST',
    data
  })
}

export function journalExport(data) {
  return request({
    url: url.journalExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function oaAnnounceIndex(data) {
  return request({
    url: url.oaAnnounceIndex,
    method: 'POST',
    data
  })
}

export function oaAnnounceAdd(data) {
  return request({
    url: url.oaAnnounceAdd,
    method: 'POST',
    data
  })
}

export function oaAnnounceSeen(data) {
  return request({
    url: url.oaAnnounceSeen,
    method: 'POST',
    data
  })
}

export function stageIndex(data) {
  return request({
    url: url.stageIndex,
    method: 'POST',
    data
  })
}

export function stageExport(data) {
  return request({
    url: url.stageExport,
    method: 'POST',
    responseType: 'blob',
    data
  })
}

export function stageAttendSchedule(data) {
  return request({
    url: url.stageAttendSchedule,
    method: 'POST',
    data
  })
}

export function stageAttendScheduleExport(data) {
  return request({
    url: url.stageAttendSchedule,
    method: 'POST',
    responseType: 'blob',
    data
  })
}


export function stageAttend(data) {
  return request({
    url: url.stageAttend,
    method: 'POST',
    data
  })
}

export function stageNoShow(data) {
  return request({
    url: url.stageNoShow,
    method: 'POST',
    data
  })
}

export function oaExamineAdd(data) {
  return request({
    url: url.oaExamineAdd,
    method: 'POST',
    data
  })
}

export function oaExamineIndex(data) {
  return request({
    url: url.oaExamineIndex,
    method: 'POST',
    data
  })
}

export function oaExamineDetail(data) {
  return request({
    url: url.oaExamineDetail,
    method: 'POST',
    data
  })
}

export function oaExamineUpdate(data) {
  return request({
    url: url.oaExamineUpdate,
    method: 'POST',
    data
  })
}

export function oaExamineRevoke(data) {
  return request({
    url: url.oaExamineRevoke,
    method: 'POST',
    data
  })
}

export function oaExamineCheck(data) {
  return request({
    url: url.oaExamineCheck,
    method: 'POST',
    data
  })
}

export function oaExamineCheckRecord(data) {
  return request({
    url: url.oaExamineCheckRecord,
    method: 'POST',
    data
  })
}

export function oaExamineStep(data) {
  return request({
    url: url.oaExamineStep,
    method: 'POST',
    data
  })
}

export function oaTaskIndex(data) {
  return request({
    url: url.oaTaskIndex,
    method: 'POST',
    data
  })
}

export function oaTaskAdd(data) {
  return request({
    url: url.oaTaskAdd,
    method: 'POST',
    data
  })
}

export function oaTaskDeatil(data) {
  return request({
    url: url.oaTaskDeatil,
    method: 'POST',
    data
  })
}

export function oaTaskUpdate(data) {
  return request({
    url: url.oaTaskUpdate,
    method: 'POST',
    data
  })
}

export function oaTaskDelete(data) {
  return request({
    url: url.oaTaskDelete,
    method: 'POST',
    data
  })
}

export function oaTaskCheck(data) {
  return request({
    url: url.oaTaskCheck,
    method: 'POST',
    data
  })
}

export function oaTaskLog(data) {
  return request({
    url: url.oaTaskLog,
    method: 'POST',
    data
  })
}

export function oaTaskAddLog(data) {
  return request({
    url: url.oaTaskAddLog,
    method: 'POST',
    data
  })
}

export function oaTaskReOrder(data) {
  return request({
    url: url.oaTaskReOrder,
    method: 'POST',
    data
  })
}

export function oaUpgradeLog(data) {
  return request({
    url: url.oaUpgradeLog,
    method: 'POST',
    data
  })
}
