import Vue from 'vue'
import { dashboardIndex, dashboardIndexCrm, dashboardIndexEducation, dashboardIndexFinance, dashboardTeacher, dashboardCc } from '@/api/dashboard'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    dashboardIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    dashboardIndexCrmAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardIndexCrm(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    dashboardIndexEducationAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardIndexEducation(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    dashboardIndexFinanceAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardIndexFinance(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    dashboardTeacherAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardTeacher(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    dashboardCcAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        dashboardCc(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
