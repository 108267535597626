export default {
    addRouters: state => state.permission.addRouters,
    adminRouter: state => state.permission.adminRouter,
    marketRouter: state => state.permission.marketRouter,
    settingRouter: state => state.permission.settingRouter,
    studentsRouter: state => state.permission.studentsRouter,
    financeRouter: state => state.permission.financeRouter,
    analysisRouter: state => state.permission.analysisRouter,
    customerRouter: state => state.permission.customerRouter,
    educationRouter: state => state.permission.educationRouter,
    enterpriseRouter: state => state.permission.enterpriseRouter,
    orginizationRouter: state => state.permission.orginizationRouter,
    routesMenus: state => state.permission.routesMenus,
    menusKey: state => state.permission.menusKey,
    allAuth: state => state.user.allAuth,
    employee_id: state => state.user.employee_id,
    realname: state => state.user.realname,
    brand_info: state => state.user.brand_info,
    wxwork_info: state => state.user.wxwork_info,
}