const Layout = () => import('@/views/layout')
const Finance = () => import('@/views/finance')
const FinanceDaily = () => import('@/views/finance/daily')
const FinanceContract = () => import('@/views/finance/contract')
const FinanceCosttimes = () => import('@/views/finance/costtimes')
const FinanceRefund = () => import('@/views/finance/refund')
const FinanceInvoice = () => import('@/views/finance/invoice')
const SettingBizGoals = () => import('@/views/finance/bizGoals')

const financeRouter = {
    path: '/finance',
    component: Layout,
    hide: true,
    parent:'financeRouter',
    meta: {
      title: '财务中心',
      requiresAuth: false,
      type:'finance',
      icon: '#icon-MoneyOutline',
      menusKey:7,
    },
    children:[
          {
            path: 'daily',
            component: FinanceDaily,
            hide:false,
            parent:'finance',
            meta: { title: '收款记录', icon: '#icon-MoneyOutline' ,parent:'finance'}
          }, {
            path: 'contract',
            component: FinanceContract,
            hide:false,
            parent:'finance',
            meta: { title: '合同管理', icon:'#icon-OrderOutline' ,parent:'finance'}
          }, {
            path: 'cost-times',
            component: FinanceCosttimes,
            hide:false,
            parent:'finance',
            meta: { title: '课消记录' ,icon:'#icon-MoremoneyOutline' ,parent:'finance'}
          }, {
            path: 'refund',
            component: FinanceRefund,
            hide:false,
            parent:'finance',
            meta: { title: '退费记录' ,icon:'#icon-GoldOutline' ,parent:'finance'}
          }, {
            path: 'invoice',
            component: FinanceInvoice,
            hide:false,
            parent:'finance',
            meta: { title: '发票申请' ,icon:'#icon-LetterOutline' ,parent:'finance'}
          },{
            path: 'BizGoals',
            component: SettingBizGoals,
            hide:false,
            parent:'finance',
            meta: { title: '业绩目标' , icon: '#icon-CrownOutline' ,parent:'finance'}
          }
    ]
}

export default financeRouter