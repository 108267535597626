<template>
  <div class="dashboard">
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '积分操作', dataIndex: 'name', key: 'name' },
  { title: '变动', dataIndex: 'num', key: 'num',align:"right" },
  { title: '备注', dataIndex: 'remark', key: 'remark' ,ellipsis: true,align:"right" },
  { title: '经办人', dataIndex: 'created_by', key: 'created_by' ,align:"right" },
  { title: '操作时间', dataIndex: 'created_at', key: 'created_at' ,align:"right" }
]
import tableMixins from '@/common/mixins/table'
export default {
  name: 'xybj',
  props: {
    studentId: Number
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      searchData: {},

      visible: false,
      refundVisible: false,
    }
  },

  mixins:[tableMixins],

  methods: {
    async getList() {
      this.loading = true
      this.searchParams.student_id = this.studentId
      let res = await this.$store.dispatch('studentsProfileCreditAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
  }
}
</script>
