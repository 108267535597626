import url from '@/utils/URL'
import request from '@/utils/request'

export function marketingQrchannel(params) {
  return request({
    url: url.marketingQrchannel,
    method: 'POST',
    ...params
  })
}

export function marketingQrchannelAdd(params) {
  return request({
    url: url.marketingQrchannelAdd,
    method: 'POST',
    ...params
  })
}

export function marketingQrchannelUpdate(params) {
  return request({
    url: url.marketingQrchannelUpdate,
    method: 'POST',
    ...params
  })
}

export function marketingQrchannelDownload(params) {
  return request({
    url: url.marketingQrchannelDownload,
    method: 'POST',
    ...params
  })
}

export function marketingParklist(params) {
  return request({
    url: url.marketingParklist,
    method: 'POST',
    ...params
  })
}

export function marketingParkDetail(params) {
  return request({
    url: url.marketingParkDetail,
    method: 'POST',
    ...params
  })
}

export function marketingRoomDetail(params) {
  return request({
    url: url.marketingRoomDetail,
    method: 'POST',
    ...params
  })
}


export function marketingParkUpdate(params) {
  return request({
    url: url.marketingParkUpdate,
    method: 'POST',
    ...params
  })
}

export function marketingParkRoomAdd(params) {
  return request({
    url: url.marketingParkRoomAdd,
    method: 'POST',
    ...params
  })
}
export function marketingParkRoomUpdate(params) {
  return request({
    url: url.marketingParkRoomUpdate,
    method: 'POST',
    ...params
  })
}

export function marketingParkAdd(params) {
  return request({
    url: url.marketingParkAdd,
    method: 'POST',
    ...params
  })
}

export function marketingParkBillAdd(params) {
  return request({
    url: url.marketingParkBillAdd,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepayment(params) {
  return request({
    url: url.marketingQrcodepayment,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentQrcodeview(params) {
  return request({
    url: url.marketingQrcodepaymentQrcodeview,
    method: 'POST',
    ...params
  })
}

export function marketingRentFilterPark(params) {
  return request({
    url: url.marketingRentFilterPark,
    method: 'POST',
    ...params
  })
}

export function marketingRentFilterRoom(params) {
  return request({
    url: url.marketingRentFilterRoom,
    method: 'POST',
    ...params
  })
}

export function marketingRentRoomlist(params) {
  return request({
    url: url.marketingRentRoomlist,
    method: 'POST',
    ...params
  })
}

export function marketingRentRoomDel(params) {
  return request({
    url: url.marketingRentRoomDel,
    method: 'POST',
    ...params
  })
}

export function marketingRentBillFapiao(params) {
  return request({
    url: url.marketingRentBillFapiao,
    method: 'POST',
    ...params
  })
}

export function marketingRentRoomRecovery(params) {
  return request({
    url: url.marketingRentRoomRecovery,
    method: 'POST',
    ...params
  })
}

export function marketingRentBilllist(params) {
  return request({
    url: url.marketingRentBilllist,
    method: 'POST',
    ...params
  })
}

export function marketingRentBillAnalysis(params) {
  return request({
    url: url.marketingRentBillAnalysis,
    method: 'POST',
    ...params
  })
}

export function marketingRentBillDel(params) {
  return request({
    url: url.marketingRentBillDel,
    method: 'POST',
    ...params
  })
}

export function marketingRentBillExport(params) {
  return request({
    url: url.marketingRentBillExport,
    responseType: 'blob',
    method: 'POST',
    ...params
  })
}

export function marketingRentBillConfirm(params) {
  return request({
    url: url.marketingRentBillConfirm,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentPaymentlist(params) {
  return request({
    url: url.marketingQrcodepaymentPaymentlist,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentExportPaymentlist(params) {
  return request({
    url: url.marketingQrcodepaymentExportPaymentlist,
    responseType: 'blob',
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentSetting(params) {
  return request({
    url: url.marketingQrcodepaymentSetting,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentAdd(params) {
  return request({
    url: url.marketingQrcodepaymentAdd,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentDownload(params) {
  return request({
    url: url.marketingQrcodepaymentDownload,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentDeleteNotify(params) {
  return request({
    url: url.marketingQrcodepaymentDeleteNotify,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentDelete(params) {
  return request({
    url: url.marketingQrcodepaymentDelete,
    method: 'POST',
    ...params
  })
}

export function marketingQrcodepaymentRecovery(params) {
  return request({
    url: url.marketingQrcodepaymentRecovery,
    method: 'POST',
    ...params
  })
}