<template>
    <a-modal title="" v-model="visible" :footer='null' width="750px" class="videoModal"
     cancelText="取消" okText="保存" @ok="handleOk" @cancel="handleCancel">
        <div id="dplayer"></div>
        <canvas id="canvas" style="position:absolute;top: 45px;"></canvas>
        <!-- <a-button type="round" @click="highlight"></a-button> -->
  </a-modal>
</template>

<script>
    import DPlayer from 'dplayer';
    import Hls from 'hls.js'
    require("tracking/build/tracking-min.js");
    require("tracking/build/data/face-min.js");
    import dat from "dat.gui";
    import * as faceapi from "face-api.js";
    export default {
        name:'videoModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
            }
        },
        props: {
            videoSrc: String,
            highlight: Array,
        },
        mounted(){
        },
        created () {
            this.visible = true
            this.$nextTick(() => {
                if(this.videoSrc){
                    let playerOtions =  {
                        container: document.getElementById('dplayer'),
                        autoplay:true,
                        live: false,
                        muted: true,
                        screenshot:true,
                        // playbackSpeed:false,
                        preload: 'auto',
                        contextmenu:[],
                        highlight:this.highlight,
                        video: {
                            url: this.videoSrc,
                            // type:'mp4',
                            type: 'customHls',
                            customType: {
                                customHls: function (video, player) {
                                    const hls = new Hls();
                                    hls.loadSource(video.src);
                                    hls.attachMedia(video);
                                    player.events.on('destroy', () => {
                                        hls.stopLoad();
                                        hls.destroy();
                                    });
                                },
                            },
                        }
                    }
                    this.player = new DPlayer(playerOtions)
                    this.$nextTick(() => {
                        this.initTracking();
                    })
                }
            })
            // console.log(this.videoSrc,'videoSrc')
            // this.playerOtions.video.url=
            // console.log(this.$refs.player)
            
        },
        beforeDestroy() {
            if(this.player) {
                this.player.destroy()
                this.player = null
            }
        },
        components: {},
        methods: {
            async initTracking() {
                var video = this.player.video;
                //   绘图
                var canvas = document.getElementById('canvas');
                canvas.width=video.offsetWidth;
                canvas.height=video.offsetHeight;
                var context = canvas.getContext('2d');
            
                var tracker = new tracking.ObjectTracker('face');
                //   设置识别的放大比例
                tracker.setInitialScale(4);
                //   设置步长
                tracker.setStepSize(2);
                //   边缘密度
                tracker.setEdgesDensity(0.1);
                //   启动摄像头，并且识别视频内容
                tracking.track(video, tracker);
            
                tracker.on('track', function (event) {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    // 绘制
                    event.data.forEach(function (rect) {
                    context.strokeStyle = '#a64ceb';
                    context.strokeRect(rect.x, rect.y, rect.width, rect.height);
                    });
                });
            },
            handleOk(){},
            handleCancel() {
                this.parent.hideVideoModal()
            }
        },
    }
</script>

<style lang="scss">
    .videoModal{
        .ant-modal-content{
            background-color:inherit;
            box-shadow:none;
        }
        .ant-modal-body{
            padding: 40px;
        }
    }
    video{
        position: relative;
    }
    // video,
    // canvas {
    //   position: absolute;
    // }
</style>