import Vue from 'vue'
import {discourseGroup,discourseAddGroup,discourseUPdateGroup,discourseDeleteGroup,discourseIndex,discourseAdd,discourseDetail,discourseUpdate,discourseDelete,
  discourseGroupSort,collectIndex,collectAdd,collectDetail,collectUpdate,discourseClick,discourseLeads,discourseLeadsAdd,discourseCustomer,discourseCustomerAdd,
  collectLeads} from '@/api/discourse'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    discourseGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        discourseGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    discourseAddGroupAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            discourseAddGroup(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseUPdateGroupAction({ commit }, params) {
        return new Promise((resolve,reject) => {
            discourseUPdateGroup(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseDeleteGroupAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseDeleteGroup(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseGroupSortAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        discourseGroupSort(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
  },
    discourseIndexAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseIndex(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseAddAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseAdd(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseDetailAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseDetail(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseUpdateAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseUpdate(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseDeleteAction({ commit }, params) {
        return new Promise((resolve,reject) => {
        discourseDelete(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseClickAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          discourseClick(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseLeadsAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          discourseLeads(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseLeadsAddAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          discourseLeadsAdd(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseCustomerAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          discourseCustomer(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    discourseCustomerAddAction({ commit }, params) {
        return new Promise((resolve,reject) => {
          discourseCustomerAdd(params).then(response => {
            resolve(response)
          }).catch(reason => {
            console.log(reason)
            reject(reason)
          })
        })
    },
    collectIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        collectIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    collectAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        collectAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    collectDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        collectDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    collectUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        collectUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    collectLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        collectLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
  }
}
