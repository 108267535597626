<template>
  <div class="receipt_detail">
    <div class="hide_info" style="display: block;" ref="print_info">
        <div class="print_info" v-if="data">
            <div class="print_title">
                <!-- <span class="cost_nickname">{{ data.student_name }}</span>收据 -->
                收据
            </div>
            <div class="row receipt_title">
                <div class="col-sm-4">
                    <div>
                        姓名：<span class="cost_nickname">{{ data.student_name }}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div>
                        课程顾问：<span class="cost_saleuser">{{ consult }}</span>
                    </div>
                </div>
            </div>
            <div class="table-responsive receipt_table">
                <p>课程信息</p>
                <div>
                  <div v-if="data.localCourseFee_course.length>0">
                    <table border="1" class="table">
                        <thead>
                        <tr>
                            <th>课程名称</th>
                            <th>有效课时</th>
                            <th>赠送课时</th>
                            <th>单价</th>
                            <th>优惠金额</th>
                            <th>实收金额</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) of data.localCourseFee_course" :key="index">
                            <td><span class="cost_cardtitle">{{ item.fee_name_short }}</span></td>
                            <td><span class="cost_cardtimes">{{ item.times }}</span></td>
                            <td><span class="cost_card_balances">{{ item.gift_times}}</span></td>
                            <td><span class="cost_cardgift">{{ item.unit_price }}</span></td>
                            <td><span class="cost_cardsign">{{ item.discount}}</span></td>
                            <td><span class="cost_card_start_time">{{ item.price}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                  </div>
                  <div v-if="data.localCourseFee_month.length>0">
                    <table border="1" class="table">
                        <thead>
                        <tr>
                            <th>课程名称</th>
                            <th>开始/结束</th>
                            <th>赠送时间</th>
                            <th>单价</th>
                            <th>优惠金额</th>
                            <th>实收金额</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) of data.localCourseFee_month" :key="index">
                            <td><span class="cost_cardtitle">{{ item.fee_name_short }}</span></td>
                            <td>
                              <span class="cost_cardtimes">
                                <span v-if='item.end_date_type == 1'>{{moment(item.start_date).format('YYYY-MM-DD')}} - {{moment(item.end_date).format('YYYY-MM-DD')}}</span>
                                <span v-else>{{moment(item.start_date).format('YYYY-MM-DD')}} - {{moment().add(item.month, 'M').format('YYYY-MM-DD')}}</span>
                              </span>
                            </td>
                            <td><span class="cost_card_balances">{{ item.gift_day }}</span></td>
                            <td><span class="cost_cardgift">{{ item.unit_price || 0 }}</span></td>
                            <td><span class="cost_cardsign">{{ item.discount || 0 }}</span></td>
                            <td><span class="cost_card_start_time">{{ item.price }}</span></td>
                        </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="table-responsive receipt_table" v-if="data.incidental.length>0">
                <p>其他物品信息</p>
                <table border="1" class="table">
                    <thead>
                    <tr>
                        <th>物品名称</th>
                        <th>单价</th>
                        <th>数量</th>
                        <th>实收金额</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) of data.incidental" :key="index">
                        <td><span class="cost_cardtitle">{{ item.incidental_name }}</span></td>
                        <td><span class="cost_cardtimes">{{ item.sale_price }}</span></td>
                        <td><span class="cost_card_balances">{{ item.product_count }}</span></td>
                        <td><span class="cost_cardgift">{{ item.sale_price * item.product_count }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive receipt_table">
                <p>总计</p>
                <table border="1" class="table">
                    <thead>
                    <tr>
                        <th>金额（大写）</th>
                        <th>应收金额</th>
                        <th>优惠金额</th>
                        <th>实收金额</th>
                        <th>支付方式</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="ng-scope">
                        <td><span id="convertCurrency">{{money}}</span></td>
                        <td><span class="cost_signprice">{{ signprice }}</span></td>
                        <td><span class="discount_price">{{ totalDiscount }}</span></td>
                        <td><span class="cost_totalprice">{{ totalPrice }}</span></td>
                        <td><span class="payment_type">{{ payment }}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive receipt_table">
                <p>交费记录</p>
                <table border="1" class="table">
                    <thead>
                    <tr>
                        <th>交费时间</th>
                        <th>付款方式</th>
                        <th>课程顾问</th>
                        <th>金额</th>
                        <th>状态</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><span class="creat_time">{{ data.payment_datetime }}</span></td>
                        <td><span class="payment_type">{{ payment }}</span></td>
                        <td><span class="cost_saleuser">{{ consult }}</span></td>
                        <td><span class="cost_totalprice">{{ totalPrice }}</span></td>
                        <td>收款成功</td>
                    </tr>
                    </tbody>
                </table>
                <div class="remark_content clearfix" style="display: none;">
                    <div class="remark_content_left">备注信息：</div>
                    <div class="remark_content_right">{{ data.remark }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import htmlToPdf from '@/utils/htmlToPdf'
import { NoToChinese } from '@/utils/timeFormat'
import moment from 'moment'
export default {
  inject: ['parent'],
  data() {
    return {
      data: null
    }
  },

  props:{
    exportNum:Number
  },

  computed: {
    totalDiscount() {
      let p = 0
      for (let item of this.data.fee) {
        p += (+item.discount || 0)
      }
      return p
    },
    money(){
      let p = 0
      for (let item of this.data.fee) {
        p += Number(item.price) || 0
      }
      for (let item of this.data.incidental) {
        p += (+item.sale_price * item.product_count || 0)
      }
      return NoToChinese(Number(p))
    },
    totalPrice() {
      let p = 0
      for (let item of this.data.fee) {
        // p += (+item.price - (item.discount || 0)) || 0
        p = Number(p) + Number(item.price) || 0
        p = p.toFixed(2)
      }
      for (let item of this.data.incidental) {
        p += (+item.sale_price * item.product_count || 0)
      }
      return p
    },
    signprice(){
      let p = 0
      for (let item of this.data.fee) {
        // p += (+item.price - (item.discount || 0)) || 0
        p = Number(p) + Number(item.course_price) || 0
        p = p.toFixed(2)
      }
      for (let item of this.data.incidental) {
        p += (+item.sale_price * item.product_count || 0)
      }
      return p
    },
    payment() {
      for (let item of this.parent.paymentTypes) {
        if(this.data.payment_type === item.payment_type) {
          return item.filter_name
        }
      }
    },
    consult() {
      for (let item of this.parent.consults) {
        if(this.data.course_consultant_id === item.course_consultant_id) {
          return item.filter_name
        }
      }
    }
  },

  created() {
    this.data = this.parent.pData
  },
  methods: {
    moment,
    download() {
      let that = this
      setTimeout(()=>{
        htmlToPdf.downloadPDF(that.$refs.print_info, `${that.data.student_name}`)
        that.parent.downLoading = false
      },100)
    },
  },
  watch: {
    exportNum(newValue, oldValue) {
      this.download()
    }
  },
}
</script>

<style>

</style>
