import Vue from 'vue'
import { monitorIndex,monitorUpdate,monitorRefreash, monitorAdd,monitorRecallIndex} from '@/api/monitor'
import config from '@/utils/const'

export default { 
  state: {
  },
  mutations: {
  },
  actions: {
    monitorIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        monitorIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    monitorAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        monitorAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    monitorUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        monitorUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    monitorRefreashAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        monitorRefreash(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },

    monitorRecallIndexAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        monitorRecallIndex(params).then(response => {
          resolve(response)
        }).catch(reason => {
          reject(reason)
        })
      })
    },
    
  }
}
