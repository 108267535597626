import url from '@/utils/URL'
import request from '@/utils/request'

/**
 * 课程科目-列表
 */
export function cCategoryList(params) {
  return request({
    url: url.cCategoryList,
    method: 'POST',
    ...params
  })
}
export function cCategoryAdd(params) {
  return request({
    url: url.cCategoryAdd,
    method: 'POST',
    ...params
  })
}
export function cCategoryStudios(params) {
  return request({
    url: url.cCategoryStudios,
    method: 'POST',
    ...params
  })
}
export function cCategoryUpdate(params) {
  return request({
    url: url.cCategoryUpdate,
    method: 'POST',
    ...params
  })
}
export function cCategoryDetail(params) {
  return request({
    url: url.cCategoryDetail,
    method: 'POST',
    ...params
  })
}
export function cCategoryDelete(params) {
  return request({
    url: url.cCategoryDelete,
    method: 'POST',
    ...params
  })
}
export function cCategoryRecovery(params) {
  return request({
    url: url.cCategoryRecovery,
    method: 'POST',
    ...params
  })
}

export function cCategoryCoursesSingleFee(params) {
  return request({
    url: url.cCategoryCoursesSingleFee,
    method: 'POST',
    ...params
  })
}

export function cCategoryCoursesList(params) {
  return request({
    url: url.cCategoryCoursesList,
    method: 'POST',
    ...params
  })
}
export function cCategoryCoursesAdd(params) {
  return request({
    url: url.cCategoryCoursesAdd,
    method: 'POST',
    ...params
  })
}
export function cCategoryCoursesUpdate(params) {
  return request({
    url: url.cCategoryCoursesUpdate,
    method: 'POST',
    ...params
  })
}
export function cCategoryCoursesDelete(params) {
  return request({
    url: url.cCategoryCoursesDelete,
    method: 'POST',
    ...params
  })
}
export function cCategoryCoursesRecovery(params) {
  return request({
    url: url.cCategoryCoursesRecovery,
    method: 'POST',
    ...params
  })
}
