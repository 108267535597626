
<template>
  <a-modal title="图片管理" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :zIndex='4002'
    @ok="handleOk" @cancel="handleCancel">
    <material isModal isSingle='1'/>
  </a-modal>
</template>

<script>
import material from '@/views/orginization/material'
export default {
  name: 'ImageModal',
  inject: ['parent'],
  provide() {
    return {
      parent: this
    }
  },
  props: {
    item: Object
  },

  components: {
    material
  },

  data() {
    return {
      visible: false,
      confirmLoading: false,
    }
  },

  created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    handleOk(image) {
      this.parent.hideImagesModal(image)
    },
    handleCancel() {
      this.parent.hideImagesModal()
    }
  }
}
</script>