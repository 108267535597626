<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-if="!class_id" label="所属班级" :label-col="labelCol" :wrapper-col="{ span: 6 }">
        <a-select placeholder="请选择" v-decorator="rules.classId" showSearch :filterOption="filterOption">
          <a-select-option v-for="(item, index) of category" :key="index" :value="item.class_id">{{ item.filter_name }}</a-select-option>
        </a-select> 
      </a-form-item>
      <a-form-item label="动态内容" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-textarea v-decorator="rules.messageContent" :rows="4"/>
      </a-form-item>
      <a-form-item label="动态图片" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <div style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal('message_images')">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
      </a-form-item>
      <a-form-item label="动态音频" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.messageAudios" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :defaultFileList="fileList2"
          @change="changeFile2">
          <a-button type="dashed">
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="动态视频" :label-col="labelCol" :wrapper-col="{ span: 15 }">
        <a-upload v-decorator="rules.messageVideos" name="file"
          multiple
          :data="uploadParams"
          :action="uploadUrl" 
          :beforeUpload="beforeUpload"
          :defaultFileList="fileList3"
          @change="changeFile3">
          <a-button type="dashed">
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="资料库文件" :label-col="labelCol" :wrapper-col="{ span: 6 }">
        <a-select placeholder="请选择" allowClear v-decorator="rules.messageKnowledge" allowClear >
          <a-select-option v-for="(item, index) of zlList" :key="index" :value="item.id">{{ item.file_name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import ImageModal from '@/views/modal/images'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object,
    class_id:Number || String,
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增动态',

      form: this.$form.createForm(this),
      rules: {
        classId: [ 'class_id',
          {rules: []}
        ],
        messageContent: [ 'message_content',
          {rules: [{ required: true, message: '请输入动态内容!' }]}
        ],
        messageImages: [ 'message_images', {rules: []} ],
        messageAudios: [ 'message_audios', {rules: []} ],
        messageVideos: [ 'message_videos', {rules: []} ],
        messageKnowledge: [ 'message_knowledge', {rules: []} ],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: [],
      fileList2: [],
      fileList3: [],

      imageRoot: url.imageRoot,
      imageUrl: '',
      imageUrls: [],
      imagesVisible: false,
      imageKey: '',

      students: [],
      category: [],
      zlList: [],
    }
  },

  async created() {
    this.visible = true
    this.getCate()
    this.getZlList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'message_images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getCate() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.category = res.data
    },
    async getZlList() {
      let res = await this.$store.dispatch('zlShareListAction', { })
      this.zlList = res.items
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          // if (this.fileList.length) {
          //   let arr = []
          //   for (let file of this.fileList) {
          //     if (file.response.key) {
          //       arr.push(file.response.key)
          //     }
          //   }
          //   params.message_images = arr
          // }
          params.message_images = this.imageUrls
          if (this.fileList2.length) {
            let arr = []
            for (let file of this.fileList2) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.message_audios = arr
          }
          if (this.fileList3.length) {
            let arr = []
            for (let file of this.fileList3) {
              if (file.response.key) {
                arr.push(file.response.key)
              }
            }
            params.message_videos = arr
          }
          if(this.class_id){
            params.class_id = this.class_id.toString()
          }
          this.confirmLoading = true
          let res = await this.$store.dispatch('daMessagesPublishAction', { data: params })
          .then(res=>{
            this.$message.success('操作成功~')
            form.resetFields()
            this.parent.submitModal('5')
          })
          .catch(err=>{
            this.$message.error('操作失败~')
            this.confirmLoading = false
          })
        }
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideModal()
    },

    async beforeImageUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    changeFile({ file, fileList }) {
      this.fileList = fileList
      console.log(fileList)
    },
    changeFile2({ file, fileList }) {
      this.fileList2 = fileList
      console.log(fileList)
    },
    changeFile3({ file, fileList }) {
      this.fileList3 = fileList
      console.log(fileList)
    },

    
    async handleSearch(value) {
      let data = {
        "search": {fuzzy: value},
      }
      let res = await this.$store.dispatch('studentsAction', { data })
      this.students = res.items
      this.$forceUpdate()
    },
    handleChange(value) {
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      });
    },
  },
}
</script>
