<template>
  <a-card :title="title">
      <div class="ve_rings">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <ve-ring
        judge-width
        :legend="{show:false}"
        height="360px"
        :toolbox="toolbox"
        :title="{
            text: item.title,
            subtext: subtext,
            textStyle: {
                fontSize: 22,
                color: ['#23d9ae']
            },
            subtextStyle: {
                fontSize: 14
            },
            x: 'center',
            y: '50%'
        }"
        :data="item" 
        :extend="{
            series:{
                label: {
                    normal: {
                        formatter: params => {
                            return (
                                 `${params.name} ${params.value} (${params.percent}%)`
                            );
                        }
                    }
                }
            }
        }"
        :settings="{
            limitShowNum: 25,
            offsetY: 200
        }"
        ></ve-ring>
        <LEmpty v-if="first>0 && item.rows.length == 0"/>
    </div>
  </a-card>
</template>

<script>
export default {
  name: 'VErings',
  props:['item','loading','title','subtext'],
  data(){
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
      return{
          first:0
      }
  },
  watch: {
      loading(newValue, oldValue) {
          this.first++;
      }
  },
}
</script>
<style lang="scss">
    .ve_rings{
        .lempty{
            z-index: 10;
            background: #fff;
        }
    }
</style>
