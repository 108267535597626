const Analysis = () => import('@/views/analysis')
const Layout = () => import('@/views/layout')
const biCrm = () => import('@/views/bi/crm')
const biCrmTotal = () => import('@/views/bi/crm/total')
const biCrmFollow = () => import('@/views/bi/crm/follow')
const biCrmTransfer = () => import('@/views/bi/crm/transfer')
const biCrmSea= () => import('@/views/bi/crm/sea')
const biCrmSign= () => import('@/views/bi/crm/sign')
const biCrmCustomerStock= () => import('@/views/bi/crm/customerStock')
const biEducation= () => import('@/views/bi/education')
const biEducationOpen= () => import('@/views/bi/education/open')
const biEducationTeaching= () => import('@/views/bi/education/teaching')
const biEducationAudition= () => import('@/views/bi/education/audition')
const biEducationSummary= () => import('@/views/bi/education/summary')
const biEducationMessage= () => import('@/views/bi/education/message')
const biEducationToClass= () => import('@/views/bi/education/toClass')
const biEducationClassroom= () => import('@/views/bi/education/classroom')
const biMarket = () => import('@/views/bi/market')
const biMarketClue = () => import('@/views/bi/market/clue')
const biMarketClueStock = () => import('@/views/bi/market/clueStock')
const biMarketChannel = () => import('@/views/bi/market/channel')
const biMarketStock = () => import('@/views/bi/market/stock')
const biMarketChange = () => import('@/views/bi/market/change')
const biMarketMeeting = () => import('@/views/bi/market/meeting')
const biMarketContent = () => import('@/views/bi/market/content')
const biMarketSurvey = () => import('@/views/bi/market/survey')
const biStudents = () => import('@/views/bi/students')
const biStudentsStudent = () => import('@/views/bi/students/student')
const biStudentsIntegral = () => import('@/views/bi/students/integral')
const biStudentsCourse = () => import('@/views/bi/students/course')
const biStudentsCostFee = () => import('@/views/bi/students/costFee')
const biFinance = () => import('@/views/bi/finance')
const biFinanceTotal = () => import('@/views/bi/finance/total')
const biFinanceWallet = () => import('@/views/bi/finance/wallet')
const biFinanceReturn = () => import('@/views/bi/finance/return')
const biFinanceContract = () => import('@/views/bi/finance/contract')
const biFinanceDebt = () => import('@/views/bi/finance/debt')
const biFinanceCommision = () => import('@/views/bi/finance/commision')
const biFinanceRevenue= () => import('@/views/bi/finance/revenue')
const biFinanceForecast= () => import('@/views/bi/finance/forecast')
const biRanking = () => import('@/views/bi/ranking')
const biRankingMoney = () => import('@/views/bi/ranking/money')
const biRankingCourse = () => import('@/views/bi/ranking/course')
const biRankingInvite = () => import('@/views/bi/ranking/invite')
const biRankingClue = () => import('@/views/bi/ranking/clue')
const biRankingCustomer = () => import('@/views/bi/ranking/customer')
const biRankingFollow = () => import('@/views/bi/ranking/follow')
const biRankingTeacher = () => import('@/views/bi/ranking/teacher')
const CrmContract = () => import('@/views/bi/achievement')
const CrmContractGoal = () => import('@/views/bi/achievement/goal')
const CrmContractCampus = () => import('@/views/bi/achievement/contract')
const CrmContractEmployee = () => import('@/views/bi/achievement/contract/employee')
const CrmCourseCampus = () => import('@/views/bi/achievement/course/campus')
const CrmCourseEmployee = () => import('@/views/bi/achievement/course/employee')
const biWork = () => import('@/views/bi/work')
const biWorkJournal = () => import('@/views/bi/work/journal')
const biWorkTask = () => import('@/views/bi/work/task')
const biWorkApprove = () => import('@/views/bi/work/approve')

// 无用
// const CrmBusiness = () => import('@/views/bi/business')
// const CrmReceivables = () => import('@/views/bi/receivables')
const CrmAnalysis = () => import('@/views/crmAnalysis')

const analysisRouter = {
    path: '/bi',
    component: Layout,
    hide: true,
    parent:'analysisRouter',
    meta: {
      title: '商业智能',
      requiresAuth: true,
      type:'bi',
      icon: '#icon-OperationOutline',
      menusKey:6
    },
    children:[
          // {
          //   path: 'analysis',
          //   component: Analysis,
          //   hide: false,
          //   parent:'analysis',
          //   meta: { title: '数据中心', icon: '#icon-OperationOutline', parent: 'bi', requiresAuth: false}
          // }, 
          // {
          //   path: 'crmAnalysis',
          //   component: CrmAnalysis,
          //   hide: false,
          //   parent:'analysis',
          //   meta: { title: '招生分析', icon: '#icon-NarrateOutline', parent: 'bi', requiresAuth3: false}
          // },
          {
            path: 'market',
            component: biMarket,
            hide: false,
            parent:'analysis',
            meta: { title: '市场数据分析', icon: '#icon-NarrateOutline', parent: 'bi', requiresAuth: true, type:'market'},
            children:[
              {
                path: 'clue',
                component: biMarketClue,
                meta: { title: '线索总量分析'},
              },
              {
                path: 'clue-stock',
                component: biMarketClueStock,
                meta: { title: '线索存量分析'},
              },
              {
                path: 'channel',
                component: biMarketChannel,
                // component: biCrmSign,
                meta: { title: '市场渠道分析'},
              },              
              {
                path: "follow",
                component: biCrmFollow,
                meta: { title: '线索跟进分析',name:'1'},
              },
              {
                path: 'change',
                component: biMarketChange,
                meta: { title: '线索有效转化'},
              },
              {
                path: 'transfer',
                component: biCrmTransfer,
                meta: { title: '转移记录统计'},
              },
              {
                path: 'stock',
                component: biMarketStock,
                meta: { title: '库存进出统计'},
              },
              {
                path: 'meeting',
                component: biMarketMeeting,
                meta: { title: '活动数据统计'},
              },
              {
                path: 'content',
                component: biMarketContent,
                meta: { title: '专栏数据统计'},
              },
              {
                path: 'survey',
                component: biMarketSurvey,
                meta: { title: '表单数据统计'},
              },
            ]
          },
          {
            path: 'crm',
            component: biCrm,
            hide: false,
            parent:'analysis',
            meta: { title: '招生客户分析', icon: '#icon-RetrievalOutline', parent: 'bi', requiresAuth: true, type:'crm'},
            children:[
              {
                path: 'total',
                component: biCrmTotal,
                meta: { title: '客户总量分析'},
              },
              {
                path: 'stock',
                component: biCrmCustomerStock,
                meta: { title: '客户存量分析'},
              },
              {
                path: 'follow',
                component: biCrmFollow,
                meta: { title: '客户跟进分析',name:'2'},
              },
              {
                path: 'sea',
                component: biCrmSea,
                meta: { title: '客户公海分析'},
              },
              {
                path: 'invite',
                component: biRankingInvite,
                meta: { title: '邀约到访分析'},
              },
              {
                path: 'sign',
                component: biCrmSign,
                meta: { title: '签约周期分析'},
              },
              // {
              //   path: 'channel',
              //   component: biCrmSign,
              //   meta: { title: '签约周期分析'},
              // }
            ]
          },
          {
            path: 'work',
            component: biWork,
            hide: false,
            parent:'analysis',
            meta: { title: '办公数据分析', icon: '#icon-BadgeOutline', parent: 'bi', requiresAuth: true, type:'work'},
            children:[
              {
                path: 'journal',
                component: biWorkJournal,
                meta: { title: '日报统计'},
              },
              {
                path: 'task',
                component: biWorkTask,
                meta: { title: 'OKR统计'},
              },
              {
                path: 'approve',
                component: biWorkApprove,
                meta: { title: '审批统计'},
              }
            ]
          },
          {
            path: 'education',
            component: biEducation,
            hide: false,
            parent:'analysis',
            meta: { title: '教务数据分析', icon: '#icon-IndentOutline', parent: 'bi', requiresAuth: true, type:'education'},
            children:[
              // {
              //   path: 'attend',
              //   component: biStudentsCourse,
              //   meta: { title: '到课统计'},
              // },
              {
                path: 'teaching',
                component: biEducationTeaching,
                meta: { title: '排课统计'},
              },
              {
                path: 'audition',
                component: biEducationAudition,
                meta: { title: '试听统计'},
              },
              {
                path: 'summary',
                component: biEducationSummary,
                meta: { title: '备课统计'},
              },
              {
                path: 'message',
                component: biEducationMessage,
                meta: { title: '反馈统计'},
              },
              {
                path: 'course',
                component: biStudentsCourse,
                meta: { title: '课消统计'},
              },
              // {
              //   path: 'open',
              //   component: biEducationOpen,
              //   meta: { title: '开班统计'},
              // },
              {
                path: 'toClass',
                component: biEducationToClass,
                meta: { title: '请假统计'},
              },
              {
                path: 'classroom',
                component: biEducationClassroom,
                meta: { title: '教室统计'},
              }
            ]
          },
          {
            path: 'students',
            component: biStudents,
            hide: false,
            parent:'analysis',
            meta: { title: '在籍学员分析', icon: '#icon-ScanOutline', parent: 'bi', requiresAuth: true, type:'students'},
            children:[
              {
                path: 'student',
                component: biStudentsStudent,
                meta: { title: '学员分析'},
              },
              {
                path: 'integral',
                component: biStudentsIntegral,
                meta: { title: '积分分析'},
              },
              {
                path: 'costFee',
                component: biStudentsCostFee,
                meta: { title: '优惠券统计'},
              },
            ]
          },
          {
            path: 'finance',
            component: biFinance,
            hide: false,
            parent:'analysis',
            meta: { title: '财务数据分析', icon: '#icon-MoneyOutline', parent: 'bi', requiresAuth: true, type:'finance'},
            children:[
              {
                path: 'contract',
                component: biFinanceContract,
                meta: { title: '业绩统计'},
              },
              {
                path: 'revenue',
                component: biFinanceRevenue,
                meta: { title: '课消统计'},
              },
              {
                path: 'debt',
                component: biFinanceDebt,
                meta: { title: '债务统计'},
              },
              {
                path: 'commision',
                component: biFinanceCommision,
                meta: { title: '绩效统计'},
              },
              {
                path: 'forecast',
                component: biFinanceForecast,
                meta: { title: '大盘晴雨'},
              },
              {
                path: 'wallet',
                component: biFinanceWallet,
                meta: { title: '电子钱包'},
              },
              {
                path: 'total',
                component: biFinanceTotal,
                meta: { title: '总量统计'},
              },
              {
                path: 'return',
                component: biFinanceReturn,
                meta: { title: '退费统计'},
              }
            ]
          },
          {
            path: 'achievement',
            component: CrmContract,
            hide: false,
            parent:'analysis',
            meta: { title: '业绩目标分析', icon: '#icon-ArcheryOutline', parent: 'bi', requiresAuth: true, type:'achievement'},
            children:[
              // {
              //   path: 'goal',
              //   component: CrmContractGoal,
              //   meta: { title: '(旧)业绩目标分析'},
              // },
              {
                path: 'contractGoal',
                component: CrmContractCampus,
                meta: { title: '业绩目标分析'},
              },
              {
                path: 'revenueGoal',
                component: CrmCourseCampus,
                meta: { title: '课消目标分析'},
              }
            ]
          },
          {
            path: 'ranking',
            component: biRanking,
            hide: false,
            parent:'analysis',
            meta: { title: '各数据排行榜', icon: '#icon-MedalOutline', parent: 'bi', requiresAuth: true, type:'ranking'},
            children:[
              {
                path: 'money',
                component: biRankingMoney,
                meta: { title: '签约金额排行'},
              },
              // {
              //   path: 'course',
              //   component: biRankingCourse,
              //   meta: { title: '签约课程排行'},
              // },
              {
                path: 'invite',
                component: biRankingInvite,
                meta: { title: '邀约到访排行'},
              },
              // {
              //   path: 'clue',
              //   component: biRankingClue,
              //   meta: { title: '新增线索排行'},
              // },
              // {
              //   path: 'customer',
              //   component: biRankingCustomer,
              //   meta: { title: '新增客户排行'},
              // },
              {
                path: 'follow',
                component: biRankingFollow,
                meta: { title: '线索/客户跟进排行'},
              },
              {
                path: 'teacher',
                component: biRankingTeacher,
                meta: { title: '课消金额排行'},
              }
            ]
          },
          {
            path: 'comparing',
            component: biRanking,
            hide: true,
            parent:'analysis',
            meta: { title: '数据比拼', icon: '#icon-AdjustOutline', parent: 'bi', requiresAuth: true, type:'ranking'},
            children:[
              {
                path: 'money',
                component: biRankingMoney,
                meta: { title: '签约金额排行'},
              },
              // {
              //   path: 'course',
              //   component: biRankingCourse,
              //   meta: { title: '签约课程排行'},
              // },
              {
                path: 'invite',
                component: biRankingInvite,
                meta: { title: '邀约到访排行'},
              },
              // {
              //   path: 'clue',
              //   component: biRankingClue,
              //   meta: { title: '新增线索排行'},
              // },
              // {
              //   path: 'customer',
              //   component: biRankingCustomer,
              //   meta: { title: '新增客户排行'},
              // },
              {
                path: 'follow',
                component: biRankingFollow,
                meta: { title: '线索/客户跟进排行'},
              },
              {
                path: 'teacher',
                component: biRankingTeacher,
                meta: { title: '课消金额排行'},
              }
            ]
          }
    ]
}

export default analysisRouter