<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="primary" icon="plus" @click="showEditModal()">新增排课</a-button>
          </a-form-item>
          <!-- <a-form-item>
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item> -->
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline'>
          <a-form-item>
              <a-tooltip placement="topLeft" >
              <template slot="title">
                  <span>时间筛选</span>
              </template>
              <a-range-picker
                  style="width: 240px"
                  :ranges="rangesData"
                  :value='rangesValue'
                  allowClear
                  @change="(date, dateString)=>onChange(date, dateString,2)" 
                  />
              </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-button @click="searchList" type="primary">筛选</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="course_class_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300 }" @change="handleChange">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="classDate" slot-scope="text, record">
          <a @click="showStudentModal(record)">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】') }} {{record.class_time}}</a>
        </template>
        <template slot="tableCount" slot-scope="text, record">
          <a @click="showStudentModal(record)">{{ record.reserve_count }}</a>
        </template>

        <template slot="is_summary" slot-scope="text, record">
            <svg @click="showContentModal(record)" style="width:20px;height: 20px;vertical-align: middle;cursor: pointer;" aria-hidden="true">
                <use  v-show="record.is_summary == true" xlink:href="#icon-beike-active"></use>
                <use v-show="record.is_summary == false" xlink:href="#icon-beike"></use>
            </svg>
        </template>

        <template slot="signin_count" slot-scope="text, record">
          <a @click="showStudentModal(record)">{{ record.signin_count }}</a>
        </template>

        <template slot="cancel_count" slot-scope="text, record">
          <a @click="showStudentModal(record)">{{ record.cancel_count }}</a>
        </template>

        <template slot="class_status" slot-scope="text, record">
          <a-tag color="green" v-if="text=='已上课'">
              {{ text }}
          </a-tag>
          <a-tag color="orange" v-if="text=='待上课'">
              {{ text }}
          </a-tag>
        </template>

        <template slot="khzy" slot-scope="text, record">
          <a>去布置</a>
        </template>
        <template slot="khfk" slot-scope="text, record">
          <a>去反馈</a>
        </template>
        <template slot="action" slot-scope="text,record">
          
           <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">

              <a-menu-item key="1" @click="showStudentModal(record)">
                上课学员
              </a-menu-item>

              <a-menu-item key="2" @click="showAddTempModal(record)">
                插班上课
              </a-menu-item>

              <a-menu-item key="3" @click="deleteCourse(record)">
                取消排课
              </a-menu-item>

              <a-menu-item key="4" @click="showSubModal(record)">
                代课老师
              </a-menu-item>

              <a-menu-item @click="cleanCourse(record)" key="5">
                撤销签到
              </a-menu-item>

              
            </a-menu>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    <editModal v-if="visible" :item="modalData" :studio_id="studio_id" :classId="id"/>
    <a-modal :title="`取消课程 (${curItem.class_date})`" v-model="cancelVisible" @ok="hideCancelModal" okText="确认" cancelText="取消">
      <a-textarea placeholder="请输入取消原因" v-model="reason" :rows="4"/>
      <a-checkbox :checked="send_notice" @change="handleCheck">微信公众号发送取消上课信息。</a-checkbox>
    </a-modal>

    <studentModal v-if="studentVisible" :item="modalData"/>

    <substituteModal v-if="substituteVisible" :item="modalData" :classId="id"/>

    <addTempModal v-if="addTempVisible" :item="modalData" :classId="id"/>
    <contentModal v-if="isContentModal" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '', width: '35px', dataIndex: 'is_summary', key: 'is_summary',scopedSlots: { customRender: 'is_summary' }, fixed: 'left'},
  { title: '上课时间', width:'240px', dataIndex: 'class_date', key: '1', scopedSlots: { customRender: 'classDate' } , fixed: 'left' ,sorter:()=>{}},
  { title: '状态', dataIndex: 'class_status', key: '9' ,scopedSlots: { customRender: 'class_status' } ,align:"center",sorter:()=>{}},
  { title: '课程名称', dataIndex: 'course_id',ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_id', key: '2' ,align:"center",ellipsis:true},
  { title: '助教老师', dataIndex: 'teacher_assistant', key: 'teacher_assistant' ,ellipsis:true},
  { title: '上课教室', dataIndex: 'room_id', key: 'room_id',ellipsis:true},
  { title: '排课', dataIndex: 'reserve_count', key: '4', scopedSlots: { customRender: 'tableCount' } ,align:"center",sorter:()=>{}},
  { title: '到课', dataIndex: 'signin_count', key: '5', scopedSlots: { customRender: 'signin_count' } ,align:"center",sorter:()=>{}},
  { title: '请假', dataIndex: 'cancel_count', key: 'cancel_count', scopedSlots: { customRender: 'cancel_count' } ,align:"center",sorter:()=>{}},
  { title: '单课消耗', dataIndex: 'class_costs', key: '6' , align:"center"},
  { title: '全员课消', dataIndex: 'total_class_costs', key: 'total_class_costs' ,align:"center"},
  { title: '排课人员', dataIndex: 'created_by', key: 'created_by' ,align:"center"},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]

import tableMixins from '@/common/mixins/table'
import editModal from './editModal'
import studentModal from './studentModal'
import substituteModal from './substituteModal'
import addTempModal from './addTempModal'
import cItems from '@/utils/items'
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import contentModal from '@/views/education/mySchedule/listMode/contentModal'

export default {
  name: 'bjgl',
  mixins: [ tableMixins , ranges ],
  props: {
    id: Number || String,
    toRefresh:Number,
    isTab:Number,
    studio_id: Number || String,
  },
  components: {
    editModal,
    studentModal,
    substituteModal,
    addTempModal,
    contentModal,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      columns,
      cancelVisible: false,
      send_notice: true,
      reason: '',
      curItem: {},

      modalData: {},
      studentVisible: false,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      paramsData:{
        start_date:'',
        end_date:'',
      },
      substituteVisible: false,
      addTempVisible: false,
      isContentModal: false,
    }
  },

  created() {
  },

  methods: {
    moment,
    async getList() {
      this.loading = true
      this.searchParams.class_id = this.id
      this.searchParams.search.class_status = this.isTab
      let res = await this.$store.dispatch('classesScheduleAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    fixedDate(str) {
      return str.replace('<br>', ' ')
    },
    deleteCourse(item) {
      this.cancelVisible = true
      this.curItem = item
    },
    async hideCancelModal() {
      let res = await this.$store.dispatch('classesCancelCourseAction', 
      { course_class_id: this.curItem.course_class_id, reason: this.reason, send_notice:this.send_notice?1:0 })
      if(res) {
        this.cancelVisible = false
        this.send_notice = false
        this.getList()
      }
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList();
    },
    showStudentModal(item) {
      this.modalData = item
      this.studentVisible = true
    },
    hideStudentModal(val) {
      this.studentVisible = false
      // this.getList()
    },
    
    showSubModal(item) {
      this.modalData = item
      this.substituteVisible = true
    },
    hideSubModal(val) {
      this.substituteVisible = false
      this.getList()
    },
    hideModal(){
      this.visible = false
    },
    showAddTempModal(item) {
      this.modalData = item
      this.addTempVisible = true
    },
    hideAddTempModal(val) {
      this.addTempVisible = false
      if(val){
        this.getList()
      }
    },
    showContentModal(item){
      this.modalData = item
      this.isContentModal = true
    },
    hideContentModal(val){
      if(val){
        this.getList()
      }
      this.isContentModal = false
    },
    cleanCourse(item){
      let that = this
      let selection = [item.course_class_id]
      this.$confirm({
        title: `您确定撤销签到？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            await that.$store.dispatch('courseScheduleCleanCheckinAction', { selection } )
            .then(res=>{
              that.$message.success('操作成功！！')
              that.selectedRowKeys = []
              that.getList()
              resolve(res)
            })
            .catch(err=>{
              resolve(err)
            })
          }).catch(error => console.log(error))
        }
      })
    },
    handleCheck(){
      this.send_notice = !this.send_notice
    }
  },
  watch: {
      toRefresh() {
          this.getList()
      }
  },
}
</script>
