const Layout = () => import('@/views/layout')
const Market = () => import('@/views/market')
const MarketXuetang = () => import('@/views/market/xuetang')
const MarketCourseFee = () => import('@/views/market/courseFee')
const MarketCostsAlert = () => import('@/views/market/costsAlert')
const MarketWeipages = () => import('@/views/market/weipages')
const MarketConference = () => import('@/views/market/conference')
const MarketMeeting = () => import('@/views/market/meeting')
const MarketMeetingDetail = () => import('@/views/market/meeting/detail')
const MarketSurvey = () => import('@/views/market/survey')
const MarketTool = () => import('@/views/market/tool')
const MarketRent = () => import('@/views/market/rent')
const MarketSop = () => import('@/views/market/sop')
const MarketSopEdit = () => import('@/views/market/sop/list/edit')
const MarketToolEditModal = () => import('@/views/market/tool/editModal')
const MarketPool = () => import('@/views/market/pool')
const MarketStore = () => import('@/views/market/store')
const MarketQrcodepayment = () => import('@/views/market/qrcodepayment')
const MarketContent = () => import('@/views/market/content')
const MarketWeixin = () => import('@/views/market/weixin')
const MarketSurveyEdit = () => import('@/views/market/surveyEdit')
const MarketSurveyDetail = () => import('@/views/market/surveyDetail')
const MarketHuidongApply = () => import('@/views/market/huidongApply')
const MarketHuidongPrint = () => import('@/views/market/huidongPrint')
const MarketHuidongPrintDetail = () => import('@/views/market/huidongPrint/detail')

const marketRouter = {
    path: '/market',
    component: Layout,
    hide: true,
    parent:'marketRouter',
    meta: {
      title: '市场应用',
      requiresAuth: false,
      type:'market',
      icon: '#icon-CollectOutline',
      menusKey:4
    },
    children:[
          {
            path: 'index',
            component: Market,
            hide: false,
            meta: { title: '市场应用', icon: '#icon-CollectOutline' },
          }, {
            path: 'rent',
            component: MarketRent,
            hide: true,
            meta: { title: '租赁备案' }
          },, {
            path: 'xuetang',
            component: MarketXuetang,
            hide: true,
            meta: { title: '学习课代表' }
          }, {
            path: 'course_fee',
            component: MarketCourseFee,
            hide: true,
            meta: { title: '优惠券' }
          }, {
            path: 'costs_alert',
            component: MarketCostsAlert,
            hide: true,
            meta: { title: '续费预警' }
          }, {
            path: 'weipages',
            component: MarketWeipages,
            hide: true,
            meta: { title: '在线报班' }
          }, {
            path: 'conference',
            component: MarketConference,
            hide: true,
            meta: { title: '会议小助手' }
          }, {
            path: 'meeting',
            component: MarketMeeting,
            hide: true,
            meta: { title: '活动管理' }
          }, {
            path: 'meeting/detail',
            component: MarketMeetingDetail,
            hide: true,
            meta: { title: '活动详情' }
          }, {
            path: 'survey',
            component: MarketSurvey,
            hide: true,
            meta: { title: '表单收集' }
          }, {
            path: 'tool',
            component: MarketTool,
            hide: true,
            meta: { title: '小工具' }
          }, {
            path: 'sop',
            component: MarketSop,
            hide: true,
            meta: { title: 'SOP' }
          }, {
            path: 'sop/edit',
            component: MarketSopEdit,
            hide: true,
            meta: { title: 'SOP' }
          }, {
            path: 'tool/create',
            component: MarketToolEditModal,
            hide: true,
            meta: { title: '创建活码' }
          }, {
            path: 'pool',
            component: MarketPool,
            hide: true,
            meta: { title: 'Pool' }
          }, {
            path: 'store',
            component: MarketStore,
            hide: true,
            meta: { title: '微信小店' }
          }, {
            path: 'qrcodepayment',
            component: MarketQrcodepayment,
            hide: true,
            meta: { title: '收款二维码' }
          }, {
            path: 'content',
            component: MarketContent,
            hide: true,
            meta: { title: '课程专栏' }
          }, 
          {
            path: 'weixin',
            component: MarketWeixin,
            hide: true,
            meta: { title: '微信管理' }
          }, {
            path: 'surveyEdit/:id/:isEdit',
            component: MarketSurveyEdit,
            hide: true,
            meta: { title: '表单编辑' }
          }, {
            path: 'surveyDetail/:id',
            component: MarketSurveyDetail,
            hide: true,
            meta: { title: '表单数据' }
          }, {
            path: 'huidong_apply',
            component: MarketHuidongApply,
            hide: true,
            meta: { title: '昂立慧动' }
          }, {
            path: 'huidong_print',
            component: MarketHuidongPrint,
            hide: true,
            meta: { title: '云打印' }
          }, {
            path: 'huidong_print/detail',
            component: MarketHuidongPrintDetail,
            hide: true,
            meta: { title: '云打印详情' }
          }
    ]
}

export default marketRouter