<template>
    <div class="common zlgx">
    <div class="c-main">
      <div class="clearfix table-tools">
        <div class="buttons">
            <a-form layout='inline'>
                <a-form-item>
                    <a-button v-if='!imgCheckable' icon="setting" @click="toDeleteImages">批量操作</a-button>
                    <a-button v-else icon="delete" @click="cancelDeleteImages">取消</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="search"></div>
      </div>
      <div class="zl-list">
        <div v-if="currentCategory.album_name" class="title">
          <h3>{{currentCategory.album_name}}</h3>
        </div>
        <div 
            class="image-wrap" 
            style="padding: 20px 10px;height:calc(100vh - 230px);overflow-y:auto;"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10">
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <a-empty v-if="list.length == 0" />
          <div v-if='imgCheckable'>
            <span style='margin-right:5px'>选中{{selectedKeys.length}}项</span>
            <a-button @click='deleteImages(1)' size='small'>确认显示</a-button>
            <a-button style="margin-left:10px" @click='deleteImages(2)' size='small'>确认不显示</a-button>
            <a-button style="margin-left:10px" @click='deleteImages(0)' size='small'>确认删除</a-button>
          </div>
          <!-- <a-tree @check="oncheck" @select="onSelect" v-model="selectedKeysF" :checkable="imgCheckable" defaultExpandAll v-if="list.length>0"> -->
          <a-tree @check="oncheck" @select="onSelect" v-model="selectedKeysF" :checkable="imgCheckable" defaultExpandAll v-if="showTree">
              <a-tree-node v-for="item in list" :key="item.created_at" :title="item.created_at">
                <a-tree-node v-for="(it,index) in item.origin" :key="it.image_id">
                    <div slot="title" style="position: relative;">
                        <LImg style="height:150px;margin:5px" :preview="!imgCheckable" :key="index" :src='it.image_thumb'/>
                        <div style="position: absolute;top:10px;right:10px">
                            <a-icon v-if="it.image_status == 1" type="eye" theme="twoTone" two-tone-color="#00cca2" />
                            <a-icon v-else type="eye-invisible" theme="twoTone" two-tone-color="#00cca2" />
                        </div>
                    </div>
                </a-tree-node>
              </a-tree-node>
          </a-tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import moment from 'moment'
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'album',
        directives: { infiniteScroll },
        provide() {
            return {
                parent: this
            }
        },
        props: {
            id: Number || String,
        },
        data() {
            return {
                busy: false,
                loading: false,
                imgCheckable: false,
                list: [],
                albumList: [],
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                current: 1,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 0,
                    "per-page": 50,
                    "search": {},
                    "sort": ''
                },
                currentCategory: {},
                selectedKeys:[],
                selectedKeysF:[],
                uploadIndex:0,
                uploadIndexF:0,
                fileList:[],
                showTree:false,
            }
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                if(this.searchParams.page ==1 ){
                    this.loading = true
                }
                this.showTree = false
                this.searchParams.class_id = this.id
                let res = await this.$store.dispatch('classesClassesAlbumAction', this.searchParams)
                if(this.albumList.length >= res._meta.totalCount){
                    this.busy = true
                }
                this.albumList = [...this.albumList,...res.items]
                let list = await this.albumArray(this.albumList)
                this.list = list
                this.loading = false
                this.showTree = true
            },
            async deleteImages(val) {
                if(this.selectedKeys.length == 0){
                    this.$message.warning('请选择!!')
                    return false
                }
                await this.$store.dispatch('classesAlbumImagesShowAction', {image_id:this.selectedKeys,image_status:val})
                    .then(res=>{
                        this.selectedKeys = []
                        this.selectedKeysF = []
                        this.searchParams.page = 1
                        this.albumList = []
                        this.imgCheckable = false
                        this.getList()
                        this.$message.success('操作成功~')
                    })
                    .catch(err=>{
                        this.$message.error('操作失败~')
                    })
            },
            toDeleteImages(){
                this.imgCheckable = true
            },
            cancelDeleteImages(){
                this.selectedKeys = []
                this.selectedKeysF = []
                this.imgCheckable = false
            },
            albumArray(beforeData){
                let tempArr = []
                let afterData = []
                for (let i = 0; i < beforeData.length; i++) {
                    if (tempArr.indexOf(beforeData[i].created_at) === -1) {
                        afterData.push({
                        created_at: beforeData[i].created_at,
                        origin: [beforeData[i]]
                        })
                        tempArr.push(beforeData[i].created_at);
                    } else {
                        for (let j = 0; j < afterData.length; j++) {
                        if (afterData[j].created_at == beforeData[i].created_at) {
                            afterData[j].origin.push(beforeData[i]);
                            break;
                        }
                        }
                    }
                }
                let afterDataSort = this.sortKey(afterData,'created_at')
                return afterDataSort
            },
            oncheck(val){
                this.selectedKeysF = val
                let selectedKeys = []
                val.forEach(item=>{
                    if(typeof(item) == 'number'){
                       selectedKeys.push(item)
                    }
                })
                this.selectedKeys = selectedKeys
            },
            onSelect(val,a){
                let parentEventKey = a.node.$parent.eventKey
                let select = a.node.eventKey
                if(this.imgCheckable){
                    this.handleOnSelect(select,parentEventKey)
                }
            },
            handleInfiniteOnLoad() {
                if(this.searchParams.page !=0){
                    if(!this.busy){
                        this.searchParams.page++
                        this.getList()
                    }
                }else{
                    this.searchParams.page++
                }
            },
            handleOnSelect(select,parentEventKey){
                let selectedKeysF = [...this.selectedKeysF]
                if(typeof(select) == 'number'){
                    if(selectedKeysF.indexOf(select) === -1){
                        this.selectedKeysF.push(select)
                        let selectArr = []
                        this.list.forEach(item=>{
                            if(item.created_at === parentEventKey){
                                item.origin.forEach(it=>{
                                    selectArr.push(it.image_id)
                                })
                            }
                        })
                        let selectedKeysF = [...this.selectedKeysF]
                        let SamePart=selectedKeysF.filter(item=>selectArr.includes(item))
                        if(SamePart.length == selectArr.length){
                            this.selectedKeysF.push(parentEventKey)
                        }
                    }else{
                        this.selectedKeysF.splice(selectedKeysF.indexOf(select),1)
                        if(selectedKeysF.indexOf(parentEventKey) != -1){
                            this.selectedKeysF.splice(this.selectedKeysF.indexOf(parentEventKey),1)
                        }
                    }
                }else{
                    let selectArr = []
                    this.list.forEach(item=>{
                        if(item.created_at === select){
                            selectArr.push(select)
                            item.origin.forEach(it=>{
                                selectArr.push(it.image_id)
                            })
                        }
                    })
                    if(selectedKeysF.indexOf(select) === -1){
                        this.selectedKeysF = [...this.selectedKeysF,...selectArr]
                    }else{
                        //并集
                        let RemoveSame=[...new Set([...selectArr,...selectedKeysF])]
                        // 交集
                        let SamePart=selectedKeysF.filter(item=>selectArr.includes(item))
                        // 差集
                        let Difference=RemoveSame.filter(item=>!SamePart.includes(item))
                        this.selectedKeysF = Difference
                    }
                }
                this.oncheck(this.selectedKeysF)
            },
            sortKey(array, key) {
                return array.sort(function(a, b) {
                    var x = a[key];
                    var y = b[key];
                    return x > y ? -1 : x < y ? 1 : 0;
                })
            },
        },
    }
</script>

<style lang="scss">
  .vue-select-image__item{
    margin-left:0px !important;
  }
  .vue-select-image__thumbnail{
    label{
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        display:inline-block;
        text-align:center;
        width:100px;
        margin-top:4px;
    }
  }
  .image-wrap .ant-tree-switcher{
      display:none !important;
  }
  .image-wrap ul li ul{
      display:flex;
      flex-wrap:wrap;
  }
  .image-wrap ul li ul li{
      height:150px;
      margin:5px;
  }
  .image-wrap ul li ul li .ant-tree-node-content-wrapper{
      height:160px;
  }
</style>