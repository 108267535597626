<template>
  <a-modal v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认排课" width="750px"
    @ok="handleOk" @cancel="handleCancel">
    <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">循环排课</span>
          <a-form v-if="activeKey === '1'" :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index" 
              :label="item.label">
              <a-radio-group name="radioGroup" v-if="item.type === 'radios'" :value="course_type">
                  <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
              </a-radio-group>

              <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
              <a-select v-else-if="item.type === 'select'" style='width:300px' placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]" allowClear showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>

              <a-select allowClear mode="multiple" v-else-if="item.type === 'select-multi'" style='width:300px' placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>
              <a-radio-group name="radioGroup"  v-else-if="item.type === 'room_radio'" v-decorator="[item.name, { rules: item.rules }]">
                <a-radio v-for='it in seleteItems.rooms' :key="it.room_id" :value="it.room_id">
                  {{it.filter_name}}
                </a-radio>
              </a-radio-group>
              <a-radio-group name="radioGroup" v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
                  <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
              </a-radio-group>
              <a-date-picker style='width:300px' @change="e=>handleGenerateDate(e,'start_date')" :allowClear="false" v-else-if="item.type === 'time'" format="YYYY-MM-DD"  v-decorator="[item.name, { rules: item.rules }]" />
              <a-button @click="showTeacher" v-if="item.name == 'teacher_id'" style="margin-left:15px" icon="schedule">查看老师空闲时间</a-button>
              <a-button @click="showRoom" v-if="item.name == 'room_id'" style="margin-left:15px" icon="schedule">查看教室空闲时间</a-button>
              <a-checkbox style="margin-left:15px" v-if="item.name == 'start_date'" @change="handleCheckbox1">跳过节假日</a-checkbox>
              <a-checkbox style="margin-left:15px" v-if="item.name == 'start_date'" @change="handleCheckbox2">隔周排课</a-checkbox>
            </a-form-item>
            <!-- <a-form-item>
              <div class="simple-example">
                <vue-meeting-selector
                  class="simple-example__meeting-selector"
                  :date="date"
                  v-model="meeting"
                  :loading="loading"
                  :class-names="classNames"
                  calendarOptions='calendarOptions'
                  :meetings-days="meetingsDays"
                  @next-date="nextDate"
                  @previous-date="previousDate">
                  <template
                    #header="{ meetings }">
                    <div>
                      <div>{{ moment(meetings.date).format('dddd')}}</div>
                      <div>{{ moment(meetings.date).format('MM-DD')}}</div>
                    </div>
                  </template>
                </vue-meeting-selector>
              </div>
            </a-form-item> -->

            <a-form-item label="排课周期" >
              <a-input-number :min="1"  style='width:300px' @change="e=>handleGenerateDate(e,'week_count')" v-decorator="['week_count', {rules:[{ required: true, message: '请输入排课数周期!' }]}]"/> 周
              <!-- <a-input-number :min="1"  v-decorator="['course_count', {rules:[{ required: true, message: '请输入排课数量!' }]}]"/> 课 -->
            </a-form-item>

            <a-form-item label="自助预约" >
              <a-checkbox @change="handleCheckbox3">可预约</a-checkbox>

              <!-- <a-checkbox @change="handleCheckbox4" checked="true">学员预排课</a-checkbox> -->
              <a-checkbox @change="handleCheckbox4" :checked="pre_arrange">学员预排课</a-checkbox>
            </a-form-item> 

            <a-form-item label="排课规则" >
              <div v-for="(it,index) in week_schedule" :key='index' class='addSelf'>
                <a-form-item label="" >
                  <a-checkbox-group
                    @change="handleGenerateDate"
                    v-model="it.week"
                    name="checkboxgroup"
                    :options="plainOptions"
                  />
                </a-form-item>
                <a-form-item label="上课时间" >
                  <span style='margin-right:5px' v-for='(d,ind) in it.time' :key='ind'>
                    <a-time-picker v-model="d.week_time" :allowClear="false" format="HH:mm" @change="handleGenerateDate">
                      <a-icon v-if='it.time.length > 1' @click.stop='delTime(ind,index)' slot="suffixIcon" type="minus-circle" />
                    </a-time-picker>
                  </span>
                  <span @click='addTime(index)'><a-icon type="plus-circle" theme="twoTone" style='fontSize:18px' two-tone-color="#00cca2"/></span>
                </a-form-item>
                <span v-if='week_schedule.length>1' @click='todel(index)' class='addSelf-del'><a-icon style='fontSize:18px' type="close-circle" /></span>
              </div>
            </a-form-item>
            <a-form-item label="" >
              <span @click='toAdd' style='cursor:pointer'>
                <a-icon  type="plus-circle" theme="twoTone" two-tone-color="#00cca2"/>
                <span style='color:#00cca2;margin-left:5px'>添加排课规则</span>
              </span>
            </a-form-item>
            <a-form-item label="" >
              <a-tag v-for="(item,index) in tagList" :key="item" closable @close="tocloseTag(item)">
                {{item}}
              </a-tag>
            </a-form-item>
          </a-form>          
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">单次排课</span>
          <a-form v-if="activeKey === '2'" :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
              <a-radio-group name="radioGroup" v-if="item.type === 'radios'" :value="course_type">
                  <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
              </a-radio-group>

              <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
              <a-select v-else-if="item.type === 'select'" style='width:300px' placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]" allowClear showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>

              <a-select allowClear mode="multiple" v-else-if="item.type === 'select-multi'" style='width:300px' placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>
              <a-radio-group name="radioGroup"  v-else-if="item.type === 'room_radio'" v-decorator="[item.name, { rules: item.rules }]">
                <a-radio v-for='it in seleteItems.rooms' :key="it.room_id" :value="it.room_id">
                  {{it.filter_name}}
                </a-radio>
              </a-radio-group>
              <a-radio-group name="radioGroup" v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
                  <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
              </a-radio-group>
              <a-date-picker style='width:300px' v-else-if="item.type === 'time'" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }" v-decorator="[item.name, { rules: item.rules }]" />
              <a-button @click="showTeacher" v-if="item.name == 'teacher_id'" style="margin-left:15px" icon="schedule">查看老师空闲时间</a-button>
              <a-button @click="showRoom" v-if="item.name == 'room_id'" style="margin-left:15px" icon="schedule">查看教室空闲时间</a-button>
            </a-form-item>
            <a-form-item label="自助预约" >
              <a-checkbox @change="handleCheckbox3">可预约</a-checkbox>

              <a-checkbox @change="handleCheckbox4" :checked="pre_arrange">学员预排课</a-checkbox>
            </a-form-item>  
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <availableRoom :studio_id="studio_id" v-if="isRoom" />
      <availableTeacher :studio_id="studio_id" v-if="isTeacher" />
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import moment from 'moment'
import VueMeetingSelector from 'vue-meeting-selector'
import slotsGenerator from '@/utils/slotsGenerator'
import availableTeacher from '@/views/classes/bjgl/detail/bjpk/availableTeacher'
import availableRoom from '@/views/classes/bjgl/detail/bjpk/availableRoom'

const formData = [
  {
    name: 'course_id',
    label: '课程名称',
    type: 'select',
    rules: [{ required: true, message: '请选择课程!' }],
    items: {
      data: 'courses',
      label: 'filter_name',
      value: 'course_id'
    }
  },
  {
    name: 'teacher_id',
    label: '授课老师',
    type: 'select',
    rules: [{ required: true, message: '请选择授课老师!' }],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'teacher_assistant_ids',
    label: '助教老师',
    type: 'select-multi',
    rules: [{ required: false, message: '请选择助教老师!' }],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'room_id',
    label: '上课教室',
    type: 'select',
    rules: [{ required: true, message: '请选择上课教室!' }],
    items: {
      data: 'rooms',
      label: 'filter_name',
      value: 'room_id'
    }
  },
  {
    name: 'start_date',
    label: '起始时间',
    type: 'time',
    rules: [{ required: true, message: '请选择起始时间!' }],
  },
]

const plainOptions = [
  { label: '星期一', value: '1' },
  { label: '星期二', value: '2' },
  { label: '星期三', value: '3' },
  { label: '星期四', value: '4' },
  { label: '星期五', value: '5' },
  { label: '星期六', value: '6' },
  { label: '星期日', value: '0' },
];

export default {
  name: 'EditModal',
  components: {
    availableRoom,
    availableTeacher
  },
  mixins: [ editModal ],
  inject: ['parent'],
  props:['classId','studio_id'],
  provide() {
    return {
        parent: this
    }
  },
  data() {
    return {
      formData,
      seleteItems: {
        courseTypes: C_ITEMS.courseTypes,
        classWeeklys: C_ITEMS.classWeeklys,
        courses: [],
        teachers: [],
        rooms: [],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      imageUrl: '',
      date: new Date(),
      meetingsDays: [],
      meeting: null,
      loading: true,
      nbDaysToDisplay: 7,
      week_schedule:[{
        week:[],
        time:[{week_time:moment('09:30', 'HH:mm')}],
      }],
      plainOptions,
      course_type:1,
      can_appointment:0,
      pre_arrange:true,
      activeKey:"1",
      isTeacher:false,
      isRoom:false,
      skip_holiday:0,
      gap_week:0,
      tagList:[]
    }
  },
  computed: {
    classNames() {
      return {
        tabLoading: 'loading-div',
      };
    },
  },
  async created() {
    let { item, authArr } = this
    this.visible = true
    this.getCourseType()
    this.getTeachers()
    this.getRoom(item)
    await this.$nextTick()
    if (item) {
      this.ModalTitle = '编辑排课'
      let res = await this.$store.dispatch('studentsDetailAction', { data: { student_id: item.student_id } })
      if (res) {
        item = res.data
        let formFields = {}
        for (let d of formData) {
          if (d.name === 'student_birthday') {
            formFields[d.name] = moment(item[d.name], 'YYYY-MM-DD')
          } else {
            formFields[d.name] = item[d.name]
          }
        }
        this.imageUrl = item['student_avatar']
        this.form.setFieldsValue(formFields)
      }
    } else {
      this.ModalTitle = '新增排课'
      this.form.setFieldsValue({week_count:1,start_date:moment()})
    }

    const start = {
      hours: 8,
      minutes: 0,
    };
    const end = {
      hours: 16,
      minutes: 0,
    };
    this.meetingsDays = await this.slotsGeneratorAsync(
      this.date,
      this.nbDaysToDisplay,
      start,
      end,
      30,
    );
    this.loading = false;


  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getCourseType() {
      let res = await this.$store.dispatch('searchCourseAction', {params:{class_id:this.classId}})
      this.seleteItems.courses = res.data
    },
    async getTeachers() {
      let res = await this.$store.dispatch('searchTeacherAction', {params:{class_id:this.classId}})
      this.seleteItems.teachers = res.data
    },
    async getRoom(item) {
      let res = await this.$store.dispatch('searchStudioRoomAction', {params:{class_id:this.classId}})
      this.seleteItems.rooms = res.data
      if(!item){
        if(res.data.length > 0){
          this.form.setFieldsValue({room_id:res.data[0].room_id})
        }
      }
      
    },
    async handleOk() {
      try {
        const { item, form,  meeting, week_schedule } = this
        let params = await form.validateFields()
        if(this.activeKey === '1'){
          params.start_date = moment(params.start_date).format('YYYY-MM-DD')
        }else{
          params.start_date = moment(params.start_date).format('YYYY-MM-DD HH:mm:ss')
        }
        params.class_id = this.parent.id
        params.can_appointment = this.can_appointment
        params.pre_arrange = this.pre_arrange?1:2

        if(this.activeKey === '1'){
          try{
            week_schedule.forEach(item=>{
              if(item.week.length == 0){
                this.$message.warning('请勾选上课日期!')
                throw ('请勾选上课日期!')
              }
              item.week_time = []
              item.time.forEach(it=>{
                if(it.week_time){
                  item.week_time.push(moment(it.week_time).format('HH:mm'))
                }else{
                  this.$message.warning('请填写上课时间!')
                  throw ('请填写上课时间!')
                }
              })
            })
          } catch {
            return false
          }
          if (params) {
            params.week_schedule = week_schedule
            params.final_schedule = this.tagList
            this.confirmLoading = true
            await this.$store.dispatch('classesScheduleAddAction', params)
              .then(res=>{
                form.resetFields()
                this.parent.submitModal('2')
              })
              .catch(err=>{
                this.confirmLoading=false;
              })
          }
        }else{
            this.confirmLoading=true
            await this.$store.dispatch('classesScheduleAddDateAction', params)
              .then(res=>{
                form.resetFields()
                this.parent.submitModal('2')
              })
              .catch(err=>{
                this.confirmLoading=false;
              })
        }
      } catch {

      }
    },
    handleCancel(){
      this.parent.hideModal()
    },
    toAdd(){
      let obj = {
        week:[],
        time:[{week_time:moment('09:30', 'HH:mm')}],
      }
      this.week_schedule.push(obj)
    },
    todel(index){
      this.week_schedule.splice(index,1)
      this.handleGenerateDate()
    },
    delTime(ind,index){
      this.week_schedule[index].time.splice(ind,1)
      this.handleGenerateDate()
    },
    addTime(index){
      this.week_schedule[index].time.push({week_time:moment('09:30', 'HH:mm')})
      this.handleGenerateDate()
    },
    slotsGeneratorAsync(...args) {
      return new Promise((resolve,reject) => {
        setTimeout(() => {
          resolve(slotsGenerator(...args));
        }, 1000);
      });
    },
    async nextDate() {
      this.loading = true;
      const start = {
        hours: 8,
        minutes: 0,
      };
      const end = {
        hours: 16,
        minutes: 0,
      };
      const date = new Date(this.date);
      const newDate = new Date(date.setDate(date.getDate() + 7));
      this.date = newDate;
      this.meetingsDays = await this.slotsGeneratorAsync(
        newDate,
        7,
        start,
        end,
        30,
      );
      this.loading = false;
    },
    async previousDate() {
      this.loading = true;
      const start = {
        hours: 8,
        minutes: 0,
      };
      const end = {
        hours: 16,
        minutes: 0,
      };
      const date = new Date(this.date);
      date.setDate(date.getDate() - 7);
      const formatingDate = (dateToFormat) => {
        const d = new Date(dateToFormat);
        const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
      };
      const newDate = formatingDate(new Date()) >= formatingDate(date)
        ? new Date()
        : new Date(date);
      this.date = newDate;
      this.meetingsDays = await this.slotsGeneratorAsync(
        newDate,
        this.nbDaysToDisplay,
        start,
        end,
        30,
      );
      this.loading = false;
    },
    async changeTab(key) {
        this.activeKey = key
        if(key == 1){
          await this.$nextTick()
          this.form.setFieldsValue({week_count:1,start_date:moment()})
        }
    },
    showTeacher(){
      this.isTeacher = true
    },
    hideTeacher(){
      this.isTeacher = false
    },
    showRoom(){
      this.isRoom = true
    },
    hideRoom(){
      this.isRoom = false
    },
    handleCheckbox1(e){
      this.skip_holiday = e.target.checked?1:0
      this.handleGenerateDate()
    },
    handleCheckbox2(e){
      this.gap_week = e.target.checked?1:0
      this.handleGenerateDate()
    },
    handleCheckbox3(e){
      this.can_appointment = e.target.checked?1:0
    },
    handleCheckbox4(e){
      this.pre_arrange = e.target.checked?true:false
    },
    async handleGenerateDate(){
      await this.$nextTick()
      let start_date = this.form.getFieldValue('start_date')
      let week_count = this.form.getFieldValue('week_count')
      let week_schedule = this.week_schedule.map(it=>{return it})
      for(let i=0; i < week_schedule.length; i++){
        week_schedule[i].week_time = []
        week_schedule[i].time.forEach(it=>{
          week_schedule[i].week_time.push(moment(it.week_time).format('HH:mm'))
        })
        if(week_schedule[i].week.length == 0){
          return false
        }
      }
      if(week_count){
        await this.$store.dispatch('courseScheduleGenerateDateAction',{week_count,week_schedule,start_date:moment(start_date).format('YYYY-MM-DD'),skip_holiday:this.skip_holiday,gap_week:this.gap_week})
        .then(res=>{
          this.tagList = res.data
          this.$forceUpdate()
        })
      }

    },
    tocloseTag(removedTag){
      const tags = this.tagList.filter(tag => tag !== removedTag)
      this.tagList = tags
      this.$forceUpdate()
    }

  },
}
</script>

<style lang="scss">
.slots-example {
  &__meeting-selector {
    max-width: 542px;
  }
}
.title {
  margin: 0 0px;
}
.meeting{
  .meeting__button {
    display: inline-block;
    padding: 5px;
    margin: 5px 0;
    background-color: #845EC2;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    &--selected {
      background-color: #B39CD0;
    }
    &--empty {
      display: inline-block;
      padding: 5px;
      margin: 5px 0;
      cursor: not-allowed;
    }
  }
}
.button-pagination {
  border: none;
  padding: 0;
  width: 30px;
}
// since our scss is scoped we need to use ::v-deep
::v-deep .loading-div {
  top: 32px!important;
}
.addSelf{
  border:1px solid #d9d9d9;
  border-radius:4px;
  margin-bottom:10px;
  position:relative;
  padding: 10px;
  .ant-form-item{
    margin-bottom:4px
  };
  .addSelf-del{
    position:absolute;
    right:-2px;
    top:-9px;
    display:flex;
    background-color:#fff;
    cursor:pointer;
  }
}
</style>