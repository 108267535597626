import Vue from 'vue'
import { achievement, studioAchievement, achievementUpdate, studioAchievementUpdate, settingCrmConfig, settingCrmConfigUpdate, achievementChannel, audition, auditionBook, auditionDemoSchedule, 
  auditionUpdate, auditionAdd, auditionPendding, auditionDetail, auditionCancel, auditionSign, auditionDelete, auditionCancelDemoClass, auditionDemoTime, auditionResend, cCustomerLost ,
  auditionAddDemo, auditionAddDemoSchedule, auditionDemoArrage, client, clientAssign, clientPendding, clientChangeLog, clientFollowLog, clientStar, clientAdd, clientUpdate, 
  clientDetail, clientFollow, clientNoneWill, clientToday, clientMultiSetting, clientMultiExport, clientMultiAction, clientDelete, cImport, crmBrief, cChangeLogExport,cFollowExport,
  cLeadsExample, cLeadsExport, cLeadsErrorFile, cImportUpload, cImportDelete, cFollow, cFollowDetail, cFollowDelete, cTodo, cChannel, cChannelAdd, cChannelUpdate, cChannelMoveTo, 
  cChannelDetail, cChannelDelete, cChannelCount,auditionSignin, auditionNoShow , cLeads , cLeadsTransfer, cLeadsChangeLog, cLeadsFollowLog, cLeadsFollow, cLeadsAdd, cCustomerRefresh,
  cLeadsUpdate, cLeadsUpload,cLeadsDetail, cLeadsDelete, cLeadsTransform, cWorkbench , cWorkbenchAchievement , cWorkbenchFunnel , cCustomer, cCustomerFollow, cCustomerFollowLog, cCustomerChangeLog, cCustomerDetail, 
  cCustomerUpdate, cCustomerUpload , cCustomerExample, cCustomerErrorFile, cCustomerReceive, cCustomerTransfer, cCustomerDelete, cCustomerContract, cCustomerPool, cCustomerPoolReceive, cCustomerPoolTransfer, 
  cWorkbenchSale, todoToday, todoTodayLeads, todoLeads, todoCustomer, todoContract, todoRemindCustomer,todoCount,cContract,cInviteAttend,cInviteAttendAttend,
  cInviteAttendNoShow,cCustomerContact,cCustomerAddContact,cCustomerAdd,cCustomerTaskUpdate,cCustomerStudentAccount,cCustomerContactDetail,cCustomerUpdateContact,
  cCustomerAudition,cCustomerExport,cCustomerPoolExport,cContractExport,cCustomerPutinPool,auditionSchedule,auditionArrange,achievementExport,cLeadsTaskUpdate,cChanneExport,
  cWorkbenchHeatmap,cInviteAttendExport,cCustomerStar,cLeadsRenew,cLeadsStar,cCustomerRenew,cChannelLevel,cLeadsBoard,cFollowHeatmap,cCustomerWxworkMsg,
  cCustomerWxworkContact,missionSingle,missionAddSingle,missionSyncSingle,missionGroup,missionAddGroup,missionSyncGroup,missionMoment,missionSyncMoment,
  missionUploadmedia,missionMomentUserList,missionMomentLikes,missionMomentComments,missionSingleCount,missionSingleUserList,missionGroupList,settingCrmCustomerConfig,
  settingCrmCustomerConfigAdd,settingCrmCustomerConfigUpdate,settingCrmCustomerConfigDelete,cContractReminder,cContractReminderExport,cFollowEvent,cFollowEventAdd,studioAchievementExport,
  cTransfer,cTransferExport,cChangeLog,cCustomerStartEvent} from '@/api/crm'
import config from '@/utils/const'

export default {
  state: {

  },
  mutations: {
  },
  actions: {
    achievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        achievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studioAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    studioAchievementExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioAchievementExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    achievemenUpdatetAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        achievementUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    studioAchievementUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        studioAchievementUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    achievementExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        achievementExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmConfigAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmConfig(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmConfigUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmConfigUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmCustomerConfigAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmCustomerConfig(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmCustomerConfigAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmCustomerConfigAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmCustomerConfigUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmCustomerConfigUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    settingCrmCustomerConfigDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        settingCrmCustomerConfigDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    achievementChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        achievementChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        audition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionBookAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionBook(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionDemoScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionDemoSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionPenddingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionPendding(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionCancelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionCancel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionSignAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionSign(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionCancelDemoClassAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionCancelDemoClass(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionDemoTimeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionDemoTime(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionResendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionResend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionAddDemoAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionAddDemo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionAddDemoScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionAddDemoSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionDemoArrageAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionDemoArrage(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        client(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientAssignAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientAssign(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientPenddingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientPendding(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientChangeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientChangeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientFollowLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientFollowLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientStarAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientStar(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientNoneWillAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientNoneWill(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientTodayAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientToday(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientMultiSettingAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientMultiSetting(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientMultiExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientMultiExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientMultiActionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientMultiAction(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    clientDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        clientDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    crmBriefAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        crmBrief(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    todoTodayAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoToday(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    todoTodayLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoTodayLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    todoCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    todoLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    todoCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    todoContractAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoContract(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    todoRemindCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        todoRemindCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cWorkbenchAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cWorkbench(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cWorkbenchAchievementAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cWorkbenchAchievement(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cWorkbenchFunnelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cWorkbenchFunnel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cWorkbenchSaleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cWorkbenchSale(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cWorkbenchHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cWorkbenchHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeads(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsBoardAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsBoard(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsChangeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsChangeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsFollowLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsFollowLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsTransformAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsTransform(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsTaskUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsTaskUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsRenewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsRenew(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cLeadsStarAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsStar(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerFollow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerLostAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerLost(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerFollowLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerFollowLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerChangeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerChangeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerRefreshAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerRefresh(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerExample(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerErrorFileAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerErrorFile(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerReceiveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerReceive(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerContractAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerContract(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerTaskUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerTaskUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerStudentAccountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerStudentAccount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerPutinPoolAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerPutinPool(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerStarAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerStar(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerRenewAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerRenew(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cCustomerWxworkContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerWxworkContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerWxworkMsgAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerWxworkMsg(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cCustomerStartEventAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerStartEvent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerAddContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerAddContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerContactDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerContactDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerUpdateContactAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerUpdateContact(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerAuditionAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerAudition(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerPoolAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerPool(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerPoolReceiveAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerPoolReceive(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerPoolTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerPoolTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cCustomerPoolExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cCustomerPoolExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cContractAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cContract(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cContractExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cContractExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cContractReminderAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cContractReminder(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cContractReminderExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cContractReminderExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cInviteAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cInviteAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cInviteAttendAttendAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cInviteAttendAttend(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cInviteAttendNoShowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cInviteAttendNoShow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cInviteAttendExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cInviteAttendExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cImportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cImport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsExampleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsExample(params).then(response => {
          const content = response
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsExport(params).then(response => {
          const content = response
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cLeadsErrorFileAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cLeadsErrorFile(params).then(response => {
          const content = response
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cImportUploadAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cImportUpload(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cImportDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cImportDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cFollowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollow (params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cFollowExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    cFollowHeatmapAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowHeatmap(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cFollowDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cFollowDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cFollowEventAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowEvent(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cFollowEventAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cFollowEventAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cTodoAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cTodo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cTransferAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cTransfer(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cTransferExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cTransferExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    cChangeLogAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChangeLog(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChangeLogExportAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChangeLogExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelExpoortAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChanneExport(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },


    cChannelAddAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelAdd(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelUpdateAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelUpdate(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelMoveToAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelMoveTo(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelDetailAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelDetail(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelDeleteAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelDelete(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    cChannelLevelAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        cChannelLevel(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionSigninAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionSignin(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionNoShowAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionNoShow(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionScheduleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionSchedule(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    auditionArrangeAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        auditionArrange(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionSingleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSingle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionSingleCountAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSingleCount(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionAddSingleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionAddSingle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionSyncSingleAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSyncSingle(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    missionUploadmediaAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionUploadmedia(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionAddGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionAddGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionSyncGroupAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSyncGroup(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionMomentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionMoment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionSyncMomentAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSyncMoment(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    missionMomentUserListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionMomentUserList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionMomentLikesAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionMomentLikes(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },

    missionMomentCommentsAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionMomentComments(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    missionSingleUserListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionSingleUserList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
    
    missionGroupListAction({ commit }, params) {
      return new Promise((resolve,reject) => {
        missionGroupList(params).then(response => {
          resolve(response)
        }).catch(reason => {
          console.log(reason)
          reject(reason)
        })
      })
    },
  }
}
