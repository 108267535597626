import {asyncRouterMap} from '@/router'

const permission = {
    state:{
        addRouters: [],
        adminRouter:[],
        marketRouter:[],
        settingRouter:[],
        studentsRouter:[],
        financeRouter:[],
        analysisRouter:[],
        customerRouter:[],
        educationRouter:[],
        enterpriseRouter:[],
        orginizationRouter:[],
        workserviceRouter:[],
        RSSRouter:[],
        menusKey:0,
        routesMenus:[
            {
              title:'工作台',
              parentKey:'work',
              icon: '#icon-SchemeOutline',
              routerName:"adminRouter",
              router:[],
            },
            {
              title:'招生咨询',
              parentKey:'crm',
              icon: '#icon-CoverageOutline',
              routerName:"customerRouter",
              router:[],
            },
            {
                title:'学员中心',
                parentKey:'students',
                icon: '#icon-PersonalOutline',
                routerName:"studentsRouter",
                router:[],
            },
            {
              title:'教务中心',
              parentKey:'education',
              icon: '#icon-EnshrineOutline',
              routerName:"educationRouter",
              router:[],
            },
            {
              title:'市场应用',
              parentKey:'market',
              path:'/market/index',
              icon: '#icon-CollectOutline',
              routerName:"marketRouter",
              router:[],
            },
            {
              title:'校区管理',
              parentKey:'orginization',
              path:'/orginization/index',
              icon: '#icon-HomeOutline',
              routerName:"orginizationRouter",
              router:[],
            },
            {
              title:'商业智能',
              parentKey:'bi',
              icon: '#icon-OperationOutline',
              routerName:"analysisRouter",
              router:[],
            },
            {
              title:'财务中心',
              parentKey:'finance',
              icon: '#icon-MoneyOutline',
              routerName:"financeRouter",
              router:[],
            },
            {
              title:'通用设置',
              parentKey:'manager',
              icon: '#icon-SetOutline',
              routerName:"settingRouter",
              router:[],
            },
            {
              title:'企业管理',
              parentKey:'enterprise',
              icon: '#icon-DiamondOutline',
              routerName:"enterpriseRouter",
              router:[],
            },
            {
              title:'工单服务',
              parentKey:'workservice',
              icon: '#icon-CodeOutline',
              routerName:"workserviceRouter",
              router:[],
            },
            // {
            //   title:'RSS',
            //   parentKey:'labs',
            //   icon: '#icon-rss',
            //   routerName:"RSSRouter",
            //   router:[],
            // }
        ],
    },
    mutations:{
        SET_ROUTERS(state, data){
            state.addRouters = data
            data.forEach((item)=>{
                state[item.parent] = item
            })
        },
        SET_ROUTERSMENUS(state, data){
            state.routesMenus.forEach(item=>{
                item.router = state[item.routerName]
                if(!item.path){
                    if(state[item.routerName]['children']){
                        let routerItem = state[item.routerName]['children']
                        if(routerItem[0].children){
                            item.path = `/${routerItem[0].meta.parent}/${routerItem[0].path}/${routerItem[0].children[0].path}`
                        }else{
                            item.path = `/${routerItem[0].meta.parent}/${routerItem[0].path}`
                        }
                        
                    }
                }
            })
            // console.log(state.routesMenus,'这是设置路由按钮')
        },
        SET_MENUSKEY(state, data){
            state.menusKey = data
        }
    },
    actions:{
        GenerateRoutes(context,data){
            let accessedRouters = filterAsyncRouter(asyncRouterMap,data)
            // console.log(accessedRouters)
            let redirectItem = ''
            accessedRouters.forEach(item=>{
                if(!redirectItem){
                    redirectItem = item
                }else{
                    if(redirectItem.meta.menusKey > item.meta.menusKey){
                        redirectItem = item
                    }
                }
            })
            let redirect = `${redirectItem.path}/${redirectItem.children[0].path}`
            accessedRouters.push({
                path: '/',
                redirect: redirect,
            })
            context.commit("SET_MENUSKEY",redirectItem.meta.menusKey)
            context.commit("SET_ROUTERS",accessedRouters)
            context.commit("SET_ROUTERSMENUS")
        }
    }
}

const filterAsyncRouter = function(routers,authInfo){
    const res = []
    routers.forEach(router => {
        const tmp = {
        ...router
        }
        if(authInfo[tmp.meta.type]){
            tmp.children = checkAuth(tmp.children, authInfo[tmp.meta.type])
            res.push(tmp)
        }
    })
    return res
}

const checkAuth = function(routers, authInfo){
    const res = []
    routers.forEach(item=>{
        if(!item.meta.requiresAuth){
            res.push(item)
        }else{
            if(authInfo[item.meta.type]){
                res.push(item)
            }
        }
    })
    return res
}

export default permission